// Root Reducer
// store/reducers/index.js 
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import authUserReducer from './authUser';
import questionsReducer from './questionsReducers';
import statsReducer from './statsReducer';
import { buttonReducer } from './reducers';
 
export const appReducer = combineReducers({
	auth: authReducer,
	authUser: authUserReducer,
	questions: questionsReducer,
	stats: statsReducer,
	button: buttonReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT') {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};
export default rootReducer;
