import firebase from '../config/firebase';
import {saveReferrerToLocalStorage, checkReferrerLocalStorage} from '../helpers/referrerStorageUtils';

export const updateReferredByArrayInFirestore = async (uid, refArray)  =>{
    if (!uid) {
      console.error('somehow uid param is false in this func')
      return false;
    }
    const userRef = firebase.firestore().collection('users').doc(uid);
    try {
      await userRef
        .update({
          referredBy:refArray
        }) 
          console.log('Profile db successfully updated with referrer');
       
    } catch (err) {
        console.error(err);
    }
};

export const whoIsTheReferrer = async () => {

    let referrerData = checkReferrerLocalStorage();

    if (!referrerData || referrerData.length===0) {
        return null;
    }
    
        const userRef1 = await firebase.firestore().collection('referredBy').doc(referrerData[0]);
        userRef1.get();

    if (referrerData.length===2) {
        const userRef2 = await firebase.firestore().collection('referredBy').doc(referrerData[1]);
        userRef2.get();
    }
    if (referrerData.length===3) {
        const userRef3 = await firebase.firestore().collection('referredBy').doc(referrerData[2]);
        userRef3.get();
    }

};