import { part5Questions } from "helpers/GlobalConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getQuestions } from "store/actions/QuestionsAction";
import { addSessions } from "store/actions/QuestionsAction";
import { updateButtonPercent } from 'store/actions/actions.js';
import VisualRewards from "../../components/VisualRewards/VisualRewards";
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';

const PersonalityTestPart5 = () => {
 
  const [showResult, setShowResult] = useState(false);
  const [scoreAfterSummarize, setScoreAfterSummarize] = useState({
    summary: {
      mainTrait: 'NA',
      secondaryTrait: 'NA',
      mainNegativeTrait: 'NA',
      secondaryNegativeTrait: 'NA',
      percentCompleted: 0,
    }
  });
  const { uid } = useSelector((state) => state.authUser);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (uid) {
      dispatch(getQuestions(uid, "part5"));
    }
  }, []);

  const { questions } = useSelector((state) => state.questions);
  
  const savedResults = localStorage.getItem("part5") ? 
  JSON.parse(localStorage.getItem("part5")) : null;

  const [loveLanguage, setLoveLanguage] = useState(
    savedResults  || {
      loveLanguage1: "",
      loveLanguage2: "",
    }
  );


  useEffect(() => {
    localStorage.setItem("part5", JSON.stringify(loveLanguage));

    setUpScoreObject();

    window.showVariables = () => {
      return {
        loveLanguage,
        showResult,
        scoreAfterSummarize

      };
    }

  }, [loveLanguage]);


  const setUpScoreObject = () =>{
    

    if (loveLanguage.loveLanguage1!=='NA') {
      const summary = {
        mainTrait: loveLanguage.loveLanguage1.value,
        secondaryTrait: loveLanguage.loveLanguage2.value,
        mainNegativeTrait: 'NA',
        secondaryNegativeTrait: 'NA',
        percentCompleted: 100,
      };
      const newScoreWithSummary = {
        ...loveLanguage,
        summary
      };
      console.log('newScoreWithSummary =',newScoreWithSummary );
      setScoreAfterSummarize(newScoreWithSummary);
      setShowResult(true);
    } else {
      setScoreAfterSummarize(newScoreWithSummary);
      setShowResult(false);
    }

  };

  useEffect(() => {
    if (questions?.part5.loveLanguage1) {
      setLoveLanguage(questions?.part5);
    }
  }, [questions?.part5]);

  const history = useHistory();

  const updatePreviousLoveLanguage = (prev, value, options, selectedOption, name) => {
    return {
      ...prev,
      [name]: { value, label: selectedOption.text },
    };
  };
  
  const handleSelect = (event) => {
    const { value, options } = event.target;
    const selectedOption = options[event.target.selectedIndex];
    const { name } = event.target;

    const newState = updatePreviousLoveLanguage(loveLanguage, value, options, selectedOption, name);

    setLoveLanguage(newState);

    if (newState.loveLanguage1 !== "" || newState.loveLanguage2 !== "") {
      // if they put one in, that's good enough.
      dispatch(updateButtonPercent("lovelang", 100));
    }
  };
  /*
  const traitMap = {
  'big5': 'part1',
  'mnp':  'part2',
  'bb':   'part3', // hcl
  'comm': 'part4',
  'lovelang': 'part5',
  'mdq':  'part6'
};*/



  const handleSaveData = (e) => {
    e.preventDefault();
    
    const summaryAddedToScore = {
      summary : { 
        mainTrait: loveLanguage.loveLanguage1.value,
        secondaryTrait: loveLanguage.loveLanguage2.value,
        mainNegativeTrait: '',
        secondaryNegativeTrait: '',
        percentCompleted: 100 // Math.floor(50/50 * 100) don't for get to dynamically update 
      }
    };
    
    console.log('loveLanguage=', loveLanguage , 'summaryAddedToScore', summaryAddedToScore); 
    if (uid) {
      dispatch(addSessions(loveLanguage, "part5", uid, summaryAddedToScore));
    } else {
      alert('Please log in to save your results');
      history.push("/admin/home");
    }
  };



  return (
    <>
    <Form>
      <Row className="personality-test-part">
        <Col md="12 py-4">
          <div className="text-center my-3">
          <h2 className='test-title'>Love Language</h2>
          </div>

          <div className="text-center my-3">
            <h4>
              Do the Love Language test at<br/>
              <Link to="https://5lovelanguages.com/quizzes/love-language"> 5lovelanguages.com/quizzes/love-language</Link> 
             
            </h4>
          </div>
          <div className="text-center my-3">
            <h4>Then Select your top 2 love languages here.</h4>
          </div>

          <div>
            <div>
              <Row className="px-lg-4 px-md-4 px-0">
                <Col sm="12" xs="12">
                  <Row className="mt-1">
                    <Col>
                      <div>
                        <Label className='d-none'>
                          <b>Your Love Language</b>
                        </Label>
                        <div>
                          <Input
                            type="select"
                            className="personality-test-part__input-field"
                            name="loveLanguage1"
                            onChange={handleSelect}
                            value={loveLanguage.loveLanguage1.value}
                          >
                            {part5Questions?.map((option, index) => {
                              return (
                                <option key={index} value={option?.value}>
                                  {option?.label}
                                </option>
                              );
                            })}
                          </Input>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>
                      <div>
                        <div>
                          <Input
                            type="select"
                            className="personality-test-part__input-field"
                            name="loveLanguage2"
                            onChange={handleSelect}
                            value={loveLanguage.loveLanguage2.value}
                          >
                            {part5Questions?.map((option, index) => {
                              return (
                                <option key={index}  value={option?.value}>
                                  {option?.label}
                                </option>
                              );
                            })}
                          </Input>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className='d-flex justify-content-center'> 
                  <Button
                    className="personality_see_and_save_btn  my-2"
                    onClick={handleSaveData} 
                  >
                    Save & See Results
                  </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {scoreAfterSummarize?.summary?.secondaryTrait!==undefined && (
              <VisualRewards 
              isShowAnimations={showResult}
              part="part5"
              mainTrait={scoreAfterSummarize?.summary?.mainTrait}
              secondaryTrait={scoreAfterSummarize?.summary?.secondaryTrait}
              />
            )}
             <FriendlyErrMsgBox/>
 
          </div>
        </Col>
      </Row>
    </Form>
    </>
  );
};

export default PersonalityTestPart5;
