import seal3 from '../assets/img/icons/certified-seal-referee@2x.png';
import icon1 from '../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png';
import icon2 from '../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png';
import icon3 from '../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-green-heart-icon-1-1@2x.png';
import icon4 from '../assets/img/icons/speak-emoj.png';
import icon5 from '../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png';
import icon6 from '../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png';
import icon7 from '../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-green-heart-icon-1-1@2x.png';
import icon8 from '../assets/img/icons/speak-emoj.png';

//lovelang
import llGifts    from '../assets/img/icons/webm/Gifts.webm';
import llTime     from '../assets/img/icons/webm/Quality Time.webm';
import llTouch    from '../assets/img/icons/webm/Touch.webm';
import llService  from '../assets/img/icons/webm/Act Of Service.webm';
import llWords    from '../assets/img/icons/webm/Words.webm';

//big5 


import b5Agree         from '../assets/img/icons/webm/Agreeablenesse.webm';
import b5Conscientious from '../assets/img/icons/webm/conscientiousness.webm';
import b5Extraversion  from '../assets/img/icons/webm/Extraversion.webm';
import b5Neurotic      from '../assets/img/icons/webm/Neurotic.webm';
import b5Open          from '../assets/img/icons/webm/Openness.webm';
import b5Mixed         from '../assets/img/icons/webm/Mixed.webm';
import b5Introversion  from '../assets/img/icons/webm/Intraversion.webm';


//comm
import commVision   from '../assets/img/icons/webm/Vision-Idea.webm';
import commAction   from '../assets/img/icons/webm/Action-Oriented.webm';
import commPeople   from '../assets/img/icons/webm/PeopleOriented.webm';
import commProcess  from '../assets/img/icons/webm/Process-Oriented.webm';

// import fetchPersonalityResults = helpers/fetchPersonalityResults.js
// Define the function
import firebase from "../config/firebase";
import { defaultButtonData } from "./defaultButtonData";

 
import iconResultsllTouch from '../assets/img/icons/webm/2 fingers.webm';
import iconWakandaCoins1 from '../assets/img/icons/webm/Coin Explosion 1.webm';
import iconWakandaCoins2 from '../assets/img/icons/webm/Coin Explosion 2.webm';
import iconWakandaCoins3 from '../assets/img/icons/webm/Coin Explosion 3.webm';

import iconResultsMNP1 from '../assets/img/icons/webm/peaceful beach icon [1].webm';
import iconResultsMNP2 from '../assets/img/icons/webm/peaceful beach icon [2].webm';
import iconResultsMNP3 from '../assets/img/icons/webm/peaceful beach icon [3].webm';
// use mnp1 1 and 2 for bipolar as well.
import iconResultsMNP0 from '../assets/img/icons/webm/peaceful beach icon.webm';
import iconResultsStars from '../assets/img/icons/webm/Stars.webm';
import iconResultsBB2 from '../assets/img/icons/webm/stormy beach icon.webm'; // for bp 2 


/**
 * mapTraitResults is organized as 
 * mapTraitResults[test category][trait] 
 * import { mapTraitResults , categoryByPartMap } from src/helpers/fetchPersonalityResults.js
 * mapTraitResults[categoryByPartMap[part]][mainTrait].iconResults
 */
export const mapTraitResults = {
  'big5': { 
    A: {iconResults: b5Agree,         iconAlt: 'Agreeableness',     part:'part1'},
    C: {iconResults: b5Conscientious, iconAlt: 'Conscientiousness', part:'part1'},
    E: {iconResults: b5Extraversion,  iconAlt: 'Extroverted',       part:'part1'},
    N: {iconResults: b5Neurotic,      iconAlt: 'Neuroticism',       part:'part1'},
    O: {iconResults: b5Open,          iconAlt: 'Oppenness',         part:'part1'},
    M: {iconResults: b5Mixed,         iconAlt: 'Mixed',             part:'part1'},
    I: {iconResults: b5Introversion,  iconAlt: 'Introverted',       part:'part1'},
  },
  'mnp':  { 
    'clear':          { iconResults: iconResultsMNP0,  iconAlt: 'clear', part:'part2'},
    'zscoreMACH':     { iconResults: iconResultsMNP1,  iconAlt: 'mach',  part:'part2'},
    'zscoreNARC':     { iconResults: iconResultsMNP1,  iconAlt: 'psych', part:'part2'},  
    'zscorePSYCH':    { iconResults: iconResultsMNP1,  iconAlt: 'narc',  part:'part2'},    
    'zscoreMACH,zscoreNARC': { iconResults: iconResultsMNP2,  iconAlt: 'mach,narc', part:'part2'},
    'zscoreMACH,zscoreNARC,zscorePSYCH': { iconResults: iconResultsMNP3,  iconAlt: 'mach,narc,psych', part:'part2'},  
  },
  'bb': {
    positivehcl: { iconResults: iconResultsMNP1,  iconAlt: 'positive hcl ', part:'part3'},
    negativehcl: { iconResults: iconResultsMNP0,  iconAlt: 'negative hcl', part:'part3'}, //blue = calm
  }, // hcl
  'comm': { 
    Action:  { iconResults: commAction,   iconAlt: 'Action-oriented communicator', part:'part4'},
    Process: { iconResults: commProcess,  iconAlt: 'Process-oriented communicator', part:'part4'},
    People:  { iconResults: commPeople,   iconAlt: 'People-oriented communicator', part:'part4'},
    Ideas:   { iconResults: commVision,   iconAlt: 'Idea-oriented communicator', part:'part4'},
  },
  'lovelang': {
    "Acts of Service": { iconResults:      llService,      iconAlt: 'Acts of Service', part:'part5'},
    "Gift-Giving":    { iconResults:       llGifts,        iconAlt: 'Thoughtful Gifts', part:'part5'},
    "Physical Touch": { iconResults:       iconResultsllTouch,        iconAlt: 'Physical Touch', part:'part5'},
    "Quality Time":   { iconResults:       llTime,         iconAlt: 'Quality Time', part:'part5'},
    "Words of Affirmation": { iconResults: llWords,  iconAlt: 'Words of Affirmation', part:'part5'},
  },

  'mdq':  { 
    positiveMDQ: { iconResults: iconResultsBB2,  iconAlt: 'positive mdq', part:'part6'},
    negativeMDQ: { iconResults: iconResultsMNP0,  iconAlt: 'negative mdq', part:'part6'}, //blue = calm
 }
};
export const categoryByPartMap = {
  'part1': 'big5' ,
  'part2': 'mnp',
  'part3': 'bb', // hcl
  'part4': 'comm',
  'part5': 'lovelang',
  'part6': 'mdq'
};
export const traitMap = {
  'big5': 'part1',
  'mnp':  'part2',
  'bb':   'part3', // hcl
  'comm': 'part4',
  'lovelang': 'part5',
  'mdq':  'part6'
};
export const getIconInfo = (part, traitName) => {
  let buttonId = categoryByPartMap[part];
  let iconObj = mapTraitResults[buttonId]?.[traitName];
  return iconObj || { iconResults: '',  iconAlt: ''};
};

// helpers/fetchPersonalityResults.js 

/**
 * @param {Array} buttonData 
 * @param {Array} results 
 * @returns 
 */
const updateButtonData = (buttonData, results) => {

  if ( !Array.isArray(buttonData) || buttonData.length<5 ) {
    console.error('const updateButtonData = (buttonData, results) expected param buttonData to be an array length greater than 5.');
    return defaultButtonData;
  }
  if (results) {

    console.log('in const updateButtonData with results param =', results);
    // Do something with the results
    buttonData.forEach(function (button, index) {
      const part = traitMap[button.id];
      console.log('in const updateButtonData  part=',  part, 'results?.[part]?.score?.summary = ', results?.[part]?.score?.summary);
      if (part) {
        const percentCompleted = results?.[part]?.score?.summary?.percentCompleted || 0;
        buttonData[index].percentCompleted = percentCompleted;
 
        if (buttonData[index].percentCompleted===100) {
          const obIconInfo = getIconInfo(part, results?.[part]?.score?.summary?.mainTrait);
          buttonData[index].iconResults = obIconInfo.iconResults; 
          buttonData[index].iconAlt = obIconInfo.iconAlt;
        } 
      }
    }); 
    console.log('modifications were made to buttonData by fetchpersonalityResutls ', buttonData);
    return buttonData;
  } 
    console.error('Un Expected error  personalitytest data param results was empty returning buttonData without modifications ');
    return buttonData;
};

const fetchPersonalityResults = async (uid, buttonData = null) => {
  console.log('inside fetchPersonalityResults with =',uid, buttonData);
  let personalityResults = {};
  try {
    const sessionsRef = firebase.firestore().collection("sessions");
    const snapshot = await sessionsRef
      .where("userId", "==", uid)
     // .where("active", "==", true)
      .get();

    if (!snapshot.empty) {
      const sessionId = snapshot.docs[0].id;
      const resultsCollection = await firebase
        .firestore()
        .collection("sessions")
        .doc(sessionId)
        .collection("results")
        .get();
        // /sessions/8abbe417-015a-4d59-9376-a0f486dff188/results

      if (!resultsCollection.empty) {
        console.log('resultsCollection=',resultsCollection);
        resultsCollection.forEach(doc => {
          const part = doc.id; // Assume doc.id is 'part1', 'part2', etc.
          personalityResults[part] = doc.data();
        });

        console.log('buttonData && personalityResult=',buttonData, ' \n ', personalityResults);
        if ( buttonData && Object.keys(personalityResults).length > 0) {
          buttonData = updateButtonData(buttonData, personalityResults);
        }

        return { status: 'success', personalityResults, updatedButtonData: buttonData };

      } else {
        console.log("No results found.");
        return { status: 'error', error: 'No results found.' };
      }
    } else {
      console.log("No active session found.");
      return { status: 'error', error: 'No active session found.' };
    }
  } catch (error) {
    console.error("Error fetching results:", error);
    return { status: 'error', error: 'Error fetching results:' };
  }
};
export default fetchPersonalityResults;