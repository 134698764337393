// src/helpers/validateJson.js
// import { validateLocalStorageData } from "../../helpers/validateJson";
export const validateLocalStorageData = (localData, defaultData) => {
    if (!Array.isArray(localData) || !Array.isArray(defaultData)) {
      // Handle this case appropriately; maybe throw an error or return defaultData
      return defaultData;
    }
  
    if (localData.length !== defaultData.length) {
      return defaultData;
    }
  
    return localData.map((item, index) => {
      const defaultItem = defaultData[index];
      if (!defaultItem) {
        return item;
      }
  
      const newItem = { ...item }; // Create a copy of the item so we're not modifying the original
  
      for (const key of Object.keys(defaultItem)) {
        if (newItem[key] === undefined) {
          newItem[key] = defaultItem[key];
        }
      }
      return newItem;
    });
  };
  