import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from "react";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const MatchingLocation = ({radius, setRadius, city, setCity}) => {


  const handleRadiusChange = (values) => {
    setRadius(values);
  };

  const customTipProps = {
    visible: true, // Set the tooltip to always be visible.
    placement: "bottom", // Change the tooltip's placement (top, bottom, left, right).
    overlayStyle: {
      fontSize: 9,
      backgroundColor: "transparent",
      color: "white",
    }, // Custom styles for the tooltip.
  };

  return (
    <div className="gender">
      <h3 style={{ marginBottom: "0px" }}>Match-making Location</h3>
      <div className="matching-location">
        <p>
          Type city/cities where you would like to meet for our
          public group events; Select the radius around that city/cities.
        </p>
        <div>
          <input type="text" placeholder="Type your city/cities." value={city} onChange={(e)=>setCity(e.target.value)} />
          <div className="radius-range-wrapper">
            <span>Select a radius</span>
            <Range
              min={0}
              max={1000}
              defaultValue={[45, 100]}
              tipFormatter={(value) => `${value} mile`}
              onChange={handleRadiusChange}
              tipProps={customTipProps}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchingLocation;
