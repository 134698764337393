import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import eyeslash from '../../assets/img/icons/common/eye-slash.png';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Spinner,
} from 'reactstrap';
import { signInWithEmail_Password } from 'store/actions/authActions';
import { EmailCheck } from 'store/actions/authActions';
import { signUpWithEmail_Password } from 'store/actions/authActions';

const SignInEmailModal = props => {
	// const [email, setEmail] = useState("");
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();

	const [result, setResult] = useState('');
	// const [userName, setUserName] = useState("");
	const [userData, setUserData] = useState({
		email: '',
		userName: '',
	});
	console.log('userData', userData);
	const [modalBody, setModalBody] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [image, setImage] = useState(false);

	const handlePasswordVisibility = () => {
		setShowPassword(!showPassword);
		setImage(!image);
	};
	const userCred = e => {
		setUserData(obj => ({
			...obj,
			[e.target.name]: e.target.value,
		}));
	};
	const { userFriendlyMsg, loading, loginLoading } = useSelector(state => state.auth);

	const history = useHistory();
	return (
		<>
			<Modal
				isOpen={props.modal}
				// toggle={props.toggle}

				// centered={true}
				className='px-0'
			>
				{modalBody ? (
					<ModalBody className='px-2 '>
						<div className='text-center'>
							<h2 className='w-100 text-center mt-3'>
								Sign in With Email
							</h2>
						</div>
						<Form
							onSubmit={e => {
								e.preventDefault();
								dispatch(
									EmailCheck(userData, res => {
										setResult(res);
										setModalBody(false);
									})
								);
							}}
						>
							<Row className='px-5'>
								<Col>
									<FormGroup>
										<Label>Email</Label>
										<Input
											required
											name='email'
											placeholder='Enter Email'
											type='email'
											onChange={e => userCred(e)}
										></Input>
									</FormGroup>
								</Col>
							</Row>
							<Row className='px-5'>
								<Col className='d-flex justify-content-end'>
									<Button
										className='modal__button mt-5 '
										onClick={props.toggle}
									>
										Cancel
									</Button>
									<Button
										className='modal__button mt-5 '
										type='submit'
									>
										Next
									</Button>
								</Col>
							</Row>
						</Form>
					</ModalBody>
				) : (
					<>
						{result == 'notExist' ? (
							<>
								<ModalBody className='px-2 '>
									<div className='text-center'>
										<h2 className='w-100 text-center mt-3'>
											Create Account
										</h2>
									</div>
									<Form
										onSubmit={e => {
											e.preventDefault();
											dispatch(
												signUpWithEmail_Password(
													userData,
													password,
													() => {
														setResult(' ');
														setModalBody(true);
														setUserData('');
														// props.toggle();
														setPassword('');
														// history.push("/admin/user-profile");
													}
												)
											);
										}}
									>
										<Row className='px-5'>
											<Col>
												<FormGroup>
													<Label>Email</Label>
													<Input
														required
														name='email'
														value={userData.email}
														placeholder='Enter Email'
														type='email'
														onChange={e =>
															userCred(e)
														}
													></Input>
												</FormGroup>
											</Col>
										</Row>
										<Row className='px-5'>
											<Col>
												<FormGroup>
													<Label>UserName</Label>
													<Input
														required
														name='userName'
														placeholder='Enter UserName'
														type='text'
														onChange={e =>
															userCred(e)
														}
													></Input>
												</FormGroup>
											</Col>
										</Row>
										<Row className='px-5'>
											<Col>
												<FormGroup>
													<Label> Password</Label>
													<Input
														required
														type={
															showPassword
																? 'text'
																: 'password'
														}
														id='password'
														name='password'
														value={password}
														onChange={e =>
															setPassword(
																e.target.value
															)
														}
													></Input>

													<div
														className='float-right auth_icon mr-2'
														onClick={
															handlePasswordVisibility
														}
													>
														{image ? (
															<svg
																viewBox='64 64 896 896'
																focusable='false'
																class=''
																data-icon='eye'
																width='1.5em'
																height='1.5em'
																fill='currentColor'
																aria-hidden='true'
															>
																<path
																	d='M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z'
																	fill='#E6F7FF'
																></path>
																<path
																	d='M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z'
																	fill='#E6F7FF'
																></path>
																<path
																	d='M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z'
																	fill='#1890FF'
																></path>
																<path
																	d='M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z'
																	fill='#1890FF'
																></path>
															</svg>
														) : (
															<img
																src={eyeslash}
																alt=''
															/>
														)}
													</div>
												</FormGroup>
											</Col>
										</Row>
										<Row className='px-5'>
											<Col className='d-flex justify-content-end'>
												<Button
													className='modal__button mt-5 '
													onClick={() => {
														props.toggle();
														setModalBody(true);
														setResult('');
														setUserData('');
														setPassword('');
													}}
												>
													Cancel
												</Button>
												<Button
													className='modal__button mt-5 '
													type='submit'
													disabled={loading}
												>
													{loading ? (
														<Spinner size='md' />
													) : (
														'Sign Up'
													)}
												</Button>
											</Col>
										</Row>
									</Form>
								</ModalBody>
							</>
						) : (
							<>
								<ModalBody className='px-2 '>
									<div className='text-center'>
										<h2 className='w-100 text-center mt-3'>
											Sign In
										</h2>
									</div>
									<Form
										onSubmit={e => {
											e.preventDefault();
											dispatch(
												signInWithEmail_Password(
													userData,
													password,
													history,
													() => {
														setResult('');
														setModalBody(true);
														props.toggle();
													}
												)
											);
										}}
									>
										<Row className='px-5'>
											<Col>
												<FormGroup>
													<Label>Email</Label>
													<Input
														required
														name='email'
														value={userData.email}
														placeholder='Enter Email'
														type='email'
														onChange={e =>
															userCred(e)
														}
													></Input>
												</FormGroup>
											</Col>
										</Row>
										<Row className='px-5'>
											<Col>
												<FormGroup>
													<Label> Password</Label>
													<Input
														required
														type={
															showPassword
																? 'text'
																: 'password'
														}
														id='password'
														name='password'
														value={password}
														onChange={e =>
															setPassword(
																e.target.value
															)
														}
													></Input>

													<div
														className='float-right auth_icon mr-2'
														onClick={
															handlePasswordVisibility
														}
													>
														{image ? (
															<svg
																viewBox='64 64 896 896'
																focusable='false'
																class=''
																data-icon='eye'
																width='1.5em'
																height='1.5em'
																fill='currentColor'
																aria-hidden='true'
															>
																<path
																	d='M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z'
																	fill='#E6F7FF'
																></path>
																<path
																	d='M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z'
																	fill='#E6F7FF'
																></path>
																<path
																	d='M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z'
																	fill='#1890FF'
																></path>
																<path
																	d='M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z'
																	fill='#1890FF'
																></path>
															</svg>
														) : (
															<img
																src={eyeslash}
																alt=''
															/>
														)}
													</div>
												</FormGroup>
											</Col>
										</Row>
										<Row className='px-5'>
											<Col className='d-flex justify-content-end'>
												<Button
													className='modal__button mt-5 '
													onClick={() => {
														setModalBody(true);
														setResult('');
														setUserData('');
														setPassword('');
														props.toggle();
													}}
												>
													Cancel
												</Button>
												<Button
													className='modal__button mt-5 '
													type='submit'
													disabled={loginLoading}
												>
													{loginLoading ? (
														<Spinner size='md' />
													) : (
														'Sign In'
													)}
												</Button>
											</Col>
										</Row>
									</Form>
								</ModalBody>
							</>
						)}
					</>
				)}
			</Modal>
		</>
	);
};

export default SignInEmailModal;
