import { mainPageHeroImgs } from 'helpers/GlobalConstants';
import Slider from 'react-slick';
import './heroSlider.scss';

const HeroSlider = () => {

    const settings = {
      dots: false,
      infinite: true,
      fade: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
        <Slider {...settings}>
          {mainPageHeroImgs.map((slide, index) => (
            <div key={index}>
              <div
                className="hero-slider-item"
                style={{ backgroundImage: `url(${slide?.imageUrl})` }}
              ></div>
            </div>
          ))}
       </Slider>
    );
};

export default HeroSlider;