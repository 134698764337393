import unlockToExchangeCoins from '../../assets/images/wakanda-coin-.png';
import unlockToSeeJewelry from '../../assets/images/transparent-heart-shaped-diamond-.png';
import unlockToSeeYourMatch from '../../assets/images/diamond-orange-200.png';
import unlockTheTVShowInvitation from '../../assets/images/tv-show-gogExperience_An_African_loving_happy_couple_with_an_African_ca_bd8c21c0-2788-4374-8808-7197600d7cfc.png';
import unlockTheTVShow from '../../assets/images/tv-show-gogExperience_An_African_loving_happy_couple_with_an_African_ca_bd8c21c0-2788-4374-8808-7197600d7cfc.png';
import unlockTheManual from '../../assets/images/serenity--gogExperience_uhd_cinematic_super_woman_wearing_modern_wonder_w_e1234310-1a35-4138-9848-20570d777bfc.png';
import unlockKeepItTrill from '../../assets/images/gogExperience_a_photo_shoot_photorealism_cinematic_loving_Afric_78f96773-05a1-4cba-88bc-fe4cf1f573d2.png';
import socialProofVideo1 from '../../assets/vids/49602862_1133814483435127_595458779459830982_n.mp4';
import unlockPersonalityTest from '../../assets/img/brand/og-600px-315px.png';
import unlockYourResults from '../../assets/images/gogexperience-duel-1.png';
import unlockHowMatchingWorks from '../../assets/images/gogexperience-duel-6.png';
import unlockSneakPeakImg from '../../assets/images/gogexperience-duel-2.png';
import goldLockIcon from '../../assets/img/icons/gold-lock-icon.png';
 
import UnlockEverything from '../../components/UnlockEverything/UnlockEverything';

import goldLockAndKey from '../../assets/img/icons/lock-and-key.png';
import './UnlockConversion.css';
import React, { useEffect, useState } from 'react';
import {Button, Modal, ModalBody, Form, FormGroup, Input, CustomInput, Container, Col, Row } from 'reactstrap';

const UnlockConversion = ({goToPaymentsPageFunc}) => {

    const handleToggle = (event) => {
        // Check if the clicked element or its parent has the class 'lockedFeature'
        const target = event.target.closest('.lockedFeature');
        if (!target) return; // If clicked outside a 'lockedFeature', do nothing
        
        // Toggle the class 'is-visible'
        target.classList.toggle("is-visible");
    };

    const handleUnlockAppBtnClickEvt = (e) => {
        goToPaymentsPageFunc();
    };

return (
<React.Fragment> 

    <div className='mt-7 py-4 unlock-conversion-wrapper'> 
        <Container>
           {/*  <Row>
                    <section id='proof-section'>
                        <div className='d-flex justify-content-center'>
                            <h4 className='text-white mx-5 mt-5 mb-4'><b>Video Clips of GOG Personality in Action - Coming Soon!</b></h4>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <video className='mt-3 u-socialproof-video' src={socialProofVideo1} controls alt='Videos of GOGPersonality in action' />     
                        </div>
                    </section>

            </Row>*/}
            <Row>
                <section id='cta-section--1' className='locked-section d-flex justify-content-center'>
                    <Button  className='btn-unlock-everything-cta' onClick={handleUnlockAppBtnClickEvt}>
                    <img src={goldLockAndKey} alt="icon" className="button-icon" />Click Here To Unlock Everything!</Button>
                </section>
            </Row>
            <Row>
                <section id='locked-features' className='lockedFeatures-grid' onClick={handleToggle}>
                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                        <div className='lockedOverlay'></div>
                        <h3>Get Your Soulmate Jewelry </h3> 
                        <img src={unlockToSeeJewelry} className='lockedFeatures-grid--icon'  alt='an image demonstrating  GOGPersonality jewelry'/>
                        <p>See what kind of jewelry matches your personality. Your perfect match will have the same necklace. </p>
                       
                    </div>
                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                        <div className='lockedOverlay'></div>
                       
                        <h3>Unlock to See & Meet your Match </h3> 
                        <img src={unlockToSeeYourMatch} className='lockedFeatures-grid--icon'  alt='an image demonstrating  your match'/>
                        <p>You will be matched on many things that are all scientifically proven to be compatible! </p>
                      
                    </div>

                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                       
                        <div className='lockedOverlay'></div>
                        <h3>Unlock to Get Invited to the SHOW!  </h3> 
                        <img src={unlockTheTVShowInvitation} className='lockedFeatures-grid--icon'  alt='an image demonstrating the invitation to the tv show because this is a reality show like Love Is Blind'/>
                        <p>After you are matched up - you can be invited to be on the Show! <br/>
                         It is like "Love Is Blind", but it's  different.  </p>
                         
                    </div>
                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                       
                        <div className='lockedOverlay'></div>
                        <h3>Unlock to Watch Our TV Show Here</h3> 
                        <img src={unlockTheTVShow} className='lockedFeatures-grid--icon'   alt='an image demonstrating the watch the TV show onlne right in this web app instead of paying for it elsewhere.'/> 
                        <p>GOG Personality is just like LOVE IS BLIND or LOVE ISLAND. You can watch it right here when you unlock it. 
                            otherwise it will be private subscription like either Netflix tv or a social media subscription. 
                        </p>
                     
                    </div>

                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                        <div className='lockedOverlay'></div>
                        <h3>Unlock to Exchange The Coins You Earned.</h3> 
                        <img src={unlockToExchangeCoins} className='lockedFeatures-grid--icon' alt='an image of GOG Black Panther inspired coins'/>
                        <p>Earn coins for everything you do in this web app. Use them to buy stuff at the meet up events.</p>
                        <p>Earn 10 for completing every button. and 10 extra for the extra "Upgrade You" activity that you do.  Exchange them for food and activities at the group dating event. </p>
                    </div>



                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                      
                        <div className='lockedOverlay'></div> 
                        
                        <h3>Get Free Gift  </h3> 
                        <img src={unlockToSeeJewelry} className='lockedFeatures-grid--icon'  alt='an image demonstrating  GOGPersonality jewelry'/>
                        <p>Get a random free gift related to GOGPersonality. it might be shirt, snap back etc.,   </p>
                       
                    </div>


                    <div className='lockedFeature'> 
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                       
                        <div className='lockedOverlay'></div>
                        <h3>Unlock to get the Manual</h3> 
                        <img src={unlockTheManual} className='lockedFeatures-grid--icon'  alt='an image demonstrating a manual that comes with the person for a better love life.'/> 
                        <p>After finishing the personality tests and getting matched up,  your partner will come with a manual or a book of secrets about how to have a great relationship with them. 
                             It will be in electronic PDF right here, after you unlock it. </p>  
                    </div>
                    <div className='lockedFeature'> 
                  
                        <img src={goldLockIcon} className='lockedFeature-goldLockIcon' alt='gold lock icon'/>
                       
                        <div className='lockedOverlay'></div>
                        <h3>Unlock to get "Keep it Trill"</h3>
                        <img src={unlockKeepItTrill} className='lockedFeatures-grid--icon'   alt='an image demonstrating the keep it trill love relationship app. '/> 
                        <p>"Keep it Trill" - is the program after you match that gives you great recommendations for your relationship.
                            It's like GROUPON with coupons and surprise trips and activities, based on your personalities, passions and goals. 

                        </p>
                    </div>
                </section>
                </Row>
                <Row>
                    <section id='cta-section--2' className='locked-section d-flex justify-content-center'>
                        
                        <Button  className='btn-unlock-everything-cta' onClick={handleUnlockAppBtnClickEvt}>
                        <img src={goldLockAndKey} alt="icon" className="button-icon" />Click Here To Unlock Everything!</Button>
                    
                    </section>
                </Row>
                <Row>
                <Container className='m-4 p-4'>
                    <div>
                       
                        <ol className='text-white'>
                            <li><b>Unlock it</b> to learn about your personality.</li>
                            <li><b>Unlock it</b> to start a relationships about personality, goals, and friendship.</li>
                            <li><b>Unlock it</b> for real-life experiences.  GOGPersonality is like the 
                                    TV Series "LOVE IS BLIND", "LOVE ISLAND", "Married on First Sight" but different. 
                                    You can watch episodes right here when you unlock it - or be on the series as well. 
                            </li>
                            <li><b>Unlock it</b>  to get matched to the right one the first time.</li> 
                        </ol> 
                    </div>

                </Container>
            </Row>
            <Row>
                <section id="cta-section--3" className='locked-section  d-flex justify-content-center'>
                    <Button  className='btn-unlock-everything-cta'  onClick={handleUnlockAppBtnClickEvt}>
                    <img src={goldLockAndKey} alt="icon" className="button-icon" />Click Here To Unlock Everything!</Button>
                </section>
            </Row>
        </Container>
    </div>

</React.Fragment>
    );
};
export default UnlockConversion;

/* 
    
        <Modal isOpen={payOverlay} toggle={handleUnlockAppBtnClickEvt} centered={true} className='p-0'>
            <ModalBody className="u-gradient--style-1">
                <h3 className='fontStyleMontserrat'><b>UNLOCK EVERYTHING</b></h3>
                <p className='fontStyleMontserrat'><b>Get All The Benefits</b> </p>
                <Card>
                    <Form>
                        <FormGroup>
                            <Input type='text' placeholder='first and last name'/>
                        </FormGroup>
                    <div className='p-4 d-flex justify-content-center align-items-center'>
                        <p className='p-2'> PAYMENT UI COMING SOON. 
                        It's all free for now. 
                        </p>
                        <Button type='button' >Submit Paument</Button>
                    </div>
                    </Form>
                </Card>
            </ModalBody>
        </Modal>
     */