import React, { useState } from 'react';

const IconCheckbox = ({elementId, isSelected = false}) => {
  const [isActive, setIsActive] = useState(isSelected);

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  return (
    <label className="checkbox-label">
      <input
        id={elementId}
        type="checkbox"
        checked={isActive}
        onChange={handleCheckboxChange}
      />
      <span className={`checkbox-icon ${isActive ? 'active' : ''}`}>
      <svg fill={isActive ? 'green' : 'red'} 
        width="80px"
        height="80px"
        cx ="80px" cy="80px" r="80px"
        viewBox="0 0 512 512" 
        xmlns="http://www.w3.org/2000/svg">
          <title>heart icon</title><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm74.69,252.82c-9.38,11.44-26.4,29.73-65.7,56.41a15.93,15.93,0,0,1-18,0c-39.3-26.68-56.32-45-65.7-56.41-20-24.37-29.58-49.4-29.3-76.5.31-31.06,25.22-56.33,55.53-56.33,20.4,0,35,10.63,44.1,20.41a6,6,0,0,0,8.72,0c9.11-9.78,23.7-20.41,44.1-20.41,30.31,0,55.22,25.27,55.53,56.33C360.27,251.42,350.68,276.45,330.69,300.82Z"></path></svg>
      </span>
    </label>
  );
};
export default IconCheckbox;