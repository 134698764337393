import animationJump   from '../../assets/vids/Jump.webm';
import animationIdle from '../../assets/vids/Idle.webm'; 
/*
import coin2 from '../../assets/img/icons/webm//Coin Explosion 2.webm'; 
import coin1 from '../../assets/img/icons/webm//Coin Explosion 1.webm';
import coin3 from '../../assets/img/icons/webm//Coin Explosion 3.webm';
import ran1animationStretch from '../../assets/vids/cat-stretch.webm';
import ran1animationIdle from '../../assets/vids/cat-idle.webm';
import charJump1 from '../../assets/img/icons/webm/charAnimationJump1.webm';
import mainChar from '../../assets/img/icons/webm/charAnimationCrouch.webm';  

import SpeechIcon from '../../assets/img/icons/cat-speaks.png';
import ReactModal from 'react-modal';
import webm7  from '../../assets/img/icons/webm/Intraversion.webm';
import imgDesktopOpen from '../../assets/images/DESKTOPUX-4.png';
import UploadCamera from "../../assets/images/camera.svg";
import { getAllJSDocTagsOfKind } from 'typescript';
import { useSpeech }  from '../../helpers/speakText';
*/
// and useDispatch is not used in this import statement.
import { useDispatch, useSelector } from "react-redux";


import firebase from '../../config/firebase';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './ProfileTest.css'; 


import ButtonCircleGraph from '../ButtonCircleGraph/ButtonCircleGraph';
import BackButton from '../../components/BackButton/BackButton';
import { Tooltip, CustomInput, Button, Container, Row } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom'; 

import imgDesktopClosed from '../../assets/images/DESKTOPUX-3.png'; 
import imgDesktopDefault from '../../assets/images/orange-swirl-giphy.webp';  
import notLoggedInDefaultImg from '../../assets/images/woman7.png';
import gogPersonalityLogo from '../../assets/img/brand/logo.c784cb44bc36ebf9431febfa75db79c0.svg';
import UnlockConversion from '../../components/UnlockConversion/UnlockConversion';
//image upload 
import handleImageChange from '../../helpers/fileUploadUtil';
import SimplestFeedback from '../../components/SimplestFeedback/SimplestFeedback';

import { useImage } from '../../contexts/ImageContext';
// personalitySummary
import PersonalitySummary  from './PersonalitySummary';
import BadgesSection from '../../components/BadgesSection/BadgesSection';
import diamondJewelry from '../../assets/images/transparent-heart-shaped-diamond-.png';
import moment from 'moment';


//import {defaultButtonData as buttonData} from '../../helpers/defaultButtonData';
import { usePersonalityTestResults } from '../../helpers/usePersonalityTestResults';
import { defaultButtonData } from "../../helpers/defaultButtonData";

import { TikTokEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';

const useViewportWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
  
	useEffect(() => {
	  const handleResize = () => {
		setWidth(window.innerWidth);
	  };
  
	  window.addEventListener('resize', handleResize);
  
	  // Clean up by removing the event listener when the component unmounts
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []); // An empty dependency array means this effect will run once on mount and clean up on unmount
  
	return width;
};

const getBackgroundStyle = (tabIndex) => {
    switch (tabIndex) {
        case 0:
        return 'linear-gradient(135deg, #fb6d26, #ff9e64)';
        case 1:
        return 'linear-gradient(135deg, rgb(251, 109, 38), rgb(255, 158, 100)'; // 'linear-gradient(135deg, #26fb6d, #64ff9e)';
        case 2:
        return 'linear-gradient(45deg, #fe590a, rgb(251, 109, 38))';
        case 3:
        return 'linear-gradient(45deg, #F44336, rgb(251, 109, 38))';
        default:
        return 'linear-gradient(135deg, #fb6d26, #ff9e64)';
    }
};
 /*
   const timeStringToSeconds = (timeString) => { 
        const duration = moment.duration(timeString);
        return duration.asSeconds();
    }; 


   
    
     const [image, setNewImage] = useState('');
    const [playerAnimationState, setPlayerAnimationState] = useState(animationIdle);

    const changePlayerAnimationState = (animationType) => {
        if (animationType==='IDLE') {
            setPlayerAnimationState(animationIdle);
        } else if (animationType==='JUMP1') {
            setPlayerAnimationState(animationJump);
        }
    };
 
tooltip: 
to upload your picture follow these steps 

1. go to  adobe express background remover. 
2. upload your picture 
3. let it remove the background. 
4. download the png file 
5. then upload it to our site. 
    */

/* This will listen for the first click event 
    anywhere on the document and start the video accordingly.
    After the video starts playing, it removes the event listener.
  
    
        const consoleOutVideoEvent = (e) => {
        let target = e.target;
        let eventType = e.type;
        //console.log('consoleoutvideoevent e=', e, '  target=', target);
        console.log('Video id='+target.id +' event.type='+ eventType +' Date.now()='+ Date.now());
    };
  */

const ProfileTest = () => {

    const { userFriendlyMsg, loading, loginLoading } = useSelector(state => state.auth);
    const handleUpload = (e) => {
        console.log('stubbed in', e);
    };

    const [shouldFetch, setShouldFetch] = useState(true); // Set your flag logic here
    const { uid, user } = useSelector((state) => state.authUser);
    console.log('here is user =', user);  
    
    
    
    const [MAX_BUTTONS,setMAX_BUTTONS] = useState(5); 
    
    const handleChangeMAX_BUTTONS = (e) => {
        const val = parseInt(e.target.value, 10);
        setMAX_BUTTONS(val);
    };

    const slicedButtonData = defaultButtonData;
     /*

    expecting buttonData 
    export const defaultButtonData = [
        { 
            buttonName: 'Big 5',
            percentCompleted: 0,
            iconResults: webm5,
            iconAlt: 'high for extraversion',
            id: 'big5',
            navId: 1,
            view: '/admin/mypersonalitytest/Part1',
            msgData: {
                title: 'Extraversion',
                msg: 'You are Extroverted.'
            }
        },

    */  
    let buttonData = usePersonalityTestResults(slicedButtonData, uid, shouldFetch);
    

    const getContiguous100Percent = (buttonData) => {
        let contiguous100Percent = 0;
        for (let index = 0; index < buttonData.length; index++) {
            if (buttonData[index].percentCompleted === 100) {
                contiguous100Percent++;
            } else {
                break;
            }
        }
        return contiguous100Percent;
    };

    const ANIMATION_DELAY = 2200;
    
    const INNER_DELAY = 1000;
  
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const showWhatYouAreUpTo = async (e) => {
        e.preventDefault();
        // setShouldFetch(!shouldFetch);  // toggle the state to trigger re-render
        const selectIndex = getContiguous100Percent(buttonData);
        console.log('showWhatYouAreUpTo selectIndex =', selectIndex);
        setMAX_BUTTONS(selectIndex);
        resetAllButtonGraphsToDefault2();
       //  await sleep(ANIMATION_DELAY - INNER_DELAY); 
        changeAllCircleGraphsTo100(selectIndex);
    
    };
    
    // console.log('final buttonData before use to render', buttonData);
    const buttonRefs = useRef([]); // Create a ref to store the button references

    const saveButtonRef = (ref, index) => { 
        buttonRefs.current[index] = ref;
       // console.log('saveButtonRef  buttonRefs', buttonRefs );
    };

    const [videosLoaded, setVideosLoaded] = useState(0);
   
    const videoWrapperRef = useRef(null);
    const videoRef = useRef(null);
    const videoRefs = useRef([]);
    const totalNumberOfVideos = 2;
    let sectionPositionCounter = 0;
    const sectionPositions = [0, 47, 107,167, 227, 287, 347, 401, 461,  741];// 701, 741]; // these would be actual y-coordinates



    const changeAllCircleGraphsTo100 = async (selectIndex) =>{
      //  console.log("START MAIN ANIMATION: changeAllCircleGraphsTo100", Date.now());
        //console.log('buttonRefs changeAllCircleGraphsTo100 ',buttonRefs);
        const DELAY = 1533;

        const targetIndex = selectIndex || MAX_BUTTONS;
        for (const [index, btnRef] of buttonRefs.current.entries()) {  
            console.log('START MAIN ANIMATION: for loop with sectionPositionCounter='+sectionPositionCounter);
            const shouldTranslate = 1;
            const isReverse = false; 
            if (index===targetIndex) {
                showIdle();
                return;
            }
            await sleep(INNER_DELAY);
            btnRef.changePercent(100,'close');
           playAnimationAt100Percent(++sectionPositionCounter, shouldTranslate, isReverse, DELAY );
           // console.log("Before sleep: ANIMATION_DELAY - INNER_DELAY", Date.now());
            await sleep(ANIMATION_DELAY - INNER_DELAY); 
           // console.log("After sleep: ANIMATION_DELAY - INNER_DELAY", Date.now());
              
        }

    //  console.log("END MAIN ANIMATION: changeAllCircleGraphsTo100 buttonRefs=", buttonRefs, Date.now());
    };
     // console.log("Before sleep: INNER_DELAY", Date.now());
           // console.log("After sleep: INNER_DELAY", Date.now());
  
           
    const resetAllButtonGraphsToDefault2 = async () => {
        sectionPositionCounter = sectionPositions.length;
        console.log('in resetAllButtonGraphsToDefault with sectionPositionCounter ='+sectionPositionCounter);
        const reversedItems = [...buttonRefs.current].reverse();
       // console.log('reversedItems=',reversedItems);
        let isopen = 'open';
        const isReversing = true;
        const DELAY = 0;
        showIdle();
        for (const [index, btnRef] of reversedItems.entries()) {  
            // first make the character move up 
            // await sleep(INNER_DELAY); 
            playAnimationAt100Percent(--sectionPositionCounter, true, true, DELAY);
            btnRef.changePercent(0, isopen);
        }
    };

    const resetAllButtonGraphsToDefault = async () => {
        console.log('in resetAllButtonGraphsToDefault with sectionPositionCounter ='+sectionPositionCounter);
        const reversedItems = [...buttonRefs.current].reverse();
       // console.log('reversedItems=',reversedItems);
        let isopen = 'open';
        const isReversing = true;
        const DELAY = 1533;
            for (const [index, btnRef] of reversedItems.entries()) {  
            // first make the character move up 

            
            // await sleep(INNER_DELAY); 
            playAnimationAt100Percent(--sectionPositionCounter, true, isReversing, DELAY);
            btnRef.changePercent(0, isopen);
            //console.log("Before sleep: INNER_DELAY", Date.now());
            
            //await sleep(INNER_DELAY); 
           // console.log("After sleep: INNER_DELAY", Date.now());
            
          //  console.log('btnRef.changePercent(buttonData[index].percentCompleted, isopen) btnRef=', btnRef, 'buttonData[index].percentCompleted =', buttonData[index].percentCompleted, 'isopen =', isopen); 
            
            // btnRef.changePercent(buttonData[index].percentCompleted, isopen);
            
           // console.log("Before sleep: ANIMATION_DELAY - INNER_DELAY", Date.now());
          //  await sleep(ANIMATION_DELAY - INNER_DELAY); 
           // console.log("After sleep: ANIMATION_DELAY - INNER_DELAY", Date.now());
        }
    };

    const showIdle = ()=> {
        videoRefs.current[0].style.visibility = 'visible';  // idle
        videoRefs.current[1].style.visibility = 'hidden';
        let position = -1;
        if (sectionPositionCounter+1 >= sectionPositions.length) {
            position = sectionPositions.length-1; 
        } else {
            position = sectionPositionCounter+1
        }
        const translateToPosition = 'translateY('+ sectionPositions[position] +'px)';
        const vid1 = videoRefs.current[0].querySelector('video');
       
        setTimeout(function() {
            videoWrapperRef.current.style.transform = translateToPosition;
           // console.log('intime out to translate playAnimationAt100Percent= index='+index,' translateToPosition ='+ translateToPosition + 'Date.now()', Date.now());
             
            
        }, 1533);
        vid1.currentTime = 0;//idle
       
        return vid1.play();//idle
    };
    const showJumping = () => {
        videoRefs.current[1].style.visibility = 'visible';  // jump
        videoRefs.current[0].style.visibility = 'hidden';
        
        const vid2 = videoRefs.current[1].querySelector('video');
         vid2.pause();
        vid2.currentTime = 0;//idle
        return vid2.play();//idle
    };

    const playAnimationAt100Percent = (index, shouldTranslate, isReversing, delay ) => {
        //console.log('BEGIN playAnimationAt100Percent index='+index, Date.now());
        //console.log('BEGIN playAnimationAt100Percent sectionPositionCounter should be same as index. sectionPositionCounter =',sectionPositionCounter , 'index =', index);  
        let idx = index; // initial index is 1

        if (idx<0){
            idx=0;
            console.error('In playAnimationAt100Percent param index<than 0 ='+idx+' fixed ot tho.');
        }

        if (idx >= sectionPositions.length) {
            idx=sectionPositions.length-1;
            console.error('In playAnimationAt100Percent param index is greater than the number of sections. index ='+idx+' fixed it ')
        }

        const translateToPosition = 'translateY('+ sectionPositions[idx] +'px)';

        let playPromise = null;
        if (delay===0) {

        } else {


            
            if(idx===0 ||idx=== sectionPositions.length-1) {
                playPromise = showIdle();
            } else if (idx >= 1 || idx <=  sectionPositions.length-2) {
                playPromise = showJumping(); 
            } 

            if (playPromise &&  playPromise  !== undefined) {
                playPromise.then(_ => { 
                console.log('Inside the playPromise. what should do in here now');
                })
                .catch(error => {
                    console.log("playPromise. Playback failed: " + error);
                });
            }
        }


        setTimeout(function() {
            videoWrapperRef.current.style.transform = translateToPosition;
           // console.log('intime out to translate playAnimationAt100Percent= index='+index,' translateToPosition ='+ translateToPosition + 'Date.now()', Date.now());
        }, delay)
       // console.log('END playAnimationAt100Percent index='+index, Date.now());
    };  
 


    useEffect(() => {
      // Check if all videos are loaded
      if (videosLoaded === totalNumberOfVideos) {
        // All videos are loaded, you can start your animation
        videoRefs.current[0].style.visibility= 'visible';
    
      }
    }, [videosLoaded]);

    const handleVideoLoaded = () => {
      setVideosLoaded((prevCount) => prevCount + 1);
    };
 
    
   
    const [isCharacterClickable, setIsCharacterClickable] = useState('none');

    const toggleIsCharacterClickable = (isClickable) => {
        setIsCharacterClickable(isClickable);
    };
/* name is stored in 


 user?.firstName && user?.lastName 
user?.onBoardingData?.profileInformation?.firstName  &&  user?.onBoardingData?.profileInformation?.lastName ? user?.onBoardingData?.profileInformation?.firstName  +' '+  user?.onBoardingData?.profileInformation?.lastName 
user?.name
.profileInformation?.nickName 

therefore the query to update the name will be 
firebase.firestore.collection('users').update({
    firstName: ,
    lastName: ,
    name: 
    onBoardingData: {
        profileInformation : {
            firstName:
            lastName :
            nickName : 
        }
    }


}, {merge:true})


*/
    const firstCheck = user?.firstName && user?.lastName ? user?.firstName +' '+ user?.lastName : null;
    
    const secondCheck = user?.onBoardingData?.profileInformation?.firstName  &&  user?.onBoardingData?.profileInformation?.lastName ? user?.onBoardingData?.profileInformation?.firstName  +' '+  user?.onBoardingData?.profileInformation?.lastName : null;
    
    const uNameFromDB = user?.name || firstCheck || user?.onBoardingData?.profileInformation?.nickName || secondCheck || 'Shaylah Lashay';
    console.log('user?.name || firstCheck || user?.onBoardingData?.profileInformation?.nickName || secondCheck || Shaylah Lashay', 
    ' user?.name,', user?.name,
    ' firstCheck,', firstCheck, 
    ' user?.onBoa', user?.onBoardingData?.profileInformation?.nickName, 
    ' secondCheck', secondCheck);
    console.log('user=',user, 'uid =', uid);
    const avatarData = {
        percentCompleted: 0, // overall completion. In the previous design, it was rendered. 
        userName: uNameFromDB,
        userGender: user?.onBoardingData?.gender,
        notificationIconData: 2,
        certificatesEarned:[{id:'test1', name:'test1name'}]
    };

    // console.log('user=',user);

    const gtag = window.gtag;

    const playWithErrorChecking = () => {
        let playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
          })
          .catch(error => {
            console.log("Playback failed: " + error);
            // Handle the failure gracefully
          });
        }
    };
    /*
      if (isReverse) { // do it backwards eg., it comes in as  1 2 3 4 5  should be 5-1 = 4
            idx = sectionPositions.length - index;
        }
        */


    useEffect(() => {
        const handleFirstClick = () => {
        if (videoRefs.current[0]) {
            videoRefs.current[0].style.display='block';
            videoRefs.current[0].querySelector('video').play();
            // Remove the event listener after playing the video once
            document.removeEventListener('click', handleFirstClick);
        }
        };
    
        // Add the click event listener to the whole document
        document.addEventListener('click', handleFirstClick);
    
        return () => {
        // Cleanup: Remove the event listener if the component is unmounted
        document.removeEventListener('click', handleFirstClick);
        };
    }, []);
  
    const history = useHistory();

    const goToPaymentsPageFunc = () => {
        history.push('/admin/UnlockEverything');
    };

    const handleOpenPersonalityTestEvent =  useCallback((id) => {
        //console.log('Button clicked! id=', id);
        let idFound = false; // to track if the ID was found.
        buttonData.forEach(function (val, index) {
            if (val.id===id) {
                idFound = true;
                history.push(val.view);
            }
        });

        if (!idFound) {
            const errMsg = `ID of a profile button click not found: ${id}`;
            // Log the message for debugging
            console.error(errMsg);
            // Send an event to Google Analytics
            gtag('event', 'exception', {
                'description': errMsg,
                'fatal': false
            });
        }
    }, []);

    const getImgFromLocalStorage = () => {
        const str = localStorage.getItem('onboardingData'); 
        try {
            if (str) 
                return  str ? JSON.parse(str)?.profileInformation?.img : null;
        } catch (error){
            console.error(error);
            return null;
        }
    };

    const [userPathFromFirebaseCloud, setUserPathFromFirebaseCloud] = useState(null);

    const getImageUrl = async (uid, fileName) => {
        if (!user || !uid || !fileName) {
            return null;
        }
        const storageRef = firebase.storage().ref("users");
        const fileRef = storageRef.child(`${uid}/${fileName}`);
        try {
          const url = await fileRef.getDownloadURL();
          setUserPathFromFirebaseCloud(url); 
         // console.log('here is the url =', url );
        } catch (error) {
            if (user || uid) {
                alert('Error. Please log out then login again');  
            }
          console.error("Error fetching image URL:", error);
        }
    };

    const { imageUrlIC, setImageUrlIC, imagesIC, setImagesIC, videosIC, setVideosIC, imageDataIC, setImageDataIC } = useImage();

    useEffect(() => {
        // if it is not in the image context then fetch it 
        if (!imageUrlIC) {
            getImageUrl(uid, uid);
        }
    },[]);

    /**
     * code to handle image upload 
     * I received the error message "Upload Failed: setProfileInfo is not a function"
     */
    const [image, setImage] = useState(null);
    // in the context 
    const [profileInfo, setProfileInfo2] = useState({
        img:null,        //  previewURL,
        imageUrl: null,  //  fileUrl,
        imageFileName: null, // uid
    });
    
    const setProfileInfo = (newData) => {
        setProfileInfo2((prevProfileInfo) => ({
          ...prevProfileInfo,
          ...newData
        }));
      };

      const [notLoggedInPicture, setNotLoggedInPic] = useState(null);
      if (!user ) {
          setTimeout(function(){
            setProfileInfo({img:notLoggedInDefaultImg});
            setNotLoggedInPic(notLoggedInDefaultImg);
          }, 3000);
      }
    const [imageUrL, setImageUrl] = useState(null);

    const reusableHandleImageChange = handleImageChange(setImage, setImageUrlIC, setProfileInfo, uid);
    // Create a ref to refer to the label element.
    const refToLabel = useRef();

    // Define a function that will be triggered when the outer button is clicked.
    const handleLargerTargetClickEvt = (e) => {
         e.stopPropagation();// Prevent the default action.
        refToLabel.current.click(); // Programmatically trigger a click on the label.
    };

    /**
     * 
     * 
            img: user?.onBoardingData?.profileInformation?.img || "",
            imageUrl: user?.onBoardingData?.profileInformation?.imageFileName || "",
            nickName: user?.onBoardingData?.profileInformation?.nickName || "",
            firstName:user?.onBoardingData?.profileInformation?.firstName ||  "",
            lastName: user?.onBoardingData?.profileInformation?.lastName || "",
            email: user?.onBoardingData?.profileInformation?.email || "",
            birthDate: user?.onBoardingData?.profileInformation?.birthDate || "",
            country: user?.onBoardingData?.profileInformation?.country ||  "",
            youtubeVideo: user?.onBoardingData?.profileInformation?.youtubeVideo || "",
     */

    const  userIMGFromLocalStorage = getImgFromLocalStorage();

    const vidWidth =  useViewportWidth() * 0.70;

    const [personalitySummaryVisibility, setPersonalitySummaryVisibility] = useState(false);
   
    const [activeTab, setActiveTab] = useState(0); // assuming 0 is the index of the initial active tab
    const [isFirstRender, setIsFirstRender] = useState(true);

    /* commenting out text to speech 
    const [isAvatarSpeaking, toggleAvatarSpeaking] = useState(false);

    const { speakText, pauseSpeech, resumeSpeech, restartSpeech } = useSpeech();

    const [textByPositionOrEntryCounter, settextByPositionOrEntryCounter] = useState(-2);
    
 
      
    const textByPositionOrEntry = [
       //  
        'The 1st button is the Big 5 Personality Test. Click it.',
        'The 2nd button checks your communication styles.  You will learn your favorite communication styles.',
        'The 3rd button will ask about your top 2 love languages.  Go to a website called 5 love languages dot com. Take their love language test. Then put your top 2 on our site.',
        'The 4th button asks you a few questions about your mood. All of this is extremely helpful information to start and maintain a relationship.',
        'The 5th button asks you a few questions about your mood. All of this is extremely helpful information to start and maintain a relationship.',
        'The 6th button says Temperament it asks you a few questions about your temperament.',
        'The 7th button says "Let\'s upgrade you" It has things that we can do to upgrade ourselves as relationship partners. Do some an eaern a certificate for it and post video proof.',
        'The 8th button says JEWELRY color. After you finish your personality tests, yu will get a jewelry piece. Your perfect match will hav the same jewelry piece. When you meet up as a group, you all will be able to recognize each other by the jewelry and the right vibes.',
        'The 9th buton says MATCHES. When you are matched up it will light up. and show you who you are matched up with. You have to unlock it to see who it is and then you will be invited to the group meetup events.',
        'There is one more button. CLick it.  It\'s easy. It\'s just sliders. You will use the sliders to share details about you, and details about your preferences for your significant other.'

    ];


    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false); // Set the flag to false after the first render
            return;
        }

        changeTheSentencesSpoken();
        console.log('textByPositionOrEntryCounter has been updated:', textByPositionOrEntryCounter);
    }, [textByPositionOrEntryCounter ]);

    const changeTheSentencesSpoken = () => {

        const isSpeaking = firstSentence();
        if (isSpeaking ) {
            return;
        }

        let wordIndex = 0;
        let txt = '';
        if (textByPositionOrEntryCounter >= sectionPositions.length || 
            textByPositionOrEntryCounter >= textByPositionOrEntry.length) {
            settextByPositionOrEntryCounter(0);   
        }   

        console.log(' textByPositionOrEntryCounter=', textByPositionOrEntryCounter, 
        ' sectionPositions.length = ', sectionPositions.length,
        ' textByPositionOrEntry.length =', textByPositionOrEntry.length);  

        // 
        if (sectionPositionCounter === 0 && textByPositionOrEntryCounter===0 ) { // the person is idle at the top.
            wordIndex = 0;
        } 
        if (sectionPositionCounter === 0 && textByPositionOrEntryCounter>0 ) { // the person is idle at the top.
        
            wordIndex = textByPositionOrEntryCounter;
        }

        if (sectionPositionCounter > 0) {
        // just add I am standing on this button 
       
            wordIndex = sectionPositionCounter;
            let num = 0;
            if (wordIndex ===0) {
                num = '1st';
            }
            if (wordIndex ===1) {
                num = '2nd';
            }
            if (wordIndex ===2) {
                num = '3rd';
            }
            if (wordIndex >2) {
                num = (wordIndex+1)  + 'th';
            }

            txt = 'I am standing on the ' + num + ' button. '; 
        }
      
       // speakText('this is awesome i finally got her to speak', 'Samantha pitch rate');
        speakText(txt + textByPositionOrEntry[wordIndex ], 'Karen', 1.4, 0.9);
      }

    const hasRun = useRef(false);
   
   
    const firstSentence = () => {
        if (!hasRun.current) {
            // your logic
      
        const sentence1 = 'Welcome to  G O G Personality. This is going to make our relationships easier, safer, more reliable and more fun. I am standing on top of 9 buttons. Each button is a personality tests. Some are as short as 2 questions, some are longer. When you finish a personality test, you will see me jump from from one button to the next. Icons will pop up that represents your results.  To see what it looks like when you finish, select a number at the lower right, then click Jump Demo. That will show you what it looks like when you are completing these personality tests. Click the button that I\'m standing on to start. Click it. You will love it!';
        speakText(sentence1, 'Karen', 1.4, 0.9);

            hasRun.current = true;
            return true;
        }
        return false;
    };

    
   
     
    const startSpeechIcon = (e) => {
        e.preventDefault(); 
        settextByPositionOrEntryCounter( prev => { return  prev + 1 } );
    };
     
    const handlePauseSpeechIcon = (e) => {
        e.stopPropagation();
        pauseSpeech();
    };
    const handleResumeSpeechIcon = (e) => {
        e.stopPropagation();
        resumeSpeech();
    };
    const handleRestartSpeechIcon = (e) => {
        e.stopPropagation();
        restartSpeech();
    };
*/
const [tooltipOpenPicture, setTooltipOpenPicture] = useState(false);
const [tooltipOpenSpeak, setTooltipOpenSpeak] = useState(false);
const toggleToolPicture  = () =>  setTooltipOpenPicture(!tooltipOpenPicture);
const toggleToolSpeak = () => setTooltipOpenSpeak(!tooltipOpenSpeak);
/* commenting out notifications 
const [tooltipOpenNotification, setTooltipOpenNotification] = useState(false);
const toggleToolNotification = () => setTooltipOpenNotification(!tooltipOpenNotification);

const handleNotificationClickEvt = (e) => {
    e.preventDefault();
}
*/


    const togglePersonalitySummary = () => {
        setPersonalitySummaryVisibility(!personalitySummaryVisibility);
    };

    return (
    <React.Fragment>
        <div className="profile2-container">

<div className='testAnimation__wrap'>
    <CustomInput 
      className='testAnimation__select'
      type="select" 
      id="testCustomSelectMAX_NUMBER" 
      name="testCustomSelectMAX_NUMBER"
      value={MAX_BUTTONS}
      onChange={handleChangeMAX_BUTTONS}
    >
      <option value="">Select #</option>
      {Array.from({ length: 10 }, (_, i) => (
        <option value={i} key={i}>{i}</option>
      ))}
    </CustomInput>

    <button className="testBtn testBtn-3" onClick={changeAllCircleGraphsTo100}>Jump Demo </button> 
     <button className="testBtn testBtn-1" onClick={resetAllButtonGraphsToDefault}>Reset </button>  
    <button className="testBtn testBtn-2" onClick={showWhatYouAreUpTo}>Show Your Progress </button>

</div>
  
            <Link className='u-img--logo-left'  to='/admin/home?'><img className='u-img--logo-left' src={gogPersonalityLogo} alt='gogPersonality.com logo' /></Link> 
            { ( !user || !uid || !user.id ) && (
                <Link to='/admin/home' className='loggedout-notice'>You are not logged in.<br/> Click here to login</Link>
            )}
            <BackButton/>
            
            <div className='new-avatar'>
{/* leave this reference here. swirl image source:
   - https://giphy.com/gifs/loop-spiral-fractal-0JXhP33IC7yynHz7yI
   - https://www.instagram.com/kamiraii/
*/}


{/*`new-avatar-userImg-container ${(profileInfo?.img || profileInfo?.imageUrl || userPathFromFirebaseCloud) ? 'z-index-back' : ''}`*/}


<div className={`new-avatar-userImg-containerbg ${!( imageUrlIC || profileInfo?.img || profileInfo?.imageUrl || userPathFromFirebaseCloud || imgDesktopDefault ) ? 'is-visible' : ''}`}>
    <img src={ imgDesktopDefault } className='new-avatar-userImg'   />
</div>

                <div className='new-avatar-userImg-container'>
                    
                    <img src={ imageUrlIC || profileInfo?.img  || profileInfo?.imageUrl || userPathFromFirebaseCloud  || notLoggedInPicture}
                        className='new-avatar-userImg'
                        id='u-js-userImg' />
                </div>
                <img className='new-avatar-img-bottom' src={imgDesktopClosed}  alt='background image to make the profile page look pretty. It expects your picture to be a transparent png. go to adobe express to remove your profile pictures background for the best look on this page.'  />
                <div className='new-avatar-bg-gradient'></div>
            </div>
            
            
            <div className="new-avatar-container-inner">
                <div className="name-description"><p>{avatarData.userName}</p></div> 
            </div>

        <div className="button-section">
 


            <div className='d-flex justify-content-start u-profile--icon-wrapper'>
             
                    {/* 
                    <Tooltip
                    placement="top"
                    isOpen={tooltipOpenSpeak}
                    target="toolTipSpeak"
                    toggle={toggleToolSpeak}
                    >Click to hear the avatar speak.</Tooltip> 
                    
                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpenPicture}
                        target="toolTipPicture"
                        toggle={toggleToolPicture}
                    >
                        Upload your picture. Make it a transparent PNG. Use Adobe Express to remove the background.
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpenNotification}
                        target="toolTipNotifications"
                        toggle={toggleToolNotification}
                        >Notifications: Coming soon</Tooltip>
                        
                    <Button id='toolTipNotifications' 
                        onClick={handleNotificationClickEvt}
                       className="d-flex u-profile--notification-icon justify-content-center">      
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24.816" viewBox="0 0 60 74.45" fill="white">
                        <path id="Notification-Icon-SVG-03cde" d="M20.978,62.685H34.989a7,7,0,0,1-.525,2.726,7.122,7.122,0,0,1-5.044,4.137,6.256,6.256,0,0,1-1.4.136A7.054,7.054,0,0,1,20.978,62.685ZM2.48,59.733A2.477,2.477,0,0,1,.725,55.5l4.26-4.261a6.891,6.891,0,0,0,2.02-4.869V31.711c0-10.794,5.711-21.69,15.759-24.079V5.255A5.25,5.25,0,0,1,28.359.01a5.373,5.373,0,0,1,4.914,5.42v2.2c10.017,2.39,15.766,13.325,15.766,24.079V46.375a6.884,6.884,0,0,0,2.013,4.869L55.319,55.5a2.477,2.477,0,0,1-1.754,4.228Z" transform="translate(0.5 0.502)" stroke="#fffff" strokeMiterlimit="10" strokeWidth="1"/>
                        </svg>
                    </Button> 
                        */}
                    
                    <Button  
                      onClick={handleLargerTargetClickEvt} 
                        className="d-flex btn btn-secondary u-profile--camera-icon justify-content-center">
                        <input
                            type="file"
                            accept="image/*"
                            id={"uploadId"}
                            onChange={reusableHandleImageChange}
                            hidden
                        />
                        <div className="profile-img">
                           {/*  <img  src={image?image:imgDesktopDefault}  alt="img" className="img-fluid" /> */}
                            <label
                                ref={refToLabel}
                                className="upload-btn"
                                htmlFor={"uploadId"}
                                onClick={(e)=>{ handleUpload(e); e.stopPropagation();}}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"><path d="M6 12a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2h-9l-2-5H17l-2 5zm18 4a10 10 0 1 1-.1 0zm0 5a5 5 0 1 0 .1 0z"/></svg>

                           {/*  <img src=UploadCamera} alt="icon" className="img-fluid" /> */}
                            </label>
                        </div>
                    </Button>
{/*

                    <Button id='toolTipSpeak' 
                        alt='start speech' 
                        onClick={startSpeechIcon} 
                        className="smaller d-flex u-profile--speech-icon u-profile--speech-icon-1 justify-content-center" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"  viewBox="0 0 448 512"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg>
                     
                    </Button>
                    <Button id='toolTipPause' 
                        alt='pause speech' 
                        onClick={handlePauseSpeechIcon} 
                        className="smaller2 d-flex u-profile--speech-icon u-profile--speech-icon-2 justify-content-center">
                  
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <title>
                                pause
                            </title>
                            <rect width="6" height="16" x="3" y="2" rx="1" ry="1"/>
                            <rect width="6" height="16" x="11" y="2" rx="1" ry="1"/>
                            </svg>

                    </Button>
                    <Button id='toolTipResume' 
                        alt='resume speech' 
                        onClick={handleResumeSpeechIcon} 
                        className="d-flex u-profile--speech-icon u-profile--speech-icon-3 justify-content-center">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height="20px" width="20px"   viewBox="0 0 24 24" version="1.1" x="0px" y="0px"><title>icon/play-pause</title><desc>Created with Sketch.</desc><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><path d="M15,18 L15,6 L17,6 L17,18 L15,18 Z M20,18 L20,6 L22,6 L22,18 L20,18 Z M2,5 L13,12 L2,19 L2,5 Z" /></g></svg>

                    </Button> */}
                </div>



                <div className="u-personalitytest__button-container">
                   {/* <div ref={videoWrapperRef} className='animated-character--wrap'>
                        <video ref={videoRef} src={playerAnimationState} className='animated-character' style={{ pointerEvents: isCharacterClickable }}></video>
                    </div> */}


                    <div ref={videoWrapperRef} className='animated-character--wrap'>
                    <div ref={(e1) => (videoRefs.current[0]=e1)} style={{ visibility: 'hidden',  pointerEvents: isCharacterClickable }}>
                        <video
                        id='idleVideo'
                        preload='auto' 
                        onLoadedData={handleVideoLoaded}
                        className='animated-character' 
                        src={animationIdle} 
                        autoPlay
                        loop
                        ></video>
                    </div>
                    <div ref={(e1) => (videoRefs.current[1]=e1)} style={{ visibility: 'hidden',  pointerEvents: isCharacterClickable }}>
                        <video
                        id='JumpingVideo'
                        preload='auto' 
                        autoPlay
                        onLoadedData={handleVideoLoaded}
                        className='animated-character' 
                        src={animationJump} 
                        ></video>
                    </div>
                    </div>
                
                {buttonData && buttonData.map((button, index) => (
                    <React.Fragment key={index}>      
                        <ButtonCircleGraph
                        key ={index}
                            id={button.id}
                            percentCompleted= {button.percentCompleted}
                            buttonName={button.buttonName}
                            msgData={button.msgData}
                             
                            iconResults={button.iconResults}
                            iconAlt={button.iconAlt}
                            onclick={()=>handleOpenPersonalityTestEvent(button.id)}
                            ref={(ref) => saveButtonRef(ref, index)}
                            classname = {'ptest-default'}         
                        />
                        {(button.buttonName==='Jewelry Color') && (
                            <img src={diamondJewelry} className="button-section--heartJewelry rotateY360deg mx-auto d-block img-fluid" id='buttonjs--section--heartJewelry' /> 
                        )}
                    </React.Fragment>
                ))}
                </div>
                <div className="d-flex justify-content-center">
                    <Button type="button" className="my-2 u-personalitySummary-darkbtn btn btn-primary btn-dark" onClick={togglePersonalitySummary}>Open/Close Details About You &  Preferences About Your Sig/O</Button>
                </div>
            </div>



            <div className='u-profile-icon--wrapper'>
                <BadgesSection user= { user } />
                {personalitySummaryVisibility && (
                    <Container className='u-personalitySummary'  style={{ background: getBackgroundStyle(activeTab) }}>
                        <Row>
                            <PersonalitySummary
                                uid={uid}
                                user={user}
                                onTabChange={(newTabIndex) => setActiveTab(newTabIndex)} 
                            />
                        </Row>
                    </Container>
                )}

               {/*  <div className="mt-5">
                    <h3 className="d-flex justify-content-center text-white mt-4 mb-4">Updates: Coming Soon</h3>
                    <div className="mt-4 d-flex justify-content-center">
                        <EmbeddedYoutubeVideo 
                            path={'https://youtu.be/UxNuYQsZ95U'} 
                            width = {vidWidth}
                            aspectRatio={'16:9'} />
                    </div>
                </div> */}
                <div className="mt-5 profile-details-video-container">
                    { user?.youtubeURL  ? (
                        
                        <div className='video-item' style={{ display: 'flex', justifyContent: 'center' }}>
                        <YouTubeEmbed url={user?.youtubeURL} width={325} height={220} />
                        </div>

                        ) : null} 
                        
                    { user?.tikTokURL && user?.tikTokURL.indexOf('tiktok')>-1 ? (
                            
                                <div className='video-item'  style={{ display: 'flex', justifyContent: 'center' }}>
                                <TikTokEmbed url={user?.tikTokURL} width="100%" />
                                </div>

                    ) : null} 
                </div>

                <UnlockConversion goToPaymentsPageFunc= {goToPaymentsPageFunc} /> 
                
                <Container className='container-sm'>
                    <Row className='row d-flex justify-content-center'> 
                        {/* <Col className='col-sm-6 d-flex flex-column text-center'>
                            <Button type="button" className="my-2 btn btn-primary btn-dark" >About Matching </Button>
                            <Button type="button" className="my-2 btn btn-primary btn-dark" >About Us </Button>
                           </Col> */}
                    </Row>  
                    <div className='profilepg'> 
                    <SimplestFeedback user= { user } /> 
                    </div> 
                </Container>  
            </div>
	 
        </div>
    </React.Fragment>
    );
  };
export default ProfileTest;

    /*
        ReactModal.setAppElement('#root');
    */
/*
    const handleTransitionStart = () => {
        console.log('in handleTransitionStart ');
        videoRef.current.pause();
        videoRef.current.seek(0);
        let playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                // Playback started. Do additional UI changes here.
            })
            .catch(error => {
                // Handle the error
            });
        }

    };
    */


   /*
// const animationNameX = animationName || getRandomAnimationName();
       

useEffect(() => {
    
      videoRef.current.play();
     
  }, [playerAnimationState]);


       
    
        // const animation = animationSeekPositionsAndDurations[animationNameX];
       
       // if ( videoRef.current) {
          //  videoRef.current.play(); 
     
      //  }
       

      
    /* usage  seekVideo(timeStringToSeconds(animation.seek));
        avoid using seek.  it is not precise. 

    const seekVideo = (timeInSeconds) => {
        if (videoRef.current) {
          videoRef.current.currentTime = timeInSeconds;
        }
    };
      


    const characterAnimationsByGender = {
        male: characterVideoAnimationsMale,
        female: characterVideoAnimationsFemale,
    };
    */
  //  const characterVideoAnimations = characterAnimationsByGender[avatarData.userGender] || characterAnimationsByGender.male;

    /*
    useEffect(() => {
        if (characterVideoAnimations && videoRef && videoRef.current) {
            videoRef.current.play(); 
        }
       
    },[]);
    */
/*



"https://youtu.be/PKDTNfXXYV8" 
                   {personalitySummaryVisibility && (
                        <ReactModal
                            isOpen={personalitySummaryVisibility}
                            onRequestClose={togglePersonalitySummary} 
                            style={{
                                overlay: {
                                  position: 'fixed',
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  top:0,
                                  overflowX:"scroll",
                                  zIndex:1000,
                                  backgroundColor: 'rgba(0, 5,  5, 0.75)'
                                },
                                content: {
                                    position: 'absolute',
                                    top: '40px',
                                    left: '60px',
                                    right: '60px',
                                    maxWidth: '700px',
                                    margin: '0 auto',
                                    width: 'auto',
                                    bottom: 'initial',
                                    border: 'none', 
                                    paddingTop: '68px',
                                    background: getBackgroundStyle(activeTab),
                                    overflow: 'auto',
                                    WebkitOverflowScrolling: 'touch',
                                    borderRadius: '0',
                                    outline: 'none',
                                    padding: '20px'
                                }
                            }}>
                            <PersonalitySummary
                                uid={uid}
                                user={user}
                                onTabChange={(newTabIndex) => setActiveTab(newTabIndex)} 
                                // function to handle tab change
                            />
                        </ReactModal> 
                    )};

                    */
/* 
This is to show what the old table looked like 
<UserProfileSummary user={user}/>

*/

    /*
        console.log('imageUrl = ', imageUrl,
        '  userIMGFromLocalStorage ',  userIMGFromLocalStorage,
        ' userPathFromFirebaseCloud ',userPathFromFirebaseCloud,
        '  imgDesktopDefault ',  imgDesktopDefault  );


        <video ref={videoRef} src={characterVideoAnimations} className='animated-character'></video>
        <img src={transpGif} className='animated-character'/> 
    */
      /*
    const characterAnimationsByGender = {
        male: characterVideoAnimationsMale,
        female: characterVideoAnimationsFemale,
      };
    
    const characterVideoAnimations = characterAnimationsByGender[avatarData.userGender] || characterAnimationsByGender.male;
 
*/

/*
const UserProfileSummary = (user) => {


    return (
        <React.Fragment>
        <div className='u-tbl--wrapper'>
        <h3 className='font-weight-bold text-center text-white'>Now that you are matched up!</h3>
        <h3 className='font-weight-bold text-center text-white'>The Next Step is .... under construction. coming soon.!</h3>
            <p className='font-weight-bold text-center text-white'>Your Profile Summary</p>
                <table className='u-tbl'>
                <tbody>
                <tr><td>Your passions/Interests</td><td></td></tr>
                <tr><td>Your Gender</td><td></td></tr>
                <tr><td>Gender your are interested</td><td></td></tr>
                <tr><td>Age Range you are interested in</td><td></td></tr>
                <tr><td>Height preference</td><td></td></tr>
                <tr><td>Type you are</td><td></td></tr>
                <tr><td>Type you are interested in </td><td></td></tr>
                <tr><td>talents you would like to give to your children</td><td></td></tr>
                <tr><td>relationship goals</td><td></td></tr>
                <tr><td>match making location</td><td></td></tr>
                <tr><td>Type you are interested in </td><td></td></tr>
                <tr><td>talents you would like to give to your children</td><td></td></tr>
                
                <tr><td>dream location to live</td><td></td></tr>
                <tr><td>Nick name</td><td></td></tr>
                <tr><td>Age</td><td></td></tr>
                <tr><td>Youtube Video</td><td></td></tr> 
                </tbody>
            </table>
            </div>
        </React.Fragment>
    );
}
*/
   /*   
      const handleChangePercentCompleted = (e) => {
        console.log('handleChangePercentCompleted e', e);
    };
  
   <button className="testBtn" onClick={handleChangePercent}>Start Animation</button>


        <div className="avatar-section">
          <AvatarCircle
            pictureSource={avatarData.pictureSource}
            percentCompleted={avatarData.percentCompleted}
          />
        </div>
        */
       /*
    const handleChangePercent = (e) => {
        // Call the changePercent function in the child component
        console.log('buttonRefs= ', buttonRefs);
        console.log('buttonRefs.current= ', buttonRefs.current);
        if (buttonRefs.current[1]) {
          buttonRefs.current[1].changePercent(50,'open'); // Pass the desired newPercent value
        }
    };
*/


    /*
    const [percentCompleted, setPercentCompleted] = useState(0);
    
    const changePercentCompleted  = () => {
    setPercentCompleted(100);
    console.log('changePercentCompleted');
};



    this startAnimation function works but i'm using useeffects
    const startAnimation = (e) => {
        console.log('e',e,' before buttonRefs=', buttonRefs);
        setPercentCompleted(100);
       const thirdButtonRef = buttonRefs.current[2];
        console.log( 'thirdButtonRef=', thirdButtonRef);
        thirdButtonRef.setAttribute('percentcompleted', '100%');
        const circlePathElement2 = thirdButtonRef.querySelector('.circle-green');
        circlePathElement2.style.strokeDashoffset = 120;
    }; 

     */

/*
  

   const [characterPosition, setCharacterPosition] = useState(0);

    const [isJumping, setIsJumping] = useState(false);

    const [currentSection, setCurrentSection] = useState(0);

// Increment character position, reset at the end
    const advanceCharacter = () => {
        setCharacterPosition((oldPosition) => {
            let newPosition = oldPosition + 1;
            if (newPosition >= buttonRefs.current.length) {
                newPosition = 0;
            }
            return newPosition;
        });
    };

    // Trigger the character's jump
    const jumpCharacter = () => {
        setIsJumping(true);
        // The % operator ensures that the section index wraps around to 0 when it reaches the end of the list, creating a continuous loop.
        setCurrentSection((oldSection) => (oldSection + 1) % sectionPositions.length);
        setTimeout(() => setIsJumping(false), 500); // Adjust timing as needed
    };

 
  // Animate buttons and move character when position changes
  useEffect(() => {
    const btnRef = buttonRefs.current[characterPosition];
    if (btnRef) {
      btnRef.changePercent(100, 'close');
      jumpCharacter();
      setTimeout(() => {
        advanceCharacter();
        btnRef.changePercent(buttonData[characterPosition].percentCompleted, 'open');
      }, 2600); // Adjust timing as needed
    }
  }, [characterPosition]);
*/
 