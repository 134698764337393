import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { LogInLoader } from './authActions';
/**
* the database should be 
* collection / doc / collection
* feedbackMsgs/<userid>/messages/
* I don't know what datastructure i should choose 
* 
*../components/SimplestFeedback/SimplestFeedbac 
*/
export const addFeedback = ({payload, uid})  => async (dispatch) => {
   firebase
   .firestore()
   .collection("feedbackMsgs")
   .doc(uid)
   .collection("msgs")
   .add({
       payload,
       createdAt: firebase.firestore.Timestamp.now(),
   })
   .then(() => {
    
       alert(`Answer Submitted Successfully`);
  
       dispatch({
        type: 'ADD_FEEDBACK',
        payload: payload
      });
    })
    .catch((error) => {
      // Handle the error appropriately
      console.error("Error submitting feedback: ", error);
    });
 };
     /*   let newData = await firebase
            .firestore()
            .collection("stats")
            .doc(sessionId)
            .get();
          if (!newData.exists) {
            firebase.firestore().collection("stats").doc(sessionId).set({
              mdq: [],
            });
          }
          /sessions/{sessionId}/results/{part}/
          */
/*

https://www.tiktok.com/@king_prankt.v/video/7296678385122807086?is_from_webapp=1&sender_device=pc&web_id=7206458293601224238

          export const saveNewCertData = (payload, uid) => async (dispatch) => {
            try {
              const res = await firebase.firestore().collection("letsUpgradeYou").where("userId", "==", uid).get();
              let letsUpgradeYouId;
          
              if (res.size > 0) {
                // Existing session
                letsUpgradeYouId = res.docs[0].id;
              } else {
                // New session
                letsUpgradeYouId = uuidv4();
                await firebase.firestore().collection("letsUpgradeYou").doc(letsUpgradeYouId).set({
                  userId: uid,
                  createdAt: firebase.firestore.Timestamp.now(),
                });
              }
          
              // Save activity
              const activityRes = await firebase.firestore().collection("letsUpgradeYou").doc(letsUpgradeYouId)
                .collection("activities").doc(payload.certDataId).set({
                  userId: uid,
                  ...payload, // Spread payload properties
                  createdAt: firebase.firestore.Timestamp.now(),
                });
          
              // Dispatch actions after successful save
              dispatch(LogInLoader(true));
              // You need to replace 'doc.id' and 'doc.data()' with correct references
              dispatch({
                type: "SET_UPGRADE_ACTIVITIES",
                payload: { id: activityRes.id, data: activityRes.data() },
                part: payload.certDataId,
              });
              dispatch(LogInLoader(false));
              alert(`Activity Submitted Successfully`);
            } catch (err) {
              console.error(err);
              // Handle error, possibly dispatch an error action or show error message
            }
          };
*/

/**
 * 
 *  const payload = {
      whichDidYouDo: whichDidYouDo,
      answer: answer,
      linkedUserVideo: linkedUserVideo,
      certDataId: certData.id
    };
payload.certDataId
 * @param {*} payload 
 * @param {*} uid 

https://www.tiktok.com/@woesenpai69/video/7273138974586080554?is_from_webapp=1&sender_device=pc&web_id=7206458293601224238 
@returns 
 */
 
export const saveNewCertData =
  (payload, uid) => async (dispatch) => {
    console.log('in side saveNewCertData with ', payload, uid);
  try {
    let letsUpgradeYouId = null;

      const res = await firebase
        .firestore()
        .collection("letsUpgradeYou")
        .where("userId", "==", uid)
        .get();

      console.log(res.size, "size");
      // if it exists already
      if (res.size > 0) {
          console.log("enter in if found record for user.");
          letsUpgradeYouId = res.docs[0].id;
          console.log( "letsUpgradeYouId", letsUpgradeYouId);
      } else {
          
          console.log("enter none found");
          letsUpgradeYouId = uuidv4();
        
          await firebase
            .firestore()
            .collection("letsUpgradeYou")
            .doc(letsUpgradeYouId)
            .set({
              userId: uid,
              createdAt: firebase.firestore.Timestamp.now(),
            })
      }

      const activityRes = await firebase
          .firestore()
          .collection("letsUpgradeYou")
          .doc(letsUpgradeYouId)
          .collection("activities")
          .doc(payload.certDataId)
          .set({
            userId:uid,
            payload,
            createdAt: firebase.firestore.Timestamp.now(),
          });
          console.log('activityRes=',activityRes);
          console.log('activityRes=',{ id: payload.certDataId, data: payload });
          dispatch(LogInLoader(true));
          dispatch({
              type: "SET_UPGRADE_ACTIVITIES",
              payload: { id: payload.certDataId, data: payload },
              part: payload.certDataId,
            });
          dispatch(LogInLoader(false));
          console.log(`Activity Submitted Successfully`);
          } catch (err) {
            console.error(err);
            // Handle error, possibly dispatch an error action or show error message
          } 
  };


let activitiesSnapshots = [];
export const unSubscribeActivitiesSnapshot = () => {
  activitiesSnapshots.forEach(unsubscribe => {
    unsubscribe();
  });
  activitiesSnapshots = [];
};

export const getNewCertData = (uid) => async (dispatch) => {
  console.log("getCertDataToDB");
  firebase
    .firestore()
    .collection("letsUpgradeYou")
    .where("userId", "==", uid)
    .get()
    .then((res) => {
      const ids = ['cHomeCooking', 'cReferee', 'cCBT', 'cTop3', 'cBlackPride', 'cvalue','cPhysicallyFit','cPhysicallyFit2'];
        ids.forEach(id => {
          let snapshot = firebase
            .firestore()
            .collection("letsUpgradeYou")
            .doc(res.docs[0].id)
            .collection("activities")
            .doc(id)
            .onSnapshot(
              (doc) => {
              if (doc.exists) {
                dispatch({
                  type: "SET_UPGRADE_ACTIVITIES",
                  payload: { id: doc.id, data: doc.data() },
                  part: id,
                });
              }
            },
            (err) => {
              console.error('in getCompleteActivities Snapshot listener error:', err);
            });
            activitiesSnapshots.push(snapshot);
        });
    })
    .catch(err=>{
      console.error('in getCompleteActivities =', err);
    });
};

export const addSessions =
  (payload, part, uid, scoreObj) => async (dispatch) => {
   console.log('payload, part, uid, scoreObj', payload, part, uid, scoreObj); 
  
    firebase
      .firestore()
      .collection("sessions")
      .where("userId", "==", uid)
      .where("active", "==", true)
      .get()
      .then(async (res) => {
        console.log(res.size, "size");
        if (res.size > 0) {
          console.log("enter in if");
          let sessionId = res.docs[0].id;
          console.log(sessionId, "sessionid");
  
          firebase
            .firestore()
            .collection("sessions")
            .doc(sessionId)
            .collection("results")
            .doc(part)
            .set({
              userId:uid,
              payload,
              score: scoreObj || "",
            })
            .then(() => {
              alert(`${part} Answer Submitted Successfully`);
            });
        } else {
          // new Session
          console.log("enter in else");
          let sessionId = uuidv4();
        
          firebase
            .firestore()
            .collection("sessions")
            .doc(sessionId)
            .set({
              userId: uid,
              createdAt: firebase.firestore.Timestamp.now(),
              active: true,
            })
            .catch(err=>{
              console.error('1 err',err);

            })
            .then((res) => {

             
              firebase
                .firestore()
                .collection("sessions")
                .doc(sessionId)
                .collection("results")
                .doc(part)
                .set({
                  userId: uid,
                  payload,
                  score: scoreObj,
                })
                .catch(err=>{
                  console.error('2 err',err);
    
                })
                .then((res) => {
                  alert(`${part} Answer Submitted Successfully`);
                });
            });
        }
      });
  };

export const getQuestions = (uid, part) => async (dispatch) => {
  console.log('getQuestions= ', uid, part);
  firebase
    .firestore()
    .collection("sessions")
    .where("userId", "==", uid)
    .where("active", "==", true)
    .get()
    .then((res) => {
      if (res.size > 0) {
        firebase
          .firestore()
          .collection("sessions")
          .doc(res.docs[0].id)
          .collection("results")
          .doc(part)
          .get()
          .then((query) => {
            if (query.data()) {
              console.log("Res", query.id, query.data());
              dispatch({
                type: "GET_QUESTIONS",
                part: part, // add part identifier 
                payload: query.data().payload,
              });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        // alert("Login first to continue");
      }
    })
    .catch((err) => {
      // alert(err.message);
    });
};
let snapshots = [];

export const getCompleteResult = (uid) => async (dispatch) => {
  console.log("getCompleteResult");
  firebase
    .firestore()
    .collection("sessions")
    .where("userId", "==", uid)
    .where("active", "==", true)
    .get()
    .then((res) => {
      if (res.size > 0) {
        for (let i = 1; i <= 6; i++) {
          let snapshot = firebase
            .firestore()
            .collection("sessions")
            .doc(res.docs[0].id)
            .collection("results")
            .doc(`part${i}`)
            .onSnapshot((doc) => {
              if (doc.exists) {
                dispatch({
                  type: "SET_QUESTION",
                  payload: { id: doc.id, data: doc.data() },
                });
              }
            });
          snapshots.push(snapshot);
        }
      }
    });
};

export const clearSession = () => async (dispatch, getState) => {
  let { uid } = getState().authUser;
  let activeSessionDocs = await firebase
    .firestore()
    .collection("sessions")
    .where("userId", "==", uid)
    .where("active", "==", true)
    .get();
  for (let activeSessionDoc of activeSessionDocs.docs) {
    await firebase
      .firestore()
      .collection("sessions")
      .doc(activeSessionDoc.id)
      .update({ active: false });
  }
  window.location.href = "/admin/mypersonalitytest";
};
