import BottomDrawer from "components/createProfileSteps/BottomDrawer";
import DreamPlace from "components/createProfileSteps/DreamPlace";
import FinalStep from "components/createProfileSteps/FinalStep";
import Gender from "components/createProfileSteps/Gender";
import InterestedGender from "components/createProfileSteps/InterestedGender";
import Interests from "components/createProfileSteps/Interests";

import MatchingLocation from "components/createProfileSteps/MatchingLocation";
import RelationShipGoals from "components/createProfileSteps/RelationShipGoals";
import Types from "components/createProfileSteps/Types";
import NewHeader from "components/newHeader/NewHeader";
import firebase from '../../config/firebase';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// i needed to add useHistory to get the rerouting to work.
import { useHistory, Link } from "react-router-dom";
import AgeRangeBg from "../../assets/images/age-range-bg.png";
import BackBtnIcon from "../../assets/images/back-btn.svg";
import Diamond from "../../assets/images/diamond-orange-200.png";
import DreamPlaceBg from "../../assets/images/dream-place.png";
import GenderBg from "../../assets/images/gender-bg.png";
import InterestBg from "../../assets/images/interest-bg.png";
import InterestedGenderBg from "../../assets/images/interested-gender.png";
import MatchingLocationBg from "../../assets/images/matching-location.png";
import RelationShipGoalsBg from "../../assets/images/relationship-goal.png";
import TypesBg from "../../assets/images/types-bg.png";
import FinalStepBG from "../../assets/images/DESKTOPUX-version-mobile.jpg";
import AgeRange from "../../components/createProfileSteps/AgeRange";
// using React Context
import { setOnboardingStatusFromLocalStorage } from '../../helpers/referrerStorageUtils';
import { updateProfile } from '../../store/actions/authActions';
import "./createProfile.scss";
import { LogInLoader } from '../../store/actions/authActions';
import {
	LOGIN,
	LOGIN_LOADER,
	LOGOUT,
	REGISTER_LOADER,
	USER_FRIENDLY_ERR_MSG,
	SIGNUP,
} from '../../store/actionTypes';
import CryptoJS from "crypto-js";
const CreateProfile = () => {
 
  // this is the redirection code.
  const { uid, user } = useSelector((state) => state.authUser);
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      if (uid && user?.onBoardingData) { // completed onboarding go to user profile 
        history.push("/admin/user-profile");
      } else if (!uid) { // if not logged in - log him in .
        history.push("/admin/home#signin"); // 
      }
    }, 500);
  }, [uid, user?.onBoardingData]);
 

  const [current, setCurrent] = useState(0);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [interestedGender, setInterestedGender] = useState('');
  const [ageRange, setAgeRange] = useState([]);
  const [usersAge, setUsersAge] = useState([]);
  const [myTypes, setMyTypes] = useState([]);
  const [myLikeTypes, setMyLikeTypes] = useState([]);
  const [relationshipGoal, setRelationshipGoal] = useState('');
  const [radius, setRadius] = useState([]);
  const [city, setCity] = useState('');
  const [dreamLocation, setDreamLocation] = useState([]);
  const [profileInfo, setProfileInfo] = useState({
    imageUrl: "",
    nickName: "",
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    country: "",
    youtubeURL: "",
    tikTokURL: "",
  });

  const [completed, setCompleted] = useState(false);
  const [agreed, setAgreed] = useState('');

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      content: (
        <Interests
          selectedInterests={selectedInterests}
          setSelectedInterests={setSelectedInterests}
        />
      ),
      bgImg: InterestBg,
      cls: 'create-profile--interests',
    },
    {
      content: (
        <Gender
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
        />
      ),
      bgImg: GenderBg,
      cls: 'create-profile--gender',
    },
    {
      content: (
        <InterestedGender
          interestedGender={interestedGender}
          setInterestedGender={setInterestedGender}
        />
      ),
      bgImg: InterestedGenderBg,
      cls: 'create-profile--gender',
    },
    {
      content: <AgeRange ageRange={ageRange} setAgeRange={setAgeRange} usersAge={usersAge} setUsersAge={setUsersAge} />,
      bgImg: AgeRangeBg,
      cls: 'create-profile--ageRange',
    },
    {
      content: (
        <Types
          myTypes={myTypes}
          setMyTypes={setMyTypes}
          myLikeTypes={myLikeTypes}
          setMyLikeTypes={setMyLikeTypes}
        />
      ),
      bgImg: TypesBg,
      cls: 'create-profile--types',
    },
    {
      content: (
        <RelationShipGoals
          relationshipGoal={relationshipGoal}
          setRelationshipGoal={setRelationshipGoal}
        />
      ),
      bgImg: RelationShipGoalsBg,
      cls: 'create-profile--relationshipGoals',
    },
    {
      content: (
        <MatchingLocation
          radius={radius}
          city={city}
          setRadius={setRadius}
          setCity={setCity}
        />
      ),
      bgImg: MatchingLocationBg,
      cls: 'create-profile--matchingLocation',
    },
    {
      content: (
        <DreamPlace
          dreamLocation={dreamLocation}
          setDreamLocation={setDreamLocation}
        />
      ),
      bgImg: DreamPlaceBg,
      cls: 'create-profile--dreamPlace',
    },
    {
      content: (
        <FinalStep
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
          completed={completed}
          setCompleted={setCompleted}
          agreed={agreed}
          setAgreed={setAgreed}
        />
      ),
      bgImg: FinalStepBG,
      cls: 'create-profile--finalStep',
    },
  ];


   // Function to save form data to local storage
   // onboardingData.profileInformation.img 
   const saveOnboardingDataToLocalStorage = (data) => {
    localStorage.setItem("onboardingData", JSON.stringify(data));
  };

   // Function to get form data from local storage
   const getOnboardingDataFromLocalStorage = () => {
    const data = localStorage.getItem("onboardingData");
    return data ? JSON.parse(data) : null;
  };


   // When the component mounts, check for data in local storage and set the initial state
   useEffect(() => {
    const formData = getOnboardingDataFromLocalStorage();
    if (formData) {
      setSelectedInterests(formData.interests || []);
      setSelectedGender(formData.gender || "");
      setInterestedGender(formData.interestedGender || "");
      setAgeRange(formData.ageRange || []);
      setUsersAge(formData.usersAge || []);
      setMyTypes(formData.type || []);
      setMyLikeTypes(formData.likedType || []);
      setRelationshipGoal(formData.relationshipGoal || "");
      setRadius(formData.radius || []);
      setCity(formData.city || "");
      setDreamLocation(formData.dreamLocation || []);
      setProfileInfo(formData.profileInformation || {
        img: "",
        imageUrl: "",
        nickName: "",
        firstName: "",
        lastName: "",
        email: "",
        birthDate: "",
        country: "",
        youtubeURL: "",
        tikTokURL: "",
      });
      setAgreed(formData.agreed || "");
    }
  }, []);

 
  

  const dispatch = useDispatch();

/*
DOCUMENTATION about setting the user data 
from onboarding and profile summary updates 

CreateProfile.js 
1. Data is collected in CreateProfile.js from all the onboarding pages.
2. handleSubmit collects all of this information
3. and updates it to the users datastore. 

The presentation of summary is ordered by the 
array in  statesProfile.js 
This includes the 
 firstname, 
 lastname, 
 nickename, 
 tiktokURL and 
 youtubeURL.

this information is currently duplicated  
 - at the root of the users object 
 - in the onBoardingData.profileInformation

 Therefore, it needs to be updated in both places 

 this pattern is also being used in authActions.js

*/

  const updateAuthUser = (updatedUserData) => {

    const checkObj = localStorage.getItem('auth');
    const authUser = JSON.parse(
      CryptoJS.AES.decrypt(checkObj, 'my-secret-key').toString(
      CryptoJS.enc.Utf8
        )
    );

    let updatedObj = { 
    ...authUser, 
    user: updatedUserData 
    };

    const finalData = CryptoJS.AES.encrypt(
    JSON.stringify(updatedObj),
    'my-secret-key'
    ).toString();
    localStorage.setItem('auth', finalData);

  }; 


  const handleSubmit = async(e) => {
    // e.preventDefault();
   
    // Form data to be saved in local storage 
    // this is onBoardingData fields 
    const formData = {
      interests: selectedInterests,
      gender: selectedGender,
      interestedGender: interestedGender,
      ageRange: ageRange,
      usersAge: usersAge,
      type: myTypes,
      likedType: myLikeTypes,
      relationshipGoal: relationshipGoal,
      radius: radius,
      city: city,
      dreamLocation: dreamLocation,
      profileInformation: {
        img: profileInfo?.imageUrl || '',
        imageFileName: profileInfo?.imageFileName || '', // Include the file name here
        nickName: profileInfo?.nickName || '',
        firstName: profileInfo?.firstName || '',
        lastName: profileInfo?.lastName || '',
        email: profileInfo?.email || '',
        birthDate: profileInfo?.birthDate || '',
        country: profileInfo?.country || '',
        youtubeURL: profileInfo?.youtubeURL || '',//previousl youtubeVideos
        tikTokURL: profileInfo?.tikTokURL || '',
      },
      permission: agreed,

    };

    // Step 1: Retrieve the existing user data
    const db = firebase.firestore();
    const userRef = db.collection("users").doc(uid);
    const userDoc = await userRef.get();
    // Step 2: Merge existing user data with the new onboarding data
    const existingUserData = userDoc.exists ? userDoc.data() : {};

    // these fields are the  root of the users/<uid> fields 
    const unfortunateRedundancy = {
      nickName: profileInfo?.nickName ?? '',
      firstName: profileInfo?.firstName ?? '',
      lastName: profileInfo?.lastName ?? '',
      youtubeURL: profileInfo?.youtubeURL ?? '', //previousl youtubeVideos
      tikTokURL: profileInfo?.tikTokURL ?? '',
    };
    
    console.log('existingUserData ', {...existingUserData});
    console.log('unfortunateRedundancy ', {...unfortunateRedundancy});

    // for easy reading and update in the user summary 
    const personalityDetails = {
      ...unfortunateRedundancy,
      DreamLocation: formData?.dreamLocation ?? [],//stringfy output in personalitiy summary 
      ForfunILikeTo: formData?.interests ?? [],
      RelationshipGoal: formData?.relationshipGoal ?? "",
      RelationshipNow :"",
      Top2FavTVMovieGenre: "",
      Top2FavYoutubeCategory: "",
      TypeIAm : formData?.type ?? [],
      TypeILike: formData?.likedType ?? [],
      workGoals: "",
      age:formData?.usersAge ?? "",
      CommunicationStyle: '',
		  LoveLanguage: '',
    };

    const updatedUserData = { 
      ...existingUserData, 
      ...unfortunateRedundancy,
      personalityDetails: personalityDetails,
      onBoardingData: formData, 
      isOnboarded:true // indicates user is onboarded 
    };
      console.log('updatedUserDatay ', {...updatedUserData});
     // Step 3: Update the user's document with the merged data
     await userRef.set(updatedUserData);

     // save for auth store 
     updateAuthUser(updatedUserData);
    // Save the form data to local storage
    // Problem it did not save 
    // nothing is localstorage 
    saveOnboardingDataToLocalStorage(formData);
     setOnboardingStatusFromLocalStorage('dynamicOb');
     dispatch(LogInLoader(true));
     if (uid && updatedUserData) {
     dispatch({
       type: LOGIN,
       payload: {
         uid: uid,
         user: { 
           id: uid,
           ...updatedUserData
         },
       },
     });
    } else {
      console.error('uid or updatedUserData is null', uid, updatedUserData);
    }
     dispatch(LogInLoader(false));


    }; 

       // commented out because the above line handles it 
     // updateProfile is not uptodate 
     //dispatch( updateProfile(uid, updatedUserData) );


    // Clear the form after submission
    /*
    setProfileInfo({
      imageUrl: "",
      nickName: "",
      firstName: "",
      lastName: "",
      email: "",
      birthDate: "",
      country: "",
      youtubeURL: "",
      tikTokURL: "",
    });
*/
    // setCompleted(true);



   // Step validation function
   const handleStepValidation = () => {
    switch (current) {
      case 0:
        return selectedInterests.length > 0;
      case 1:
        return selectedGender !== '';
      case 2:
        return interestedGender !== '';
      case 3:
        return ageRange.length > 0;
      case 4:
        return myTypes !== "" && myLikeTypes !== "";
      case 5:
        return relationshipGoal !== '';
      case 6:
        return radius.length > 0 && city !== '';
      case 7:
        return dreamLocation !== "";
      case 8:
        // You can add additional validation for the final step if needed
        return profileInfo?.nickName !== "" && profileInfo?.firstName !== "" && profileInfo?.lastName !== "" && profileInfo?.email !== "" && profileInfo?.birthDate !== "" && profileInfo?.country !== "";
       
        default:
        return false;
    }
  };

 
  return (
    <div className="create-profile">
     {/*  <NewHeader /> no black header pompting them to sign out when they should be onboarding. */}
      <form  className={`${steps[current].cls}`}
        
        style={{ backgroundImage: `url(${steps[current].bgImg})` }}
      >
        {current > 0 && (
          <button type="button" className="back-btn" onClick={() => prev()}>
            <img src={BackBtnIcon} alt="icon" />
          </button>
        )}

        {steps[current].content}

        <div className="button-wrapper">
          {current < steps.length - 1 && (
            <button
              type="button"
              className={`next-btn ${current > 0 && "next-shadow"}`}
              onClick={() => next()}
              disabled={!handleStepValidation()}
            >
              NEXT
            </button>
          )}

          {current === steps.length - 1 && (
            <button
              type="button"
              className={`next-btn ${current > 0 && "next-shadow"}`}
              style={{ width: "300px" }}
              disabled={!handleStepValidation()}
              onClick={()=> setCompleted(true)}
            >
              Complete
            </button>
          )}
        </div>

        <BottomDrawer
          completed={completed}
          setCompleted={setCompleted}
          agreed={agreed}
          setAgreed={setAgreed}
          handleSubmit={handleSubmit}
        />
      </form>

      <div className="footer">
        <div className="container py-4">
          <div className="d-flex flex-wrap justify-content-around">
            <p className="m-color">&copy; 2023 GOGExperience</p>
            <Link to="/" className="footer-link">
              Privacy policy
            </Link>
            <Link to="/" className="footer-link">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
/*
     <div className="diamond">
        <img className="img-fluid" src={Diamond} alt="img" />
      </div>

*/