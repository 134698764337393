import { isTemplateMiddle } from 'typescript';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	CardTitle,
	CardText,
	TabPane,
	Label,
} from 'reactstrap';
import React, { useState } from 'react';
import { stats } from 'helpers/statesProfile';
import { useSelector } from 'react-redux';
import heightImg from '../assets/img/brand/image2.png';
import { mySkinTonePreferences as skinTonePreferenceTemp } from 'helpers/statesProfile';

const PersonalityPreferences = ({
	detailPreference,
	setDetailPreference,
	mySkinTonePreferences,
	setMySkinTonePreferences,
}) => {


	/*
	export const stats = [
		{
			left: 'Wants a poly family',
			right: 'Want a mono family',
			value: 80,
			key: 'polyVsMono',
		},
		{
			left: 'Wants Church As a Family Tradition',
			right: 'Does NOT Want Church as a Family Tradition',
			value: 80,
			key: 'churchTradition',
		},
	*/
	const getLabels = (key, type) => {
		let obj = stats?.find(item => {
			return item.key == key;
		});
		return type == 'left' ? obj?.left : obj?.right;

		/* 
		if (user) {
			let obj = stats?.find(item => {
				return item.key == key;
			});
			return type == 'left' ? obj?.left : obj?.right;
		} else {
			let obj = stats?.find(item => {
				return item.key == key.key;
			});
			return type == 'left' ? obj?.left : obj?.right;
		}
		*/
	};

	const handleChange = (item, value) => {
		setDetailPreference(prevState => ({
			...prevState,
			[item]: value,
		}));
	};

	const handleCheckChange = key => {
		setMySkinTonePreferences(prevState => ({
			...prevState,
			[key]: !prevState[key],
		}));
	};

	const getSkinTones = user => {
		if (user == null) {
			return skinTonePreferenceTemp;
		} else {
			return mySkinTonePreferences;
		}
	};

	const getDataObject = user => {
		if (user == null) {
			return stats;
		} else {
			return detailPreference;
		}
	};

	const { user } = useSelector(state => state.authUser);

	const [showCaption, setShowCaption] = useState(null);  // Step 1: Create a state variable
	//console.log('stats', stats);
	//console.log('mySknTone',skinTonePreferenceTemp);
	
	const handleShowCaption = (e, caption) => {  // Step 2: Update state variable
		e.preventDefault();
	    setShowCaption(caption);
	    console.log('showCaption =', showCaption);
	};
  
	const closeTooltip = () => {
	  setShowCaption(null);
	};

	/* stats 
		[
			{
				left: 'Wants a poly family',
				right: 'Want a mono family',
				value: 80,
				key: 'polyVsMono',
			},
			{
				left: 'Wants Church As a Family Tradition',
				right: 'Does NOT Want Church as a Family Tradition',
				value: 80,
				key: 'churchTradition',
			},
		];

		dataObject = { 
			TypeIAm: "corn ball", 
			TypeILike: "corn ball",
			workGoals: "ngo"
			CommunicationStyle: "action", 
			DreamLocation:  "bali",
			ForfunILikeTo: "piano",
			LoveLanguage: "touch",
			RelationshipGoal: "family",
			RelationshipNow:  "complex",
			Top2FavTVMovieGenre: "sci fi movie",
			Top2FavYoutubeCategory: "",

		};
	*/
	const getOrderlyProfileDetails = (dataObject, stats) => {
		return stats.map(detail => ({
		  label: detail.label,
		  value: dataObject[detail.key] || '',
		  detailedInfo: detail.detailedInfo,
		  key: detail.key,
		  left: detail.left,
		  right: detail.right,
		}));
	  };

	const dataObject = getDataObject(user);
	
	const skinToneObject = getSkinTones(user);

	const orderedProfileDetails = getOrderlyProfileDetails(dataObject, stats);

	return (
		<>
			{showCaption && (
				<div className="custom-tooltip">
					<div dangerouslySetInnerHTML={{ __html: showCaption }}></div>
					<button onClick={closeTooltip}>Close</button>
				</div>
			)}
			{orderedProfileDetails &&
				orderedProfileDetails.map(detail => {
					return (
						<div key={detail.key} className='profile__stat justify-content-center'>
							<Row className=' px-md-3 my-4 px-1 justify-content-center'>
								<Col
									xs='12 '
									className='profile__stat--slider d-flex justify-content-center'
								>
									<FormGroup>
										<Input
											type='range'
											value={detail.value  || ''}
											onChange={e =>
												handleChange(
													detail.key,
													e.target.value
												)
											}
										/>
									</FormGroup>
								</Col>

								<Col
									xs='3'
									md='4'
									className='profile__stat--left d-flex justify-content-start '
								>
									{' '}
									{getLabels(detail.key, 'left')}
								</Col>
								<Col
									xs='6'
									md='4'
									className=' profile__stat--icons d-flex justify-content-center'
								>
									{' '}
						
									<Button onClick={e => handleShowCaption(e, detail.detailedInfo)}> <i className='fa fa-info-circle pr-1'></i></Button>
									{/* <i className='fa fa-plus-circle pl-1'></i> */}
								</Col>
								<Col
									xs='3'
									md='4'
									className='profile__stat--right d-flex justify-content-end'
								>
									{' '}
									<span className=' text-right '>
										{getLabels(detail.key, 'right')}
									</span>
								</Col>
							</Row>
						</div>
					);
				})}
			<Row>
				<Col md={12} className='m-auto'>
					<img src={heightImg} width='100%' />
				</Col>
			</Row>
			<p className='text-center white-text profile-preferences-sk'>
				Some people have special preferences in skin color. <br/>
				Do you have a preference?<br/>
				 You can check all if you have no preference.<br/> 
				or check the right one or a few. <br/>
				{ /* Check the skin color you are Preferences for a relationships/spouse */ }
			</p>
			<p className='text-center  white-text profile-preferences-sk'>
				<b>This info will not be public it will be kept private</b>
			</p>

			<div className='d-flex align-items-center justify-content-around flex-wrap mx-2'>
				{skinToneObject &&
					Object.keys(skinToneObject)?.map((key, index) => {
						return (
							<React.Fragment key={index}>
								<FormGroup>
									<input
										type='checkbox'
										onChange={() => handleCheckChange(key)}
										checked={skinToneObject[key]}
										disabled={user == null ? true : false}
									/>
									<Label className='pl-1'>{key}</Label>
								</FormGroup>

								{/* <FormGroup check>
                <Input type="checkbox" value={mySkinToneDetails[key]} />
              </FormGroup> */}
							</React.Fragment>
						);
					})}
			</div>
		</>
	);
};

export default PersonalityPreferences;
