import React, { useEffect, useState } from 'react';

/* import seal1 from '../../assets/img/icons/seal-for-womens-certificate-test@2x.png';
import seal2 from '../../assets/img/icons/seal-for-both-leaders-certificate-test@2x.png';
import seal4 from '../../assets/img/icons/seal-for-mens-certificate-test@2x.png';
import seal5 from '../../assets/img/icons/seal-for-both-men-and-womens-certificate-test@2x.png';
*/
import seal1 from '../../assets/img/icons/new-certificates-8.png'; 
import seal2 from '../../assets/img/icons/new-certificates-7.png';
import seal4 from '../../assets/img/icons/new-certificates-5.png'; 
import seal5 from '../../assets/img/icons/new-certificates-3.png';


import {Modal, ModalBody, Button, Container, Col, Row } from 'reactstrap';

const BadgesSection = (user)  => {
    // todo make sure i'm fetching the ones they earned with some additonal info 
    const [sealOverlay, setSealOverlay] = useState(null);

    const handleSeal = (e) => {
        setSealOverlay(!sealOverlay);
    };

return ( 
    <React.Fragment>
        <div className='certificate-seals'>
            <img  onClick={handleSeal} className='certificate-seal' src={seal1} alt='seal of completion of skill' /> 
            <img  onClick={handleSeal} className='certificate-seal' src={seal2} alt='seal of completion of skill' /> 
            <img  onClick={handleSeal} className='certificate-seal' src={seal4} alt='seal of completion of skill' /> 
            <img  onClick={handleSeal} className='certificate-seal' src={seal5} alt='seal of completion of skill' /> 
        </div>
        <Modal isOpen={sealOverlay} toggle={handleSeal} centered={true} className='p-0'>
            <ModalBody className="u-gradient--style-1">
                <div className='p-4 d-flex justify-content-center align-items-center'>
                    <img className='certificate-seal--larger' src={seal2} alt='seal of completion for a relationship skill.' />
                    <p className='p-2'>
                    These icons are faded for now. But, these are the certificates you will earn in the section called Let's Upgrade You.<br/>
                    Not only does this app match you up on personality, values and goals, but it have activities to upgrade you.
                    </p>
                </div>
            </ModalBody>
        </Modal>
    </React.Fragment>
)};

export default BadgesSection;