import React, { useEffect, useState } from 'react';
import { YouTubeEmbed } from 'react-social-media-embed';
 
 // src/components/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo.jsx
 // import EmbeddedYoutubeVideo from '../../components/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo';
const EmbeddedYoutubeVideo = ({path, width, aspectRatio = '16:9'}) => {
	
	const [youtubeProps, setYoutubeProps] = useState({
		ypath:path,
	    ywidth:width,
		yheight:0
	});

	useEffect(() => {
		let height = null;
		if (aspectRatio ==='16:9') { 
			height = Math.round(width * 9/16);
		} else if ( aspectRatio=='2:3')  {
			height = Math.round(width * 3/2);
		} else {  
			height =  Math.round(width * 9/16);
		}

		setYoutubeProps({
			ypath:path,
			ywidth:width,
			yheight:height
		});
	}, [path, width, aspectRatio]);


	return (

		youtubeProps.ypath && youtubeProps.yheight ? (
		<YouTubeEmbed url={youtubeProps.ypath} width={youtubeProps.ywidth} height={youtubeProps.yheight} />
		): ''
	);
};
export default EmbeddedYoutubeVideo;