import CustomSelect from "components/customSelect/CustomSelect";
import React, { useEffect, useState } from "react";

const Types = ({ myTypes, setMyTypes, myLikeTypes, setMyLikeTypes }) => {

  console.log(myTypes);
  console.log(myLikeTypes);



  const [customMyType, setCustomMyType] = useState('');
  const [customMyLikeType, setCustomMyLikeType] = useState("");


  const options = [ 
    { value: "Adventurous type", label:"Adventurous type"},
    { value: "Artsy type", label:"Artsy type"},
    { value: "Athletic type", label:"Athletic type"},
    { value: "Career-driven type", label:"Career-driven type"},
    { value: "Creative type", label:"Creative types"},
    { value: "DIY type", label:"DIY type"},
    { value: "Extroverted type", label:"Extroverted type"},
    { value: "Foodie", label:"Foodie"},
    { value: "Free-spirited type", label:"Free-spirited type"},
    { value: "Homebody", label:"Homebody"},
    { value: "Humanitarian", label:"Humanitarian"},
    { value: "Intellectual type", label:"Intellectual type"},
    { value: "Introverted type", label:"Introverted type"},
    { value: "Music lover", label:"Music lover"},
    { value: "Nature lover", label:"Nature lover"},
    { value: "Party type", label:"Party type"},
    { value: "Pet lover", label:"Pet lover"},
    { value: "Spiritual type", label:"Spiritual type"},
    { value: "Socially conscious type", label:"Socially conscious type"},
    { value: "Tech-savvy type", label:"Tech-savvy type"},
    { value: "Travel enthusiast", label:"Travel enthusiast"}
  ];


    const handleTypeChange = ({ option, checked }) => {
      if (checked) {
        setMyTypes((prevType) => [...prevType, option]);
      } else {
        setMyTypes((prevType) =>
          prevType.filter((type) => type !== option)
        );
      }
  };
  
   const handleLikeTypeChange = ({ option, checked }) => {
     if (checked) {
       setMyLikeTypes((prevType) => [...prevType, option]);
     } else {
       setMyLikeTypes((prevType) => prevType.filter((type) => type !== option));
     }
   };

    const handleTypeAdd = () => {
      if (customMyLikeType.trim() !== "") {
        setMyTypes((prevSelectedOptions) => [
          ...prevSelectedOptions,
          customMyLikeType,
        ]);
      }
      setCustomMyType('');
  };
  
   const handleLikeTypeAdd = () => {
     if (customMyLikeType.trim() !== "") {
       setMyLikeTypes((prevSelectedOptions) => [
         ...prevSelectedOptions,
         customMyLikeType,
       ]);
     }
     setCustomMyLikeType("");
   };






  return (
    <div className="gender">
      <h3 style={{ textAlign: "left", marginBottom: "0px" }}>Types</h3>

      <CustomSelect
        options={options}
        multiSelectList={myTypes}
        handleChange={handleTypeChange}
        defaultLabel="What TYPE are you?"
      />
      <div className="other-type-wrapper">
        <input
          className="other-type"
          type="text"
          placeholder="If other, enter the Type you are."
          value={customMyType}
          onChange={(event) => setCustomMyType(event.target.value)}
        />
        {customMyType !== "" && (
          <button type="button" onClick={handleTypeAdd}>
            Add
          </button>
        )}
      </div>

      <CustomSelect
        options={options}
        multiSelectList={myLikeTypes}
        handleChange={handleLikeTypeChange}
        defaultLabel="What TYPE do you like?"
      />
      <div className="other-type-wrapper">
        <input
          className="other-type"
          type="text"
          placeholder="If other, enter the Type you like."
          value={customMyLikeType}
          onChange={(event) => setCustomMyLikeType(event.target.value)}
        />
        {customMyLikeType !== "" && (
          <button type="button" onClick={handleLikeTypeAdd}>
            Add
          </button>
        )}
      </div>
    </div>
  );
};

export default Types;
