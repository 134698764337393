import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row  } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import {defaultButtonData } from "helpers/defaultButtonData"
import ButtonCircleGraph from 'components/ButtonCircleGraph/ButtonCircleGraph';
import diamondJewelry from '../../assets/images/transparent-heart-shaped-diamond-.png';
import gogPersonalityLogo from '../../assets/img/brand/logo.c784cb44bc36ebf9431febfa75db79c0.svg';
import { usePersonalityTestResults } from '../../helpers/usePersonalityTestResults';

function PersonalityTestTab(props) {
	
	const { activeTab, setActiveTab, tabs } = props;

	const buttonRefs = useRef([]); // Create a ref to store the button references

	const [shouldFetch, setShouldFetch] = useState(true);

	const saveButtonRef = (ref, index) => { 
		buttonRefs.current[index] = ref; 
	};
	
	const { uid, user } = useSelector((state) => state.authUser);
	// const buttonDataShort = defaultButtonData.slice(0, 6);
	// 
	/*
	I could get it from dispatch or i can get it as seen below
	const { completeResult } = useSelector(state => state.questions);
	*/
	const deepClonedBtn = JSON.parse(JSON.stringify(defaultButtonData));
	console.log('deepClonedBtn =',deepClonedBtn);
	let buttonDataShort = usePersonalityTestResults(deepClonedBtn.slice(0, 6), uid, shouldFetch);
	 
	console.log('buttonDataShort=', buttonDataShort);

	return (
		<>
			<div className="container">
				<div className="row">
				<Link className='u-img--logo-left'  to='/admin/user-profile'><img className='u-img--logo-left' src={gogPersonalityLogo} alt='gogPersonality.com logo' /></Link> 
					<img src={diamondJewelry} className="button-section--heartJewelry rotateY360deg mx-auto d-block img-fluid" id='buttonjs--section--heartJewelry' /> 
				</div>
				<div className="row">
				{buttonDataShort.map((button, index) => (
				<React.Fragment key={index}>      
					<ButtonCircleGraph 
						key={index}
						id={button.id}
						buttonName={button.buttonName}
						percentCompleted={button.percentCompleted}//initial 
						msgData={button.msgData}
						iconResults={button.iconResults}
						iconAlt={button.iconAlt}
						onclick={() => { setActiveTab(button.navId) }}
						ref={(ref) => saveButtonRef(ref, index)}
						classname = {'col-6 col-md-4 col-lg-3'}     
					/>
				
				</React.Fragment>
				))}
				</div>
			</div>
		</>
	);
}
export default PersonalityTestTab;

	
  
    /*
	const isPartDone = name => {
		let finalizedName = name?.toLowerCase().replace(' ', '');
		// console.log(finalizedName,'finalizedName')
		 
		console.log('in isPartDone with param name =', name, 'finalize name = ', finalizedName, ' completeResult=', completeResult, 'completeResult[finalizedName]= ',completeResult[finalizedName] );
		return completeResult[finalizedName]
		? Object.keys(completeResult[finalizedName]).length !== 0
		: false;
	};
	*/

/*
<Row className='personalityt-test d-flex justify-content-center px-lg-5 px-md-5 px-0'>
	{tabs.map(({ label, value }) =>		
	(
		<Col sm='6' md='4' lg='2' xs='6' className='mb-4 mb-lg-0' key={value}>
		
			<div
				className={`personalityt-test__tab px-1 py-2 py-md-3 my-md-2 ${
					value === activeTab
						? 'personalityt-test__selected-tab'
						: isPartDone(label)
						? 'personalityt-test__done-tab'
						: ''
				}`}
				onClick={() => {
					setActiveTab(value);
				}}
			>
				{label}
			</div>
		</Col>
	))}
</Row>
*/