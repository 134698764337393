/// questionsReducers.js
const initialState = {
	questions: {
		part1: [],
		part2: [],
		part3: [],
		part4: [],
		part5: [],
		part6: [],
	  },
	completeResult: {
		part1: {},
		part2: {},
		part3: {},
		part4: {},
		part5: {},
		part6: {},
	},
	letsUpgradeYouActivities: {
		'cHomeCooking':   null, 
		'cReferee':       null,
		'cCBT':           null,
		'cTop3':          null,
		'cBlackPride':    null,
		'cvalue':         null,
		'cPhysicallyFit': null,
		'cPhysicallyFit2': null,
	},
	loading: false,
};

export default function questionsReducer(
	state = initialState,
	action
) {
	const { type, payload, part } = action; 
	switch (type) {
		case 'SET_QUESTION':
			return {
				...state,
				completeResult: {
					...state.completeResult,
					[payload.id]: payload.data.payload,
				},
			};
		case 'SET_UPGRADE_ACTIVITIES':
			return {
				...state,
				letsUpgradeYouActivities:{
					...state.letsUpgradeYouActivities,
					[part]: payload,
				}
			};
		case 'GET_QUESTIONS':
			
			return {
				...state,
				questions: { 
					...state.questions,
					[part]: payload,
				}
			};
		default:
			return {
				...state,
			};
	}
}
