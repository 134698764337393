import React, { useState } from 'react';

const Gender = ({selectedGender, setSelectedGender}) => {
// console.log('selectedGender, setSelectedGender =', selectedGender, setSelectedGender);

     const handleGender = (e) => {
       setSelectedGender(e.target.value);
     };

    return (
      <div className="gender">
        <div>
          <h3>Your Gender</h3>
          <div className="input-wrapper">
            <label
              className={`gender-button ${
                selectedGender === "man" && "active"
              }`}
            >
              <input
                name="yourGender"
                type="radio"
                value={"man"}
                checked={selectedGender === "man"}
                onChange={handleGender}
              />
              Men
              {selectedGender === "man" && (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    fill="#FF5603"
                  />
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </label>

            <label
              className={`gender-button ${
                selectedGender === "woman" && "active"
              }`}
            >
              <input
                name="yourGender"
                type="radio"
                value={"woman"}
                checked={selectedGender === "woman"}
                onChange={handleGender}
              />
              Woman
              {selectedGender === "woman" && (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    fill="#FF5603"
                  />
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </label>
            <label
              className={`gender-button`}
            >
              <input
               
                type="text"
                placeholder='Type your gender'
                value={(selectedGender === "man" || selectedGender === "woman") ? " " : selectedGender}
                onChange={handleGender}
              />
            </label>
          </div>
        </div>
      </div>
    );
};

export default Gender;