
import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import { useDispatch  } from 'react-redux';
import {Input, Container, Label, Form, FormGroup, CustomInput, Button} from 'reactstrap';
import {addFeedback } from '../../store/actions/QuestionsAction.js';
  // end of admin functions 
// import SimplestFeedback from '../../components/SimplestFeedback/SimplestFeedback';
  const SimplestFeedback = ({user, referredBy}) => { 
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState('');
    const [SimplestFeedBack__select_value, setSimplestFeedBack__select_value] = useState('');
    const ANONYMOUS = 'ANONYMOUS';
    const [uid, setUID] = useState( user?.id || ANONYMOUS );
 
    useEffect(() => {
        setUID(user?.id || ANONYMOUS);
    }, [user]);

    const handleFeedBackSubmission = (e) => {
        e.preventDefault();
        const payload = {
            feedback: e.target.feedbackMsg.value,
            email: e.target?.feedbackEmail?.value || user?.email || '',
            phone: e.target?.feedbackPhone?.value || user?.phone || ''
        }; 
        dispatch(addFeedback({payload, uid}));
    };

    const handleChangeSimplestFeedBack__select = (e) => {
        setSimplestFeedBack__select_value(e.target.value);
    };

    const requestType = [
       {'PersQuestions': 'Question About GOG Personality Tests'},
       {'aboutTheTVShow':'Question About GOGPersonality TV Show'}, 
       {'schoolRequest': 'Bring GOGPersonality to my College'}, 
       {'interview': 'Request an Interview'}, 
       {'Collab':'Collab Request'},
       {'otherSupport': 'Other Feedback'}
    ];

    const [userMsgs, setUserMsgs] = useState([]);
/*
    useEffect(() => {

       let updatedUID = uid;
        if (!uid) {
            updatedUID = "ANONYMOUS";
        }

        const db = firebase.firestore();
        const userFeedbackRef = db.collection("feedbackMsgs").doc(updatedUID);
       
 
        // feedbackMsgs/ANONYMOUS/msgs/<msgDocId>/
        // Check if the document exists first
        userFeedbackRef.get().then(docSnapshot => {
            if (docSnapshot.exists) {
                const unsubscribe = userFeedbackRef.collection('msgs').onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            const newMsg = {
                                id: change.doc.id,
                                ...change.doc.data()
                            };
                            setUserMsgs(prevMsgs => [...prevMsgs, newMsg]);
                        }
                    });
                });
    
                return () => unsubscribe(); // Clean up listener on component unmount
            } else {
                console.log(`No document exists for uid: ${updatedUID}`);
            }
        });
    
    }, [user, uid]);
    */
    return (
        <Container className='d-flex justify-content-center u-SimplestFeedBack-container'>
            
            <Form id='u-SimplestFeedBack-form' className='u-SimplestFeedBack-form' onSubmit={handleFeedBackSubmission}> 
                <h4 className='mb-3 u-SimplestFeedBack-form-header'> Send Feedback/Contact Us</h4>
                <FormGroup>
                    <CustomInput 
                        className='u-SimplestFeedBack__select'
                        type="select" 
                        id="SimplestFeedBack__select" 
                        name="SimplestFeedBack__select"
                        value={SimplestFeedBack__select_value}
                        onChange={handleChangeSimplestFeedBack__select}
                        >
                        <option value="">Select A Category</option>
                        {requestType.map((value, i) => (
                            <option value={i} key={i}>{Object.values(value)[0]}</option>
                        ))}
                    </CustomInput>        
                </FormGroup>
               
                { (!user || !user?.id || uid==='ANONYMOUS') && (
                <FormGroup>
                    <Label for='feedbackEmail' className='hidden-label'>Email</Label>
                    <Input id='feedbackEmail' type='email' maxLength='255' className='SimplestFeedBack__txtInput' placeholder='Your Email Address'/> 
                    <Label for='feedbackPhone'  className='hidden-label'>Phone</Label>
                    <Input id='feedbackPhone' type='tel' maxLength='15' className='SimplestFeedBack__txtInput'  placeholder='Your Feedback'/> 
                </FormGroup>
                )}
                <FormGroup>
                    <Label for='feedbackMsg'  className='hidden-label'>Feedback</Label>
                    <Input id='feedbackMsg' type='textarea' maxLength='500' rows='2' placeholder='Your Feedback' required/> 
                </FormGroup>
                <Button className='u-SimplestFeedBack__button' type='submit' color='primary'>Send Feedback</Button>    
            </Form>
           {referredBy && ( <p className='referredby-color'><b> Referred by  {referredBy} </b></p>)}
        </Container>
    );
}; export default SimplestFeedback;




/*

  // this block of code is for admin pages only 
  const [msgsByUID, setMsgsByUID] = useState('');

  const adminGetAllFeedbackByUID = ({uid}) => async (dispatch) => {
    firebase
    .firestore()
    .collection("feedbackMsgs")
    .doc(uid)
    .collection("msgs")
    .then((rez) => {
        setMsgsByUID(rez);
        alert(`Answer Submitted Successfully`);
    });
  };

  const [allMsgs, setAllMsgs] = useState('');

  const adminGetAllFeedback = () => async (dispatch) => {
    firebase
    .firestore()
    .collection("feedbackMsgs") 
    .then((res) => {
        setAllMsgs(res);
        alert(`Answer Submitted Successfully`);
    });
  };


*/