import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const BottomDrawer = ({
  completed,
  setCompleted,
  agreed,
  setAgreed,
  handleSubmit,
}) => {
  console.log(agreed);

  const history = useHistory();

  useEffect(() => {
    setTimeout(()=>{
      if (agreed === "Yes" || agreed === "No") {
        handleSubmit();
  
        history.push("/admin/user-profile");
      }
    },500)
  }, [agreed, handleSubmit, history]);

  return (
    <div className={`bottom-drawer ${completed && "active"}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="5"
        viewBox="0 0 35 5"
        fill="none"
        onClick={() => setCompleted(!completed)}
      >
        <path
          d="M2.8714 2.39648H32.8714"
          stroke="#0D0140"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </svg>

      <div>
        <h6>Permission Request</h6>

        <p>
          Click the checkbox to give us permission to do a quick background
          check.It will not affect your credit or anything like that. Background
          info will never be shared with anyone. It helps us organize
          activities.
        </p>

        <div className="accept-wrapper">
          <label>I Agree</label>
          <input
            type="radio"
            value={"Yes"}
            checked={agreed === "Yes"}
            onChange={() => setAgreed('Yes')}
          />
        </div>
        <div className="accept-wrapper">
          <label>Disagree</label>
          <input
            type="radio"
            value={"No"}
            checked={agreed === "No"}
            onChange={() => setAgreed("No")}
          />
        </div>
      </div>
    </div>
  );
};

export default BottomDrawer;
