import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./onboardingSliders.scss";
import { slidesData } from "helpers/GlobalConstants";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {setOnboardingStatusFromLocalStorage } from 'helpers/referrerStorageUtils';
const OnboardingSliders = () => {

  const location = useLocation()
  const history = useHistory();

  const { uid, user } = useSelector((state) => state.authUser);

  const handleGoToHome = () => {
    history.push("/admin/home");
  }

  const handleGoToLogin = () => {
    setOnboardingStatusFromLocalStorage('staticOb');
    if (uid) {
      if (user?.onBoardingData) { // completed onboarding
        history.push("/admin/user-profile");
      } else {
        history.push("/admin/create-profile");  
      }
      
    } else {
      // history.push("/admin/signin");
      history.push("/admin/home#signin");
    }
  };
  

  const NextArrow = ({ onClick, currentSlide }) => {
    return (
      <button
        className="next-arrow"
        onClick={currentSlide === 8 ? handleGoToLogin : onClick}
      >
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4096 1.02289C11.1197 0.733005 10.6507 0.729931 10.357 1.01599C10.0582 1.30704 10.0547 1.78606 10.3491 2.08153L15.4992 7.25H1.13672C0.722506 7.25 0.386719 7.58579 0.386719 8C0.386719 8.41421 0.722505 8.75 1.13672 8.75H15.4992L10.3508 13.8936C10.0546 14.1896 10.0547 14.6698 10.3512 14.9656C10.647 15.2608 11.1262 15.2606 11.4217 14.965L17.6796 8.70711C18.0701 8.31658 18.0701 7.68342 17.6796 7.29289L11.4096 1.02289Z"
            fill="white"
          />
        </svg>
      </button>
    );
  };
  

  const PrevArrow = ({ onClick, currentSlide }) => (
    <button
      className="prev-arrow"
      onClick={currentSlide === 0 ? handleGoToHome : onClick}
    >
      <svg
        width="22"
        height="13"
        viewBox="0 0 22 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00602 6.00066L8.85399 1.71005L7.76449 0.745813L1.05664 6.68248L7.76449 12.6191L8.85399 11.6549L4.00602 7.3643L21.4058 7.3643V6.00066L4.00602 6.00066Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </button>
  );

  const settings = {
    dots: true,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow currentSlide={8} />,
    prevArrow: <PrevArrow currentSlide={0} />,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

    const mobileSettings = {
      dots: false,
      fade:true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

  return (
    <div className="slider-wrapper">
      <Slider {...settings} className="onboarding-slider">
        {slidesData.map((slide, index) => (
          <div
            key={index}
            className="onboarding-slider-container container-fluid"
          >
            <div className="row onboarding-slider-item">
              <div className="col-5">
                <div className="left-side">
                  <h3>{slide.title}</h3>
                  {slide.description && <p>{slide.description}</p>}
                </div>
              </div>
              <div className="col-7 right-side">
                {slide?.nestedSlides ? (
                  <Slider {...settings2} className="nested-slider">
                    {slide?.nestedSlides?.map((nestedSlide, nestedIndex) => (
                      <div key={nestedIndex} className="nested-slider-item">
                        <div className="nested-slider-img">
                          <img
                            src={location.search==="?i=1" ? nestedSlide.imageUrl2 : nestedSlide.imageUrl}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : slide?.duelNestedSlides ? (
                  <Slider {...settings3} className="duel-nested-slider">
                    {slide?.duelNestedSlides?.map(
                      (duelNestedSlide, duelNestedIndex) => (
                        <div
                          key={duelNestedIndex}
                          className="nested-slider-item"
                        >
                          <div className="nested-slider-img">
                            <img
                              src={duelNestedSlide.imageUrl}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )
                    )}
                  </Slider>
                ) : (
                  <img
                    src={slide.imageUrl}
                    alt={slide.title}
                    className="img-fluid"
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* For Mobile  */}
      <Slider {...settings} className="m-onboarding-slider">
        {slidesData.map((item, index) => (
          <div className="m-slider-container" key={index}>
            <div
              className="m-slider-item"
              style={{ backgroundImage: `url(${item?.mobileImageUrl})` }}
            >
              {item?.nestedSlides && (
                <Slider {...settings2} className="nested-slider">
                  {item?.nestedSlides?.map((nestedSlide, nestedIndex) => (
                    <div key={nestedIndex} className="nested-slider-item">
                      <div className="nested-slider-img">
                        <img
                          src={nestedSlide.imageUrl}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              )}

              {item?.duelNestedSlides && (
                <Slider {...mobileSettings} className="duel-nested-slider">
                  {item?.duelNestedSlides?.map(
                    (duelNestedSlide, duelNestedIndex) => (
                      <div key={duelNestedIndex} className="nested-slider-item">
                        <div className="nested-slider-img">
                          <img
                            src={duelNestedSlide.imageUrl}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    )
                  )}
                </Slider>
              )}

              <div className="content-area">
                <h4 className="slider-title">{item?.title}</h4>
                {item.divider && item.divider}
                {item?.description && <p>{item?.description}</p>}
              </div>
              <div className="dark-overlay"></div>
            </div>
          </div>
        ))}
      </Slider>

      <Link to="/admin/home#signin" className="login-link">
        Jump To Login
      </Link>
    </div>
  );
};

export default OnboardingSliders;
