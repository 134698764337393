
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import './ButtonCircleGraph.css';
import coin1 from '../../assets/img/icons/webm//Coin Explosion 1.webm';
import coin2 from '../../assets/img/icons/webm//Coin Explosion 2.webm'; 
import coin3 from '../../assets/img/icons/webm//Coin Explosion 3.webm';
/*
// custom hook 
const useVideoReplay = (maxLoop) => {

  const videoRef = useRef(null);

const loopCounter = useRef(0); 
useEffect(() => {
  const handleVideoEnd = () => {
    loopCounter.current++;
    if (loopCounter.current < maxLoop) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };
  videoRef.current.addEventListener('ended', handleVideoEnd);
  return () => {
    videoRef.current.removeEventListener('ended', handleVideoEnd);
  };
}, []);
return videoRef;
};
*/
 
 
const ButtonCircleGraphMini = React.forwardRef(
  ({ buttonName='uninitialized', id,  percentCompleted=0,  msgData, ontransitionStart, iconResults, iconAlt, onclick, classname }, 
    ref) => {
      
      //const videoRef = useVideoReplay(7);


      const buttonPercent = useSelector(state => state.button.buttonPercent);

      const [thisid, setButtonId] = useState(id);

      useEffect(() => { 
         if (buttonPercent?.[thisid] && typeof buttonPercent?.[thisid] ==='number') {
          console.log('thisid = ', thisid,'  buttonPercent=', buttonPercent);
         
          const circle = circleRef.current;
          const percent = buttonPercent[thisid]/100;
          const calculated = 151 - (151 * percent);
          circle.setAttribute('percentcompleted', `${buttonPercent[thisid]}%`);
          circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
          
        }
      }, [buttonPercent, id]);

    const circleRef = useRef(null);
 
    const calculatedPercentCompleted =  percentCompleted;
    
    useEffect(() => {
      const circle = circleRef.current;
      const percent = percentCompleted / 100;
      const calculated = 151 - (151 * percent);
      circle.setAttribute('percentcompleted', `${percentCompleted}%`);
      circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
    }, [percentCompleted]);
 

    const [percent, setPercent] = useState(0);

    useImperativeHandle(ref, () => ({
      changePercent
    }));

    const changePercent = (newPercent, closeOrOpen) => {
      const circle = circleRef.current;
      const percent = newPercent / 100;
      const calculated = 151 - 151 * percent;
      circle.setAttribute('percentcompleted', `${newPercent}%`);
      circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
 
       setPercent(newPercent);
      let elmRoot =circle.classList.contains('u-js-animation-parent')? circle : circle.closest('.u-js-animation-parent');
      if (closeOrOpen==='open') {
        elmRoot.classList.remove('is-closed');
      } else if (closeOrOpen==='close') {
        elmRoot.classList.add('is-closed');
        let webmElm = elmRoot.querySelector('.gog-js-iconResult');
        if (webmElm) {
          webmElm.play();
        }
      }
    };

 
    const handleClick = () => {
      console.log('ButtonCircleGraph Button clicked! empty function. should not be called');
    };
  return (
    <div className={`u-js-animation-parent new-avatar--button-row ${classname}`}>
      <div className='u-animated-icon'>
          <video  autoPlay loop preload='auto' className="gog-js-iconResult" src={iconResults} alt={iconAlt} ></video>
     
      </div>
      <div className='u-personalitytest--bar u-js-personalitytest--bar-1' onClick={onclick || handleClick} > <span>{buttonName} </span>
        <div className="circular-graph" ref={circleRef} percentcompleted="">
          <svg className="circle-svg" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle circle-white" cx="50%" cy="50%" r="40%"/>
            <circle className="circle circle-green" cx="50%" cy="50%" r="40%"/>
          </svg>
        </div>
      </div>
    </div>
  );
});
export default ButtonCircleGraphMini;
