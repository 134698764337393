// Import the functions you need from the SDKs you need
/* import { initializeApp } from "firebase/app";

import firebase from "firebase";

import firebase from "firebase/compat/app"; // Import the compat version of the app module
import "firebase/compat/firestore"; // 
import "firebase/compat/auth"; // 
import "firebase/compat/storage"; // Firebase Cloud Storage package for storing and serving user-generated content like images, videos, and files. user generaed content like images videos 
import "firebase/compat/analytics";
import "firebase/compat/functions";
 */
// i need to go back to version 8 
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
 
const firebaseConfig = {
  apiKey: "AIzaSyAsi5syhUNHWp9li4aaiVhQjN0X2KzWUpk",
  authDomain: "gogpersonality.firebaseapp.com",
  projectId: "gogpersonality",
  storageBucket: "gogpersonality.appspot.com",
  messagingSenderId: "90769735585",
  appId: "1:90769735585:web:871b76eaa09c21910c15e0",
  measurementId: "G-WKKEBY4NJL",
};

// Initialize Firebase
//const app = firebase.apps.length ? firebase.app() : firebase.initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
/* 
REMEMBER 

I am forced to use firebase 8.10.1 because firebaseui does not support firebase 9 to 12 

It's not an ideal solution because it requires 
 firebaseui and firebase 9   should solve compilation problems 
  until Firebase UI is updated to support the new import structure in Firebase v9 and onwards.
*/


/* junk 
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firestore } from "firebase/firestore"; // 
import { auth } from "firebase/auth";
import { storage } from "firebase/firestore"; // Firebase Cloud Storage package for storing and serving user-generated content like images, videos, and files. user generaed content like images videos 
import { functions} from "firebase/functions";
 


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsi5syhUNHWp9li4aaiVhQjN0X2KzWUpk",
  authDomain: "gogpersonality.firebaseapp.com",
  projectId: "gogpersonality",
  storageBucket: "gogpersonality.appspot.com",
  messagingSenderId: "90769735585",
  appId: "1:90769735585:web:871b76eaa09c21910c15e0",
  measurementId: "G-WKKEBY4NJL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/