import { relationshipData } from "helpers/GlobalConstants";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import EditIcon from "../../assets/images/Edit.svg";

const RelationShipGoals = ({relationshipGoal, setRelationshipGoal}) => {

  const isDefaultRelationShipData = relationshipData.find(item => item?.title === relationshipGoal);
  
  const [isTextInputSelected, setIsTextInputSelected] = useState(false);
  
  const handleTextEvent = (val) => {
      setIsTextInputSelected(val.length >0?true:false);
      setRelationshipGoal(val);
  };
  return (
    <div className="gender">
      <h3>Relationship Goals</h3>

      <div>
        <Row>
          {relationshipData.map((item, index) => {
            return (
              <Col xs={6} key={index} className="relationship-item-wrapper">
                <div
                  className={`relationship-item ${
                    item.title === relationshipGoal && "active"
                  }`}
                  onClick={() => setRelationshipGoal(item?.title)}
                >
                  <h6>{item?.title}</h6>
                  <img src={item?.imgUrl} alt="img" className="img-fluid" />
                </div>
              </Col>
            );
          })}
        </Row>
        <div className={`optional-input ${ isTextInputSelected && !isDefaultRelationShipData ? " is-selected":"" }`}>
          <label>
            <span>Optional:</span>
            <img src={EditIcon} alt="img" className="img-fluid" />
          </label>
          <input
            type="text"
            placeholder="Type your Relationship Goals."
            value={ isDefaultRelationShipData ? "" : relationshipGoal}
            onChange={(e) => handleTextEvent(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default RelationShipGoals;
