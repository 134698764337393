import React, {useEffect, useState} from 'react'; 
import LoginPanel from 'components/loginPanel/LoginPanel';
import { Link, useLocation } from 'react-router-dom';
import GetStartedImg from '../../assets/images/login-section-img.png';
import SleekerImage from '../../assets/images/another-cute-front-of-the-pcture.png';
import './footer.scss';

const Footer = React.forwardRef((props, ref) =>  {
  const [imageName, setImageName] = useState(GetStartedImg);
  
  const location = useLocation(); // <-- use this to get the current path

  useEffect(() => {
    if (location.pathname.includes('Bet')) {
      setImageName(SleekerImage);
    }
  }, [location.pathname]);

    return (
      <footer ref={ref} id='signin'>
        <div className="container-fluid m-z-index">
          <div className="row align-items-center mobile-spacing">
            <div className="col-lg-6 col-xl-8">
              <div className="img-wrapper">
                <img src={imageName} alt="img" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="text-center">
                <h4 className="fw-700">Let’s get started</h4>
                <LoginPanel />
              </div>
            </div>
          </div>
        </div>

        <div className="container py-4">
          <div className="d-flex flex-wrap justify-content-around">
            <p className="m-color">&copy; 2023 GOGExperience</p>
            <Link to="/" className="m-color">
              Privacy policy
            </Link>
            <Link to="/" className="m-color">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </footer>
    );
});

export default Footer;