import { part6Questions } from "helpers/GlobalConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getQuestions } from "store/actions/QuestionsAction";
import { addSessions } from "store/actions/QuestionsAction";
import { updateButtonPercent } from "store/actions/actions";
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';

import VisualRewards from "../../components/VisualRewards/VisualRewards";
 
  /*
hcl is a misnomer here. it should be mdq.
change it later.
  */

/*  mood 1 mdq  
https://novopsych.com.au/assessments/diagnosis/mood-disorder-questionnaire-mdq */
const PersonalityTestPart6 = () => {

  const [showResult, setShowResult] = useState(false);

  const [scoreAfterSummarize, setScoreAfterSummarize] = useState({
    summary: {
      mainTrait: 'NA',
      secondaryTrait: 'NA',
      mainNegativeTrait: 'NA',
      secondaryNegativeTrait: 'NA',
      percentCompleted: 0,
    }
  });

  let [scoreObj, setScoreObj] = useState(
    JSON.parse(localStorage.getItem("part6")) || {
      hclQuestion1: new Array(part6Questions.questions[0].options.length).fill(
        ""
      ),
      hclQuestion2: "",
      hclQuestion3: "",
      hclQuestion4: "",
      hclQuestion5: "",
    }
  );
  
  const dispatch = useDispatch();
  
  const { questions } = useSelector((state) => state.questions);
  console.log('questions  in personality 6 =', questions?.part6);
  
  const history = useHistory();

  const { uid } = useSelector((state) => state.authUser);

  useEffect(() => {
    if (uid) {
      dispatch(getQuestions(uid, "part6"));
    } else {
      alert('you need to be logged in to do these questions');
    }
    window.TestCase = testCase;
  }, []);

  useEffect(() => {
    if (questions.hclQuestion1) {
      let temp = {
        hclQuestion1: questions?.part6.hclQuestion1,
        hclQuestion2: questions?.part6.hclQuestion2,
        hclQuestion3: questions?.part6.hclQuestion3,
        hclQuestion4: questions?.part6.hclQuestion4,
        hclQuestion5: questions?.part6.hclQuestion5,
      };
      setScoreObj(temp);
    }
  }, [questions?.part6]);
  
  const [result, setResult] = useState("");

  useEffect(() => {
    const defaultObj = {
      hclQuestion1: new Array(part6Questions.questions[0].options.length).fill(""),
      hclQuestion2: "",
      hclQuestion3: "",
      hclQuestion4: "",
      hclQuestion5: "",
    };
    
    // 1. validate scoreObj or make it new 
    const isValidScoreObj = scoreObj && Object.keys(defaultObj).every(key => 
      Object.keys(scoreObj).includes(key) && scoreObj[key] !== undefined
    );

    console.log('isValidScoreObj =',isValidScoreObj );
    if (isValidScoreObj) {

      // 2. check if completed 
      const is100pct = calculatePercentCompleted(scoreObj);
      dispatch(updateButtonPercent("mdq", is100pct));
      console.log('is100pct=',is100pct);
      if (is100pct===100) {
        // 3. make sure summary is set up 
        // to populate VisualRewards
        var rez = calculate();
        console.log('is100pct=',is100pct);
        const summary = setupScoreSummary(rez.isTestPositive, rez.activationType); 
        const newScore = {
          ...scoreObj,
          summary:summary,
        };
        setScoreAfterSummarize(newScore); 
        setShowResult(true);
        console.log('newScore =', newScore); 
        console.log('newScore.summary =', newScore.summary); 
        console.log('newScore.hclQuestion1 =', newScore.hclQuestion1); 
        
      } else {
        setShowResult(false);
      }

    } else if (!isValidScoreObj) { // if something is missing then set default 
      setScoreObj(defaultObj);
      setShowResult(false);
    }
   
  }, [scoreObj]);
/*

localStorage.setItem("part6", JSON.stringify({hclQuestion1: [true,  true,  true,  true,  true,  false,  true, true, false, true, true, true, true],hclQuestion2: "Yes",  hclQuestion3: "Moderate Problem", hclQuestion4: "Yes", hclQuestion5: "Yes"}));
*/

const [activation, setActivation] = useState({});

useEffect(() => {
  // window.showStates();
  window.showStates = () => {
    console.log('================================');
    
      return {
        scoreAfterSummarize,
        showResult,
        result,
        scoreObj,
        activation,
      };
  };
 
  return () => {
    window.showStates = undefined;
  };
}, [ scoreAfterSummarize, showResult, result, activation,  scoreObj]);



 

  useEffect(() => {
    localStorage.setItem("part6", JSON.stringify(scoreObj));
   // console.log('scoreObj =', scoreObj);
    const rez = calculate();
    const scoreupdate = setupScoreSummary(rez.isTestPositive, rez.activationType); 
  }, [scoreObj]);

   // window.TestCase(1) = true; 
   // window.TestCase(-1) = false; 
  const testCase = (posOrNegative) => {
    let tempObjScoreCount = {};

    if (typeof posOrNegative!='boolean'){
      console.error('expecting boolean true or false');
      return;
    }
   
    if (posOrNegative) {
      tempObjScoreCount = {
        hclQuestion1: [true,  true,  true,  true,  true,  false,  true, true, false, true, true, true, true],
        hclQuestion2: "Yes",
        hclQuestion3: "Moderate Problem",
        hclQuestion4: "Yes",
        hclQuestion5: "Yes",
      };
    } else {
      tempObjScoreCount = {
        hclQuestion1: [false, false, false, false,  false,  false, true, true, false, false, false, false, false],
        hclQuestion2: "No",
        hclQuestion3: "No Problem",
        hclQuestion4: "Yes",
        hclQuestion5: "Yes",
      };
    }
   
    setScoreObj(tempObjScoreCount);
  };
    



 


  const setupScoreSummary = (isTestPositive, activationType) => {
    return {
        mainTrait: isTestPositive==='positive' ? 'positiveMDQ':'negativeMDQ',
        secondaryTrait: isTestPositive==='positive' ? activationType:'',
        mainNegativeTrait: '',
        secondaryNegativeTrait: '',
        percentCompleted: 100 // Math.floor(50/50 * 100) don't for get to dynamically update 
      
    };

  };

  const handleNewCalculate = (e) => {
    e.preventDefault();
    console.log('handleNewCalculat =', e);
      if (uid) {
        let res = calculate();//returns positive/negative
        console.log('score for part6 =', res);
        const scoreupdate = setupScoreSummary(res.isTestPositive, res.activationType); 
        /* {
          summary : {
            mainTrait: result==='positive'? 'positiveMDQ':'negativeMDQ',
            secondaryTrait: result==='positive'? activation:'',
            mainNegativeTrait: '',
            secondaryNegativeTrait: '',
            percentCompleted: 100 // Math.floor(50/50 * 100) don't for get to dynamically update 
          }
        };
        */
        console.log('scoreObj, "part6", uid, scoreupdate', scoreObj,  uid, {summary:scoreupdate}); 
        dispatch(addSessions(scoreObj, "part6", uid, {summary:scoreupdate}));
      } else {
        alert('Please log in to save personality tests');
        history.push("/admin/home");
      }
  };

  const calculate = () => {

    console.log('in calculate() scoreObj=', scoreObj);
    if(Array.isArray(scoreObj.hclQuestion1)){
      let Q1score = scoreObj.hclQuestion1.filter(
        (hclQuestion) => hclQuestion === true
      ).length;
      
      console.log('in calculate() Q1score =',  Q1score);
      if ( Q1score >= 7 &&
        scoreObj.hclQuestion2 == "Yes" &&
        (scoreObj.hclQuestion3 == "Moderate Problem" ||
          scoreObj.hclQuestion3 == "Serious Problem")
      ) {
        

        const indicesToCheckPositiveActivation = [3, 4, 8, 9];
        const indicesToCheckNegativeActivation = [1, 2, 6, 7, 12, 13];
          // setActivation is either positive or negative activation 

        const trueCountPositive = indicesToCheckPositiveActivation.filter(
          index => scoreObj.hclQuestion1[index-1] === true
        ).length;
    
        const trueCountNegative = indicesToCheckNegativeActivation.filter(
          index => scoreObj.hclQuestion1[index-1] === true
        ).length;
        
        const positiveActivation = trueCountPositive / indicesToCheckPositiveActivation.length;
        const negativeActivation = trueCountNegative / indicesToCheckNegativeActivation.length;

       
        const activation = {
          positiveActivation:positiveActivation,
          negativeActivation:negativeActivation       
        };
        setResult("positive");
        setActivation(activation);

        return { 
          isTestPositive:"positive",
          activationType:""//JSON.stringify(activation)
        };

      } else {
        setResult("negative");
        setActivation("");

        return { 
          isTestPositive:"negative",
          activationType:activation
        };
      }
    } else {
      console.error('in calculate() scoreObj.hclQuestion1 is not an array. it is =',scoreObj.hclQuestion1);
    }
  };



  const updatePrevState = (prevState, key, userSelection, idx) => {
    // If the type is array (yesAndNo type questions)
    if (Array.isArray(prevState[key])) {
      const updatedArray = [...prevState[key]];
      updatedArray[idx] = userSelection;
      return { ...prevState, [key]: updatedArray };
    } else {
      // For radio type questions
      return { ...prevState, [key]: userSelection };
    }
  };

  //  reference book "The hopes of the 20th century" book by use a vpn 
  /* 
  Hi truth teller could you send me the link to the audio book. 
  I believe the book under discussion was "The hopes of the 20th century"
  I'm also very interested in learning how hitler created a new economy by separating his economy from the international monetary economy.
If you have any additional reference for that, i would be glad to hear of it.

more references: 
E michael jones - the holocaust narrative 

ron unz - why everything you konw about world war 2 is wrong. 
it's an article. 

why did hitler want to kick them out/
the jews were raping nuns and robbing preist 

jews were promoting communist 
germany's problem was with the mentality of communistm. 


earnest zundl 
  */
  // find out how the germans built their own independent economy
  // learn about 
  // there was no gas chamber 
  // nazi means national zionist 
  // there was not 6 million jws in all of europe 
  // only 400,000 were missing 
  // germany said you can make your independent nation from nothing 
  // without the need for the international monetary system 
  // germany made his nation very strong 

  const calculatePercentCompleted = (newState) => {
    let totalQuestions = 13;
    let questionsCompleted = 0;
    console.log('newState ',newState);
    // Count completed questions in hclQuestion1
    questionsCompleted += newState.hclQuestion1.filter((val) => val === true || val === false).length;
    console.log('1 questionsCompleted =',questionsCompleted );
    // If any true in hclQuestion1, update total questions to 17
    if (questionsCompleted >= 13 && newState.hclQuestion1.includes(true)) {
      totalQuestions = 17;
    }
  console.log('2 questionsCompleted =',questionsCompleted );
    // Count remaining questions
    if (["Yes", "No"].includes(newState.hclQuestion2)) questionsCompleted += 1;
    if (["No Problem", "Minor Problem", "Moderate Problem", "Serious Problem"].includes(newState.hclQuestion3)) questionsCompleted += 1;
    if (["Yes", "No"].includes(newState.hclQuestion4)) questionsCompleted += 1;
    if (["Yes", "No"].includes(newState.hclQuestion5)) questionsCompleted += 1;
  
    console.log('3 questionsCompleted =',questionsCompleted );
    const pct = Math.round((questionsCompleted / totalQuestions) * 100);
    return pct;
  };
  
  const onChangeEventHandler = (e) => {
      let key = e.target.name.split("-")[0];
      const idx = parseInt(e.target.name.split("-")[1], 10);
      const userSelection = convertTrueFalseToBoolean(e.target.value);

      const newState = updatePrevState(scoreObj, key, userSelection, idx);

      setScoreObj(newState);
      // Calculate the percentage
      const percentCompleted = calculatePercentCompleted(newState);
      dispatch(updateButtonPercent("mdq", percentCompleted));
      if (percentCompleted===100) {
        setShowResult(true);
        console.log('scoreAfterSummarize=', scoreAfterSummarize);
      } else {
        setShowResult(false);
      }
      console.log(`Percent Completed: ${percentCompleted}%`);
      console.log('newState =',newState, ' percentCompleted =',percentCompleted);
  };

  const convertTrueFalseToBoolean = (value) => {
    if (value === "true") return true;
    if (value === "false") return false;
    return value; // or whatever default/fallback you want
  }


  const resetAnswers = () => {
    setScoreObj({
      hclQuestion1: new Array(part6Questions.questions[0].options.length).fill(
        ""
      ),
      hclQuestion2: "",
      hclQuestion3: "",
      hclQuestion4: "",
      hclQuestion5: "",
    });
    localStorage.removeItem("part6"); 
    setResult("");
    setShowResult(false);
  };

  
  return (
    <>
    <Form noValidate>
      <Row className="personality-test-part py-4">
        <Col md={12}>
          <div>
          <h2 className='test-title'>Mood Type:</h2>
            <h4>Check (√) the answer that best applies to you</h4>
          </div>
          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <div>
                  <h4>Please answer each question as best you can.</h4>
                </div>
                <div>
                  <span className="mr-4">
                    <b>Yes</b>
                  </span>
                  <span>
                    <b>No</b>
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              {part6Questions.questions.map((question, qIdx) => (
                <Row className="mb-5" key={qIdx}>
                  <Col>
                    <div>
                      <p className="my-1">
                        <span className="mr-2 d-inline-block"><b>{qIdx + 1}.</b></span>
                        <b>{question?.statement}</b>
                      </p>

                      <div className="mt-3">
                        {question.options?.map((option, idx) =>
                          question.type === "yesAndNo" ? (
                            
                              <Row key={idx} className="align-items-center mb-3">
                              <Col lg="10" md="10" sm="8" xs="8">{option}</Col>
                              <Col lg="2" md="2" sm="4" xs="4">
                                  <div className="d-flex justify-content-end">
                                    <FormGroup check className="mr-2">
                                      <Input
                                        required
                                        type="radio"
                                        value={true}
                                        checked={
                                          scoreObj[`hclQuestion${qIdx + 1}`][
                                            idx
                                          ] === true
                                        }
                                        name={`hclQuestion${qIdx + 1}-${idx}`}
                                        onChange={onChangeEventHandler}
                                        
                                      />
                                      <Label check>Yes</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                      <Input
                                        required
                                        type="radio"
                                        value={false}
                                        checked={
                                          scoreObj[`hclQuestion${qIdx + 1}`][
                                            idx
                                          ] === false
                                        }
                                        name={`hclQuestion${qIdx + 1}-${idx}`}
                                        onChange={onChangeEventHandler}
                                        
                                      />
                                      <Label check>No</Label>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row>
                           
                          ) : question.type === "radio" ? (
                            <FormGroup check key={idx} >
                              <Input
                                required
                                checked={
                                  scoreObj[`hclQuestion${qIdx + 1}`] === option
                                }
                                type="radio"
                                name={`hclQuestion${qIdx + 1}`}
                                value={option}
                                onChange={onChangeEventHandler}
                              />
                              <Label check>{option}</Label>
                            </FormGroup>
                          ) : (
                            <></>
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              ))} 
             <div className='d-flex justify-content-center'>
              <Button
                type="button"
                className="personality_see_and_save_btn  my-2"
                onClick={handleNewCalculate}
              >
                Save & See Results
              </Button>
              <Button type="button"  className="my-2" onClick={resetAnswers} >Reset to Start Again</Button>
</div>

              {showResult && result !== "" && (
                <div className="mt-4 rounded overflow-hidden">
                  <div className='p-2'>
                    {/* ${
											result == 'positive'
												? 'bg-success'
												: 'bg-danger'
										} */}
                    {result === "positive" ? (
                      <>
                      <div className='p-3 personality__postive-test-result'>
                          <h5 >Thank you for taking this. </h5>
                          <p>
                            You just took the MDQ Questionnaire.
                          <br/>  <br/> 
                            If you have a doctor or counselor, you
                            can tell them <b>you scored  positive  on this test and 
                            you said your mood interrupts your life at times.</b>
                            <br/>
                            Ask your doctor or counselor if they could double check or evaluate you.
                              
                            
                            <br/><br/>
                            <b>Knowing about your mood is great.</b>
                            <br/>  
                            By knowing, we can take some steps to prevent it from affecting you relationships, work and more.
                            <br/> 
                            <br/>
                                                   
                            Ask your counselor or doctor if they could evaluate you for Bipolar. <br/>
                            If they evaluate you to be bipolar. Next make a plan with them about how you can protect your relationships and work experience.
<br/><br/>
                            If you want, you can let use know what they said.
                            <br/><b>Knowledge is power. </b>
                            <br/>
                            <b> Disclaimer:</b>  Scoring positive on the MDQ is not a clinical diagnosis but a sign to consult a healthcare provider for a thorough evaluation.

                          </p>
                          <p>Please continue the Personality tests.</p>
                      </div>
                      </>
                    ) : result === "negative" ? (
                      <>
                        <div className='p-3 personality__negative-test-result'>
                          <p className='font-weight-bold'>Cool Result.
                           <br/> Your test is "negative". 
                           <br/>Thank you for taking this. It was important.
                           <br/>Please continue
                          </p>
                        </div>
                      </>
                    ):null }
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='showScore'> <p> {scoreAfterSummarize?.summary?.secondaryTrait} </p> 
      {scoreAfterSummarize?.summary?.secondaryTrait!==undefined && (
          <VisualRewards 
          isShowAnimations={showResult}
          part="part6"
          mainTrait={scoreAfterSummarize?.summary?.mainTrait}
          secondaryTrait={scoreAfterSummarize?.summary?.secondaryTrait}

          />
        )}
        <FriendlyErrMsgBox/>
      </Row>
      </Form>
    </>
  );
};

export default PersonalityTestPart6;
/*

MDQ Scoring and Interpretation

A total score is calculated for questions 1-13 where a “Yes” provides a score of 1 and “No” is 0.
 The percentage of items endorsed (raw score / number of items multiplied by 100) is included to provide an indication of the proportion of symptoms identified with by the respondent.

In order to meet the threshold for bipolar disorder the traditional scoring method is as follows:

A score of 7 or more for questions 1-13 (53% of items endorsed) AND
Check “yes” for the item asking if the symptoms clustered in the same time period (question 14) AND
Symptoms caused either “moderate” or “serious” problems (question 15).
Subscale scores were also developed (Carpenter et al., 2020, Stanton & Watson, 2017) using 10 of the 13 items in the symptom questions:

Positive Activation (items 3, 4, 8, 9): assesses increased energy/activity, grandiosity, and decreased need for sleep. Individuals endorsing symptoms defining Positive Activation are not likely to report significant levels of negative affect and are likely to be energetic and extraverted. Individuals scoring high on Positive Activation may be less likely to rate their symptoms as impairing given that increased levels of energy and activity may be experienced as advantageous to some degree, especially if they are mild in nature. This factor is strongly associated with a BD diagnosis.
Negative Activation (items 1, 2, 6, 7, 12, 13): assesses irritability, racing thoughts, levels of negative affectivity, and distractibility. This factor is strongly associated with BD as well as a a range of other disorders, many of them (e.g. depressive disorders, PDs, PTSD, GAD, substance use disorders) characterised by emotion dysregulation and/or transdiagnostic personality traits such as neuroticism and disinhibition. Clients high in Negative Activation may be at risk for engaging in impulsive behavior in emotional situations.

*/


/*

==============
important note 

with this the person you marry comes with a manual


the summary shows you exactly what they like 

so you can just refer to the manual 

=============
*/