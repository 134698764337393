// src/contexts/ImageContext.js
import React, { createContext, useContext, useState } from 'react';

const ImageContext = React.createContext();

export const useImage = () => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};

export const ImageProvider = ({ children }) => {
  const [imagesIC, setImagesIC] = useState([]);
  const [videosIC, setVideosIC] = useState([]);
  const [imageUrlIC, setImageUrlIC] = useState('');
  const [imageDataIC, setImageDataIC ] = useState('');

  return (
    <ImageContext.Provider value={{ 
      imageUrlIC, setImageUrlIC,
      imagesIC, setImagesIC,
      videosIC, setVideosIC,
      imageDataIC, setImageDataIC
    }}>
      {children}
    </ImageContext.Provider>
  );
};

/* 
 

usage in child component 
import { ImageProvider, useImage } from 'contexts/ImageContext';

const {imageUrl, setImageUrl, images, setImages, videos, setVideos, imageData, setImageData } = useImage();

====
import { useImage } from 'contexts/ImageContext';

const {imageUrl, setImageUrl, images, setImages, videos, setVideos, imageData, setImageData } = useImage();

const addImageURLPath = (path) => {
  setImageUrl(path)
};

const addArrayOfVideoURLPaths = (ar) => {
  setImages(( prevPaths ) => [...prevPaths, ar]);
};

const addArrayOfImageURLPaths = (ar) => {
  setVideos(( prevPaths ) => [...prevPaths, ar]);
};

const getImageURLPath = () => {
   return imageUrl;
};

const getArrayOfImageURLPaths = () => {
  return images;
};
const getArrayOfVideoURLPaths = () => {
  return videos; // Added this missing function
};


 const fetchImageData = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  };

  // Usage (you can call this function when needed)
  
  fetchImageData('path/to/your/image.jpg');



const addImageURLPath = (path) => {
  setImageUrl(path)
};

const addArrayOfVideoURLPaths = (ar) => {
  setImages(( prevPaths ) => [...prevPaths, ar]);
};

const addArrayOfImageURLPaths = (ar) => {
  setVideos(( prevPaths ) => [...prevPaths, ar]);
};

const getImageURLPath = () => {
   return imageUrl;
};

const getArrayOfImageURLPaths = () => {
  return images;
};
const getArrayOfVideoURLPaths = () => {
  return videos; // Added this missing function
};



QUESTION FOR CHAT GPT 


ReactJS image management. 

I am using this function to upload images to the firestore database. 

    const handleImageChange = (e) => {
      const file = e.target.files[0];

      if (file && file.type.includes("image") && file.size <= 6 * 1024 * 1024) {
        const previewUrl = URL.createObjectURL(file);
        setImage( previewUrl ); 
       
        const storageRef = firebase.storage().ref("users");
        
        const fileRef = storageRef.child(`${uid}/${uid}`);
  
        fileRef.put(file )
        .then(async () => {
          const fileUrl = await fileRef.getDownloadURL();
          setImageUrl(fileUrl);  // this is an alternate way to set up the profile image 
  
          setProfileInfo((prevProfileInfo) => ({
            ...prevProfileInfo,
            img: fileUrl,
            imageUrl: fileUrl,
            imageFileName: uid // Save the file name here - previously used random fileName
          }))
        })
        .catch((error) => {
          alert("Upload Failed: " + error.message);
        });

      } else {
        alert("Please select an image file less than 6 MB");
      }
    };


I am using this function to fetch images from the firestore database. 
    const getImageUrl = async (uid, fileName) => {
        if (!uid || !fileName) {
            return null;
        }
        const storageRef = firebase.storage().ref("users");
        const fileRef = storageRef.child(`${uid}/${fileName}.png`);
        try {
          const url = await fileRef.getDownloadURL();
          setUserPathFromFirebaseCloud(url); 
          console.log('here is the url =', url );
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
    };

    useEffect(() => {
        getImageUrl(uid, uid);
    },[]);


I will be calling this function from multiple files. Therefore it seems reasonable for me to make it a helper function and put it in the helper folder. 

So am I correct to say the only thing i need to do is put them in a helper/getAndSetImages.js

and just add export in front of the functions like this?

export   const getImageUrl = async (uid, fileName) => {
        if (!uid || !fileName) {
            return null;
        }
        const storageRef = firebase.storage().ref("users");
        const fileRef = storageRef.child(`${uid}/${fileName}.png`);
        try {
          const url = await fileRef.getDownloadURL();
          setUserPathFromFirebaseCloud(url); 
          console.log('here is the url =', url );
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
    };


export 
    const handleImageChange = (e) => {
      const file = e.target.files[0];

      if (file && file.type.includes("image") && file.size <= 6 * 1024 * 1024) {
        const previewUrl = URL.createObjectURL(file);
        setImage( previewUrl ); 
       
        const storageRef = firebase.storage().ref("users");
        
        const fileRef = storageRef.child(`${uid}/${uid}`);
  
        fileRef.put(file )
        .then(async () => {
          const fileUrl = await fileRef.getDownloadURL();
          setImageUrl(fileUrl);  // this is an alternate way to set up the profile image 
  
          setProfileInfo((prevProfileInfo) => ({
            ...prevProfileInfo,
            img: fileUrl,
            imageUrl: fileUrl,
            imageFileName: uid // Save the file name here - previously used random fileName
          }))
        })
        .catch((error) => {
          alert("Upload Failed: " + error.message);
        });

      } else {
        alert("Please select an image file less than 6 MB");
      }
    };




When the user uploads their image for the first time, I would like to save it in my image provider.  I have the image provider set up as follows in index.js 

import { ImageProvider } from 'contexts/ImageContext';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root'); // Use 'root' if that's the correct ID in your HTML file
const root = createRoot(container);
root.render(
  <ImageProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/home" />
        </Switch>
      </BrowserRouter>
    </Provider>
  </ImageProvider>
);


// src/contexts/ImageContext.js
import React, { createContext, useContext, useState } from 'react';

const ImageContext = React.createContext();

export const useImage = () => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [imageData, setImageData ] = useState('');

  return (
    <ImageContext.Provider value={{ 
      imageUrl, setImageUrl,
      images, setImages,
      videos, setVideos,
      imageData, setImageData
    }}>
      {children}
    </ImageContext.Provider>
  );
};

I think the usage in the child component is supposed to look like this 


import { useImage } from 'contexts/ImageContext';

const {imageUrl, setImageUrl, images, setImages, videos, setVideos, imageData, setImageData } = useImage();

const addImageURLPath = (path) => {
  setImageUrl(path)
};

const addArrayOfVideoURLPaths = (ar) => {
  setImages(( prevPaths ) => [...prevPaths, ar]);
};

const addArrayOfImageURLPaths = (ar) => {
  setVideos(( prevPaths ) => [...prevPaths, ar]);
};

const getImageURLPath = () => {
   return imageUrl;
};

const getArrayOfImageURLPaths = () => {
  return images;
};
const getArrayOfVideoURLPaths = () => {
  return videos; // Added this missing function
};

is that correct? 
am i supposed to check thse to see if the path is NULL and then if they are null, then do the fetch to the database fort he image or video path?


*/