import { part1Questions } from "helpers/GlobalConstants";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Label, Progress, Row } from "reactstrap";
import { getQuestions } from "store/actions/QuestionsAction";
import { addSessions } from "store/actions/QuestionsAction";
import { updateButtonPercent } from 'store/actions/actions.js';
import { validateLocalStorageData } from "../../helpers/validateJson.js";
import VisualRewards from "../../components/VisualRewards/VisualRewards";
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';

/* 
This is a personality test form.
I do not seem to have form  validation checking before the user can submit answers.

it starts by setting values which might come from part1Questons import of questions 

part1Questions looks like this. 


export const part1Questions = [
  {
    value: "none",
    label: "1. Am the life of the party.",
    cat: "E",
  },
  {
    value: "none",
    label: "2. Feel little concern for others",
    cat: "A",
  },
  {
    value: "none",
    label: "3. Am always prepared.",
    cat: "C",
  },
...
];

All the  values are initially set to none. 


As the user selects each select item  the handleChange is called 
which changes the value to what the user selected 


When values is changed, the information is saved into localstorage

if trigger is csalled to calculate - there is no validation of value
as you can see in the code snppet below values[i].value is not checked to see if it is none. 

if it is none - i need to place a marker like red text to show the user which of these items they did not complete as yet 
but it should return from the calculate function .


Please provide some advice after this snippet, here is the code. 

  const calculate = () => {
    setShowResult(true);
    for (let i = 0; i < values.length; i++) {
     const cat = values[i].cat;

      const iValue = values[i].value;
      scoringTemplate[i].val = iValue;

      // math is +1 or -1 for reversals
      var x = scoringTemplate[i].math * scoringTemplate[i].val; 
  */

 

  /*
    score is an object with the following properties 
    { 
      A: 0,
      AAvg: 0,
      A_Zfactor : -9.114285714285714,
      C : 0,
      CAvg : 0,
      C_Zfactor: -8.153846153846153,
      E: 0,
      EAvg: 0,
      E_Zfactor : -6.222222222222221,
      N: 40,
      NAvg: 4 ,
      N_Zfactor: 3.9400000000000004,
      O: 0,
      OAvg: 0,
      O_Zfactor : -8.289473684210526
    }

    The goal of this function is to add summarizing data to indicate 
    1. the user's highest scoring trait if any   Zfactor >0.5 
    2. the user's second highest scoring trait   Zfactor >0.5 
    3. the user's most negative trait            Zfactor < -0.5 
    4. the user's second most negative trait     Zfactor < -0.5 
    5. mixed if the user                         Zfactor > -0.5 && 0.5 < Zfactor
  */
 
   
/*
    score will be saved with 
 I am creatng an object with 
 the following properties 
 {
    mainTrait 'A',
    secondaryTrait:'E',
    mainNegativeTrait: ''
    secondaryNagtiveTrait: ''

 }
*/

// from IPIP Big-Five Factor 
const PersonalityTestPart1 = ({ activeTab }) => {
  // rewards 
  const [isVisualRewardsShowAnimations, setIsVisualRewardsShowAnimations] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const { questions } = useSelector((state) => state.questions);
 
  useEffect(() => {
    if (uid) {
      dispatch(getQuestions(uid, "part1"));
    }
  }, []);
  console.log('questions part 1 =', questions);
  
  useEffect(() => {
      if ( Array.isArray(questions.part1) && questions.part1.length === 0 ) {

        const savedResults = localStorage.getItem("part1") ? 
          JSON.parse(localStorage.getItem("part1")) : null;

        setValues(savedResults || part1Questions);
      } else { 
        setValues(JSON.parse(JSON.stringify(questions.part1)));
      }
  }, [questions?.part1]);

  const [values, setValues] = useState(part1Questions);
  
  useEffect(() => {
     

    const localData = localStorage.getItem("part1") ? 
      JSON.parse(localStorage.getItem("part1")) : null;
    const validatedData = validateLocalStorageData(localData, part1Questions);
    setValues(validatedData);
  }, []);
  /*
  function validate(jsonQuestions, part1Questions) {
    if (!jsonQuestions || jsonQuestions.length !== part1Questions.length) {
      return part1Questions;
    }
  
    for (let i = 0; i < jsonQuestions.length; i++) {
      const localQuestion = jsonQuestions[i];
      const originalQuestion = part1Questions[i];
  
      if (localQuestion.label !== originalQuestion.label || localQuestion.cat !== originalQuestion.cat) {
        return part1Questions;
      }
  
      if (![1, 2, 3, 4, 5, 'none'].includes(localQuestion.value)) {
        return part1Questions;
      }
    }
  
    return jsonQuestions;
  }
  */
  /* 

  stringifying and then parsing an imported array using
   JSON.stringify() and JSON.parse() is a way to 
   create a deep copy of the array or object.
    This technique is often used to clone objects and
     arrays without keeping references
      to the original objects.

  const [values, setValues] = useState(
    JSON.parse(localStorage.getItem("part1")) ||
      JSON.parse(JSON.stringify(part1Questions))
  );
  */


  
  const formRef = useRef(null);
  const [showResult, setShowResult] = useState(false);
  const [triggerCalculate, setTriggerCalculate] = useState(null);
  let objNewScore = {
    E: 20,
    A: 14,
    C: 14,
    N: 38,
    O: 8,
    EAvg: "Extroversion Avg",
    OAvg: "Openess Avg",
    CAvg: "Contientiousness Avg",
    NAvg: "Neuroticism Avg",
    AAvg: "Agreeableness Avg",

    // calculated z-factor 
    E_Zfactor: -20,
    A_Zfactor: -20,
    C_Zfactor: -20,
    N_Zfactor: -20,
    O_Zfactor: -20,
  };

  const [scoreObj, setScoreObj] = useState(
    JSON.parse(JSON.stringify(objNewScore))
  );

  console.log("scoreObj", scoreObj);
  const scoringTemplate = [
    { count: "1", math: 1, cat: "E" },
    { count: "2", math: -1, cat: "A" },
    { count: "3", math: 1, cat: "C" },
    { count: "4", math: -1, cat: "N" },
    { count: "5", math: 1, cat: "O" },

    { count: "6", math: -1, cat: "E" },
    { count: "7", math: 1, cat: "A" },
    { count: "8", math: -1, cat: "C" },
    { count: "9", math: 1, cat: "N" },
    { count: "10", math: -1, cat: "O" },

    { count: "11", math: 1, cat: "E" },
    { count: "12", math: -1, cat: "A" },
    { count: "13", math: 1, cat: "C" },
    { count: "14", math: -1, cat: "N" },
    { count: "15", math: 1, cat: "O" },

    { count: "16", math: -1, cat: "E" },
    { count: "17", math: 1, cat: "A" },
    { count: "18", math: -1, cat: "C" },
    { count: "19", math: 1, cat: "N" },
    { count: "20", math: -1, cat: "O" },

    { count: "21", math: 1, cat: "E" },
    { count: "22", math: -1, cat: "A" },
    { count: "23", math: 1, cat: "C" },
    { count: "24", math: -1, cat: "N" },
    { count: "25", math: 1, cat: "O" },

    { count: "26", math: -1, cat: "E" },
    { count: "27", math: 1, cat: "A" },
    { count: "28", math: -1, cat: "C" },
    { count: "29", math: -1, cat: "N" },
    { count: "30", math: -1, cat: "O" },

    { count: "31", math: 1, cat: "E" },
    { count: "32", math: -1, cat: "A" },
    { count: "33", math: 1, cat: "C" },
    { count: "34", math: -1, cat: "N" },
    { count: "35", math: 1, cat: "O" },

    { count: "36", math: -1, cat: "E" },
    { count: "37", math: 1, cat: "A" },
    { count: "38", math: -1, cat: "C" },
    { count: "39", math: -1, cat: "N" },
    { count: "40", math: 1, cat: "O" },

    { count: "41", math: 1, cat: "E" },
    { count: "42", math: 1, cat: "A" },
    { count: "43", math: 1, cat: "C" },
    { count: "44", math: -1, cat: "N" },
    { count: "45", math: 1, cat: "O" },

    { count: "46", math: -1, cat: "E" },
    { count: "47", math: 1, cat: "A" },
    { count: "48", math: 1, cat: "C" },
    { count: "49", math: -1, cat: "N" },
    { count: "50", math: 1, cat: "O" },
  ];
  const testCase = (trait) => {
    let [maxInt, minInt] = [5, 1];
    if (!["E", "A", "C", "N", "O"].includes(trait)) {
      throw new Error("Trait must be one of ['E', 'A', 'C', 'N', 'O']");
    }
    let temp = JSON.parse(JSON.stringify(part1Questions));
    setValues(temp);
    let idx = 0;
    for (let part1Question of part1Questions) {
      if (part1Question.cat === trait) {
        if (scoringTemplate[idx].math > 0) {
          temp[idx].value = maxInt;
        } else {
          temp[idx].value = minInt;
        }
      } else {
        if (scoringTemplate[idx].math > 0) {
          temp[idx].value = minInt;
        } else {
          temp[idx].value = maxInt;
        }
      }
      idx++;
    }
    setValues(JSON.parse(JSON.stringify(temp)));
    setTriggerCalculate((prevState) =>
      prevState === null ? false : !prevState
    );
    console.log("response:", temp);
    return `Part 1 - Calculation Test for '${trait}'`;
  };
  const [category, setCategory] = useState("");
  

 // means from another source 
 // https://www.researchgate.net/figure/Means-M-and-Standard-Deviations-SD-for-the-Big-Five-Traits_tbl1_50376756
 // Social Curiosity and Interpersonal Perception: A Judge × Trait Interaction 
 const big5Means = {
    E: { mean: 2.80, sd: 0.45 },
    N: { mean: 2.03, sd: 0.50 },
    A: { mean: 3.19, sd: 0.35 },
    C: { mean: 3.18, sd: 0.39 },
    O: { mean: 3.15, sd: 0.38  },
  };

  const toggleVisualRewards = (trueorfalse) =>{
    setIsVisualRewardsShowAnimations(trueorfalse);
  };

  useEffect(() => {
    window.TestCase = testCase;
    window.toggleVisualRewards = toggleVisualRewards;

  }, []);



  useEffect(() => {
    if (triggerCalculate !== null) {
      setUpScoreObject();
    }
  }, [triggerCalculate]);

   /*  graph animation */
  useEffect(() => {
    const timer = setTimeout(() => { 
      let newValues = [...values];
      updatePercentageChange(newValues);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  



  const updatePercentageChange = (newValues) => {
    let numAnswered = 0;
    const NUMBER_OF_QUESTIONS = 50;
    console.log('updatePercentageChange = (newValues) with', newValues);
    for (let i = 0, ilen = newValues.length; i<ilen; i++) {
      if (newValues[i].value !=='' || newValues[i].value !=='none' ) {
        ++numAnswered;
      }
    }
    const newPercent = Math.round((numAnswered/NUMBER_OF_QUESTIONS) * 100)
    dispatch(updateButtonPercent('big5', newPercent));
  };

  const handleChange = (event, index, cat) => {
    let newValues = [...values];
    console.log("newValues:", newValues);

    newValues[index].value = event.target.value;
    updatePercentageChange(newValues);
    setValues(newValues);

  
  };

  

/**
 * onChange Event handler 
 * new values for going from rich community to poor community 
 * 
  feeding the people and giving away free stuff means 
  you are resourceful - and you use the resources to think about them. 
 
   but it is important to get people do free work 
 */
  useEffect(() => {
    // part 1 save it 
    localStorage.setItem("part1", JSON.stringify(values));

    // part 2 check if it is complete 
    const pctCompleted = getPercentageCompleted();
    console.log('getPercentageCompleted =', pctCompleted);
   
    if (pctCompleted===100) {
      setUpScoreObject();
    }
  }, [values]);

  const calculateZFactor = (
    big5MeanForCat,
    big5MeanForCatSD,
    objNewScoreAvg
  ) => {
   return  (objNewScoreAvg - big5MeanForCat)/big5MeanForCatSD;
  };

  const maxScorePerTrait = 40;

  const calculate = () => {
    for (let i = 0; i < values.length; i++) {
     const cat = values[i].cat;

      const iValue = values[i].value;
      scoringTemplate[i].val = iValue;

      // math is +1 or -1 for reversals
      var x = scoringTemplate[i].math * scoringTemplate[i].val;

      // here is the calculation
      objNewScore[cat] = objNewScore[cat] + x;
    }

    objNewScore.EAvg = objNewScore.E / 10;
    objNewScore.OAvg = objNewScore.O / 10;
    objNewScore.CAvg = objNewScore.C / 10;
    objNewScore.NAvg = objNewScore.N / 10;
    objNewScore.AAvg = objNewScore.A / 10;

    objNewScore.E_Zfactor = calculateZFactor(
      big5Means.E.mean, big5Means.E.sd, objNewScore.EAvg
    );
    objNewScore.O_Zfactor = calculateZFactor(
      big5Means.O.mean, big5Means.O.sd, objNewScore.OAvg
    );
    objNewScore.C_Zfactor = calculateZFactor(
      big5Means.C.mean, big5Means.C.sd, objNewScore.CAvg
    );
    objNewScore.N_Zfactor = calculateZFactor(
      big5Means.N.mean, big5Means.N.sd, objNewScore.NAvg
    );
    objNewScore.A_Zfactor = calculateZFactor(
      big5Means.A.mean, big5Means.A.sd, objNewScore.AAvg
    );
    // i am just making the introversion the opposite of extraversion
    // there are a lot of similarities  
    objNewScore.I_Zfactor = - objNewScore.E_Zfactor;

    
    setScoreObj(objNewScore); 
 
    console.log('inside calculate with ', objNewScore );
    return objNewScore;
  };
  

const [scoreAfterSummarize, setScoreAfterSummarize] = useState({
  summary: {
    mainTrait: 'NA',
    secondaryTrait: 'NA',
    mainNegativeTrait: 'NA',
    secondaryNegativeTrait: 'NA',
    percentCompleted: 0,
  }
});

const summarizeMainTraits = (score) => {
  // An array to store trait-Zfactor pairs
  const traits = [
    { trait: 'A', Zfactor: score.A_Zfactor },
    { trait: 'C', Zfactor: score.C_Zfactor },
    { trait: 'E', Zfactor: score.E_Zfactor },
    { trait: 'N', Zfactor: score.N_Zfactor },
    { trait: 'O', Zfactor: score.O_Zfactor },
    { trait: 'I', Zfactor: -score.E_Zfactor }, // I is the inverse of E
  ];

  // Initialize summary object
  const summary = {
    mainTrait: 'NA',
    secondaryTrait: 'NA',
    mainNegativeTrait: 'NA',
    secondaryNegativeTrait: 'NA',
    percentCompleted: 100, // You may want to update this dynamically
  };

  // Filter traits based on Zfactor > 0.5 and sort them in descending order
  const positiveTraits = traits.filter(t => t.Zfactor > 0.5).sort((a, b) => b.Zfactor - a.Zfactor);

  // Filter traits based on Zfactor < -0.5 and sort them in ascending order
  const negativeTraits = traits.filter(t => t.Zfactor < -0.5).sort((a, b) => a.Zfactor - b.Zfactor);

  // Filter traits based on -0.5 < Zfactor < 0.5
  const mixedTraits = traits.filter(t => t.Zfactor > -0.5 && t.Zfactor < 0.5);

  // Assign main and secondary positive traits if they exist
  if (positiveTraits.length > 0) summary.mainTrait = positiveTraits[0].trait;
  if (positiveTraits.length > 1) summary.secondaryTrait = positiveTraits[1].trait;

  // Assign main and secondary negative traits if they exist
  if (negativeTraits.length > 0) summary.mainNegativeTrait = negativeTraits[0].trait;
  if (negativeTraits.length > 1) summary.secondaryNegativeTrait = negativeTraits[1].trait;

  // Check for 'mixed' traits
  if (mixedTraits.length === traits.length) {
    summary.mainTrait = 'mixed';
    summary.secondaryTrait = 'mixed';
    summary.mainNegativeTrait = 'mixed';
    summary.secondaryNegativeTrait = 'mixed';
  }
  
  const newScore = {
    ...score,
    summary,
  };

  return newScore;
};


const setUpScoreObject = () =>{
  const score = calculate();

  const beforeScore = {...score};
  console.log('beforeScore  = ', beforeScore);
  const scoreAfterSummarize_ = summarizeMainTraits(beforeScore);
  setScoreAfterSummarize(scoreAfterSummarize_);
  setShowResult(true);
};


useEffect(() => {
  window.showStates = () => {
    console.log('================================');
    const pctCompleted = getPercentageCompleted();
    console.log('getPercentageCompleted =', pctCompleted);
   
    if (pctCompleted===100) {
      setUpScoreObject();
      return {
        isVisualRewardsShowAnimations,
        values,
        showResult,
        triggerCalculate,
        scoreObj,
        category,
      };
    }
  }

  // Return a cleanup function
  return () => {
    window.showStates = undefined;
  };
}, [isVisualRewardsShowAnimations,
     values, showResult, triggerCalculate, scoreObj, category]);


const getPercentageCompleted = () => {
  let numAnswered = 0;
  const NUMBER_OF_QUESTIONS = 50;
  const newValues = [...values];
  for (let i = 0, ilen = newValues.length; i<ilen; i++) {
    if (newValues[i].value !=='') {
      ++numAnswered;
    }
  }
  const newPercent = Math.round((numAnswered/NUMBER_OF_QUESTIONS) * 100);
  return newPercent;
};

  const handleData = () => {
    let score = calculate();
    const beforeScore = {...score};
    console.log('beforeScore  = ', beforeScore);
    if (uid && beforeScore) {

      const scoreAfterSummarize_ = summarizeMainTraits(beforeScore);

      setScoreAfterSummarize(scoreAfterSummarize_);
      
      console.log('scoreAfterSummarize = ', scoreAfterSummarize_);
      console.log('uid= ', uid);
      console.log('values = ', values);
      dispatch(addSessions(values, "part1", uid, scoreAfterSummarize_));
      setShowResult(true);
    } else {
      alert('you have to be logged in to save this. please log in your answers will be here after you login. ');
      history.push("/admin/home");
    }
  };
/*
  
   useEffect(()=>{

    const percentComplete = getPercentageCompleted();
    console.log('percentComplete =',percentComplete );
    if (percentComplete===100) {
      let score = calculate();
      const beforeScore = {...score};
      const scoreAfterSummarize_ = summarizeMainTraits(beforeScore);
      setScoreAfterSummarize(scoreAfterSummarize_);
    }
  },[scoreObj]);
  */
  /*
  a tooltip pops up that says 
  Please select an item in this list.
  This happens because the required property is set to true on the input . 
   
  */

  return (
    <>
      <Row className="personality-test-part">
        <Col md="12 py-4">
          <div>
            <h2 className='test-title'>Big 5 Personality Traits</h2>{/* <!-- part 1 -->' */}
            <p>
              <b>Instructions: For each statement, select how much you agree.</b> 
                <br/>
              1—disagree, 2=slightly disagree, 3=neutral, 4=slightly agree and
              5=agree.<br/><br/>
            </p>
          </div>

          <div>
            <div>
              <Row className="px-lg-4 px-md-4 px-0">
                <Col sm="12" xs="12">
                  <Form
                    ref={formRef}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleData();
                    }}
                  >
                    {part1Questions?.map((ques, index) => {
                      return (
                        <Row className="mt-1" key={index}>
                          <Col>
                            <div>
                              <Label>{ques.label}</Label>
                              <div>
                                <Input
                                  type="select"
                                  required
                                  value={values[index]?.value}
                                  className="personality-test-part__input-field"
                                  onChange={(e) =>
                                    handleChange(e, index, ques.cat)
                                  }
                                >
                                  <option value="">Rate It</option>
                                  <option value="1">1=disagree</option>
                                  <option value="2">2=slightly disagree</option>
                                  <option value="3">3=neutral</option>
                                  <option value="4">4=slightly agree</option>
                                  <option value="5">5=agree</option>
                                </Input><br/>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                    {/* <Button
                      size="lg"
                      className="my-3"
                      color="info"
                      type="submit"
                    >
                      Calculate
                    </Button> */}

                    <div className="d-flex justify-content-center align-items-center m-4">
                      <Button className="personality_see_and_save_btn" type="submit">Save & See Results</Button>
                    </div>
                  </Form>
                  {showResult && (
                    <>
                    <div className='mx-3'>
                      <div className="text-center">
                        <span>{scoreObj?.E}</span> Introversion - Extroversion
                      </div>
                      {}
                      <Progress
                        value={scoreObj?.E}
                        max={maxScorePerTrait}
                      />
                      <div className="text-center">
                        <span>{scoreObj?.A}</span> Aggreeableness
                      </div>
                      <Progress
                        value={scoreObj?.A}
                        max={maxScorePerTrait}
                      />
                      <div className="text-center">
                        <span>{scoreObj?.C}</span> Conscientiousness
                      </div>
                      <Progress
                        value={scoreObj?.C}
                        max={maxScorePerTrait}
                      />
                      <div className="text-center">
                        <span>{scoreObj?.N}</span> Neuroticism
                      </div>
                      <Progress
                        value={scoreObj?.N}
                        max={maxScorePerTrait}
                      />
                      <div className="text-center">
                        <span>{scoreObj?.O}</span> Openess
                      </div>
                      <Progress
                        value={scoreObj?.O}
                        max={maxScorePerTrait}
                      />
                    </div> 

                      {scoreAfterSummarize?.summary?.secondaryTrait!==undefined && (
                       <VisualRewards 
                        isShowAnimations={showResult}
                        part="part1"
                        mainTrait={scoreAfterSummarize?.summary?.mainTrait}
                        secondaryTrait={scoreAfterSummarize?.summary?.secondaryTrait}

                        />
                      )}


                    </>
                  )}
                 
                </Col>
              </Row>
              <Row>  <FriendlyErrMsgBox/>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        
      </Row>
    </>
  );
};
export default PersonalityTestPart1;
/*
    console.log('inside calculateZFactor with parameters ',  big5MeanForCat, big5MeanForCatSD, objNewScoreAvg );
    if (objNewScoreAvg - big5MeanForCat < 0) {
      //-
      // negative - means the your calculated average = ( objNewScoreAvg ) is lower than the means.

      // 1 standard deviation lower test
      if (objNewScoreAvg - big5MeanForCat + big5MeanForCatSD >= 0) {
        // it was within 1 standard deviation
        return -1;
      } else if (objNewScoreAvg - big5MeanForCat + 2 * big5MeanForCatSD >= 0) {
        //2nd standard
        return -2;
      } else if (objNewScoreAvg - big5MeanForCat + 3 * big5MeanForCatSD >= 0) {
        //3rd  standard
        return -3;
      } else {
        return -4;
      }
    } else if (objNewScoreAvg - big5MeanForCat > 0) {
      // it means your average is bigger than the means

      // E mean:3.05 - 4 =
      // 4 - 3.05
      if (objNewScoreAvg - big5MeanForCat - big5MeanForCatSD <= 0) {
        // it was within 1 standard deviation  = 2 - 0.5
        return 1;
      } else if (objNewScoreAvg - big5MeanForCat - 2 * big5MeanForCatSD <= 0) {
        //2nd standard
        return 2;
      } else if (objNewScoreAvg - big5MeanForCat - 3 * big5MeanForCatSD <= 0) {
        //3rd  standard
        return 3;
      } else {
        return 4;
      }
    } else if (objNewScoreAvg - big5MeanForCat === 0) {
      return 0;
    }
    throw new Error("unexpected condition.");
    */