// HowMatchingWorks.jsx
import React, { useState } from 'react';
import '../../components/ProfileTest/ProfileTest.css'; 
import AvatarCircle from '../../components/AvatarCircle/AvatarCircle';
import LoveLanguageModal from '../../components/Modal/LoveLanguageModal';
import BackButton from '../../components/BackButton/BackButton';
import { Button } from 'react-bootstrap';
import iconC_vision from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-eye-representing-amazin--1@2x.png";
import iconC_action from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-gold-megaphone-icon-1@2x.png";
import iconC_process from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-green-puzzle-icon-1@2x.png";
import iconC_people from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png";

import iconB5_openness from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-light-blue-d--1@2x.png";
import iconB5_agreeableness from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-green-heart-icon-1-1@2x.png";
import iconB5_mixed from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-grey-star-ic--1@2x.png";
import iconB5L_extroverted from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-orange-sun-i--1@2x.png";
import iconB5_introverted from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-purple-ancho--2@2x.png";
import iconB5_Neurotic from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-red-whirlwin--1@2x.png";
import iconB5_conscientiousness from "../../assets/img/icons/light-blue-check-mark-conscientiousness-1@2x.png";

import iconLL_acts from "../../assets/img/icons/wrench-1@2x.png";
import iconLL_gifts from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-gold-gift-box-gold-box---1@2x.png";
import iconLL_touch from "../../assets/img/icons/hand-touch-emojid-1-1@2x.png";
import iconLL_time from "../../assets/img/icons/hourglass-1@2x.png";
import iconLL_words from "../../assets/img/icons/speak-emoj-1@2x.png";
import { nestedCardImgs } from "../../helpers/GlobalConstants";
import Slider from "react-slick";


const HowMatchingWorks = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleModalOpen = (content) => {
    setShowModal(true);
    setModalContent(content);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalContent('');
  };
  const avatarData = {
    percentCompleted: 40,
    userName:"Jael Lovelli",
    notificationIconData: 2
  };
  const userDataProfile = {
    userName:"Jael Lovelli",
    ageRange:""
  };

  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
<div className="howMatchingWorks bg-orange">
    <div className="letsUpgradeYou-container">
    <BackButton/>
        <div className="avatar-section">
          <AvatarCircle
            pictureSource={avatarData.pictureSource}
            percentCompleted={avatarData.percentCompleted}
          />
        </div>
       
        <div className="notification-bell"></div>
        <h4 className="text-center font-weight-bold text-white">How Matching Works</h4>
        <p className="p-4 text-center font-weight-bold text-white">After You finish your each personality tests, you will get one of these icons. Click the icon to see what it means.  </p>
    </div>

  
     
    <h6 className="label-lightOrange font-weight-bold">Big  Personality Test</h6>
    <div className="p-4">
        <Button onClick={() => handleModalOpen('openness')} size="lg">
        <img src={iconB5_openness} alt="Openness Icon" className="icon-left" /> Openness</Button>
        <Button onClick={() => handleModalOpen('conscientiousness')} size="lg">
        <img src={iconB5_conscientiousness} alt="Conscientiousness Icon" className="icon-left" /> Conscientiousness</Button>
        <Button onClick={() => handleModalOpen('extraversion')} size="lg">
        <img src={iconB5L_extroverted} alt="Extraversion Icon" className="icon-left" /> Extraversion</Button>
        <Button onClick={() => handleModalOpen('agreeableness')} size="lg">
        <img src={iconB5_agreeableness} alt="Agreeableness Icon" className="icon-left" /> Agreeablenesse</Button>
        <Button onClick={() => handleModalOpen('neuroticism')} size="lg">
        <img src={iconB5_Neurotic} alt="Neurotic Icon" className="icon-left" /> Neurotic</Button>
        <Button onClick={() => handleModalOpen('intraversion')} size="lg">
        <img src={iconB5_introverted} alt="Intraversion Icon" className="icon-left" /> Intraversion</Button>
        <Button onClick={() => handleModalOpen('mixed')} size="lg">
        <img src={iconB5_mixed} alt="Mixed Icon" className="icon-left" /> Mixed</Button>
    </div>

    <h6 className="label-lightOrange font-weight-bold">Communication Style</h6>
    <div className="p-4">
        <Button onClick={() => handleModalOpen('vision')} size="lg">
        <img src={iconC_vision} alt="Vision/Idea Icon" className="icon-left" /> Vision/Idea</Button>
        <Button onClick={() => handleModalOpen('people')} size="lg">
        <img src={iconC_people} alt="people Icon" className="icon-left" /> People Oriented</Button>
        <Button onClick={() => handleModalOpen('process')} size="lg">
        <img src={iconC_process} alt="process Icon" className="icon-left" /> Process-Orientedr</Button>
        <Button onClick={() => handleModalOpen('action')} size="lg">
        <img src={iconC_action} alt="action Icon" className="icon-left" /> Action Oriented</Button> 
    </div>

    <h6 className="label-lightOrange font-weight-bold">Love Languages</h6>
    <div className="p-4">
        <Button onClick={() => handleModalOpen('gifts')}  size="lg">
        <img src={iconLL_gifts} alt="Gift Icon" className="icon-left" /> Gifts</Button>
        <Button onClick={() => handleModalOpen('touch')} size="lg">
        <img src={iconLL_touch} alt="Gift Icon" className="icon-left" /> Touch</Button>
        <Button onClick={() => handleModalOpen('words')} size="lg">
        <img src={iconLL_words} alt="Gift Icon" className="icon-left" /> Words</Button>
        <Button onClick={() => handleModalOpen('qualityTime')} size="lg">
        <img src={iconLL_time} alt="Gift Icon" className="icon-left" /> Quality Time</Button>
        <Button onClick={() => handleModalOpen('actsOfService')} size="lg">
        <img src={iconLL_acts} alt="Gift Icon" className="icon-left" /> Acts Of Service</Button>
    </div>

     <h6 className="label-lightOrange font-weight-bold">Passions & Interests</h6>
    <p className="p-4 text-white">Having the same passions are important.  There will be hundreds of different icons to represent different interests.
    
  </p>

    <h6 className="label-lightOrange font-weight-bold">Values & Goals</h6>
    <p className="p-4 text-white">    Research says people with matching values and goals is the 2nd most important thing after matching big 5 personality trait.

We represent passions, & interests with activity icons on the middle right of the card as you'll see below. </p>


    <h6 className="label-lightOrange font-weight-bold">Understand it with Cards</h6>
    <div className="text-white">
        <p className="p-4 text-white">  These cards is another way to represent different personalities. 
        
        The Background color represents the big 5 personality traits. 
        </p>
        <ul  className="p-4 text-white">
            <li>Orange Background = extroverted personality </li>
            <li>Purple Background = extroverted personality </li>
            <li>Blue Background = blue diamond icon = openness.   </li>
            <li>Orange Background = extroverted personality </li>
            <li>Red Background = neurtoic personality </li>
        </ul>
        <p  className="p-4 text-white">Notice the icons on the cards </p> 
        <ul  className="p-4 text-white">
            <li>Top Left Icons = Big 5 personality  </li>
            <li>Top Right Icons = Communication style </li>
            <li>Middle Right icons = passions   </li>
            <li>Bottom Right Icons = </li>
            <li>Bottom Left Icons = values  </li>
            <li>Middle Left something we call BBMP    </li>
        </ul>
     </div>


 
  <Slider {...settings3} className="nested-slider">
    {nestedCardImgs?.map((nestedSlide, nestedIndex) => (
      <div key={nestedIndex} className="nested-slider-item">
        <div className="nested-slider-img">
          <img
            src={location.search==="?i=1" ? nestedSlide.imageUrl2 : nestedSlide.imageUrl}
            alt="img"
            className="img-fluid"
          />
        </div>
      </div>
    ))}
  </Slider>


    <h6 className="label-lightOrange font-weight-bold">References. For those of you who like references.</h6>
    <div className="p-5 text-white">
        
<div>
    <div>
        <h4 className="font-weight-bold text-white"> Research List</h4>
        <p>The GOGPersonality App matches people based on their scores on (1) the Big 5 Personality Test, (2) Communication Styles, (3) Love Languages and more. It also matches people on values, goals, passions and more.<br/><br/>
        Everything we do has a scientific base Here is our reference list of articles that provide proof for matching people the way we match them. 
        </p>


        <h5 className="font-weight-bold text-white">BIG 5 PERSONALITY TEST</h5>
    
        <p>There is some research that suggests that couples who score similarly on certain traits of the Big Five Personality Test may be more compatible than couples who score differently. Here are some examples of studies that support this idea:
        </p>

    <ol>
        <li>Bouchard, T. J., Jr., Lussier, Y. A., Sabourin, S., & Tremblay, R. E. (1999). Personality and marital adjustment: Utility of the five-factor model of personality. Journal of Marriage and Family, 61(3), 651-660.</li>

        <li>Botwin, M. D., Buss, D. M., & Shackelford, T. K. (1997). Personality and mate preferences: Five factors in mate selection and marital satisfaction. Journal of Personality, 65(1), 107-136.</li>

        <li>Costa, P. T., Jr., & McCrae, R. R. (1988). Personality in adulthood: A six-year longitudinal study of self-reports and spouse ratings on the NEO Personality Inventory. Journal of Personality and Social Psychology, 54(5), 853-863.</li>

        <li>Dyrenforth, P. S., Kashy, D. A., Donnellan, M. B., & Lucas, R. E. (2010). Predicting relationship and life satisfaction from personality in nationally representative samples from three countries: The relative importance of actor, partner, and similarity effects. Journal of Personality and Social Psychology, 99(4), 690-702.</li>

        <li>Huang, J. L., Curran, P. G., Keeney, J., Poposki, E. M., & DeShon, R. P. (2012). Detecting and deterring insufficient effort responding to surveys. Journal of Business and Psychology, 27(1), 99-114.</li>

        <li>Luo, S., Li, X., & Wang, Y. (2020). Personality similarity and relationship satisfaction in couples: A meta-analysis. Journal of Family Psychology, 34(1), 46-55.</li>

        <li>Watson, D., Hubbard, B., & Wiese, D. (2000). General traits of personality and affectivity as predictors of satisfaction in intimate relationships: Evidence from self‐ and partner‐ratings. Journal of Personality, 68(3), 413-449.</li>
    </ol>
    </div>



    <div>
    <h5 className="font-weight-bold text-white">COMMUNICATION STYLE</h5>
    

        <p>A study published in the Journal of Family Communication found that couples who had similar communication styles reported higher relationship satisfaction, better communication quality, and lower levels of conflict. However, it's important to note that communication is just one aspect of a relationship, and other factors such as shared values, trust, and commitment are also important for a successful relationship.
        The reference for the study is:</p> 

    <ol>
        <li>Sahlstein Parcell, E., Rogers, K. B., & Keys, C. B. (2006). Communication competence, social support, and depression among college students: A model of Facebook and face-to-face support network influence. Journal of Family Communication, 6(2), 141-154.</li>

        <li>Prager, K. J., & Buhrmester, D. (1998). Intimacy and need fulfillment in couple relationships. Journal of Social and Personal Relationships, 15(5), 658-669.</li>

        <li>Canary, D. J., Stafford, L., & Hause, K. S. (1993). What couples say and do: The relation of communication and interpersonal satisfaction. Journal of Social and Personal Relationships, 10(2), 177-195.</li>

        <li>LePoire, B. A., & Dudley, N. M. (2010). Communication satisfaction, attraction, and psychological well-being in opposite-sex friendships. Communication Research Reports, 27(2), 105-114.</li>

        <li>Eriksen, K., & Kress, V. E. (2005). Communication in intimate relationships: A study of communication patterns and satisfaction among heterosexual and same-sex couples. Communication Studies, 56(1), 23-44.</li>

        <li>Fitzpatrick, M. A., & Sollie, D. L. (1999). Communicative competence in interpersonal relationships. Interpersonal Communication Research: Advances through Meta-analysis, 1, 1-29.</li>

    </ol>
    </div>



        <div>

        <h5 className="font-weight-bold text-white">LOVE LANGUAGES</h5> 

            <p>There is some research that suggests that couples with similar love languages may be more compatible and have more satisfying relationships. Here are some references:
            </p>


            <ol>
            <li>Chapman, G. (2010). The 5 love languages: The secret to love that lasts. Northfield Publishing.</li>
            <li>Sánchez, J. I., Yoo, S. H., & Singh, R. (2018). The role of love languages in marital satisfaction for long-term marriages. The Family Journal, 26(2), 142-147.</li>
            <li>Singh, R., & Sánchez, J. I. (2018). The role of love languages in relationship satisfaction for dating relationships. Journal of Couple & Relationship Therapy, 17(3), 202-219.</li>
            <li>Stephens, L. E., & Ruhl, H. (2010). Communication satisfaction, love languages, and relationship satisfaction. Journal of Couple & Relationship Therapy, 9(4), 303-320.</li>
            <li>Stephens, L. E., & Ruhl, H. (2014). Love languages and marital satisfaction in a sample of Brazilian couples. International Journal of Psychology and Behavioral Sciences, 4(2), 27-34.</li>
            <li>Szymanski, K., & Riggio, R. E. (2010). The relation between self-monitoring and the five love languages. The Journal of Social Psychology, 150(1), 73-91.</li>
            <li>Williams, C. L., & Berry, D. (2018). The impact of the five love languages on relationship satisfaction for couples in long-distance relationships. Journal of Family Studies, 24(3), 331-347.</li>
            <li>Zhao, X., & Wang, Q. (2019). Love languages and relationship satisfaction: A study of Chinese couples. Journal of Couple & Relationship Therapy, 18(4), 298-312.</li>
            <li>Shadish, W. R., & Baldwin, S. A. (2005). Meta-analysis of MFT interventions. Journal of Marital and Family Therapy, 31(2), 129-162.</li>
            <li>Acevedo, B. P., Aron, A., Fisher, H. E., & Brown, L. L. (2012). Neural correlates of long-term intense romantic love. Social Cognitive and Affective Neuroscience, 7(2), 145-159.</li>
            </ol>

            <p> It's important to note, however, that the concept of love languages is not universally accepted in the field of psychology, and there may be other factors that are more important for relationship satisfaction.

            </p>
        </div>


  <div>
  <h5 className="font-weight-bold text-white">VALUES </h5> 

  <p>there is research that suggests some important values that need to be shared in a relationship.
   According to a study published in the Journal of Social and Personal Relationships, the following values are most important for couples to share: <br/>
   </p>
   <ol>
    <li>honesty  </li>
    <li>commitment</li>
    <li>forgiveness</li>
    <li>equality</li>
    <li>reliability</li>
    <li>affection  </li>
    </ol>
  <p>
  Reference:
  Hendrick, S. S., & Hendrick, C. (1996). Measuring respect in close relationships. Journal of Social and Personal Relationships, 13(3), 364-393.
  </p>
  </div>



  <div>
  <h5 className="font-weight-bold text-white">EMPATHY</h5> 
    <p> 
    <b> Interpersonal Reactivity Index (IRI):</b>
     This is a self-report questionnaire that measures four different dimensions of empathy: 
     perspective-taking, fantasy, empathic concern, and personal distress.
      It consists of 28 questions and has been widely used in research on empathy.
  

    </p>
  </div>

</div>
        </div>
      <LoveLanguageModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        modalContent={modalContent} 
      />
     
</div>
  );
};
export default HowMatchingWorks;