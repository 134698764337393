import classNames from "classnames";
import { useState } from "react";
import "./customSelect.scss";

const CustomSelect = ({
  options,
  defaultLabel,
  multiSelectList,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

    const handleOptionChange = (event) => {
      const { value, checked } = event.target;

      if (handleChange) {
        handleChange({ option: value, checked });
      }
    };

  return (
    <>
     
      <div className={`custom-type-select`}>
        <div className={`select-wrapper ${isOpen && "active"}`}>
          <div
            className={`select-box ${isOpen && "radius-none"}`}
            onClick={toggleDropdown}
          >
            <span className="selected-option">{defaultLabel}</span>
            <i className={classNames("arrow", { open: isOpen })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
              >
                <path
                  d="M0.76165 1.58801L5.95877 6.42716L10.7535 1.18192"
                  stroke="#FF5603"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </i>
          </div>

          {isOpen && (
            <div className="options">
              {options.map((option) => (
              
                <label
                  className={`option ${
                    multiSelectList?.includes(option?.value) && "active"
                  }`}
                  key={option.value}
                >
                  <input
                    type="checkbox"
                    value={option?.value}
                    checked={multiSelectList?.includes(option?.value)}
                    onChange={handleOptionChange}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomSelect;
