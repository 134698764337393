import { part3Questions } from "helpers/GlobalConstants";
import React, { useEffect } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import parse from "html-react-parser";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSessions } from "store/actions/QuestionsAction";
import { getQuestions } from "store/actions/QuestionsAction";
import { useHistory } from "react-router-dom";
import { validateLocalStorageData } from "../../helpers/validateJson";
import { updateButtonPercent } from 'store/actions/actions.js';
// import { updateStatPart3 } from "store/actions/StatsAction";
import VisualRewards from "../../components/VisualRewards/VisualRewards";
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';


const PersonalityTestPart3 = () => {

  const [triggerCalculate, setTriggerCalculate] = useState(null);
  
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => state.authUser);
  
  useEffect(() => {
    if (uid) {
      dispatch(getQuestions(uid, "part3"));
    }
  }, []);
  const { questions } = useSelector((state) => state.questions);

  // to show VisualRewards
  const [showResult, setShowResult] = useState(false);

  const [scoreAfterSummarize, setScoreAfterSummarize] = useState({
    summary: {
      mainTrait: 'NA',
      secondaryTrait: 'NA',
      mainNegativeTrait: 'NA',
      secondaryNegativeTrait: 'NA',
      percentCompleted: 0,
    }
  });

  const [score, setScore] = useState(null);

  const [scoreObj, setScoreObj] = useState({
      hclQuestion1: questions?.part3?.hclQuestion1 ?? "",
      hclQuestion2: questions?.part3?.hclQuestion2 ?? "",
      hclQuestion3: questions?.part3?.hclQuestion3 ?? [""],
      hclQuestion4: questions?.part3?.hclQuestion4 ?? [""],
      // new Array(part3Questions.questions[3].options.length).fill( "" ),
      hclQuestion5: questions?.part3?.hclQuestion5 ?? "",
      hclQuestion6: questions?.part3?.hclQuestion6 ?? "",
      hclQuestion7: questions?.part3?.hclQuestion7 ?? "",
      hclQuestion8: questions?.part3?.hclQuestion8 ?? "",
  });


  console.log('questions = ', questions);
  const history = useHistory();

  useEffect(() => {
    const templateResults =  {
      hclQuestion1: questions?.part3?.hclQuestion1 ?? "",
      hclQuestion2: questions?.part3?.hclQuestion2 ?? "",
      hclQuestion3: questions?.part3?.hclQuestion3 ?? [""],
      hclQuestion4: questions?.part3?.hclQuestion4 ?? [""],
      hclQuestion5: questions?.part3?.hclQuestion5 ?? "",
      hclQuestion6: questions?.part3?.hclQuestion6 ?? "",
      hclQuestion7: questions?.part3?.hclQuestion7 ?? "",
      hclQuestion8: questions?.part3?.hclQuestion8 ?? "",
    };

    
    const savedResults = localStorage.getItem("part3") ? 
      JSON.parse(localStorage.getItem("part3")) : null;
   
    const mergedResults = { ...templateResults };
    console.log('savedResults =', savedResults);
    console.log('mergedResults =', mergedResults);

    if (savedResults) {
      Object.keys(templateResults).forEach(key => {
        if (savedResults[key] !== undefined && savedResults[key]) {
          // Perform additional validation, e.g., array length checks if needed
          if (key==='hclQuestion3' ) {
            if (savedResults[key].filter(x => x === true || x === false).length >  
              mergedResults[key].filter(x => x === true || x === false).length) { 
              mergedResults[key] = savedResults[key];
            }
          } else if (key==='hclQuestion4') {
            if (savedResults[key].filter(x => x === true || x === false).length >  
              mergedResults[key].filter(x => x === true || x === false).length) { 
              mergedResults[key] = savedResults[key];
            }
          } else {
            mergedResults[key] = savedResults[key]; 
          }
        }
      });
    } 

    console.log('new mergedResults=',mergedResults);
    setScoreObj(mergedResults);
    setShowResult(true);
  }, [questions?.part3]);
/*

  useEffect(() => {
    if (questions?.part3.hclQuestion1) {
      const updatedResults = {
        ...scoreObj,
        ...questions?.part3
      };

      setScoreObj(updatedResults);
    }
  }, [questions?.part3]);
  */


  // the purpose of this is to show all after everything is completed . 
  useEffect(() => {
    localStorage.setItem("part3", JSON.stringify(scoreObj));
    console.log('setItem scoreObj=',scoreObj);

    const pctCompleted = getAndSetPercentComplete();
     if (pctCompleted===100) {
      console.log('inside pctCompleted',pctCompleted );
      const summaryAddedToScore = setUpScoreObject();
      setScoreAfterSummarize(summaryAddedToScore);
      setShowResult(true);
     } else {
      setShowResult(false);
     }
  }, [scoreObj]);

  const testCase = () => {
    let temp = {
      hclQuestion1: "Neither better not worse than usual",
      hclQuestion2: "...is always rather stable and even",
      hclQuestion3: new Array(part3Questions.questions[2].options.length).fill(
        true
      ),
      hclQuestion4: new Array(part3Questions.questions[3].options.length).fill(
        "Pos & Neg"
      ),
      hclQuestion5: "Positively (encouraging or supportive)",
      hclQuestion6: "4-7 days",
      hclQuestion7: "Yes",
      hclQuestion8: 12,
    };
    setScoreObj(temp);
    setTriggerCalculate((prevState) =>
      prevState === null ? false : !prevState
    );
    return `Part 3 - Calculation Test'`;
  };

  /*
  Hypomania Check List (HCL-32 
  Scoring manual 
  http://www.lakarhuset.com/docs/HCL_32_R1_Manual.pdf
  How are the results of the HCL-32 R1 scored?
The total score is the number of positive answers to the 32 items listed in question 3.
Individuals with a total score of 14 or more are potentially bipolar and should be carefully
interviewed, including previous history and family history; if possible, significant others should be
questioned. A thorough examination is also indicated in subjects from the general population
with a score of 14 or more who additionally report any negative, or mixed negative and positive,
consequences of their experiences (e.g. reactions from others). Caveat: subjects with less than
14 positive answers can still be bipolar cases. 

====
The sum of the following items forms the dimension “active/elated”: 2, 3, 4, 5, 6, 10, 11, 12, 13, 15, 16, 19, 20, 22, 24, and 28; a sum score of 12 or
higher may indicate rather an “active/elated hypomania”. The sum of the following items forms the dimension "irritable/risk-taking": 7, 8, 9, 21, 25, 26,
27, 31 and 32; a sum score of 3 or higher may indicate rather an “irritable/risk-taking hypomania”


“active/elated” = [ 2, 3, 4, 5, 6, 10, 11, 12, 13, 15, 16, 19, 20, 22, 24, 28]  >=12 

"irritable/risk-taking": [7, 8, 9, 21, 25, 26, 27, 31, 32]
  */

  
  const setUpScoreObject = () => {
    let _score = scoreObj.hclQuestion3.filter(
      (hclQuestion) => hclQuestion === true
    ).length;

    console.log('_score  =', _score );
    console.log('scoreObj =', scoreObj );
    if (typeof _score === 'number') {
      setScore(_score);

      const indicesToCheckPositiveActivation = [2, 3, 4, 5, 6, 10, 11, 12, 13, 15, 16, 19, 20, 22, 24, 28];
      const indicesToCheckNegativeActivation = [7, 8, 9, 21, 25, 26, 27, 31, 32];

      const trueCountPositive = indicesToCheckPositiveActivation.filter(
        index => scoreObj.hclQuestion3[index-1] === true
      ).length;

      const trueCountNegative = indicesToCheckNegativeActivation.filter(
        index => scoreObj.hclQuestion3[index-1] === true
      ).length;

      const positiveActivation = trueCountPositive / indicesToCheckPositiveActivation.length;
      const negativeActivation = trueCountNegative / indicesToCheckNegativeActivation.length;
      const activationObject = {
        positiveActivation:positiveActivation,
        negativeActivation:negativeActivation
      };

      const summaryAddedToScore = {
        summary : {
          mainTrait: _score >= 13 ? 'positivehcl':'negativehcl',
          secondaryTrait: activationObject,
          rawScore: _score, 
          mainNegativeTrait: '',
          secondaryNegativeTrait: '',
          percentCompleted: 100 // Math.floor(50/50 * 100) don't for get to dynamically update 
        }
      };  
      setScoreAfterSummarize(summaryAddedToScore );
      getAndSetPercentComplete();
      console.log('summaryAddedToScore 1234 = ', summaryAddedToScore);
      return summaryAddedToScore ;
    } else {
      throw new Error('_score is not a number it should be 1');
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if  (!uid) {
      alert('you need to be logged in to see and save this personality test. log in your answers will be here for you when you get back.');
      return false;
    }
    const summaryAddedToScore = setUpScoreObject();
    setScoreAfterSummarize(summaryAddedToScore );
    setShowResult(true);
    console.log('score part3 = ', summaryAddedToScore);
    dispatch(addSessions(scoreObj, "part3", uid, summaryAddedToScore));
  };



    /*
    if (_score) {
      console.log('score part3 = ', _score);
      dispatch(updateStatPart3(uid, _score, 3));
    }
    */

  useEffect(() => {
    if (triggerCalculate !== null) handleSubmit({ preventDefault() {} });
  }, [triggerCalculate]);

  useEffect(() => {
    window.TestCase = testCase;
  }, []);


  const getAndSetPercentComplete = () =>{

    let currentCount = 0;
    const TOTAL_QUESTIONS = 42;
    if (scoreObj.hclQuestion1) {
      currentCount++;
    }
    if (scoreObj.hclQuestion2) {
      currentCount++;
    }
    if (Array.isArray(scoreObj.hclQuestion3)) {
      // 32 questions  
      currentCount += scoreObj.hclQuestion3.filter(x => x === true || x === false).length;
  
    }
    if (Array.isArray(scoreObj.hclQuestion4)) {
      // 4 questions 
      currentCount += scoreObj.hclQuestion4.filter(x => x === "Positive" || x==="Pos & Neg" || x==="No Impact" || x === "Negative").length;
    }
    if (scoreObj.hclQuestion5) {
      currentCount++;
    }
    if (scoreObj.hclQuestion6) {
      currentCount++;
    }
    if (scoreObj.hclQuestion7) {
      currentCount++;
    }
    if (scoreObj.hclQuestion8) {
      currentCount++;
    }
    const percentComplete =  Math.round(currentCount/TOTAL_QUESTIONS * 100);
    dispatch(updateButtonPercent("hcl", percentComplete));  
    console.log('onChange handler = ', scoreObj, 'percentComplete=', percentComplete);
    return percentComplete;
  };
 
  const onChangeEventHandler = (e) => {
    getAndSetPercentComplete();
  };


  const handleReset = ()=>{
    setShowResult(false);
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="personality-test-part">
          <Col md="12 py-4">
            <div>
             <h2 className='test-title'>{part3Questions.title}</h2>
              <p>{part3Questions.description}</p>
            </div>

            <div>
              <Row className="px-lg-4 px-md-4 px-0">
                <Col>
                  {part3Questions.questions.map((question, qIdx) => (
                    <Row className="mb-5" key={qIdx}>
                      <Col>
                        <div>
                          <p className="my-1">
                            <span className="mr-2 d-inline-block">
                              {qIdx + 1}.
                            </span>
                            {parse(question.statement)}
                          </p>
                          <p className="my-1">
                            {question.description
                              ? parse(question.description)
                              : ""}
                          </p>
                          <div className="mt-3">
                            {question.type === "number" ? (
                              <FormGroup  key={qIdx}>
                                <Input
                                  name={`hclQuestion${qIdx + 1}`}
                                  required
                                  className="w-50"
                                  type="number"
                                  value={scoreObj[`hclQuestion${qIdx + 1}`]}
                                  onChange={(e) => { 
                                    setScoreObj((prevState) => ({
                                      ...prevState,
                                      [e.target.name]: e.target.value,
                                    }));
                                    onChangeEventHandler();
                                  }}
                                />
                              </FormGroup>
                            ) : (
                              question.options?.map((option, idx) =>
                                question.type === "radio" ? (
                                  <FormGroup  key={idx} check >
                                    <Input
                                      required
                                      type="radio"
                                      name={`hclQuestion${qIdx + 1}`}
                                      value={option}
                                      checked={
                                        scoreObj[`hclQuestion${qIdx + 1}`] ===
                                        option
                                      }
                                      onChange={(e) => {
                                        let key = e.target.name.split("-")[0];
                                        setScoreObj((prevState) => ({
                                          ...prevState,
                                          [key]: e.target.value,
                                        }));
                                        onChangeEventHandler();
                                      }}
                                    />
                                    <Label check>{option}</Label>
                                  </FormGroup>
                                ) : question.type === "yesAndNo" ? (
                                  <Row  key={idx}  className="align-items-center my-3">
                             <Col lg="10" md="10" sm="8" xs="8">{option}</Col>
                              <Col lg="2" md="2" sm="4" xs="4">
                                      <div className="d-flex">
                                        <FormGroup check className="mr-2">
                                          <Input
                                            required
                                            type="radio"
                                            name={`hclQuestion${
                                              qIdx + 1
                                            }-${idx}`}
                                            value={true}
                                            checked={
                                              scoreObj[
                                                `hclQuestion${qIdx + 1}`
                                              ][idx] === true
                                            }
                                            onChange={(e) => {
                                              let key =
                                                e.target.name.split("-")[0];
                                              setScoreObj((prevState) => {
                                                prevState[key][idx] = true;
                                                return {
                                                  ...prevState,
                                                };
                                                
                                              });
                                              onChangeEventHandler();
                                            }}
                                          />
                                          <Label check>Yes</Label>
                                        </FormGroup>
                                        <FormGroup check>
                                          <Input
                                            required
                                            type="radio"
                                            name={`hclQuestion${
                                              qIdx + 1
                                            }-${idx}`}
                                            value={false}
                                            checked={
                                              scoreObj[
                                                `hclQuestion${qIdx + 1}`
                                              ][idx] === false
                                            }
                                            onChange={(e) => {
                                              let key =
                                                e.target.name.split("-")[0];
                                              setScoreObj((prevState) => {
                                                prevState[key][idx] = false;
                                                return {
                                                  ...prevState,
                                                };
                                              });
                                              onChangeEventHandler();
                                            }}
                                          />
                                          <Label check>No</Label>
                                        </FormGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : question.type === "negativeAndPositive" ? (
                                  <Row  key={idx} >
                                    <Col className="mt-2 text-center" xl="3">
                                      {option}
                                    </Col>
                                    <Col xl="9">
                                      <Row className="my-2">
                                        <Col xs="6" lg="3">
                                          <FormGroup check className="mr-3">
                                            <Input
                                              required
                                              type="radio"
                                              name={`hclQuestion${
                                                qIdx + 1
                                              }-${idx}`}
                                              value={`${option} - Pos & Neg`}
                                              checked={
                                                scoreObj[
                                                  `hclQuestion${qIdx + 1}`
                                                ][idx] === "Pos & Neg"
                                              }
                                              onChange={(e) => {
                                                let key =
                                                  e.target.name.split("-")[0];
                                                setScoreObj((prevState) => {
                                                  prevState[key][idx] =
                                                    e.target.value.split(
                                                      " - "
                                                    )[1];
                                                  return {
                                                    ...prevState,
                                                  };
                                                });
                                                onChangeEventHandler();
                                              }}
                                            />
                                            <Label check>
                                              Positive & Negative
                                            </Label>
                                          </FormGroup>
                                        </Col>
                                        <Col xs="6" lg="3">
                                          <FormGroup check className="mr-3">
                                            <Input
                                              required
                                              type="radio"
                                              name={`hclQuestion${
                                                qIdx + 1
                                              }-${idx}`}
                                              value={`${option} - Positive`}
                                              checked={
                                                scoreObj[
                                                  `hclQuestion${qIdx + 1}`
                                                ][idx] === "Positive"
                                              }
                                              onChange={(e) => {
                                                let key =
                                                  e.target.name.split("-")[0];
                                                setScoreObj((prevState) => {
                                                  prevState[key][idx] =
                                                    e.target.value.split(
                                                      " - "
                                                    )[1];
                                                  return {
                                                    ...prevState,
                                                  };
                                                });
                                                onChangeEventHandler();
                                              }}
                                            />
                                            <Label check>Positive</Label>
                                          </FormGroup>
                                        </Col>
                                        <Col xs="6" lg="3">
                                          <FormGroup check className="mr-3">
                                            <Input
                                              required
                                              type="radio"
                                              name={`hclQuestion${
                                                qIdx + 1
                                              }-${idx}`}
                                              value={`${option} - Negative`}
                                              checked={
                                                scoreObj[
                                                  `hclQuestion${qIdx + 1}`
                                                ][idx] === "Negative"
                                              }
                                              onChange={(e) => {
                                                let key =
                                                  e.target.name.split("-")[0];
                                                setScoreObj((prevState) => {
                                                  prevState[key][idx] =
                                                    e.target.value.split(
                                                      " - "
                                                    )[1];
                                                  return {
                                                    ...prevState,
                                                  };
                                                });
                                                onChangeEventHandler();
                                              }}
                                            />
                                            <Label check>Negative</Label>
                                          </FormGroup>
                                        </Col>
                                        <Col xs="6" lg="3">
                                          <FormGroup check>
                                            <Input
                                              required
                                              type="radio"
                                              name={`hclQuestion${
                                                qIdx + 1
                                              }-${idx}`}
                                              value={`${option} - No Impact`}
                                              checked={
                                                scoreObj[
                                                  `hclQuestion${qIdx + 1}`
                                                ][idx] === "No Impact"
                                              }
                                              onChange={(e) => {
                                                let key =
                                                  e.target.name.split("-")[0];
                                                setScoreObj((prevState) => {
                                                  prevState[key][idx] =
                                                    e.target.value.split(
                                                      " - "
                                                    )[1];
                                                  return {
                                                    ...prevState,
                                                  };
                                                });
                                                onChangeEventHandler();
                                              }}
                                            />
                                            <Label check>No Impact</Label>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button className='personality_see_and_save_btn' type="submit">Save & See Result</Button>
        </div>
        {score !== null && (
          <div className="mt-4 rounded overflow-hidden">
            <div
              className={`d-flex p-2 border text-white ${
                score < 14 ? "bg-danger" : "bg-success"
              }`}
            >
              Might {score < 14 ? "not" : ""} be Bipolar type 2
            </div>
          </div>



        )}
          
           {scoreAfterSummarize?.summary?.secondaryTrait!==undefined && (
            <VisualRewards 
            isShowAnimations={showResult}
            part="part3"
            mainTrait={scoreAfterSummarize?.summary?.mainTrait}
            secondaryTrait={scoreAfterSummarize?.summary?.secondaryTrait}
            />
          )}

      </Form>

    </>
  );
};

export default PersonalityTestPart3;

/* notes about using both the mdq and the hcl-32 
from chat gpt 
topic: MDQ vs HCL-32 Bipolar screening


The Mood Disorder Questionnaire (MDQ) and the Hypomania Check List (HCL-32 R1) serve somewhat overlapping but distinct roles in screening for bipolar spectrum disorders. Both are self-administered tools, but they focus on different aspects of bipolar disorder and are designed for slightly different purposes.

Mood Disorder Questionnaire (MDQ):

Scope: It screens broadly for bipolar spectrum disorders (Bipolar I, II, and Cyclothymia).
Sensitivity: Generally higher for detecting Bipolar I.
Symptoms: Focuses on the "high" or "up" symptoms to distinguish them from unipolar depression.
Functionality: Less emphasis on how the symptoms impact daily life.
History: Questions about symptom duration and impact on lifestyle but not as nuanced for hypomania specifically.


Hypomania Check List (HCL-32 R1):

Scope: More focused on identifying symptoms of hypomania, therefore, more geared toward Bipolar II, minor BP, and subthreshold hypomania.
Sensitivity: Better suited for detecting Bipolar II and subthreshold bipolar conditions.
Symptoms: Detailed coverage of hypomanic symptoms, including less obvious ones that a patient may not report.
Functionality: Addresses how symptoms have changed one's activity and affected relationships.
History: More nuanced questions about life changes and consequences tied to hypomanic episodes.


Advantages of Using Both:

Comprehensive Screening: Using both can provide a more complete picture of one's symptoms, covering a wider range of bipolar conditions.

Confirmatory Data: Positive results on both can reinforce the need for a comprehensive evaluation by a healthcare provider.

Symptom Nuances: HCL-32 R1 can pick up nuances like irritability or less severe hypomanic symptoms that might not be captured as effectively by MDQ.

Functional Impact: The HCL-32 R1 can provide more information on the impact of hypomanic symptoms on relationships and daily life, which might not be adequately covered by MDQ.

Threshold Identification: Using both tools can help identify subthreshold bipolar symptoms that may not fulfill the full criteria for a bipolar diagnosis but still warrant attention and possibly treatment.

Risk Assessment: The HCL-32 R1's focus on lifestyle and relationships could serve as a practical risk assessment tool to understand how the symptoms are affecting the individual's life, thereby helping in treatment planning.

Type Differentiation: If someone is unsure about the type of bipolar disorder they might have, using both questionnaires could provide additional information useful for a preliminary differentiation.

Resource Allocation: For clinicians or researchers, using both questionnaires can guide the type of follow-up tests or resources needed for more detailed diagnostic evaluation.

Given your observations about irritability and relationship issues, the HCL-32 R1 would likely be a useful tool to capture those specific aspects of hypomania. It's important to emphasize, however, that neither test is a substitute for professional evaluation and diagnosis, which generally involve a clinical interview, longitudinal observation, and sometimes further psychological testing.

Finally, since you have an interest in understanding human behavior, potentially for developing your app or your humanitarian work, understanding these psychological tools could be insightful. However, it's crucial to consult healthcare professionals if these tools are ever to be applied in a medical or healthcare context.

*/


