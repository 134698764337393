// usePersonalityTestResults.js
// import usePersonalityTestResults from '../../helpers/usePersonalityResults';
import { useState, useEffect } from "react";
import fetchPersonalityResults from "./fetchPersonalityResults";
// import { defaultButtonData } from "./defaultButtonData";

export const usePersonalityTestResults = (slicedButtonData, uid, shouldFetch = true) => {
  const [buttonData, setButtonData] = useState(slicedButtonData);
  const [lastFetch, setLastFetch] = useState(0); // Track last fetch time in milliseconds since the epoch
  
  const rateLimitPeriod = 120000; // 2 minutes in milliseconds
  
  console.log ('in  with usePersonalityTestResults ', slicedButtonData, uid, shouldFetch );
  useEffect(() => {
    const currentTime = Date.now();
    if (uid &&  (currentTime - lastFetch > rateLimitPeriod)) {
      fetchPersonalityResults(uid, [...buttonData]).then(({status, updatedButtonData }) => {
        if (status==='success' && Array.isArray(updatedButtonData)) {
          // found some data from the database 

          console.log('in if condition is true',status, updatedButtonData  ); 
          setButtonData(updatedButtonData  || slicedButtonData);
          setLastFetch(currentTime); 
        }
      });
    }
  }, [uid, shouldFetch]);

  return buttonData;
};