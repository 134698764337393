/**
 * @file components/Avatar/AvatarCircle.jsx
 * buttonName expecting a valid path 
 * percentCompleted expecting an integer between 0 to 100
 * 
 * @param {string} buttonName
 * @param {integer} percentCompleted
 * @param {string} iconResults path to image sources 
 * @param {string} iconAlt explanation
 * @param {Function} onclick handler
 * @returns JSX.Element
 */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import './ButtonCircleGraph.css';
import coin1 from '../../assets/img/icons/webm//Coin Explosion 1.webm';
import coin2 from '../../assets/img/icons/webm//Coin Explosion 2.webm'; 
import coin3 from '../../assets/img/icons/webm//Coin Explosion 3.webm';
/*
// custom hook 
const useVideoReplay = (maxLoop) => {

  const videoRef = useRef(null);

const loopCounter = useRef(0); 
useEffect(() => {
  const handleVideoEnd = () => {
    loopCounter.current++;
    if (loopCounter.current < maxLoop) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };
  videoRef.current.addEventListener('ended', handleVideoEnd);
  return () => {
    videoRef.current.removeEventListener('ended', handleVideoEnd);
  };
}, []);
return videoRef;
};
*/
 
//// 

/**
 * @param {string} buttonName - 'uninitialized' 
 * @param {Number} percentCompleted 
 * @param {string} iconResults - path to animation icon per button
 * @param {string} iconAlt - path to animation icon alt
 * @param {Function} onclick  
 * @param {forwardRef} ref 
 * @returns {JsxElement}
 */
const ButtonCircleGraph = React.forwardRef(
  ({ buttonName='uninitialized', id, msgData, percentCompleted=75, ontransitionStart, iconResults, iconAlt, onclick, classname }, 
    ref) => {
      
      console.log('In id='+id+' ButtonCircleGraph with percentComplete=',percentCompleted);
      //const videoRef = useVideoReplay(7);
      
      const { uid, user } = useSelector(state => state.authUser);

      const hasFullAccess = user?.hasFullAccess === 'n' ||  user?.hasFullAccess === 'y' ? user?.hasFullAccess : 'n';
      // const buttonPercent = useSelector(state => state.button.buttonPercent);
      // console.log('In ButtonCircleGraph with buttonPercent =',buttonPercent );
      const [thisid, setButtonId] = useState(id);
/* 
not needed 
      useEffect(() => { 
         if (buttonPercent?.[thisid] && typeof buttonPercent?.[thisid] ==='number') {
          console.log('thisid = ', thisid,'  buttonPercent=', buttonPercent);
         
          const circle = circleRef.current;
          const percent = buttonPercent[thisid]/100;
          const calculated = 151 - (151 * percent);
          circle.setAttribute('percentcompleted', `${buttonPercent[thisid]}%`);
          circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
          
        }
      }, [buttonPercent, id]);
*/
    const circleRef = useRef(null);
 
   // const calculatedPercentCompleted =  percentCompleted;
    
    useEffect(() => {
      const circle = circleRef.current;
      const percent = percentCompleted / 100;
      const calculated = 151 - (151 * percent);
      circle.setAttribute('percentcompleted', `${percentCompleted}%`);
      circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
    }, [percentCompleted]);
 

    //const [percent, setPercent] = useState(0);

    useImperativeHandle(ref, () => ({
      changePercent
    }));

    const changePercent = (newPercent, closeOrOpen) => {
      const circle = circleRef.current;
      const percent = newPercent / 100;
      const calculated = 151 - 151 * percent;
      circle.setAttribute('percentcompleted', `${newPercent}%`);
      circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
 
     //  setPercent(newPercent);
      let elmRoot =circle.classList.contains('u-js-animation-parent')? circle : circle.closest('.u-js-animation-parent');
      if (closeOrOpen==='open') {
        elmRoot.classList.remove('is-closed');
      } else if (closeOrOpen==='close') {
        elmRoot.classList.add('is-closed');
        let webmElm = elmRoot.querySelector('.gog-js-iconResult');
        if (webmElm) {
          webmElm.play();
        }
      }
    };

    const [tooltipOpenSpeak, setTooltipOpenSpeak] = useState(false);
    const toggleToolSpeak = () => setTooltipOpenSpeak(!tooltipOpenSpeak);
  
 
    const handleClick = () => {
      console.log('ButtonCircleGraph Button clicked! empty function. should not be called');
    };
  return (
    <div className={`u-js-animation-parent new-avatar--button-row ${classname}`}>
      <div className='u-animated-icon' onClick={toggleToolSpeak}>
          <video id={`toolTipIcon-${id}`} autoPlay loop preload='auto' className="gog-js-iconResult" src={iconResults} alt={iconAlt} ></video>
          <Tooltip
                    placement="top"
                    isOpen={tooltipOpenSpeak}
                    target={`toolTipIcon-${id}`}
                    toggle={toggleToolSpeak}
                    ><p>{msgData?.msg}</p>
                    { hasFullAccess==='n'? <p>Learn more about your personality results when you have Full Access.</p> : null }</Tooltip> 
                    
      </div>
      <div className='u-personalitytest--bar u-js-personalitytest--bar-1' onClick={onclick || handleClick} > <span>{buttonName} </span>
        <div className="circular-graph" ref={circleRef} percentcompleted="">
          <svg className="circle-svg" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle circle-white" cx="50%" cy="50%" r="40%"/>
            <circle className="circle circle-green" cx="50%" cy="50%" r="40%"/>
          </svg>
        </div>
      </div>
    </div>
  );
});
export default ButtonCircleGraph;

 




 /*  function handleTransitionStart(event) {
      //ontransitionStart();
  }
 
    useEffect(() => {
        // Check if the ref is not null and is initialized
        if (ref && ref.current) {
          console.log('ref.current=',ref.current);
            // Navigate up to find the closest parent element with the specified class
            const barElement = ref.current.closest('.u-personalitytest--bar');
            
            // Check if the barElement is not null
            if (barElement) {
                // Attach the event listener
                barElement.addEventListener('transitionstart', handleTransitionStart);
                
                // Cleanup
                return () => {
                    barElement.removeEventListener('transitionstart', handleTransitionStart);
                };
            }
        }
    }, [ref, ontransitionStart]);
*/
/* 

animation explanation 

STEP #1: call buttonref.changePercent and change the stroke. time = 1.5seconds 
 transition: stroke-dashoffset 1.5s ease-in-out 0s;

 STEP #2: Then it starts closing for 200ms
     transition: width 200ms ease-in 0s;
    will-change: width;
    
 so,  1.5 seconds after that call to btn,  jumping should begin  

  STEP #3: The jumping necessarily takes 1.533s 


 */

    /*
    useEffect(() => {
      if (ref) {
        ref.current = {
          circleRef:circleRef,
          changePercent: (newPercent, closeOrOpen) => {
            const circle = circleRef.current;
            const percent = newPercent / 100;
            const calculated = 151 - 151 * percent;
            circle.setAttribute('percentcompleted', `${newPercent}%`);
            circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
       
             
                let elmRoot =circle.classList.contains('u-js-animation-parent')? circle : circle.closest('.u-js-animation-parent');
                 if (closeOrOpen==='open') {
                  elmRoot.classList.remove('is-closed');
                 } else if (closeOrOpen==='close') {
                  elmRoot.classList.add('is-closed');
                  let webmElm = elmRoot.querySelector('.gog-js-iconResult');
                  if (webmElm) {
                    webmElm.play();
                  }
                 }
          },
        };
       // console.dir(ref.current);
        ref(ref.current);
      }
    }, [ref]);


    */


 


 /*
    const getFileExtension = (filename) => {
      if (typeof filename==='string') {
        return filename.split('.').pop().toLowerCase();
      } 
      return null;
    };

    const fileExtension = getFileExtension(iconResults);
    const pathToMedia = '../../assets/img/icons/';
  
      {['mp4', 'webm'].includes(fileExtension) && (
          <video class="gog-js-iconResult" autoplay="autoplay" src={iconResults} alt={iconAlt}></video>
        )}
        {['jpg', 'png', 'gif', 'webp'].includes(fileExtension) && (
          */
 // bank of america gets a house 