// src/store/reducers/reducers.js
const initialState = {
    buttonPercent: {},
  };
  
  export const buttonReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_BUTTON_PERCENT':
        return {
            ...state,
            buttonPercent: {
                ...state.buttonPercent,
                [action.payload.id]: action.payload.newPercent
            }
        };
      default:
        return state;
    }
  };
  