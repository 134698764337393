import React, { useEffect, useState } from "react";
import FilterIcon from "../../assets/images/Filter-icon.png";
import "./createProfileSteps.scss";

const InterestButton = ({ interest, selected, onChange, disabled }) => {
  return (
    <label
      className={`interest-button ${selected ? "selected" : ""} ${
        disabled && "disabled"
      }`}
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={() => onChange(interest)}
      />
      {interest}
    </label>
  );
};

const Interests = ({ selectedInterests, setSelectedInterests }) => {
  console.log('selectedInterests, setSelectedInterests', selectedInterests, setSelectedInterests);
  
  const [interests, setInterests] = useState([
    "Outdoor Adventures",
    "HIKING",
    "CAMPING",
    "ROCK CLIMBING",
    "KAYAKING",
    "SURFING",
    "PILOTING",
    "DRONES",
    "Sports",
    "FOOTBALL",
    "BASKETBALL",
    "TENNIS",
    "SOCCER",
    "GOLE",
    "Creative Writing",
    "POETRY",
    "FICTION",
    "SCREENWRITING",
    "Performing Arts",
    "THEATER",
    "DANCE",
    "Travel",
    "EXPLORING NEW PLACES",
    "Cooking and Baking",
    "TRYING NEW RECIPES",
    "TRYING OUT NEW FOODS",
    "Foodie",
    "COOKING",
    "Visual Arts",
    "PAINTING",
    "DRAWING",
    "AIART",
    "Social Activism",
    "COMMUNITY ACTIVISM",
    "Photography",
    "CAPTURING MOMENTS",
    "wine tasting",
  ]);

    // Function to validate and add any selected interests not present in the interests list
    const validateAndAddSelectedInterests = (selected) => {
      let newInterests = [...interests]; // Create a copy of current interests
      selected.forEach((interest) => {
        if (!interests.includes(interest)) {
          setInterests((prevInterests) => [...prevInterests, interest]);
          newInterests.unshift(interest);
        } else {
          newInterests = [interest, ...newInterests.filter((item) => item !== interest)];
        }
      });
      console.log('selected = ',selected ,' after selected interest =',interests );
      setInterests(newInterests); // Update the interests state
    };
  
    // Effect to run the validation whenever the selectedInterests prop changes
    useEffect(() => {
      validateAndAddSelectedInterests(selectedInterests);
    }, [selectedInterests]);

  // const [selectedInterests, setSelectedInterests] = useState([]);
  const [newInterest, setNewInterest] = useState("");

  const handleInterestChange = (interest) => {
    // If the interest is already selected, remove it
    if (selectedInterests.includes(interest)) {
      setSelectedInterests((prev) => prev.filter((item) => item !== interest));
    } else {
      // If not selected, check if the maximum limit (5) has been reached
      if (selectedInterests.length < 5) {
        setSelectedInterests((prev) => [...prev, interest]);
      } else {
        // You can display an error message or give a visual indication
        console.log("Maximum 5 interests allowed");
      }
    }
  };

  const handleSubmitNewInterest = (e) => {
    e.preventDefault();
    
    if (newInterest && newInterest.length < 100) {
      if (!interests.includes(newInterest)) {
        setInterests((oldInterests) => [newInterest, ...oldInterests]);
        if (selectedInterests.length < 5) {
          setSelectedInterests((oldSelectedInterests) => [
            newInterest,
            ...oldSelectedInterests,
          ]);
        }
        setNewInterest(""); // Clear the input field
      } else {
        console.log("interest already in the list");
      }
    } else {
      console.error("no text entered or greater than 100");
    }
    console.log('interests=', interests);
  };
  return (
    <div className="interests">
      <h4>Choose Your Passions</h4>
      <h6>Choose up to 5 things that you love.</h6>

      <div className="search-box">
        <div className="search-field">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10.2331"
              cy="10.8567"
              r="7.30588"
              stroke="#A0A7B1"
              strokeWidth="1.5"
            />
            <path
              d="M15.4458 16.0693L20.6584 21.282"
              stroke="#A0A7B1"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>

          <input
            list="dataListFavorites"
            type="text"
            placeholder="Search"
            onChange={(e) => setNewInterest(e.target.value)}
          />

        </div>
        <button type="button" onClick={handleSubmitNewInterest}>
          <img src={FilterIcon} alt="icon" />
        </button>
      </div>

      <div className="intererest-list">
        {interests.map((interest) => (
          <InterestButton
            key={interest}
            interest={interest}
            selected={selectedInterests.includes(interest)}
            onChange={handleInterestChange}
            disabled={
              selectedInterests.length >= 5 &&
              !selectedInterests.includes(interest)
            }
          />
        ))}
      </div>
    </div>
  );
};
export default Interests;
/*
<datalist id="dataListFavorites">
  {interests.map((interest) => (
    <option value={interest} key={interest} />
  ))}
</datalist> 
*/