import firebase from '../../config/firebase';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackButton from '../BackButton/BackButton';
import gogPersonalityLogo from '../../assets/img/brand/logo.c784cb44bc36ebf9431febfa75db79c0.svg';

import {Row, Col, Tooltip, Form, FormGroup, Button, Card, Container} from 'reactstrap'; 
import {saveReferrerToLocalStorage} from '../../helpers/referrerStorageUtils'; 
import {checkReferrerLocalStorage } from '../../helpers/referrerStorageUtils';
import EmbeddedYoutubeVideo from '../../components/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo';
import defaultRingImg1 from '../../assets/images/ring-gogexperience_UHD_photoshoot_hip_trendy_fashionable_African_wom_84422f79-57c0-4dc1-8dda-053a7cc2b3de.png';
import snowBlackDemo from '../../assets/images/snowblack.mp4';
import snowBlackDemoVid2 from '../../assets/images/snowblackdownload.mp4';
import './UnlockEverything.css';
import { isIndexedAccessTypeNode } from 'typescript';
import { SassArgumentList } from 'sass';
import logo from '../../assets/images/diamond-orange-200.png';
import Footer from '../../components/footer/Footer';
import capLargeImageBlack from '../../assets/images/productImages/snapback-black-front-64fd6df26ffae.jpg';
import capLargeImagePurple from '../../assets/images/productImages/snapback-purple-black-black-front-64fd6df27008d.jpg';
import capLargeImageRed from '../../assets/images/productImages/snapback-red-black-black-front-64fd6df270136.jpg';
import capLargeImageGold from '../../assets/images/productImages/snapback-gold-black-black-front-64fd6df26dfc3.jpg';
import capLargeImageBW from '../../assets/images/productImages/snapback-black-white-white-front-64fd6df270215.jpg';
import capLargeImageW from '../../assets/images/productImages/snapback-white-front-64fd6df2702d8.jpg';

import capThumbImageBlack from '../../assets/images/productImages/snapback-black-front-64fd6df26ffae.jpg';
import capThumbImagePurple from '../../assets/images/productImages/snapback-purple-black-black-front-64fd6df27008d.jpg';
import capThumbImageRed from '../../assets/images/productImages/snapback-red-black-black-front-64fd6df270136.jpg';
import capThumbImageGold from '../../assets/images/productImages/snapback-gold-black-black-front-64fd6df26dfc3.jpg';
import capThumbImageBW from '../../assets/images/productImages/snapback-black-white-white-front-64fd6df270215.jpg';
import capThumbImageW from '../../assets/images/productImages/snapback-white-front-64fd6df2702d8.jpg';
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';
 
const UnlockEverything = () => {

    // const stripePromise = loadStripe("your-publishable-key-here");
    
    const footerRef = useRef(null);
    const { uid, user } = useSelector((state) => state.authUser);
 /* 
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const {token, error} = await stripe.createToken(elements.getElement(CardElement));
    
        if (error) {
          console.log(error);
        } else {
          // Send the token to your server or Stripe function
          fetch('/api/charge', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({token: token.id}),
          }).then( );
        }
      };
    
   
     The following code is duplicated in ProfileTestBet as an entry point to the site. 
     Something like thiswill  have to go to Home 
     There are 3 entry points to the web app. 

     - admin/ProfileTestBet 
     - admin/home
     - admin/create-user 
     */

   /* this hook tracks who referred the user by comparing
    - local storage 
    - with query string
    - with database value if any.
    I will have to turn this into a reusable function
    because this is one of several entry points to the app 


  referrer id i just realized that any information on the client is up 
  
  for hacking. therefore when it coms to conneting referre id i should 
  probably do that as a function trigger */

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [referrerId, setreferrerId] = useState([]);
    
    const dbReferredBy = user?.referredBy ?? [];
    const refIdQueryString = urlParams.get('ref');
    const refIdArrayFromLocalStorage = checkReferrerLocalStorage();

    useEffect(() => {
        // if querystring is null & localStorage is true set referrerid from local storage 
        if ( !refIdQueryString && (refIdArrayFromLocalStorage && 
            Array.isArray(refIdArrayFromLocalStorage) &&
            refIdArrayFromLocalStorage.length>0)) {
            // referrerId = refIdArrayFromLocalStorage;// array 
            setreferrerId(refIdArrayFromLocalStorage);
        
        // if querystring is true and localStorage is null save it from querystring
        } else if (refIdQueryString && !refIdArrayFromLocalStorage ) {
                //referrerId = [refIdQueryString];// array 
                setreferrerId([refIdQueryString])
                saveReferrerToLocalStorage(referrerId);

        // if both are true and there is still space for 3 referrering ids then push 
        } else if (refIdQueryString && (refIdArrayFromLocalStorage && Array.isArray(refIdArrayFromLocalStorage))) {

            if (refIdArrayFromLocalStorage.length<3) {
                if (refIdArrayFromLocalStorage.indexOf(refIdQueryString)===-1 ) { //query string takes presedence
                    refIdArrayFromLocalStorage.push(refIdQueryString);
                    saveReferrerToLocalStorage(refIdArrayFromLocalStorage);
                    setreferrerId(refIdArrayFromLocalStorage)
                }
            }
        }
        // if both are falsey,just leave referrerId the default values of [] 
        
        // now compare that  to referredBy values that may 
        // have been in the database in users/<userid>/{referredBy}
    }, []);

    useEffect(()=>{
        compareClientAndDBReferredByIdArrays(dbReferredBy, referrerId);
    }, [dbReferredBy, referrerId]);
/*
    useEffect(()=>{
        console.log('referrerData=',referrerData);
    },[referrerData]);
*/
    const saveReferrerId = (newReferrerId) => {
        const userId = user?.id; // Assume the user object has an 'id' field
        if (userId) {
            try {
                const db = firebase.firestore();
                db.collection("users").doc(userId).update({
                    referredBy: newReferrerId,
                });
            } catch (error) {
                console.error("Error updating referral ID: ", error); // Or update UI to reflect failure
            }
        } else {
            alert('you have to be logged in to do this');
        }
    };

    const [referrerData, setReferrerData] = useState({ 
        influencerVideoPath: {'invitation':'https://www.youtube.com/watch?v=rfJRpB4Hh3w', 
                              'productHat': 'https://www.youtube.com/watch?v=IirXTlTFcVQ'},
        personalMsg: '', 
        referrerImage: '../snowblack--.jpg',
        referrerImageWithProducts:{
            default:'../snowblack--.jpg',
            productJewelry:'',
            productSub:'https://storage.googleapis.com/gogpersonality.appspot.com/influencers/6ff7a2d0-dd71-4944-b288-261a23849ef7/snowblack/e6dbbf9b-3804-44ba-8914-532b5b01e7ee.png?GoogleAccessId=firebase-adminsdk-6x9yb%40gogpersonality.iam.gserviceaccount.com&Expires=1742184000&Signature=9TDZuanGdEpH2CxfFmw8yyG30B4mj3TOVVkxkUEQdXW0dP9zyIqIH7Wxq7XXDc55pEa%2BnHLIiZMExbRYsiC7tvq21ud2FTvaHMtfRkpv5zYIOclZkhhEMKeKp1OnHgTZj7GISUkNJbxtPYCr2SQGj5oUN3aNnIlxOItHAXWG5n3QskedE79Ymp%2F9whnEn2AlcgRp%2BUJOs%2FgR7W20l6Fgm6sYcs1GedBTA%2BBAkDnQS7%2F2F6c2AT2l%2Bt%2FUmb3axXUvGL%2Fgup1dh8uj6xIiR%2BBINniXPofskwQQIV1GhiIMLYcc9bn1gVws8f0ID6ZRJGY6Za53h1aRxF8MXDQBecE%2F1Q%3D%3D',
            productHat:'https://storage.googleapis.com/gogpersonality.appspot.com/influencers/6ff7a2d0-dd71-4944-b288-261a23849ef7/snowblack/e6dbbf9b-3804-44ba-8914-532b5b01e7ee.png?GoogleAccessId=firebase-adminsdk-6x9yb%40gogpersonality.iam.gserviceaccount.com&Expires=1742184000&Signature=9TDZuanGdEpH2CxfFmw8yyG30B4mj3TOVVkxkUEQdXW0dP9zyIqIH7Wxq7XXDc55pEa%2BnHLIiZMExbRYsiC7tvq21ud2FTvaHMtfRkpv5zYIOclZkhhEMKeKp1OnHgTZj7GISUkNJbxtPYCr2SQGj5oUN3aNnIlxOItHAXWG5n3QskedE79Ymp%2F9whnEn2AlcgRp%2BUJOs%2FgR7W20l6Fgm6sYcs1GedBTA%2BBAkDnQS7%2F2F6c2AT2l%2Bt%2FUmb3axXUvGL%2Fgup1dh8uj6xIiR%2BBINniXPofskwQQIV1GhiIMLYcc9bn1gVws8f0ID6ZRJGY6Za53h1aRxF8MXDQBecE%2F1Q%3D%3D'
        },   
        referrerName: 'Snow Black',
    });
    
    /**
     * @param {Array<String>} dbReferredBy - referrer id from users/<userid>/{referredBy} 
     * @param {Array<Strong>} referrerId - referrerId from query string. or local storage. 
     */
    const compareClientAndDBReferredByIdArrays = (dbReferredBy, referrerId) => {
        // Check if the arrays are the same
        const arraysAreSame = JSON.stringify(dbReferredBy) === JSON.stringify(referrerId);
        if  (!dbReferredBy ||  !Array.isArray(dbReferredBy) || dbReferredBy.length===0) {
            saveReferrerId(referrerId)  // from  the querystring to users/<userid>/update({referredBy: referrerId})
            fetchReferrerContentsToBuildPage(referrerId[0]);
        } else if (!arraysAreSame && dbReferredBy) {
            // If the array comparison is different, then use the one in the database
            saveReferrerId(dbReferredBy);
            saveReferrerToLocalStorage(dbReferredBy);

        } else if (referrerId[0] === dbReferredBy[0] &&
            dbReferredBy.length<3 ) {  // if the first index of referred id in the arrays are the same      
            // Find referral IDs that are in referrerId but not in dbReferredBy
            const uniqueReferralIds = referrerId.filter(id => !dbReferredBy.includes(id));
            
            // Add the unique referral IDs to dbReferredBy, but only up to a max length of 3
            const newArray = [...dbReferredBy, ...uniqueReferralIds].slice(0, 3);  
            saveReferrerId(newArray)
        }
    };

     /* 
    const productTagSVG = <svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 20 20"><g><g><path d="m121.5,64.2c-31.7,0-57.3,25.7-57.3,57.3 0,31.7 25.7,57.3 57.3,57.3 31.7,0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0,94.3c-20.4,0-37-16.6-37-37s16.6-37 37-37c20.4,0 37,16.6 37,37s-16.5,37-37,37z"/><path d="m244.5,29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9,0-44.8,8.3-60.5,25-16.7,15.7-25,37.6-25,60.5l1,107.4c1,14.6 6.3,29.2 17.7,40.7l256.5,256.4 214.8-214.8-256.5-256.4zm40.7,442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3,1c10.4,0 19.8,4.2 27.1,11.5l241.9,241.9-186.6,186.6z"/>\</g></g></svg>;
    3 things will happen with the referrer id 

        1. save it for the purchase and distribution of funds 
        2. save it for when the user creates their account 
        3. fetch it to show their referrers credentials. 


      Spec  Question about Referrer Id.
       
       This is a hyperlink with a referrer Id 
       https://gogPersonality.com/admin/index?ref=9JiI8aAbmNVKOy6mmMbSxyr6gQo1
       
       Users/influencers/partners will distribute their referrer Id link.
       When people click it and arrive at the website, the referrerid will be promptly stored in a 
       localStorage array of up to 3 referrer ids such as:  

       localStorage.setItem({'ref': [ '9JiI8aAbmNVKOy6mmMbSxyr6gQo1',
        'bmNVKOy6mmMbSx9JiI8aAyr6gQo1',
        'bmNVKOy6mmMbSx9JiI8aAyr6gQo1']}) 

        
        but by another process using JSON.stringify and crypto.
        I decided to let up 3 people get the credit for the referral instead of just the 1st. . 

        It seems reasonable to then save these referrer ids with the user account when the new user 
        finally signs in/creates an account.

        if a referrer id is already in the users datasource, then no new one will be added. 

        --- 
        Another interesting place to use the referrer id is in the payments page.
        Some influencers have great influence over their followers. 
        If they were to see the referring person wearing the same product on the payment page , 
        it could encourage sales. 

        Therfore when the user goes to the payments page i would like to 
        use ther referrerId to then fetch some basic information about the referring user. 

        Question #1: i was thinking about making the referrer Id simply the same as the user id . 
        this could create a little ease in management 

        currently only a logged n person with the same userid is allowed to access users/<userid> and the users data . 

        therefor if there is any additional data that needs to be fetched for the payment page i could put it in a new collection called 

        referredBy.doc(<referringid>) = {

            referrerName:''.
            referrerImage:''
            referrerImageWIthProducts: {productA:'path', productB:'pathb', productC:'productc'},
            personalMsg; '', 
            influencerIg:'',
            influencerVideoPath: ''
        }

        since the referring id is the same as the user id, if they come to the site, 
        i can use their userid to access their referredBy content in the referredBy collection.

        this would be publicly accessible information because everyone would have to see this and utilize it for payment page presentation.
        
        when a product is made the ale can be stored with the referredby ids .  


        - and finally the referring ids is stored when the user creates an account in the users collection. 

          does this seem reasonable and safe enough strategey for referrerid 
    */

        const [tooltipOpen1, setTooltipOpen1] = useState(false);
        const [tooltipOpen2, setTooltipOpen2] = useState(false);
        const [tooltipOpen3, setTooltipOpen3] = useState(false);
        const [tooltipOpen4, setTooltipOpen4] = useState(false);
        const [tooltipOpen5, setTooltipOpen5] = useState(false);
        const [tooltipOpen6, setTooltipOpen6] = useState(false);
        const [tooltipOpen7, setTooltipOpen7] = useState(false);
        const toggleToolTip1 = () => setTooltipOpen1(!tooltipOpen1);
        const toggleToolTip2 = () => setTooltipOpen2(!tooltipOpen2);
        const toggleToolTip3 = () => setTooltipOpen3(!tooltipOpen3);
        const toggleToolTip4 = () => setTooltipOpen4(!tooltipOpen4);
        const toggleToolTip5 = () => setTooltipOpen5(!tooltipOpen5);
        const toggleToolTip6 = () => setTooltipOpen6(!tooltipOpen6);
        const toggleToolTip7 = () => setTooltipOpen7(!tooltipOpen7);
        const objDefaultPrices =  ({ discountedSubscriptionPrice = 9, jewelryDiscountedPrice = 56.5425 } = {}) => {

            let subscriptionDiscountRate = 0.55;
            let originalSubscriptionPrice = discountedSubscriptionPrice / (1 - subscriptionDiscountRate);
            originalSubscriptionPrice = Math.round(originalSubscriptionPrice * 100) / 100;
        
            let jewelryDiscountRate = 0.55;
            let origJewelry = jewelryDiscountedPrice / (1 - jewelryDiscountRate);
            origJewelry = Math.round(origJewelry * 100) / 100;
        
            let discountedTotal = Math.round((discountedSubscriptionPrice + jewelryDiscountedPrice) * 100) / 100;
            
            let totalSaved = Math.round((originalSubscriptionPrice + origJewelry - discountedTotal) * 100) / 100;
        
            return {
                subscriptionDiscountRate,
                discountedSubscriptionPrice,
                originalSubscriptionPrice,
        
                jewelryDiscountRate,
                jewelryPrice: Math.round(jewelryDiscountedPrice * 100) / 100,
                origJewelry,
        
                discountedTotal,
                totalSaved,
            };
        };

    const [costs, setCosts] = useState({
    origSubscription: objDefaultPrices().originalSubscriptionPrice,
    subscription:objDefaultPrices().discountedSubscriptionPrice,
    origJewelry:objDefaultPrices().origJewelry,
    jewelry:objDefaultPrices().jewelryPrice,
    discount:objDefaultPrices().subscriptionDiscountRate,
    totalAmount:objDefaultPrices().discountedTotal,
    totalSavings:objDefaultPrices().totalSaved});

    
/*
          const [totalAmount, setTotalAmount] = useState(68); // Monthly subscription + Jewelry piece
          const [totalSavings, setTotalSavings] = useState(68);
*/
    const fetchReferrerContentsToBuildPage = async (referrerId) => {
        if (referrerId) {
            const db = firebase.firestore();
            const refByDoc = db.collection("referredBy").doc(referrerId).get(); 
            const refDoc = await refByDoc.get();
        
            if (refDoc.exists) {
                const refData = refDoc.data();
                setReferrerData({ 
                    influencerVideoPath: refData?.influencerVideoPath ?? [],
                    personalMsg: refData?.personalMsg ?? '', 
                    referrerImage: refData?.referrerImage ?? 'placeholder.jpg',
                    referrerImageWithProducts: refData.referrerImageWithProducts ?? {'default':'placeholder.jpg'}, // {productA: ''},  
                    referrerName: refData.referrerName ?? 'Snow Black',
                });
            }
        } else {
           console.log('later on make the defaults null. uncomment below');
           // setReferrerData(null);
        }
    };
/*
    const [hiddenBilling, isShippingAndBillingTheSame] = useState(false)
    const handleShipBillAddressToggle = (target) => {
        console.log('handleShipBillAddressToggle', target, 'checked', target.checked);
        // if yes, hide billing address panel
        // if no, show billing panel
        isShippingAndBillingTheSame(!target.checked);
    };
    const [costs, setCosts] = useState({
        subscription:9,
        jewelry:60,
        cap:50,
        tshirt:60,
        ring:1500,
    });

  
    const [optionalItems, setOptionalItems] = useState({
        cap: false,
        ring: false,
        tshirt: false,
    });


    const handleToggleThumbnail = (e) => {
        e.stopPropagation();
        e.preventDefault();
    } 
  
    const itemsDictionary = {
        cap: 'GOG Personality Snap Back Cap',
        ring: 'GOG\'s Signature Ring',
        tshirt: 'GOGs T-shirts'
    };

    const [tshirtSize, setTshirtSize] = useState("XS");

   //  the purpose of guardians of greatness is to raise confidence and expectations.

   const [selectedCapColor, setSelectedCapColor] = useState(null);
   const [selectedRingSize, setSelectedRingSize] = useState(null);
   
   const handleSelectCapColor = (color) => {
       setSelectedCapColor(color);
   };
     
   const handleSelectRingSize = (event) => {
       setSelectedRingSize(event.target.value);
   };

   const variantDictionary = {
       cap: {variantType: 'colors',
           color:{
               'black':{img:capThumbImageBlack,desc:'Black'},
               'purpleblack':{img:capThumbImagePurple,desc:'Purple & Black'},
               'redblack':{img:capThumbImageRed,desc:'Red & '},
               'goldblack':{img:capThumbImageGold,desc:'Gold & Blackk'},
               'blackwhite':{img:capThumbImageBW,desc:'Black & White'},
               'white':{img:capThumbImageW,desc:'White'},
           },
       },
       ring: {
           variantType: 'ringSize',
           ringSizes: [
               {diam:14.1, USSize:3},
               {diam:14.5, USSize:3.5},
               {diam:14.9, USSize:4},
               {diam:15.3, USSize:4.5},          
               {diam:15.7, USSize:5},
               {diam:16.1, USSize:5.5},
               {diam:16.5, USSize:6},
               {diam:16.9, USSize:6.5}, 
               {diam:17.3, USSize:7},
               {diam:17.7, USSize:7.5},
               {diam:18.1, USSize:8},
               {diam:18.5, USSize:8.5},          
               {diam:19, USSize:9},
               {diam:19.4, USSize:9.5},
               {diam:19.8, USSize:10},
               {diam:20.2, USSize:10.5},
               {diam:20.6, USSize:11},
               {diam:21, USSize:11.5},          
               {diam:21.4, USSize:12},
               {diam:21.8, USSize:12.5},
               {diam:22.2, USSize:13},
               {diam:12.6, USSize:13.5},                       
           ]
       },
       tshirts: {
           variantTypes:'sizes',
           sizes: ['xs','s','m','l','xl','xxl','3xl']
       }
   };

 
    const handleOptionalItemChange = (item, price) => {
        setOptionalItems({ ...optionalItems, [item]: !optionalItems[item] });
        
        if (optionalItems[item]) {
            setTotalAmount(totalAmount - price);
        } else {
            setTotalAmount(totalAmount + price);
        }
    };
*/

    const [freeJewelryOffer, setFreeJewelryOffer] = useState(false);
/*
    useEffect( () => {
        let paypalDiv = document.createElement('div');
       
        const paypalButtonContainer = document.getElementById('paypal-button-container-P-5DR14129KD979680BMURGOWI');
        
        if (user && paypalButtonContainer) {
            // Add a new div inside the container
            paypalButtonContainer.appendChild(paypalDiv);
             
            window.paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'subscribe'
            },
            createSubscription: function(data, actions) {
                return actions.subscription.create({
                plan_id: 'P-5DR14129KD979680BMURGOWI',
                custom_id: uid // Add this line
                });
            },
            onApprove: function(data, actions) {
                alert(data.subscriptionID); // You can add optional success message for the subscriber here
            }
        }).render(paypalDiv);
        }
        // Cleanup: Remove the PayPal button when the component is unmounted
        return () => {
            if (paypalDiv && paypalDiv.parentNode === paypalButtonContainer) {
                paypalButtonContainer.removeChild(paypalDiv);
            }
        };  
    }, [user]); 


    useEffect(() => {
        let paypalDiv = document.createElement('div');
         
        const paypalButtonContainer = document.getElementById('paypal-button-container-P-60H04554M5118381WMUXSARY');
   
        if (user && paypalButtonContainer) {
            // Add a new div inside the container
            paypalButtonContainer.appendChild(paypalDiv);
             
            window.paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'subscribe'
            },
            createSubscription: function(data, actions) {
                return actions.subscription.create({ 
                plan_id: 'P-60H04554M5118381WMUXSARY',
                custom_id: uid // Add this line
                });
            },
            onApprove: function(data, actions) {
                alert(data.subscriptionID); // You can add optional success message for the subscriber here
            }
        }).render(paypalDiv);
        }
        // Cleanup: Remove the PayPal button when the component is unmounted
      return () => {
            if (paypalDiv && paypalDiv.parentNode === paypalButtonContainer) {
                paypalButtonContainer.removeChild(paypalDiv);
            }
        }; 
    }, [user, uid]); 
*/


 
         
  


 useEffect( () => {
        let paypalDiv = document.createElement('div');
       
        const paypalButtonContainer = document.getElementById("paypal-button-container-P-4F209946C70419206MUYO2XA");
    try {    
        if (user && paypalButtonContainer) {
            // Add a new div inside the container 
            paypalButtonContainer.appendChild(paypalDiv);
             
            window.paypal.Buttons({
                style: {
                    shape: 'rect',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'subscribe'
                },
                createSubscription: function(data, actions) {
                    console.log('uid in createSubscription with set up fee  = '+uid);
                    return actions.subscription.create({
                        // with the monthly set up 
                        // with the tracking uid 
                    plan_id: 'P-4F209946C70419206MUYO2XA',
                    custom_id: uid // Add this line
                    });
                },
                onApprove: function(data, actions) {
                    setOnApproveMessage(`Your subscription ${data.subscriptionID} was successful, Welcome to GOGPersonality`);
                    /*
                     
                     If I cannot pass the userid to pay pal, what do you think about this strategy. 
                     In onApprove, I could pass in the plan id 
                    
                    firebase.firestore.collections('users').doc(uid).collection('payments').update{planId: P-4F209946C70419206MUYO2XA, dt:Date.now()});

                    but all the plan ids are expected to be the same. 
                    it's not unique and this strategy is not secure. 

                    What solution might you recommend if i can't get uid to paypal and back?

                     */
                }
            }).render(paypalDiv);
        }

    } catch (err) {
        setOnErrorMessage('Your subscription was not successful, welcome to GOGPersonality'+err);
    }
        // Cleanup: Remove the PayPal button when the component is unmounted
        return () => {
            if (paypalDiv && paypalDiv.parentNode === paypalButtonContainer) {
                paypalButtonContainer.removeChild(paypalDiv);
            }
        };  
    }, [user]); 

// todo fix the personality make sure it is showing the right icons for personality tests 
// 


    useEffect(() => {
        let paypalDiv = document.createElement('div');
         
        const paypalButtonContainer = document.getElementById("paypal-button-container-P-3UP320412J114025HMUYOYJI");
    try {
        if (user && paypalButtonContainer) {
            // Add a new div inside the container
            paypalButtonContainer.appendChild(paypalDiv);
            window.paypal.Buttons({
                style: {
                    shape: 'rect',
                    color: 'gold',
                    layout: 'vertical',
                    label: 'subscribe'
                },
                createSubscription: function(data, actions) {
                    console.log('uid in createSubscription P-4YP0803 = '+uid);
                    return actions.subscription.create({ 
                        // no set up fee 
                    plan_id:'P-3UP320412J114025HMUYOYJI',
                    custom_id: uid // Add this line
                    });
                },
                onApprove: function(data, actions) {
                    setOnApproveMessage(`Your subscription ${data.subscriptionID} was successful, Welcome to GOGPersonality`);
                }
            }).render(paypalDiv);
        }

    } catch (err) {
        setOnErrorMessage('Your subscription was not successful, welcome to GOGPersonality'+err);
    }
        // Cleanup: Remove the PayPal button when the component is unmounted
      return () => {
            if (paypalDiv && paypalDiv.parentNode === paypalButtonContainer) {
                paypalButtonContainer.removeChild(paypalDiv);
            }
        }; 
    }, [user, uid]); 
 
    const  [onApproveMessage, setOnApproveMessage] = useState(null);  
    const  [onErrorMessage, setOnErrorMessage] = useState(null);
 
return (
<Container className="payment-form pt-0 px-3">
    <div className='d-flex justify-content-left pl-3'>
        <Link className='u-img--logo-left'  to='/admin/home?'><img className='u-img--logo-left' src={gogPersonalityLogo} alt='gogPersonality.com logo' /></Link> 
            { ( !user || !uid || !user.id ) && (
                <Link to='/admin/home' className='loggedout-notice'>You are not logged in.<br/> Click here to login</Link>
            )}
    </div>
    <BackButton/>
    <Row>
        <h3 className='payment-form-header d-flex justify-content-end pr-5 fontStyleMontserrat'>
            <div className='text-style-1 mt-1 text-white'>Unlock Everything </div>  
        </h3>



<div className={`p-3 my-3 successMsgNotification ${onApproveMessage? "is-visible":""}`}>{onApproveMessage} </div>
 <div className={`p-3 my-3 errMsgNotification  ${onErrorMessage? "is-visible":""}`}>{onErrorMessage} </div>


       <Card className='payment-form-wrapper fontStyleMontserrat'>
            <div className='d-flex justify-content-start payment-form--style2'>
                <span>
                    <img src={logo} className='logo-left'/>
                </span>
                <span> 
                    <h3 className='line-height-3 payment-form-header mb-0 fontStyleMontserrat'>
                        <span className='text-style-2'>GOG</span><span className='text-style-3'>Personality</span>
                    </h3>
                </span>
            </div>
            <div className='payment-form-benefits py-0 px-4 fontStyleMontserrat'>
                <span className='text-style-4-label m-1 text-white'>Unlock &gt;&gt; </span>
                <Button type="button" id='tooltipOpenTarget1' className='text-style-4 mr-1 text-white'>Get Your Soulmate Jewelry </Button> 
                <Button type="button" id='tooltipOpenTarget2' className='text-style-4 mr-1 text-white'>See & Meet Your Match </Button>  
                <Button type="button" id='tooltipOpenTarget3' className='text-style-4 mr-1 text-white'>Get Invited to the Show</Button> 
                <Button type="button" id='tooltipOpenTarget4' className='text-style-4 mr-1 text-white'>Watch our TV Show For Free</Button> 
                <Button type="button" id='tooltipOpenTarget5' className='text-style-4 mr-1 text-white'>Redeem your coins </Button> 
                <Button type="button" id='tooltipOpenTarget6' className='text-style-4 mr-1 text-white'>Get Access to Keep It Trill  </Button>   
                <Button type="button" id='tooltipOpenTarget7' className='text-style-4 mr-1 text-white'>and more </Button> 
            </div>
            <Tooltip
                placement="top"
                isOpen={tooltipOpen1}
                toggle={toggleToolTip1}
                target="tooltipOpenTarget1"
                >It's unique just like your personaliity. and your perfect match will have teh same jewelry. </Tooltip> 
               
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen2}
                    toggle={toggleToolTip2}
                    target="tooltipOpenTarget2"
     
                >
                    <b>See & Meet Your Match</b> at our matchpublic meet up event. 
                </Tooltip>
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen3}
                    toggle={toggleToolTip3}
                    target="tooltipOpenTarget3"
                    >It's like <b>Love Is Blind</b> but better!</Tooltip>
                <Tooltip
                placement="top"
                isOpen={tooltipOpen4}
                toggle={toggleToolTip4}
                target="tooltipOpenTarget4"
                >Normally shows like Love is Blind or Married at first site are paid showed on Netflix etc.,  With your subscription, you'll be able to watch it here. 
                    </Tooltip> 
                
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen5}
                    toggle={toggleToolTip5}
                    target="tooltipOpenTarget5"
                > You earn our Wakanda coins for every test and activity you take. Redeem them at our aour public meet up events for food, drinks and more.
                </Tooltip>
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen6}
                    toggle={toggleToolTip6}
                    target="tooltipOpenTarget6"
                    >After you are matched up, <b>Keep It Trill</b> is the part of our app that presents activities & trips for you all to do based on your personalities!. </Tooltip>
               <Tooltip
                    placement="top"
                    isOpen={tooltipOpen7}
                    toggle={toggleToolTip7}
                    target="tooltipOpenTarget7"
                    >GOGPersonality is the new way to find & start relationships. It makes relationships easier, safer, more fun and more reliable. </Tooltip>             
             
            { freeJewelryOffer ? <p className='p-3 text-style-1'><span className='font-weight-bold'> Special Offer:</span> The first 20 to sign up will get their Soulmate jewlery free. You have to finish the personality tests first.</p> :''}
            <div>
                <p className='mx-auto my-2 text-center fontStyleMontserrat'>Since <span className='referreName__highlight'><b>{referrerData.referrerName }</b> </span>referred you, you'll get <span className='font-weight-bold'> {costs.discount * 100}% off.</span></p> 
                
                    <div className='d-flex justify-content-center'>
                <div className="optional-items optional-items--tinted">
                
                    <ul className='ml-0 pr-1 pt-2 mb-0'>
                        <li className="payment-form__line-item">
                            <p className='fontStyleMontserrat'><span className='u-tint-1'>Monthly</span> <span className='u-tint-2'>Subscription</span> <br/><span className='line-through'>${costs.origSubscription}</span>/mo <span  style={{ color: '#2e7d32' }}>${costs.subscription}/mo.</span></p>
                        </li>
                        <li className="payment-form__line-item">
                            <p className='fontStyleMontserrat'><span className='u-tint-1'>Soulmate </span> <span className='u-tint-2'>Jewelry</span><br/> <span className='line-through'>${costs.origJewelry}</span> <span  style={{ color: '#2e7d32' }}> ${costs.jewelry}</span></p>
                    </li>
                    <li className="payment-form__line-item">
                       <b> Your Subscription unlocks everything.</b><br/>
                     This is the updated way to meet & start a relationship without guessing about Mood & Personalities.
                    </li>
                </ul>
            </div>
            <div className="optional-items">
                <video controls loop className='media' src={snowBlackDemo} style={{width:'90%'}}/>
            </div>
        </div>   
        </div>   
        <div className="d-flex payment-form__line-item total-amount">
            <p><b>Total: ${costs.totalAmount}</b> </p><p className='you-saved-txt'><b>You Saved: ${costs.totalSavings}</b></p>
            
        </div>
            
        </Card>
    </Row>
    <Row> 
        <Card className="payment-form-wrapper">
            <p className='mx-auto text-align-center'>$9/month + $54.45 for Soulmate Personality Jewelry </p>
            { !(user || uid) ? <h4 className='secondary-text'>Please sign in to pay & unlock all the features of this app. </h4> : '' }
       
            
    
        <div id="paypal-button-container-P-4F209946C70419206MUYO2XA"></div>
         </Card>
    </Row>
    <Row>
    <h3 className='payment-form-header fontStyleMontserrat'>
            <div className='text-style-1 text-white'>The Experience/Testimonials </div>  
        </h3> 
    <Card className='payment-form-wrapper fontStyleMontserrat'>
      
        <div className='d-flex justify-content-center'>
           
            <div className="optional-items">
                <video controls loop className='media' src={snowBlackDemo} style={{width:'90%'}}/>
            </div>
   
            <div className="optional-items">
                <video controls loop className='media' src={snowBlackDemoVid2} style={{width:'90%'}}/>
             </div>
        </div>
   
</Card>
    </Row>
    <Row>
        <Card className="payment-form-wrapper second-div">
        <p className='mx-auto text-center'>$9/month only. No  Soulmate-Personality Jewelry </p>
            { !(user || uid) ? <h4 className='secondary-text'>Please sign in to pay & unlock all the features of this app. </h4> : '' }
        <div id="paypal-button-container-P-3UP320412J114025HMUYOYJI"></div>
         </Card>
    </Row>
    <FriendlyErrMsgBox/>
    <Row>
    <Footer ref={footerRef}/>
    </Row>
</Container>
  );
};
export default UnlockEverything;

    {/* remember to put addd ons in the next go around. 
    <Row>
    <h3 className='payment-form-header text-style-1 fontStyleMontserrat'>Optional Add Ons</h3>
    <Card className='payment-form-wrapper fontStyleMontserrat'>
        
        <div className='d-flex justify-content-center'>
           
            <div className="optional-items">
                <div className='optional-items__label-wrapper'>
                    <label> {productTagSVG}
                        <input
                            type="checkbox"
                            checked={optionalItems.cap}
                            onChange={() => handleOptionalItemChange("cap", 50)}
                        />
                    <span>GOG Snapback </span>
                        <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                        $80
                        </span>
                        <span style={{ color: 'red' }}>
                        $40
                        </span>
                    </label>
                </div>
                <video controls loop className='media' src={snowBlackDemo} style={{width:'90%'}}/>

                <div className='d-flex payment-form__variant-list justify-content-center'>
                    {Object.keys(variantDictionary.cap.color).map((key) => (
                        <Button 
                        key={key} 
                        onClick={() => handleSelectCapColor(key)} 
                        className={`product-thumbnail__btn ${selectedCapColor === key ? 'selected' : ''}`}
                        >
                        <img src={variantDictionary.cap.color[key].img} alt={variantDictionary.cap.color[key].desc} className='product-thumbnail'/>
                        </Button>
                    ))}
                </div>   
            </div>
   
            <div className="optional-items">
                <div className='optional-items__label-wrapper'>
                    <label> {productTagSVG}
                        <input
                            type="checkbox"
                            checked={optionalItems.tshirt}
                            onChange={() => handleOptionalItemChange("tshirt", 80)}
                        />  <span>GOG T-Shirt  </span>
                        <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                        $120
                        </span>
                        <span style={{ color: 'red' }}>
                        $60
                        </span>
                    </label>
                    <video controls loop className='media' src={snowBlackDemoVid2} style={{width:'90%'}}/>
                    
                    
                    
                    <div className='mt-4 d-flex justify-content-center mx-auto'>
                        <span>T-Shirt Size</span>
                        <span>
                            <select className='optional-items__sel-list mx-auto' 
                                value={tshirtSize}
                                onChange={(e) => setTshirtSize(e.target.value)}>
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                                <option value="XXL">XXL</option>
                                <option value="3XL">3XL</option>
                            </select>
                        </span>
                    </div>
                </div> 
            </div>
        </div>
    <div>
        <div className='mt-5 optional-items__label-wrapper'>
            <label> {productTagSVG}
                <input
                    type="checkbox"
                    checked={optionalItems.ring}
                    onChange={() => handleOptionalItemChange("ring", 150)}
                />   <span>GOG's Signature Ring: </span>
                       <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                        $480
                        </span>
                        <span style={{ color: 'red' }}>
                        $240
                        </span>
            </label>
        </div>         
        <video loop controls className='vid-media' src = {ringWhiteBackground} />
        
        <div className='d-flex justify-content-center'>
            
            <div className='mt-4 d-flex justify-content-center mx-auto'>
                <span>Ring Size</span>
                <span>
                    <select onChange={handleSelectRingSize} className='product-thumbnail__btn' value={selectedRingSize}>
                        {variantDictionary.ring.ringSizes.map((val, key) => (
                        <option key={key} value={val.diam}>{val.USSize}</option>
                        ))  }
                    </select>
                </span>
            </div>
        </div> 
    </div>
</Card>
    </Row>
     <Row>

    <Card className="payment-form-wrapper">
            <div className="payment-form-header">
                Shipping Address
            </div> 
            <p>Is your shipping & billing address the same?<br/>  Check the box for Yes. 
            
                <label> 
                    <input
                        type="checkbox"
                        onChange={(e) => handleShipBillAddressToggle(e.target)}
                    />  
                </label>
            </p>
            <form onSubmit={handleSubmit}>
                <label className="payment-form-label" for="shipName">Name</label>
                <input className="payment-form-input" type="text" id="shipName" name="shipName" placeholder="Name"/>

                <label className="payment-form-label" for="shipEmail">Email</label>
                <input className="payment-form-input" type="email" id="shipEmail" name="shipEmail"  placeholder="Email"/>

                <label className="payment-form-label" for="shipAddress1">Address 1</label>
                <input className="payment-form-input" type="text" id="shipAddress1" name="shipAddress1"  placeholder='Address 1' />

                <label className="payment-form-label" for="shipAddress2">Address 2</label>
                <input className="payment-form-input" type="text" id="shipAddress2" name="shipAddress2"  placeholder='Address 2' />

<div className='d-flex'>
                <label style={{visibility: 'hidden', position: 'absolute'}} className="payment-form-label" for="shipCity">City</label>
                <input className="payment-form-input" type="text" id="shipCity" name="shipCity" placeholder='City'/>

                <label style={{visibility: 'hidden'}} className="payment-form-label" for="shipState">State</label>
                <input className="payment-form-input" type="text" id="shipState" name="shipState" placeholder='State'/>
                </div>

                <label className="payment-form-label" for="shipZip">Zip Code</label>
                <input className="payment-form-input" type="text" id="shipZip" name="shipZip" placeholder='Zip Code'/>
            </form>
            <div className="payment-form-footer">
                Your payment is secured and encrypted.
            </div>
        </Card>

        {hiddenBilling ? (

<Card className="payment-form-wrapper">
<div className="payment-form-header">
    Billing Address
</div> 
<p>s your shipping & billing address the same? <br/> Check the box for Yes.

    <label> 
        <input
            type="checkbox"
            onChange={(e) => handleShipBillAddressToggle(e.target)}
        />
    </label>
</p>
<form>
    <label className="payment-form-label" for="billingName">Name</label>
    <input className="payment-form-input" type="text" id="billingName" name="billingName" placeholder="Name"/>

    <label className="payment-form-label" for="billingAddress1">Address 1</label>
    <input className="payment-form-input" type="text" id="billingAddress1" name="billingAddress1"  placeholder="Address 1"/>

    <label className="payment-form-label" for="billingAddress2">Address 2</label>
    <input className="payment-form-input" type="text" id="billingAddress2" name="billingAddress2"  placeholder="Address 2"/>

<div className='d-flex'>
    <label style={{visibility: 'hidden', position: 'absolute'}} className="payment-form-label" for="billingCity">City</label>
    <input className="payment-form-input" type="text" id="billingCity" name="billingCity" placeholder='City'/>

    <label style={{visibility: 'hidden'}} className="payment-form-label" for="billingState">State</label>
    <input className="payment-form-input" type="text" id="billingState" name="billingState" placeholder='State'/>
</div>

    <label className="payment-form-label" for="billingZip">Zip Code</label>
    <input className="payment-form-input" type="text" id="billingZip" name="billingZip" placeholder='Zip Code'/>
</form>
<div className="payment-form-footer">
    Your payment is secured and encrypted.
</div>
</Card>


        ):''}
        </Row>*/}

