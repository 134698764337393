/**
 *   exports 5 items 
 *  	stats [{}]  = slider list - but this array is copied individually copied as properties into stats not good.
 * 							this means we have to update it in muple plages or get errors 
 * 		personalityDetail [{}]  -  same as above 
 * 		mySkinToneDetails {} - 
 * 		mySkinTonePreferences {} - i don't see the different yet 
 * 
 * 
 *   	stats is imported into
 * 			PersonalityPreferences.js 	
 * 			 
 * 
 */
export const stats = [
	{
		left: 'Wants a poly family',
		right: 'Want a mono family',
		value: 80,
		key: 'polyVsMono',
		"detailedInfo": "<b>Poly Family.</b> Some people have their preference.  Use this slider to show your preference for you.",
	},
	{
		left: 'Wants Church As a Family Tradition',
		right: 'Does NOT Want Church as a Family Tradition',
		value: 80,
		key: 'churchTradition',
		"detailedInfo": "Some people want to go to church as a family tradition or not? Use the slider to show your preferences.",
	},
	{
		left: 'Does Sx Work for Living',
		right: 'Does NOT do Sx Work for Living',
		value: 80,
		key: 'SXWorkForALiving',
		"detailedInfo": "Do you want to do Sx Work for Living or not. Use the slider to what extent.",
	},
	{
		left: 'Addicted',
		right: 'Not An Addicted',
		value: 80,
		key: 'addict',
		"detailedInfo": "Some people are addicted to illegal drug or alcohol. Use this slider to show yes no or to what extent.",
	},
	{
		left: 'Addicted to Prn',
		right: 'Not Addict to Prn',
		value: 80,
		key: 'addictedToPrn',
		"detailedInfo": "Some people are addicted to porn or would like to watch it with their partner. Use the slider to show yes or no or to what extent.",
	},
	{
		left: 'SUBMISSIVE',
		right: 'DOMINEERING',
		value: 80,
		key: 'submissiveDominant',
		"detailedInfo": "<b>Domineering</b> means someone wants to be in charge without compromise or collaboration. <br/><b>Submissive</b> means someone wants to conform to the will of others.",
	},
	{
		left: 'MEEK',
		right: 'ARROGANT',
		value: 50,
		key: 'meekArogant',
		"detailedInfo": "<b>Arrogant</b> means having or demonstrating an exaggerated sense of one's own importance or abilities; egoistical; conceited; pompous<br/><b>Meek</b>  not willing to argue or express your opinions in a forceful way; quiet and unwilling to disagree or fight ; a meek classmate who won't speak up, even when he or she is treated unfairly; quiet, gentle, and easily imposed on;docile; submissive.",
	},
	{
		left: 'SELF-CONTROLLED',
		right: 'IMPULSIVE',
		value: 50,
		key: 'controlledImpulse',
		"detailedInfo": "<b>Impulsive</b> means not disciplined, all over the place. One minute they are cooperative and the next they are completely uncooperative. One moment they are peaceful next they are fighting and angry<br/> <b>Self-Controlled</b> means more predictable, even-tempered, disciplined.",
	},
	{
		left: 'EMOTIONALLY STABLE',
		right: 'EMOTIONALLY VOLATILE',
		value: 50,
		key: 'stableVolatile',
		"detailedInfo": "<b>Emotionally Volatile</b> is the same as impulsive but specifically with their emotions.  Emotionally volatile means Mood Swings. One hour they are happy, next our they are very sad,  angry or crying. Their emotions are unpredictable.   means not disciplined, all over the place. One minute they are are with you the next minute they are not speaking to you.  One moment they are peaceful next they are fighting and angry.  The mood swings can be as quick as during the day or from week to week. Some mood swings last for 6 months. Meaning one minute they want to be in the relationship - and then 1 year or 2 later they want to break up - and then not sure why they acted like that for a whole six months.  <br/> <b>Emotionally Stable </b> means their emotions are predictable, even-tempered, or they might have one mood all the time. Some people are acxtually happy all the time. that's their primary mood. Some people are angry most of the time. Some people are studious most of the time.",
	},
	{
		left: 'Loves Cameras & Mirrors',
		right: 'Camera & Mirror Shy',
		value: 50,
		key: 'likesMirror',
		"detailedInfo": "<b>Some people love to take pictures and selfies</b> some people stay away from mirrors and cameras.",
	},
	{
		left: 'FINANCIALLY MUTUAL',
		right: 'FINANCIALLY DEMANDING',
		value: 50,
		key: 'financeMutualDemanding',
		"detailedInfo": "</b>Financially Mutual</b> ladies actually exist. Most young men hear so much about ladies who want to be supported or gifted financially.",
	},
	{
		left: 'FINANCIALLY RESPONSIBLE',
		right: 'FINANCIALLY IRRESPONSIBLE',
		value: 50,
		key: 'responsibleIrresponsible',
		"detailedInfo": "A financially RESPONSIBLE person has a budget, a savings & investment plan, and a spending plan for the month. \
		<br/><br/>A Financially IRRESPONSIBLE Person (FIP) can be helped by teaching them and giving them lots of chances to practice with small amounts of money. Most schools just don't teach Financial Management. \
		Give them a little practice with small things. They might learn - but you will have to decide if they can learn or not \
		<br/> <br/>A financially IRRESPONSIBLE Person (FIP) does 1 or more of the following things: \
		<br/>(1) Take money from credit cards & bank accounts WITHOUT checking to make sure money is available. This causes lots of overdraft fees. \
		<br/>(2) Spend money without having spending limit for the month. \
		<br/>(3) Buy impulsively as soon as they see something 'they always wanted'. They just put everything on credit card.  After a few years of this their credit card will be thousands of dollars. The interest payments will be hundreds of dollars every month. The interest payments will be so high that it will prevent them from paying their normal bills & recreational activities. \
		<br/>(4) A Financially IRRESPONSIBLE PERSON will  Puts their money in bank accounts without knowing that the bank takes X dollars per month. When they come back to their account - it is empty<br/> \
		<br/>(5) A financially irresponsible person - Plans to borrow money from credit cards & loans and will tell you they do not plan on paying it back.  <Br/> \
		<br/>(6) A financially irresponsible person might not seem to be a problem in the beginning being finacially greedy, selfish and reckless can cost a lot. For example some partners might take out a second mortgage loan on a gamble without telling you. If that plan doesn't work out they could easily lose the house. They should always tell you what they are doing with money that impacts both of you \
		Most of us did not have a chance to learn how to manage money. You can teach them after you decide to try a relationship - but if they are just IMPULSIVE, DISHONEST, RECKLESS, OR CAN'T LEARN to be responsible then being financially irresponsible will big a growing problem every year."  
	},
	{
		left: 'INTROVERT',
		right: 'EXTROVERT',
		value: 50,
		key: 'introvertExtrovert',
		"detailedInfo": "Research says that people with the same level of extraversion stay together. When it comes to being extroverted or introverted - opposites attract but like stays together. There is a personality test people can take to decide how extroverted people are on paper. But you can use this slider to decide based on what you see.<br/> \
		<br/><b>Extroverted</b> means the person loves being around other persons. Being around other persons is a way of releasing stress. \
		<br/><b>Introverted</b> means the person is more comfortable by themselves, reading doing artwork or some other solitary activity. When introverted people are stressed out they like to be alone to wind down.",
	},
	{
		left: 'SOCIALLY RESPONSIBLE',
		right: 'SOCIALLY IRRESPONSIBLE',
		value: 50,
		key: 'sociallyResponsibleIrresponsible',
		"detailedInfo": "<b>Socially Responsible</b> means they do things for the benefit of their society & environment. Being sociallly responsible also means thinking about the next generation, planning for our next generation etc. \
      <br/><br/><b>Socially Irresponsible</b> means they don't care about the society around them and the next generation - they just do their thing. They hustle, rob, steal, gangbang, might be an informant and tear down their own community - but at the extreme end they do absolutely nothing for the community.  \
      <br/>If nobody cares about the improving their community and life for the next generation - our society will be destroyed. WE can't afford that since racism is already working their agenda. We need people who want to improve our community.  The characteristic is related to being conscientious which means - organized, disciplined, the tendency to think carefully before acting.",
	},
	{
		left: 'FEEL HAPPY/NEUTRAL ABOUT THEIR PARENT OF THE OPPOSITE GENDER',
		right: 'FEELS ANGRY/HURT/ABANDONED BY THE PARENT OF THE OPPOSITE GENDER',
		value: 50,
		key: 'happyAngry',
		"detailedInfo": "Being angry at the parent of the opposite gender has a way of affecting the way they treat the opposite gender in their relationship.  It's like a child who grows up seeing abuse at home. Those negative experiences might carry over into their own lifes. Likewise being mad/angry/hating the parent of the opposite gender has a way of carrying over into relationships. \
		<br/><b>This problem also happens if </b> the person grew up in an area where the parent of the opposite gender was disrespected a lot. They might pick up those behaviors. ",
	},
	{
		left: 'LOW MAINTENANCE/DEPENDENT',
		right: 'HIGH MAINTENANCE/DEPENDENT',
		value: 50,
		key: 'lowHighMaintenance',
		"detailedInfo": "A High Maintenance person needs a lot of your attention or money. \
		<br/>(1) At the extreme, they want your attention on text messages around the clock everyday - otherwise they will get upset at you. \
		<br/>(2) Even if they have friends - they might want you at every event with them.  They don't want to give you a day off. (If you need saturdays free time you can try to tell them and see how it goes.) \
		<br/>(3) If they don't have any friends - they need your attention all day every day everywhere they go \
		<br/>(4) What every their love language is - they will really need a lot of if. Love languages are things like receiving thoughtful gifts, quiet time, physical affection & words of affirmation \
		<br/>If the person's love language is - receiving gifts - they will want a lot of gifts. \
		<br/>If the person's love language is - quality time - If it's time they'll want your time. \
		<br/>If the person's love language is - physical affection - your sig other might be very high maintenence for sex -- or too high maintenance. \
		<br/> \
		<br/>High maintainence people can seem great at first but really high maintainence can instantly turn into what it really might be - selfishness, inscurity, obsession etc. Therefore high maintainence should be avoided because it is not balanced. \
		<br/> Low maintainence means your time and activities in the relationship will be balanced. This helps you meet your goals in life and live a more balanced life. <br/>"
	},
	{
		left: 'GRATEFUL/THANKFUL',
		right: 'NOT GRATEFUL/THANKFUL',
		value: 50,
		key: 'thankfulNotThankful',
		"detailedInfo": "detailedInfo 1",
	},
	{
		left: 'NOT A BULLY',
		right: 'BULLY',
		value: 50,
		key: 'bullyNotBully',
		"detailedInfo": "detailedInfo 1",
	},
	{
		left: 'NEED HIGH DRAMA / EXCITEMENT',
		right: 'NEED NO DRAMA/EXCITEMENT',
		value: 50,
		key: 'needHighDramaNoDrama',
		"detailedInfo": "High energy high excitement can be cool if it's not crazy. In a positive way high energy high drama could mean going out to many places. \
        <br/>It can mean always wanting to go to new places etc \
        <br/>On the other other hand high drama could also mean fighting",
	},
	{
		left: 'NOT MISCHIEVOUS',
		right: 'MISCHIEVOUS',
		value: 50,
		key: 'mischievesNotMischieves',
		"detailedInfo": "<b>Being Mischevious</b> means the person loves to be bad meaning do things they know they should not be doing. It makes them happy. \
		<br/>For example - if they know you hate being teased about something - they will tease you about it to get a rise out of you. \
		<br/>Mischievious people love to push your buttons to get an emotional reaction out of you.  People who love drama might do the same too.   \
		<br/> \
		<br/>In the worst case scenario they like being mischievious meaning doing things they are not supposed to do which might include, stealing, cheating, lying, and screwing up the relationship \
		<br/>A person who is not mischevious does not get a rise out of bothering you about things they know you will hate.\
		<br/>Being aware of the difference is Important.", 
	},
	{
		left: 'HAS FRIENDS',
		right: 'NO FRIENDS',
		value: 50,
		key: 'hasFriendsNoFriends',
		"detailedInfo": "It is possible to have no friends for legitimate reasons - like if you were born in a area were people were very selfish dangerous, a war etc. \
		In general - your social network is your social networth.  Those relationships add value to your relationship experience. To make sure everybody will have friends this program will have lots of opportunities to meet, showcases and activities.",
	},
	{
		left: 'NOT ATTENTION SEEKING',
		right: 'ATTENTION SEEKING',
		value: 50,
		key: 'notAttensionSeekAttensionSeek',
		"detailedInfo": "detailedInfo 1",
	},
	{
		left: 'HISTORY OF PROMISCUITY',
		right: 'NO HISTORY OF PROMISCUITY',
		value: 50,
		key: 'historyPromiscuityNoHistory',
		"detailedInfo": "Habits are hard to break.  Sometimes people are just addicted to sex because they did it too much when they were younger.  This happens often with high school atheletes etc. If you want to just work with it - you can make your relationship whatever you agree to.",
	},
	{
		left: 'STABLE OPINIONS',
		right: 'UNSTABLE OPINIONS',
		value: 50,
		key: 'stableUnstableOpinions',
		"detailedInfo": "some people will change their mind about their opinions all the time - that's not stable.",
	}, 
	{
		left: 'FORGIVES',
		right: 'HOLDS GRUDGES AND GIVE REMINDERS',
		value: 50,
		key: 'forgivesGiveReminders',
		"detailedInfo": "Some people feel emotions more deeply and hold onto grudges/hurt for a long tme. Some people are ready to forgive very quickly and move on. Use this slider to indicate yes no or the extent. ",
	}, 
	{
		left: 'FLEXIBLE THINKER',
		right: 'RIGID THINKER',
		value: 50,
		key: 'flexibleThinkerRidgeThinker',
		"detailedInfo": "Imagine that you are driving with your significant other to a restaurant.  On the way there, you realize it would be a good idea to stop at a different store and get something. A person who is a RIGID thinker would have a hard time changing their route. They will want to go to the restaurant because that is what you planned on. That is rigid thinking. If you are like that it is good to know. if your partner is like that it is good to know.",
	}, 
	{
		left: 'DID NOT GROW UP IN ABUSIVE/VIOLENT HOME',
		right: 'GREW UP IN ABUSIVE/VIOLENT HOME',
		value: 50,
		key: 'abusiveGrewUpNonAbusiveGrewUp',
		"detailedInfo": "Placeholder",
	},
	{
		left: 'NO HISTORY OF VIOLENT CRIME',
		right: 'HISTORY OF VIOLENT CRIME',
		value: 50,
		key: 'noViolentHistoryViolentHistory',
		"detailedInfo": "Placeholder",
	},
	{
		left: 'PROOF OF LEGAL INCOME',
		right: 'NO PROOF OF LEGAL INCOME',
		value: 50,
		key: 'legalIncomeProofNoProof',
		"detailedInfo": "<b>PROOF OF LEGAL INCOME IS USED TO FIX THE PROBLEM OF PROSTITUTION</b> A unfortunate event in the history of prostitution is that some men desparate for money would try to trick or force a woman into prostitution. \
		I read one story from South/Central America where the young man had older pimps pretend to be his uncle and show up at a wedding feast at the girls' mothers house. \
		he pretended they were going to get married. Instead he made her do prostitution for at least 10 years before he was caught. \
		<br/>He tricked her into it saying the money they earn will be used to buy a house \
		<br/>In reality he had several other grils doing the same thing in  different parts of town.",
	},
	{
		left: 'NO HISTORY OF DOMESTIC VIOLENCE',
		right: 'HISTORY OF DOMESTIC VIOLENCE',
		value: 50,
		key: 'noDomesticViolenceHistoryDomesticHistory',
		"detailedInfo": "Placeholder",
	},
	{
		left: 'DOES NOT HATE OPPOSITE GENDERED PARENT',
		right: 'HATES OPPOSITE GENDERED PARENT',
		value: 50,
		key: 'noHateGenderHateGender',
		"detailedInfo": "Placeholder",
	},
];

export const personalityDetail = [
	{
		label: 'First name',
		key: 'firstName',
		value: '',
	},
	{
		label: 'Last name',
		key: 'lastName',
		value: '',
	},
	{
		label: 'Nickname',
		key: 'nickName',
		value: '',
	},
	{
		label: 'Youtube URL',
		key: 'youtubeURL',
		value: '',
	},
	{
		label: 'tikTok URL',
		key: 'tikTokURL',
		value: '',
	},
	{
		label: 'Type I Am:',
		key: 'TypeIAm',
		value: '',
	},
	{
		label: 'Type I like:',
		key: 'TypeILike',
		value: '',
	},
	{
		label: 'Relationship Now:',
		key: 'RelationshipNow',
		value: '',
	},
	{
		label: 'Relationship Goals:',
		key: 'RelationshipGoal',
		value: '',
	},
	{
		label: 'Top 2 Fav TV/Movie Genre:',
		value: '',
		key: 'Top2FavTVMovieGenre',
	},
	{
		label: 'Top 2 Fav Youtube Category:',
		value: '',
		key: 'Top2FavYoutubeCategory',
	},
	{
		key: 'ForfunILikeTo',
		value: '',
		label: 'For fun I like to:',
	},
	{
		key: 'DreamLocation',
		value: '',

		label: 'Dream Location:',
	},
	{
		key: 'workGoals',
		label: 'Work Goals:',
		value: '',
	},
	{
		key: 'LoveLanguage',
		label: 'Love Language',
		value: '',
	},
	{
		key: 'CommunicationStyle',
		label: 'Communication Style:',
		value: '',
	},
];
 

export const mySkinToneDetails = {
	1: false,
	2: false,
	3: false,
	4: false,
	5: false,
	6: false,
	7: false,
	8: false,
};

export const mySkinTonePreferences = {
	1: false,
	2: false,
	3: false,
	4: false,
	5: false,
	6: false,
	7: false,
	8: false,
};
