import { part4Questions } from "helpers/GlobalConstants";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { getQuestions } from "store/actions/QuestionsAction";
import { addSessions } from "store/actions/QuestionsAction";
import { updateButtonPercent } from 'store/actions/actions.js';
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';

import VisualRewards from "../../components/VisualRewards/VisualRewards";


const objStyles = {
  style1A: [
    "1",
    "8",
    "9",
    "13",
    "17",
    "24",
    "26",
    "31",
    "33",
    "40",
    "41",
    "48",
    "50",
    "53",
    "57",
    "63",
    "65",
    "70",
    "74",
    "79",
  ],
  style2PR: [
    "2",
    "7",
    "10",
    "14",
    "18",
    "23",
    "25",
    "30",
    "34",
    "37",
    "42",
    "47",
    "51",
    "55",
    "58",
    "62",
    "66",
    "69",
    "75",
    "78",
  ],
  style3PE: [
    "3",
    "6",
    "11",
    "15",
    "19",
    "22",
    "27",
    "29",
    "35",
    "38",
    "43",
    "46",
    "49",
    "56",
    "59",
    "64",
    "67",
    "71",
    "76",
    "80",
  ],
  style4I: [
    "4",
    "5",
    "12",
    "16",
    "20",
    "21",
    "28",
    "32",
    "36",
    "39",
    "44",
    "45",
    "52",
    "54",
    "60",
    "61",
    "68",
    "72",
    "73",
    "77",
  ],
};

const PersonalityTestPart4 = () => {
  
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const { questions } = useSelector((state) => state?.questions);

  const [showResult, setShowResult] = useState(false);
  const [scoreAfterSummarize, setScoreAfterSummarize] = useState({
    summary: {
      mainTrait: 'NA',
      secondaryTrait: 'NA',
      mainNegativeTrait: 'NA',
      secondaryNegativeTrait: 'NA',
      percentCompleted: 0,
    }
  });
  console.log('in personality part 4 questions =', questions?.part4);

  useEffect(() => {
    if (uid) {
      dispatch(getQuestions(uid, "part4"));
    }
  }, [uid]);

  const questionPairs = [];

  const [triggerCalculate, setTriggerCalculate] = useState(null);

  for (let i = 0; i < part4Questions.length; i += 2) {
    questionPairs.push(part4Questions.slice(i, i + 2));
  }
console.log('questionPairs=',questionPairs);
  const resetForm = () => {
    setSelectedRadio({});
  };

  /** 
   * get selectedRadio from localstore if it is there.
   * 
   * selectedRadio is maximally populated with 40 elements 
   * 0 represents the 1st element which is radio buttons 1 or 2 
   * 1 represents the 2nd element which is radio buttons 3 or 4
   *   therefore the object will hold data  
   * { "0":1 },
   * { "1":3 }
   * 
   * this data is populated 1 at a time as the user selects the information.
   * via the onRadioChange handler 
   **/ 
  const [selectedRadio, setSelectedRadio] = useState({});

  const [objScoreCount, setObjScoreCount] = useState({
    style1A: [],
    style2PR: [],
    style3PE: [],
    style4I: [],
  });

  const initializeObjScoreCount = (selectedRadioData) => {
    const tempObjScoreCount = {
      style1A: [],
      style2PR: [],
      style3PE: [],
      style4I: [],
    };
  
    Object.values(selectedRadioData).forEach((value) => {
      const stringValue = value.toString();
      for (let [styleKey, styleValues] of Object.entries(objStyles)) {
        if (styleValues.includes(stringValue)) {
          tempObjScoreCount[styleKey].push(stringValue);
        }
      }
    });
  
    return tempObjScoreCount;
  };
  
  useEffect(() => {
    // This will run only once when the component mounts
    const storedSelectedRadio = questions?.part4 || (localStorage.getItem("part4") ? 
      JSON.parse(localStorage.getItem("part4")) : null) ;
    
    setSelectedRadio(storedSelectedRadio);
  
    const initializedObjScoreCount = initializeObjScoreCount(storedSelectedRadio);
    console.log('const storedSelectedRadio = ', storedSelectedRadio);
    console.log('const initializedObjScoreCount =  ', initializedObjScoreCount);
    // only do the following if it is 100% complete to avoid showing scores 
    // while the user is working 
    setObjScoreCount(initializedObjScoreCount);
    setHighestScoreProperty(initializedObjScoreCount); 
    console.log('initializedObjScoreCount=', initializedObjScoreCount); 
  }, [questions?.part4]);

  useEffect(() => {
    localStorage.setItem("part4", JSON.stringify(selectedRadio));

    const {isFullyAnswer, count} = isTestFullyAnswered(selectedRadio);
    // const is100pct = isTestFullyAnswered(selectedRadio);
    if (isFullyAnswer) {
       
      setHighestScoreProperty(objScoreCount);
      console.log('objScoreCount =', objScoreCount, 'selectedRadio= ', selectedRadio);
  
      if (objScoreCount) {
       
        const summaryAddedToScore = summarizeMainTraits(objScoreCount, highestText,
            secondHighestText, Math.round(count/40 * 100));
        setScoreAfterSummarize(summaryAddedToScore); 
        setShowResult(true);
      }

    } else {
      setShowResult(false);

    }
   
  }, [selectedRadio]);

 
  const history = useHistory();
  const testCase = (style) => {
    if (!["style1A", "style2PR", "style3PE", "style4I"].includes(style)) {
      throw new Error(
        "Trait must be one of ['style1A', 'style2PR', 'style3PE', 'style4I']"
      );
    }
    let tempObjScoreCount = {
      style1A: [],
      style2PR: [],
      style3PE: [],
      style4I: [],
    };

    let temp = {};
    let addedArr = [];
    for (let part4Question of part4Questions) {
      let hasId = objStyles[style].indexOf(part4Question.value.toString());
      let value = part4Question.value;
      if (hasId >= 0) {
        tempObjScoreCount[style].push(value.toString());
        addedArr.push(value);
      }
    }
    let pairIdx = 1;
    for (let i = 1; i <= part4Questions.length; i = i + 2) {
      let p0 = i;
      let p1 = i + 1;
      let c0 = addedArr.includes(p0);
      let c1 = addedArr.includes(p1);
      if (!(c0 || c1)) {
        let value = p0;
        temp[pairIdx - 1] = value;
        for (let style1 of Object.keys(tempObjScoreCount)) {
          let hasId = objStyles[style1].indexOf(value.toString());
          if (hasId >= 0) {
            tempObjScoreCount[style1].push(value.toString());
            break;
          }
        }
      } else {
        temp[pairIdx - 1] = c0 ? p0 : p1;
      }
      pairIdx++;
    }
    console.log("response:", temp);
    setObjScoreCount(tempObjScoreCount);
    setSelectedRadio(temp);
    setHighestScoreProperty(tempObjScoreCount);
  };

  const handleRadioChange = (pairIndex, value) => {
    setSelectedRadio((selectedRadio) => ({
      ...selectedRadio,
      [pairIndex]: value,
    }));
    let parsedValue = Number(value);
    let otherPairValue =
      parsedValue % 2 === 0 ? parsedValue - 1 : parsedValue + 1;
    let keyToCheckForPairValue = "";
    for (let [key, values] of Object.entries(objStyles)) {
      let idx = values.indexOf(otherPairValue.toString());
      if (idx !== -1) {
        keyToCheckForPairValue = key;
      }
    }
    if (objStyles.style1A.indexOf(value.toString()) >= 0) {
      setObjScoreCount((prevCount) => ({
        ...prevCount,
        style1A: [...prevCount.style1A, value.toString()],
      }));
    } else if (objStyles.style2PR.indexOf(value.toString()) >= 0) {
      setObjScoreCount((prevCount) => ({
        ...prevCount,
        style2PR: [...prevCount.style2PR, value.toString()],
      }));
    } else if (objStyles.style3PE.indexOf(value.toString()) >= 0) {
      setObjScoreCount((prevCount) => ({
        ...prevCount,
        style3PE: [...prevCount.style3PE, value.toString()],
      }));
    } else if (objStyles.style4I.indexOf(value.toString()) >= 0) {
      setObjScoreCount((prevCount) => ({
        ...prevCount,
        style4I: [...prevCount.style4I, value.toString()],
      }));
    }
    setObjScoreCount((prevCount) => ({
      ...prevCount,
      [keyToCheckForPairValue]: prevCount[keyToCheckForPairValue].filter(
        (v) => v !== otherPairValue.toString()
      ),
    }));
    setHighestScoreProperty(objScoreCount);
    updatePercentageChange(selectedRadio);
  };
 
  /**
   * @param {Object} selectedRadio eg., {"0":"2", "1":"4"}
   * @returns boolean
   */
  const isTestFullyAnswered = (selectedRadio) => {
    // Check if there are exactly 40 keys
    if (Object.keys(selectedRadio).length !== 40) {
      return {
        isFullyAnswer: false, 
        count: Object.keys(selectedRadio).length
      };
    }
  
    // Iterate over the keys and check if they are valid
    for (let i = 0; i < 40; i++) {
      const key = i.toString();
      const value = selectedRadio[key];
  
      // Check if the key exists and the value is a valid question number
      if (!value || typeof value !== 'number' || value < 1 || value > 80) {
        //  return false;
        // this condition is actually an error condition.
        return {
          isFullyAnswer: false, 
          count: Object.keys(selectedRadio).length
        };
      }
    }
  
    // If all checks pass, the test is fully answered
    // return true;
    return {
      isFullyAnswer: true, 
      count: Object.keys(selectedRadio).length
    };
  };
  
  // Usage
  // const testCompleted = isTestFullyAnswered(selectedRadio);

  


  useEffect(() => {
    const timer = setTimeout(() => {  
        updatePercentageChange(selectedRadio);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const updatePercentageChange = (selectedRadio) => {
    let numAnswered = Object.keys(selectedRadio).length; 
    console.log('updatePercentageChange = (selectedRadio) with', selectedRadio);
 
     const newPercent = Math.round(numAnswered/40 * 100)
    dispatch(updateButtonPercent('comm', newPercent));
  };

  const [highestText, setHighestText] = useState("");
  const [secondHighestText, setSecondHighestText] = useState("");
  
  const setHighestScoreProperty = (objScoreCount) => {
    let highest = 0, secondHighest = 0;
    let highestText = "", secondHighestText = "";
  
    // Create an array to simplify the process
    const traitArray = [
      { name: "Action", value: objScoreCount.style1A.length },
      { name: "Process", value: objScoreCount.style2PR.length },
      { name: "People", value: objScoreCount.style3PE.length },
      { name: "Ideas", value: objScoreCount.style4I.length },
    ];
  
    // Sort the array based on value (scores)
    traitArray.sort((a, b) => b.value - a.value);
  
    // The highest and second highest traits are now the first and second elements
    highest = traitArray[0].value;
    highestText = traitArray[0].name;
  
    secondHighest = traitArray[1].value;
    secondHighestText = traitArray[1].name;
  
    // Update state
    setHighestText(highestText);
    setSecondHighestText(secondHighestText);
  };



  const summarizeMainTraits = (_objScoreCount, highestTrait, 
    secondHighestTrait, percentCompleted) => {
    const summary = {
      mainTrait: highestTrait,
      secondaryTrait: secondHighestTrait,
      mainNegativeTrait: '',
      secondaryNegativeTrait: '',
      percentCompleted: percentCompleted // Math.floor(50/50 * 100) don't for get to dynamically update 
    };
    const newScore = {
      ..._objScoreCount,
      summary,
    };
    return newScore;
  };

  const handleSubmit = (objScoreCount) => {
    setHighestScoreProperty(objScoreCount);
    console.log('objScoreCount =', objScoreCount, 'selectedRadio= ', selectedRadio);
    const {isFullyAnswer, count} = isTestFullyAnswered(selectedRadio);
    if (uid && objScoreCount && isFullyAnswer) {
     
      const summaryAddedToScore = summarizeMainTraits(objScoreCount, highestText, 
        secondHighestText, Math.round(count/40 * 100));
      console.log('score part4 = ', summaryAddedToScore);
      dispatch(addSessions(selectedRadio, "part4", uid, summaryAddedToScore));
    } else {
      alert('you have to be logged in to save this. please log in your answers will be here after you login. ');
      history.push("/admin/home");
    }
  };


  useEffect(() => {
    window.TestCase = testCase;
  }, []);

  return (
    <>
      <Row className="personality-test-part">
        <Col md="12 py-4">
          <div>
            <h2 className='test-title'>Communication Styles</h2>{/* <!-- part 4 -->' */}
          </div>
          <div className="mt-2">
            <h3>Communication Styles</h3>
          </div>
          <div>
            <p>
              <b>Communication Style Powerpoint</b>
            </p>
            <p>
              <b>Researchers identified Four Communication Styles.</b>
            </p>
          </div>
          <div>
            <ul>
              <li>
                <b>action:</b> prefers to make quick decisions to get things
                done, quickly
              </li>
              <li>
                <b>process: </b>prefers to plan things out step by step.
              </li>
              <li>
                <b>people: </b>concerned with human relationships
              </li>
              <li>
                <b>ideas:</b> like to discuss ideas, vision, concepts and new
                approaches. They like to call themselves visionaries or big idea
                people.
              </li>
            </ul>
            <br/><br/>
          </div>
          <div>
            {questionPairs.map((pair, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0 ? "bg-green p-2 m-2" : "bg-info p-2 m-2"
                }
              >
                {pair.map((ques, subIndex) => (
                  <div key={subIndex} className="mt-2">
                    <input
                      data-value={ques.value}
                      type="radio"
                      checked={selectedRadio[index] === Number(ques.value)}
                      onChange={(e) => handleRadioChange(index, ques?.value)}
                    />
                    <span>{ques.label}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="card p-4  w-100  resultDiv">
            <div className="d-flex justify-content-between align-items-center "> 

              <h3 className="text-dark">
                Your preferred communication style is<br/>
                <b> {highestText}</b></h3>
            </div><br/>
            <div className="w-50">
              <div className="d-flex justify-content-between align-items-center  text-dark">
                <b>Action</b>
                <b>{objScoreCount?.style1A.length}</b>
              </div>
              <div className="d-flex justify-content-between align-items-center  text-dark">
                <b>Process</b>
                <b>{objScoreCount?.style2PR.length}</b>
              </div>
              <div className="d-flex justify-content-between align-items-center  text-dark">
                <b>People</b>
                <b>{objScoreCount?.style3PE.length}</b>
              </div>
              <div className="d-flex justify-content-between align-items-center  text-dark">
                <b>Ideas</b>
                <b>{objScoreCount?.style4I.length}</b>
              </div>
            </div>
            <p className="text-dark mt-4">
            Researchers identified Four Communication Styles<br/>
               
              <b>Action</b>: like to get things done, and create solutions fast. <br />
             
              <b>Process</b>: likes to focus on the process and organize <br /> 
              <b>People</b>: concerned with human relationships <br /> 
              <b>Ideas</b>: like concepts and new approaches
            </p>
          </div>
        <div className='d-flex justify-content-center'>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(objScoreCount);
            }}
            className="my-4 resultDiv__btn personality_see_and_save_btn"
            color="info"
            size="xlg"
          >
            Save & See Results
          </Button>
          <Button
            onClick={(e) => {
              resetForm();
              e.preventDefault();
            }}
            className="my-4 resultDiv__btn"
            color="info"
            size="xlg"
          >
            Clear All To Restart
          </Button>
        </div>
        </Col>
      </Row>
      <Row>
      {scoreAfterSummarize?.summary?.secondaryTrait!==undefined && (
          <VisualRewards 
          isShowAnimations={showResult}
          part="part4"
          mainTrait={scoreAfterSummarize?.summary?.mainTrait}
          secondaryTrait={scoreAfterSummarize?.summary?.secondaryTrait}

          />
        )}
        <FriendlyErrMsgBox/>
      </Row>
      
    </>
  );
};
export default PersonalityTestPart4;