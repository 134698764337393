import React from 'react';
import { useEffect, useState } from 'react';
import './AvatarCircle.css';
import DefaultImage from '../../assets/images/ellipse-67-1@2x.png';
/**
 * @file components/Avatar/AvatarCircle.jsx
 * pictureSource expecting a valid path 
 * percentCompleted expecting an integer between 0 to 100 
 * @param {string} pictureSource
 * @param {integer} percentCompleted 
 * @returns JSX.Element
 */
function AvatarCircle({ pictureSource = DefaultImage, percentCompleted = 50 }) {
   /* const p = percentCompleted/100;
    const calculated = 471.24 - (471.24 * p);
    const percentCompletedString = `${percentCompleted}%`;
    */

    const [calculated, setStrokeDashOffset] = useState(471.24);
    //const [strokeDashOffset, setCalculated] = useState(471.24);
    var percentCompletedString = `${percentCompleted}%`;
  
    useEffect(() => {
      const p = percentCompleted / 100;
      const calculated = 471.24 - (471.24 * p);
      console.log('calculated=', calculated);
      const handleLoad = () => {
        percentCompletedString = `${percentCompleted}%`;
        setStrokeDashOffset(calculated); 
        console.log('calculated=', calculated);
      };
  
      window.addEventListener('load', handleLoad);
  
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }, [percentCompleted]);
 
    return (
    <div className="u-avatar-circle">
      <div className="circular-graph" percentcompleted={percentCompletedString}>
        <svg className="circle-svg" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
          <circle className="circle circle-white" cx="50%" cy="50%" r="50%"/>
          <circle style={{ strokedashoffset: calculated }} className="circle circle-green" cx="50%" cy="50%" r="50%"/>
          <mask id="circle-mask">
            <circle cx="50%" cy="50%" r="42%" fill="white" />
            <circle cx="50%" cy="50%" r="42%" fill="transparent" />
          </mask>
          <image xlinkHref={pictureSource} className="circle-image" x="0" y="0" width="100%" height="100%" mask="url(#circle-mask)" />
        </svg>
      </div>
   </div>
    );
  }
  export default AvatarCircle;