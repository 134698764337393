/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import google from '../assets/img/icons/common/google.png';
import twitter from '../assets/img/icons/common/twitter.png';
import phone from '../assets/img/icons/common/telephone.png';
import gmail from '../assets/img/icons/common/gmail.png';
// import yahoo from '../assets/img/icons/common/yahoo.png';

// react plugin used to create charts

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Container,
	Row,
	Col,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Header from 'components/Headers/Header.js';
import { useDispatch } from 'react-redux';
import { AuthWithGoogle } from 'store/actions/authActions';
import { AuthWithTwitter } from 'store/actions/authActions';
// import { AuthWithYahoo } from 'store/actions/authActions';
import SignInEmailModal from 'components/Modal/SignInEmailModal';
import SignInPhoneModal from 'components/Modal/SignInPhoneModal';
// most recently commented out facebook code.
// import facebook from '../assets/img/icons/facebook-icon.png';
// import { AuthWithFacebook } from 'store/actions/authActions';

const Index = props => {
	const { uid, user } = useSelector(state => state.authUser);
	const history = useHistory();
	const [modal, setModal] = useState(false);
	const [phoneModal, setPhoneModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};
	const phoneToggle = () => {
		setPhoneModal(!phoneModal);
	};

	const [phoneDiv, setPhoneDiv] = useState(false);

	const phoneDivToggle = () => {
		setPhoneDiv(!phoneDiv);
	};

	const dispatch = useDispatch();

  /*
  This should go on create profile page. 	 
  useEffect(() => {
       setTimeout(() => {
         if (uid && user?.onBoardingData) {
           history.push("/admin/user-profile");
         } else if (uid && !user?.onBoardingData) {l
           history.push("/admin/create-profile");
		 }
       }, 500);
     }, [uid, user?.onBoardingData]);
*/

	return (
		<>
			<Header />

			{/* Page content */}
			<Container className='mt--7' fluid>
				<Row className='my-3'>
					<Col xl='12'>
						<Card className='shadow'>
							<CardHeader className='border-0'>
								<Row className='align-items-center'>
									<div className='col'>
										<h3>Login</h3>
									</div>
								</Row>
							</CardHeader>
							<CardBody className='login  bg-site-white mb-5'>
								<Row className='justify-content-center py-2'>
									<Col
										md='7'
										className='cursor-pointer login__buttonShadow bg-site-white py-3 px-3'
									>
										<div className='d-flex align-items-center'>
											<div>
												<img
													src={google}
													alt=''
													className='img-fluid login__img mr-3 cursor-pointer'
												/>
												<span
												onClick={(e) => {
													e.preventDefault();
													dispatch(
													AuthWithGoogle(() => {
														if (uid && user?.onBoardingData) {
														history.push("/admin/user-profile");
														} else if (uid && !user?.onBoardingData) {
														history.push("/admin/create-profile");
														}
													})
													);
												}}
												>
													Sign in with Google
												</span>
											</div>
										</div>
									</Col>
								</Row>
								<Row className='justify-content-center py-2 '>
									<Col
										md='7'
										className='cursor-pointer login__buttonShadow bg-site-twitter py-3 px-3 text-white '
									>
									<div
									className="d-flex align-items-center "
									onClick={(e) => {
										e.preventDefault();
										dispatch(
										AuthWithTwitter(() => {
											if (uid && user?.onBoardingData) {
											history.push("/admin/user-profile");
											} else if (uid && !user?.onBoardingData) {
											history.push("/admin/create-profile");
											}
										})
										);
									}}
									>
											<div>
												<img
													src={twitter}
													alt='twitter icon'
													className='img-fluid login__img mr-3'
												/>
												<span>
													Sign in with Twitter
												</span>
											</div>
										</div>
									</Col>
								</Row>
								<Row className='justify-content-center py-2 '>
									<Col
										md='7'
										className='cursor-pointer login__buttonShadow bg-site-red py-3 px-3 text-white'
									>
										<div
											className='d-flex align-items-center cursor-pointer'
											onClick={(e) => {
												e.preventDefault();
												toggle();
												// dispatch(
												//   AuthWithTwitter(() => {
												//     history.push("/admin/user-profile");
												//   })
												// );
											}}
										>
											<div>
												<img
													src={gmail}
													alt='gmail icon'
													className='img-fluid login__img mr-3'
												/>
												<span>Sign in with Email</span>
											</div>
										</div>
									</Col>
								</Row>
								<Row className='justify-content-center py-2'>
									<Col
										md='7'
										className='cursor-pointer login__buttonShadow bg-site-green py-3 px-3 text-white'
									>
										<div
											className='d-flex align-items-center cursor-pointer'
											onClick={(e) => {
												e.preventDefault();
												phoneDivToggle();
											}}
										>
											<div>
												<img
													src={phone}
													alt='Phone icon'
													className='img-fluid login__img mr-3'
												/>
												<span>Sign in with Phone</span>
											</div>
										</div>
									</Col>
								</Row>
								 {/*  
								 This is the 
								 most recently commented out facebook code. 
								  Facebook disabled it saying something was wrong with the login 
							     
								 <Row className='justify-content-center py-2'>
									<Col
										md='7'
										className='cursor-pointer login__buttonShadow bg-site-primary py-3 px-3 text-white'
									>
									<div
									className="d-flex align-items-center cursor-pointer"
									onClick={(e) => {
										e.preventDefault();
										dispatch(
										AuthWithFacebook(() => {
											if (uid && user?.onBoardingData) {
											history.push("/admin/user-profile");
											} else if (uid && !user?.onBoardingData) {
											history.push("/admin/create-profile");
											}
										})
										);
									}}
									>
											<div>
												<img
													src={facebook}
													alt=''
													className='img-fluid login__img mr-3'
												/>
												<span>
													Sign in with Facebook
												</span>
											</div>
										</div>
									</Col>
								</Row> */}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<SignInEmailModal
				modal={modal}
				toggle={toggle}
				btntext='Save'
				title='Sign in With Email'
			/>
			{phoneDiv && <SignInPhoneModal />}
		</>
	);
};

export default Index;
