
// import eyeslash from "../../assets/img/icons/common/eye-slash.png";
/* 
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { signInWithEmail_Password } from "store/actions/authActions";
import { EmailCheck } from "store/actions/authActions";
import { signUpWithEmail_Password } from "store/actions/authActions";

*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as firebaseui from "firebaseui";
import firebase from "../../config/firebase";
import "firebaseui/dist/firebaseui.css";
import { AuthWithPhone } from "store/actions/authActions";
import {  useHistory } from "react-router-dom";
import { redirectBasedOnOnBoardingStatus } from '../../helpers/referrerStorageUtils';




const SignInPhoneModal = () => {
  // const [email, setEmail] = useState("");'

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const isManualSignIn = true;
    const uiConfig = {
      signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          console.log("authResult ", authResult, "redirectUrl ", redirectUrl);
          dispatch(AuthWithPhone(authResult, () => {
            redirectBasedOnOnBoardingStatus(history);
          }));
          return false;
        },
      },
    };
    let ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  return (
    <>
      {/* <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        // centered={true}
        className="px-0"
      >
        <ModalBody className="px-2 ">
        </ModalBody>
      </Modal> */}
      <div id="firebaseui-auth-container"></div>
    </>
  );
};
export default SignInPhoneModal;
