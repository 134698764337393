import DeletionPolicyContent from "components/DeletionPolicyContent";
import Header from "components/Headers/Header";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";

function DataDeletionPolicy() {
  return (
    <div>
      <Header />
      <Container className="mt--7 privacy-policy" fluid>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between">
                    <div className="d-flex ">
                      <h3 className="page-heading">Data Deletion Policy</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row className="">
                  <Col className="privacy-policy__right">
                    <DeletionPolicyContent />
                  </Col>
                </Row>
                <hr />
                <p>
                  Generated using{" "}
                  <Link>TermsFeed Privacy Policy Generator</Link>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DataDeletionPolicy;
