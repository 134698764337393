import GogExpImgSet2_1 from "../assets/images/gogExpSet-2-1.png";
import GogExpImgSet2_2 from "../assets/images/gogExpSet-2-2.png";
import GogExpImgSet2_3 from "../assets/images/gogExpSet-2-3.png";
import GogExpImgSet2_4 from "../assets/images/gogExpSet-2-4.png";
import GogExpImgSet2_5 from "../assets/images/gogExpSet-2-5.png";
import GogExpImgSet2_6 from "../assets/images/gogExpSet-2-6.png";
import GogExpImgSet2_7 from "../assets/images/gogExpSet-2-7.png";
import GogExpImgSet2_8 from "../assets/images/gogExpSet-2-8.png";
import GogExpImgSet2_9 from "../assets/images/gogExpSet-2-9.png";
import GogExpImg1 from "../assets/images/gogexperience-1.png";
import GogExpImg2 from "../assets/images/gogexperience-2.png";
import GogExpImg3 from "../assets/images/gogexperience-3.png";
import GogExpImg4 from "../assets/images/gogexperience-4.png";
import GogExpImg5 from "../assets/images/gogexperience-5.png";
import GogExpImg6 from "../assets/images/gogexperience-6.png";
import GogExpImg7 from "../assets/images/gogexperience-7.png";
import GogExpImg8 from "../assets/images/gogexperience-8.png";
import GogExpImg9 from "../assets/images/gogexperience-9.png";
import GogExpDuelImg1 from "../assets/images/gogexperience-duel-1.png";
import GogExpDuelImg2 from "../assets/images/gogexperience-duel-2.png";
import GogExpDuelImg3 from "../assets/images/gogexperience-duel-3.png";
import GogExpDuelImg4 from "../assets/images/gogexperience-duel-4.png";
import GogExpDuelImg5 from "../assets/images/gogexperience-duel-5.png";
import GogExpDuelImg6 from "../assets/images/gogexperience-duel-6.png";
import GogExpDuelImg7 from "../assets/images/gogexperience-duel-7.png";

import HomeSliderImg1 from "../assets/images/home-slider-img-1.png";
/*
import MobileSliderImg1 from "../assets/images/onboarding1.png";
import MobileSliderImg2 from "../assets/images/onboarding2.png";
import MobileSliderImg3 from "../assets/images/onboarding3.png";
import MobileSliderImg4 from "../assets/images/onboarding4.png";
import MobileSliderImg5 from "../assets/images/onboarding5.png";
import MobileSliderImg6 from "../assets/images/onboarding6.png";
import MobileSliderBg from "../assets/images/onboarding7.png";
*/
import { MobileSliderImg1, MobileSliderImg2, MobileSliderImg3, MobileSliderImg4, MobileSliderImg5, MobileSliderImg6, MobileSliderImg7, MobileSliderBg } from '../config/dynamicImages.js';


import SliderImg1 from "../assets/images/slider-img-1.png";
import SliderImg2 from "../assets/images/slider-img-2.png";
import SliderImg3 from "../assets/images/slider-img-3.png";
import SliderImg4 from "../assets/images/slider-img-4.png";
import SliderImg5 from "../assets/images/slider-img-5.png";
import SliderImg7 from "../assets/images/slider-img-7.png";

import MainPageHeroSliderImg1 from "../assets/images/mainPageSliderImgs-7.png"; 
import MainPageHeroSliderImg2 from "../assets/images/mainPageSliderImgs-5.png"; 
import MainPageHeroSliderImg3 from "../assets/images/mainPageSliderImgs-3.png"; 
import MainPageHeroSliderImg4 from "../assets/images/mainPageSliderImgs-4.png";
import MainPageHeroSliderImg5 from "../assets/images/mainPageSliderImgs-2.png"; 
import MainPageHeroSliderImg6 from "../assets/images/mainPageSliderImgs-6.png"; 
import MainPageHeroSliderImg7 from "../assets/images/mainPageSliderImgs-1.png"; 
import MainPageHeroSliderImg8 from "../assets/images/mainPageSliderImgs-8.png";
import MainPageHeroSliderImg9 from "../assets/images/mainPageSliderImgs-9.png"; 
import MainPageHeroSliderImg10 from "../assets/images/mainPageSliderImgs-14.png"; 
import MainPageHeroSliderImg11 from "../assets/images/mainPageSliderImgs-15.png";  



import relationshipImg1 from '../assets/images/relation-icon-1.svg'
import relationshipImg2 from '../assets/images/relation-icon-2.svg'
import relationshipImg3 from '../assets/images/relation-icon-3.svg'
import relationshipImg4 from '../assets/images/relation-icon-4.svg'


export const relationshipData = [
  {
    title: "Start a Family Long-term Relationship",
    imgUrl: relationshipImg1
  },
  {
    title: "Short-term Relationship",
    imgUrl: relationshipImg2
  },
  {
    title: "Short-term fun",
    imgUrl: relationshipImg3
  },
  {
    title: "not sure",
    imgUrl: relationshipImg4
  },
];

export const mainPageHeroImgs = [
  {imageUrl: MainPageHeroSliderImg1},
  {imageUrl: MainPageHeroSliderImg2},
  {imageUrl: MainPageHeroSliderImg3},
  {imageUrl: MainPageHeroSliderImg4},
  {imageUrl: MainPageHeroSliderImg5},
  {imageUrl: MainPageHeroSliderImg6},
  {imageUrl: MainPageHeroSliderImg7},
  {imageUrl: MainPageHeroSliderImg8},
  {imageUrl: MainPageHeroSliderImg9},
  {imageUrl: MainPageHeroSliderImg10},
  {imageUrl: MainPageHeroSliderImg11}
];

export const nestedCardImgs =  [
  {
    imageUrl: GogExpImg1,
    imageUrl2: GogExpImgSet2_1,
  },
  {
    imageUrl: GogExpImg2,
    imageUrl2: GogExpImgSet2_2,
  },
  {
    imageUrl: GogExpImg3,
    imageUrl2: GogExpImgSet2_3,
  },
  {
    imageUrl: GogExpImg4,
    imageUrl2: GogExpImgSet2_4,
  },
  {
    imageUrl: GogExpImg5,
    imageUrl2: GogExpImgSet2_5,
  },
  {
    imageUrl: GogExpImg6,
    imageUrl2: GogExpImgSet2_6,
  },
  {
    imageUrl: GogExpImg7,
    imageUrl2: GogExpImgSet2_7,
  },
  {
    imageUrl: GogExpImg8,
    imageUrl2: GogExpImgSet2_8,
  },
  {
    imageUrl: GogExpImg9,
    imageUrl2: GogExpImgSet2_9,
  },
  // Add more nested slides here
];

export const part6Questions = {
  questions: [
    {
      statement:
        "Has there ever been a period of time when you were not your usual self and…",
      type: "yesAndNo",
      options: [
        "...you felt so good or so hyper that other people thought you were not your normal self or you were so hyper that you got into trouble?",
        "…you were so irritable that you shouted at people or started fights or arguments?",
        "…you felt much more self-confident than usual?",
        "…you got much less sleep than usual and found you didn’t really miss it?",
        "…you were much more talkative or spoke faster than usual?",
        "…thoughts raced through your head or you couldn’t slow your mind down?",
        "…you were so easily distracted by things around you that you had trouble concentrating or staying on track?",
        "…you had much more energy than usual?",
        "…you were much more active or did many more things than usual?",
        "…you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night?",
        "…you were much more interested in sex than usual?",
        "…you did things that were unusual for you or that other people might have thought were excessive, foolish, or risky?",
        "…spending money got you or your family in trouble?",
      ],
    },
    {
      statement:
        "If you checked YES to more than one of the above, have several of these ever happened during the same period of time? Please check 1 response only",
      type: "radio",

      options: ["Yes", "No"],
    },
    {
      statement:
        "How much of a problem did any of these cause you — like being able to work; having family, money, or legal troubles; getting into arguments or fights? Please check 1 response only",
      type: "radio",
      name: "radio2",
      options: [
        "No Problem",
        "Minor Problem",
        "Moderate Problem",
        "Serious Problem",
      ],
    },
    {
      statement:
        "Have any of your blood relatives (ie, children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      statement:
        "Has a health professional ever told you that you have manic-depressive illness or bipolar disorder?",
      type: "radio",
      options: ["Yes", "No"],
    },
  ],
};

export const part4Questions = [
  {
    value: 1,
    label: "1. I like action.",
  },
  {
    value: 2,
    label: "2. I deal with problems in a systematic way.",
  },
  {
    value: 3,
    label: "3. I believe that teams are more effective than individuals.",
  },
  {
    value: 4,
    label: "4. I enjoy motivation very much.",
  },
  {
    value: 5,
    label: "5. I am more interested in the future than in the past.",
  },
  {
    value: 6,
    label: "6. I enjoy working with people.",
  },
  {
    value: 7,
    label: "7. I like to attend well organized group meetings.",
  },
  {
    value: 8,
    label: "8. Deadlines are very important for me",
  },
  {
    value: 9,
    label: "9. I cannot stand procrastination.",
  },
  {
    value: 10,
    label: "10. I believe that new ideas have to be tested before being used.",
  },
  {
    value: 11,
    label: "11. I enjoy the stimulation of interaction with others.",
  },
  {
    value: 12,
    label: "12.  I am always looking for new possibilities.",
  },
  {
    value: 13,
    label: "13. I want to set up my own objectives.",
  },
  {
    value: 14,
    label: "14. When I start something, I go through until the end.",
  },
  {
    value: 15,
    label: "15. I basically try to understand other people's emotions.",
  },
  {
    value: 16,
    label: "16. I do challenge people around me.",
  },
  {
    value: 17,
    label: "17. I look forward to receiving feedback on my performance.",
  },
  {
    value: 18,
    label: "18. I find the step-by-step approach very effective.",
  },
  {
    value: 19,
    label: "19. I find the step-by-step approach very effective.",
  },
  {
    value: 20,
    label: "20. I like creative problem-solving.",
  },
  {
    value: 21,
    label: "21. I extrapolate and project all the time.",
  },
  {
    value: 22,
    label: "22. I am sensitive to others' needs.",
  },
  {
    value: 23,
    label: "23. Planning is the key to success.",
  },
  {
    value: 24,
    label: "24. I become impatient with long deliberations.",
  },
  {
    value: 25,
    label: "25. I am cool under pressure.",
  },
  {
    value: 26,
    label: "26. I value experience very much.",
  },
  {
    value: 27,
    label: "27. I listen to people.",
  },
  {
    value: 28,
    label: "28. People say that I am a fast thinker.",
  },
  {
    value: 29,
    label: "29. Cooperation is a key word for me.",
  },
  {
    value: 30,
    label: "30. I use logical methods to test alternatives.",
  },
  {
    value: 31,
    label: "31. I like to handle several projects at the same time.",
  },
  {
    value: 32,
    label: "32. I always question myself.",
  },
  {
    value: 33,
    label: "33. I learn by doing.",
  },
  {
    value: 34,
    label: "34. I believe that my head rules my heart.",
  },
  {
    value: 35,
    label: "35. I can predict how others may react to a certain action.",
  },
  {
    value: 36,
    label: "36. I do not like details.",
  },
  {
    value: 37,
    label: "37. Analysis should always precede action.",
  },
  {
    value: 38,
    label: "38. I am able to assess the climate of a group.",
  },
  {
    value: 39,
    label: "39. I have a tendency to start things and not finish them.",
  },
  {
    value: 40,
    label: "40. I perceive myself as decisive.",
  },
  {
    value: 41,
    label: "41. I search for challenging tasks.",
  },
  {
    value: 42,
    label: "42. I rely on observation and data.",
  },
  {
    value: 43,
    label: "43. I can express my feelings openly.",
  },
  {
    value: 44,
    label: "44. I like to design new projects.",
  },
  {
    value: 45,
    label: "45. I enjoy reading very much.",
  },
  {
    value: 46,
    label: "46. I perceive myself as a facilitator.",
  },
  {
    value: 47,
    label: "47. I like to focus on one issue at a time.",
  },
  {
    value: 48,
    label: "48. I like to achieve.",
  },
  {
    value: 49,
    label: "49. I enjoy learning about others.",
  },
  {
    value: 50,
    label: "50. I like variety.",
  },
  {
    value: 51,
    label: "51. Facts speak for themselves.",
  },
  {
    value: 52,
    label: "52. I use my imagination as much as possible.",
  },
  {
    value: 53,
    label: "53. I am impatient with long, slow assignments.",
  },
  {
    value: 54,
    label: "54. My mind never stops working.",
  },
  {
    value: 55,
    label: "55. Key decisions have to be made in a cautious way.",
  },
  {
    value: 56,
    label:
      "56. I strongly believe that people need each other to get work done.",
  },
  {
    value: 57,
    label: "57. I usually make decisions without thinking too much.",
  },
  {
    value: 58,
    label: "58. Emotions create problems.",
  },
  {
    value: 59,
    label: "59. I like to be liked by others.",
  },
  {
    value: 60,
    label: "60. I can put two and two together very quickly.",
  },
  {
    value: 61,
    label: "61. I try out my new ideas on people.",
  },
  {
    value: 62,
    label: "62. I believe in the scientific approach.",
  },
  {
    value: 63,
    label: "63. I like to get things done.",
  },
  {
    value: 64,
    label: "64. Good relationships are essential.",
  },
  {
    value: 65,
    label: "65. I am impulsive.",
  },
  {
    value: 66,
    label: "66. I accept differences in people.",
  },
  {
    value: 67,
    label: "67. Communicating with people is an end in itself.",
  },
  {
    value: 68,
    label: "68. I like to be intellectually stimulated",
  },
  {
    value: 69,
    label: "69. I like to organize.",
  },
  {
    value: 70,
    label: "70. I usually jump from one task to another.",
  },
  {
    value: 71,
    label: "71. Talking and working with people is a creative act.",
  },
  {
    value: 72,
    label: "72. Self-actualization is a key word for me.",
  },
  {
    value: 73,
    label: "73. I enjoy playing with ideas.",
  },
  {
    value: 74,
    label: "74. I dislike to waste time.",
  },
  {
    value: 75,
    label: "75. I enjoy doing what I am good at.",
  },
  {
    value: 76,
    label: "76. I learn by interacting with others.",
  },
  {
    value: 77,
    label: "77. I find abstractions interesting and enjoyable.",
  },
  {
    value: 78,
    label: "78. I am patient with details.",
  },
  {
    value: 79,
    label: "79. I like brief, to the point statements.",
  },
  {
    value: 80,
    label: "80. I feel confident in myself.",
  },
];
// from IPIP Big-Five Factor 
export const part1Questions = [
  {
    value: "none",
    label: "1. Am the life of the party.",
    cat: "E",
  },
  {
    value: "none",
    label: "2. Feel little concern for others",
    cat: "A",
  },
  {
    value: "none",
    label: "3. Am always prepared.",
    cat: "C",
  },
  {
    value: "none",
    label: "4. Get stressed out easily.",
    cat: "N",
  },
  {
    value: "none",
    label: "5. Have a rich vocabulary.",
    cat: "O",
  },
  {
    value: "none",
    label: "6. Don't talk a lot.",
    cat: "E",
  },
  {
    value: "none",
    label: "7. Am interested in people.",
    cat: "A",
  },
  {
    value: "none",
    label: "8. Leave my belongings around.",
    cat: "C",
  },
  {
    value: "none",
    label: "9. Am relaxed most of the time.",
    cat: "N",
  },
  {
    value: "none",
    label: "10. Have difficulty understanding abstract ideas.",
    cat: "O",
  },
  {
    value: "none",
    label: "11. Feel comfortable around people.",
    cat: "E",
  },
  {
    value: "none",
    label: "12. Insult people.",
    cat: "A",
  },
  {
    value: "none",
    label: "13. Pay attention to details.",
    cat: "C",
  },
  {
    value: "none",
    label: "14. Worry about things.",
    cat: "N",
  },
  {
    value: "none",
    label: "15. Have a vivid imagination.",
    cat: "O",
  },
  {
    value: "none",
    label: "16. Keep in the background.",
    cat: "E",
  },
  {
    value: "none",
    label: "17. Sympathize with others' feelings.",
    cat: "A",
  },
  {
    value: "none",
    label: "18. Make a mess of things.",
    cat: "C",
  },
  {
    value: "none",
    label: "19. Seldom feel blue. ",
    cat: "N",
  },
  {
    value: "none",
    label: "20. Am not interested in abstract ideas.",
    cat: "O",
  },
  {
    value: "none",
    label: "21. Start conversations.",
    cat: "E",
  },
  {
    value: "none",
    label: "22. Am not interested in other people's problems.",
    cat: "A",
  },
  {
    value: "none",
    label: "23. Get chores done right away.",
    cat: "C",
  },
  {
    value: "none",
    label: "24. Am easily disturbed.",
    cat: "N",
  },
  {
    value: "none",
    label: "25. Have excellent ideas.",
    cat: "O",
  },
  {
    value: "none",
    label: "26. Have little to say.",
    cat: "E",
  },
  {
    value: "none",
    label: "27. Have a soft heart.",
    cat: "A",
  },
  {
    value: "none",
    label: "28. Often forget to put things back in their proper place",
    cat: "C",
  },
  {
    value: "none",
    label: "29. Get upset easily.",
    cat: "N",
  },
  {
    value: "none",
    label: "30. Do not have a good imagination.",
    cat: "O",
  },
  {
    value: "none",
    label: "31. Talk to a lot of different people at parties.",
    cat: "E",
  },
  {
    value: "none",
    label: "32. Am not really interested in others.",

    cat: "A",
  },
  {
    value: "none",
    label: "33. Like order",
    cat: "C",
  },
  {
    value: "none",
    label: "34. Change my mood a lot.",
    cat: "N",
  },
  {
    value: "none",
    label: "35. Am quick to understand things.",
    cat: "O",
  },
  {
    value: "none",
    label: "36. Don't like to draw attention to myself.",
    cat: "E",
  },
  {
    value: "none",
    label: "37. Take time out for others.",
    cat: "A",
  },
  {
    value: "none",
    label: "38. Shirk my duties.",
    cat: "C",
  },
  {
    value: "none",
    label: "39. Have frequent mood swings.",
    cat: "N",
  },
  {
    value: "none",
    label: "40. Use difficult words.",
    cat: "O",
  },
  {
    value: "none",
    label: "41. Don't mind being the center of attention.",
    cat: "E",
  },
  {
    value: "none",
    label: "42. Feel others' emotions.",
    cat: "A",
  },
  {
    value: "none",
    cat: "C",
    label: "43. Follow a schedule.",
  },
  {
    value: "none",
    label: "44. Get irritated easily.",
    cat: "N",
  },
  {
    value: "none",
    label: "45. Spend time reflecting on things.",
    cat: "O",
  },
  {
    value: "none",
    label: "46. Am quiet around strangers.",
    cat: "E",
  },
  {
    value: "none",
    label: "47. Make people feel at ease.",
    cat: "A",
  },
  {
    value: "none",
    label: "48. Am exacting in my work.",
    cat: "C",
  },
  {
    value: "none",
    label: "49. Often feel blue.",
    cat: "N",
  },
  {
    value: "none",
    label: "50. Am full of ideas.",
    cat: "O",
  },
];

export const part2Questions = [
  {
    label: "1. It's not wise to tell your secrets.",
    cat: "MACH",
  },
  {
    label:
      "2. Generally speaking, people won't work hard unless they are forced to.",
    cat: "MACH",
  },
  {
    label:
      "3. Whatever it takes, you must get the important people on your side.",
    cat: "MACH",
  },
  {
    label:
      "4. Avoid direct conflict with others because they may be useful in the future.",
    cat: "MACH",
  },
  {
    label:
      "5. It’s wise to keep track of information that you can use against people later.",
    cat: "MACH",
  },
  {
    label: "6. You should wait for the right time to get back at people.",
    cat: "MACH",
  },
  {
    label:
      "7. There are things you should hide from other people because they don’t need to know.",
    cat: "MACH",
  },
  {
    label: "8. Make sure your plans benefit you, not others.",
    cat: "MACH",
  },
  {
    label: "9. Most people can be manipulated",
    cat: "MACH",
  },
  {
    label: "10. People see me as a natural leader.",
    cat: "NARC",
  },
  {
    label: "11. I hate being the center of attention. (R)",
    isReverseCat: true,
    cat: "NARC",
  },
  {
    label: "12. Many group activities tend to be dull without me.",
    cat: "NARC",
  },
  {
    label: "13. I know that I am special because everyone keeps telling me so.",
    cat: "NARC",
  },
  {
    label: "14. I like to get acquainted with important people.",
    cat: "NARC",
  },
  {
    label: "15. I feel embarrassed if someone compliments me. (R)",
    isReverseCat: true,
    cat: "NARC",
  },
  {
    label: "16. I have been compared to famous people.",
    cat: "NARC",
  },
  {
    label: "17. I am an average person. (R)",
    isReverseCat: true,
    cat: "NARC",
  },
  {
    label: "18. I insist on getting the respect I deserve.",
    cat: "NARC",
  },
  {
    label: "19. I like to get revenge on authorities.",
    cat: "PSYCH",
  },
  {
    label: "20. I avoid dangerous situations. (R)",
    isReverseCat: true,
    cat: "PSYCH",
  },
  {
    label: "21. Payback needs to be quick and nasty.",
    cat: "PSYCH",
  },
  {
    label: "22. People often say I’m out of control.",
    cat: "PSYCH",
  },
  {
    label:
      "23. It’s true that I can be mean to others. (or I enjoy having sex with people I hardly know.)",
    cat: "PSYCH",
  },
  {
    label: "24. People who mess with me always regret it.",
    cat: "PSYCH",
  },
  {
    label: "25. I have never gotten into trouble with the law. (R)",
    isReverseCat: true,
    cat: "PSYCH",
  },
  {
    label: "26. I enjoy having sex with people I hardly know.",
    cat: "PSYCH",
  },
  {
    label: "27. I’ll say anything to get what I want.",
    cat: "PSYCH",
  },
];

export const part5Questions = [
  {
    value: "Acts of Service",
    label: "Acts of Service",
  },
  {
    value: "Gift-Giving",
    label: "Gift-Giving",
  },
  {
    value: "Physical Touch",
    label: "Physical Touch",
  },
  {
    value: "Quality Time",
    label: "Quality Time",
  },
  {
    value: "Words of Affirmation",
    label: "Words of Affirmation",
  },
];
// hcl 
export const part3Questions = {
  title: "Energy, Activity and Mood",
  description:
    "At different times in their life everyone experiences changes or swings in energy, activity and mood (“highs and lows” or “ups and downs”). The aim of this questionnaire is to assess the characteristics of the “high” periods.",
  questions: [
    {
      statement:
        "First of all, <u>how are you feeling today compared to your usual state</u>: (Please mark only ONE of the following)",
      type: "radio",
      name: "radio1",
      options: [
        "Much worse than usual",
        "Worse than usual",
        "A little worse than usual",
        "Neither better not worse than usual",
        "Better than usual",
        "Much better than usual",
      ],
    },
    {
      statement:
        "<u>How are you usually compared to other people</u>? Independently of how you feel today, please tell us how you are normally compared to other people, by marking which of the following statements describes you best.",
      description:
        "<br/><u>Compared to other people</u> my level of activity, energy and mood… (Please mark only ONE of the following)",
      type: "radio",
      name: "radio2",
      options: [
        "...is always rather stable and even",
        "...is generally higher",
        "...is generally lower",
        "...repeatedly show periods of ups and downs",
      ],
    },
    {
      statement:
        "<strong>Please try to remember a period when you were in a “high” state.</strong>",
      description:
        "How did you feel then? Please answer all of these statement independently of your Present condition:<br/>In such a state:",
      type: "yesAndNo",
      options: [
        "I need less sleep",
        "I feel more energetic and more active",
        "I am more self-confident",
        "I enjoy my work more",
        "I am more sociable (make more phone calls, go out more",
        "I want to travel and/or do travel more",
        "I tend to drive faster or take more risks when driving",
        "I spend more money/too much money",
        "I take more risks in my daily life (in my work and/or other activities)",
        "I am physically more active (sport etc.)",
        "I plan more activities or projects",
        "I have more ideas, I am more creative",
        "I am less shy or inhibited",
        "I wear more colorful and more extravagant clothes/make-up)",
        "I want to meet or actually do meet more people",
        "I am more interested in sex,",
        "I am more flirtatious and/or am more sexually active",
        "I talk more",
        "I think faster",
        "I make more jokes or puns when I am talking",
        "I am more easily distracted",
        "I engage in lots of new things",
        "My thoughts jump from topic to topic",
        "I do things more quickly and/or more easily.",
        "I am more impatient and/or get irritable more easily",
        "I can be exhausting or irritating for others",
        "I get into more quarrels",
        "My mood is higher, more optimistic",
        "I drink more coffee",
        "I smoke more cigarettes",
        "I drink more alcohol",
        "I take more drugs (sedatives, anxiolytics, stimulants…)",
      ],
    },
    {
      statement:
        "If you never experienced such a “high” you can stop here and click save.<br/><br/><strong>Impact of your “highs” on various aspects of your life:</strong>",
      type: "negativeAndPositive",
      options: ["Family Life", "Social Life", "Work", "Leisure"],
    },
    {
      statement:
        "<strong>Other people’s reactions and comments to your “highs.”</strong>",
      description:
        "How did people close to you react to or comment on your “highs”?",
      type: "radio",
      name: "otherPeople",
      options: [
        "Positively (encouraging or supportive)",
        "Neutral",
        "Negatively concerned, annoyed, irritated, critical)",
        "Positively and negatively",
        "No reactions",
      ],
    },
    {
      statement:
        "<strong>Lengths of your “highs” as a rule (on the average)</strong>",
      description: "(Please mark only ONE of the following)",
      type: "radio",
      name: "lengthOfHighs",
      options: [
        "1 day",
        "2-3 days",
        "4-7 days",
        "Longer than 1 week",
        "Longer than 1 month",
        "I can't judge/don't know",
      ],
    },
    {
      statement:
        "<strong>If yes, please estimate how many days you spent in “highs” during the last twelve months:</strong>",
      type: "radio",
      name: "otherPeople",
      options: ["Yes", "No"],
    },
    {
      statement:
        "<strong>If yes, please estimate how many days you spent in “highs” during the last twelve months:</strong>",
      description: "Taking all together: about how many days?",
      type: "number",
    },
  ],
};

export const homeSliderData = [
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
  {
    title: "GOG Personality & Matching App",
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block fw-700">
          We are perfecting the way you find & start relationships.
        </span>
        <br />
        <span className="d-inline-block mb-4">
          We make relationships <strong>Easier, Safer, More Reliable</strong>{" "}
          and <strong>More Fun!</strong>
        </span>

        <li className="fw-700">Watch the Video</li>
        <li className="fw-700">
          or Click Next Button to see the Onboarding Screens{" "}
        </li>
        <li className="fw-700">or click Jump to Login.</li>
      </span>
    ),
    imageUrl: HomeSliderImg1,
  },
];




export const slidesData = [
  {
    title: (
      <span>
        We Find your perfect
        <br className="d-none d-md-block" />
        <span> match based on</span>
        <br className="d-none d-md-block" />
        <span className="text-white mobile-title-text"> Personality</span>{" "}
        <span className="mobile-title-text"> Tests!</span>
      </span>
    ),
    description: (
      <span className="text-white">
        <span className="mb-1 mb-md-3 d-inline-block text-white">
          Swiping left & right is too random.
        </span>
        <br />
        <span>
          So, we use the same personality tests psychologists use to help you
          choose the right one the 1st time!
        </span>
      </span>
    ),
    divider: <hr className="divider" />,
    imageUrl: SliderImg7,
    mobileImageUrl: MobileSliderImg1,
  },
  {
    title: (
      <span>
        We make
        <br className="d-none d-md-block" />
        <span> relationships & </span>
        <br className="d-none d-md-block" />
        dating <span className="text-white mobile-title-text"> Safer!</span>
      </span>
    ),
    description: (
      <span>
        <span className="d-inline-block mb-0">
          <svg
            className="me-2"
            width="24"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
              fill="#fff"
            />
          </svg>
          We do background checks & more.
        </span>
        <br />
        <span className="d-inline-block mb-0">
          <svg
            className="me-2"
            width="24"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
              fill="#fff"
            />
          </svg>
          We organize public group dating events.
        </span>
        <br />
        <span>
          <svg
            className="me-2"
            width="24"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
              fill="#fff"
            />
          </svg>
          These and other steps make relationships{" "}
          <span className="text-dark fw-semibold mobile-title-text">
            Safer than ever!
          </span>
        </span>
      </span>
    ),
    divider: <hr className="divider" />,
    imageUrl: SliderImg2,
    mobileImageUrl: MobileSliderImg2,
  },
  {
    title: (
      <span>
        We make
        <br className="d-none d-md-block" />
        <span> relationships </span>
        <br className="d-none d-md-block" />
        more <span className="text-white mobile-title-text"> Fun!</span>
      </span>
    ),
    description: (
      <span>
        <span className="mb-1 mb-md-3 d-inline-block">
          Not only do we match you by{" "}
          <span className="text-dark mobile-title-text">personality</span>, we
          match you by your{" "}
          <span className="text-dar mobile-title-text">
            passions, interests & goals!
          </span>
        </span>
        <br />
        <span>
          Instead of random swiping left or right, you'll meet someone you know
          you can have <span className="text-dark mobile-title-text">fun</span>{" "}
          with.
        </span>
      </span>
    ),
    divider: <hr className="divider" />,
    imageUrl: SliderImg5,
    mobileImageUrl: MobileSliderImg3,
  },
  {
    title: (
      <span>
        We match your
        <br className="d-none d-md-block" />
        <span className="text-white mobile-title-text"> communication</span>
        <br className="d-none d-md-block" />
        <span className="mobile-title-text"> styles!</span>
      </span>
    ),
    description: (
      <span>
        <span className="mb-1 mb-md-3 d-inline-block">We match you on</span>
        <br />
        <li>the 4 communication styles</li>
        <li className="mb-1 mb-md-4">& the 5 love languages!</li>
        <span>
          Easy communication makes relationships{" "}
          <span className="text-dark mobile-title-text">Easier!</span>
        </span>
      </span>
    ),
    divider: <hr className="divider" />,
    imageUrl: SliderImg4,
    mobileImageUrl: MobileSliderImg4,
  },
  {
    title: (
      <span>
        Find your match
        <br className="d-none d-md-block" />
        <span> with matching</span>
        <br className="d-none d-md-block" />
        <span className="text-white mobile-title-text"> Jewelry!</span>
      </span>
    ),
    description: (
      <span>
        <span className="mb-1 mb-md-3 d-inline-block">
          After you finish our personality tests, you will get a colorful
          heart-shaped necklace.
        </span>
        <br />
        <span>Your match will have the same necklace color & shape.</span>
      </span>
    ),
    divider: <hr className="divider" />,
    imageUrl: SliderImg3,
    mobileImageUrl: MobileSliderImg5,
  },
  {
    title: (
      <span>
        Meet Your Match
        <br className="d-none d-md-block" />
        <span> at our </span>{" "}
        <span className="text-white mobile-title-text"> Public Group</span>
        <br className="d-none d-md-block" />
        <span className="text-white mobile-title-text"> Dating Events</span>
      </span>
    ),
    description: (
      <span>
        <span className="mb-1 mb-md-3 d-inline-block">
          You will meet around your favorite activities - at our meetings
          events.
        </span>
        <br />
        <span>
          Your match will have the same or complementary jewelry piece.
        </span>
      </span>
    ),
    divider: <hr className="divider" />,
    imageUrl: SliderImg2,
    mobileImageUrl: MobileSliderImg6,
  },
  {
    title: (
      <span>
        Watch our Meet
        <br className="d-none d-md-block" />
        up <span className="mobile-title-text text-white"> Events!</span>
      </span>
    ),
    description: (
      <span>
        <span className="mb-1 mb-md-3 d-inline-block">
          It’s like the TV Series “Love Is Blind”. But our scientifically proven
          matching strategies will help you choose the right one the first time!
        </span>
        <br />
        <span>Use this app to watch or participate.</span>
      </span>
    ),
    mobileImageUrl: MobileSliderBg,
    duelNestedSlides: [
      {
        imageUrl: GogExpDuelImg1,
      },
      {
        imageUrl: GogExpDuelImg2,
      },
      {
        imageUrl: GogExpDuelImg3,
      },
      {
        imageUrl: GogExpDuelImg4,
      },
      {
        imageUrl: GogExpDuelImg5,
      },
      {
        imageUrl: GogExpDuelImg6,
      },
      {
        imageUrl: GogExpDuelImg7,
      },
    ],
  },
  {
    title: (
      <span>
        Learn how our
        <br className="d-none d-md-block" />
        <span className="text-white"> Matching</span>
        <span className="mobile-title-text"> Works!</span>
      </span>
    ),
    description: (
      <span>
        <span className="mb-1 mb-md-3 d-inline-block">
          Cards with the same color represents one of the Big 5 personality
          traits.
        </span>
        <br />
        <span>
          Different cards have different icons that represents different things.
          You will learn more inside.
        </span>
      </span>
    ),
    mobileImageUrl: MobileSliderBg,
    nestedSlides: [
      {
        imageUrl: GogExpImg1,
        imageUrl2: GogExpImgSet2_1,
      },
      {
        imageUrl: GogExpImg2,
        imageUrl2: GogExpImgSet2_2,
      },
      {
        imageUrl: GogExpImg3,
        imageUrl2: GogExpImgSet2_3,
      },
      {
        imageUrl: GogExpImg4,
        imageUrl2: GogExpImgSet2_4,
      },
      {
        imageUrl: GogExpImg5,
        imageUrl2: GogExpImgSet2_5,
      },
      {
        imageUrl: GogExpImg6,
        imageUrl2: GogExpImgSet2_6,
      },
      {
        imageUrl: GogExpImg7,
        imageUrl2: GogExpImgSet2_7,
      },
      {
        imageUrl: GogExpImg8,
        imageUrl2: GogExpImgSet2_8,
      },
      {
        imageUrl: GogExpImg9,
        imageUrl2: GogExpImgSet2_9,
      },
      // Add more nested slides here
    ],
  },
  {
    title: (
      <span className="first-slider-title">
        Create your
        <br className="d-none d-md-block" />
        <span className="mobile-title-text"> Profile</span> in
        <br className="d-none d-md-block" />
        <span className="text-white"> ~7 easy steps</span>
      </span>
    ),
    description: "",
    imageUrl: SliderImg1,
    mobileImageUrl: MobileSliderImg1,
  },

  // Add more slides here
];
