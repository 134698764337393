import webm2  from '../assets/img/icons/webm/Action-Oriented.webm';
import webm3  from '../assets/img/icons/webm/Agreeablenesse.webm';
import webm4  from '../assets/img/icons/webm/conscientiousness.webm';
import webm5  from '../assets/img/icons/webm/Extraversion.webm';
import webm6  from '../assets/img/icons/webm/Gifts.webm';
import webm7  from '../assets/img/icons/webm/Intraversion.webm';
import webm10 from '../assets/img/icons/webm/Openness.webm';
import webm11 from '../assets/img/icons/webm/PeopleOriented.webm';

// delete this video no longer used import webm14 from '../assets/img/icons/webm/Touch.webm';


import iconResultsllTouch from '../assets/img/icons/webm/2 fingers.webm';
import iconWakandaCoins1 from '../assets/img/icons/webm/Coin Explosion 1.webm';
import iconWakandaCoins2 from '../assets/img/icons/webm/Coin Explosion 2.webm';
import iconWakandaCoins3 from '../assets/img/icons/webm/Coin Explosion 3.webm';

import iconResultsMNP1 from '../assets/img/icons/webm/peaceful beach icon [1].webm';
import iconResultsMNP2 from '../assets/img/icons/webm/peaceful beach icon [2].webm';
import iconResultsMNP3 from '../assets/img/icons/webm/peaceful beach icon [3].webm';
// use mnp1 1 and 2 for bipolar as well.
import iconResultsMNP0 from '../assets/img/icons/webm/peaceful beach icon.webm';
import iconResultsStars from '../assets/img/icons/webm/Stars.webm';
import iconResultsBB2 from '../assets/img/icons/webm/stormy beach icon.webm'; // for bp 2 

/* default button data is placed here because 
   I am using this data to produce the same buttons in multiple places.

helper/defaultButtonData.js 
export const buttonDataShort = [
    { 
        buttonName: 'Big 5',
        percentCompleted: 0,
        iconResults: webm5,
        iconAlt: 'high for extraversion',
        id: 'big5',
        navId: 1,
        view: '/admin/mypersonalitytest/Part1'
    },
    { 
        buttonName: 'Communication Style',
        percentCompleted: 0,
        iconResults: webm2,
        iconAlt: 'high for process',
        id: 'comm',
        navId: 4,
        view: '/admin/mypersonalitytest/Part4'
    },
    { 
        buttonName: 'Love Language',
        percentCompleted: 0,
        iconResults:iconResultsllTouch ,
        iconAlt: 'touch and time',
        id: 'lovelang',
        navId: 5,
        view: '/admin/mypersonalitytest/Part5'

    },
    { 
        buttonName: 'Moods 1',//mdq
        percentCompleted: 10,
        iconResults: webm4,
        iconAlt: 'Icon 2',
        id: 'mdq',
        navId: 6,
        view: '/admin/mypersonalitytest/Part6'
    },
    { 
        buttonName: 'Moods 2',//hcl hypo
        percentCompleted: 0,
        iconResults: webm3,
        iconAlt: 'Icon 1',
        id: 'bb',
        navId: 3,
        view: '/admin/mypersonalitytest/Part3'
    },
    { 
        buttonName: 'Temperament', // mnp
        percentCompleted: 0,
        iconResults: webm3,
        iconAlt: 'Icon 1',
        id: 'mnp',
        navId: 2,
        view: '/admin/mypersonalitytest/Part2'
    } 
];

A 9-element array holds the defaultButtonData 
export const defaultButtonData = [... ]
in file src/helpers/defaultButtonData.js

The defaultButtonData is used to create buttons on different pages 

components/PersonalityTest/PersonalityTestTab.js
components/ProfileTest/ProfileTest.jsx  
components/LetsUpgradeYou/LetsUpgradeYou.jsx
views/PersonalityTest.js

*/
// src/helpers/defaultButtonData.js
export const defaultButtonData = [
    { 
        buttonName: 'Big 5',
        percentCompleted: 0,
        iconResults: webm5,
        iconAlt: 'high for extraversion',
        id: 'big5',
        navId: 1,
        view: '/admin/mypersonalitytest/Part1',
        msgData: {
            title: 'Extraversion',
            msg: 'You are Extroverted.'
          }
    },
    { 
        buttonName: 'Communication Style',
        percentCompleted: 0,
        iconResults: webm2,
        iconAlt: 'high for process',
        id: 'comm',
        navId: 4,
        view: '/admin/mypersonalitytest/Part4',
        msgData: {
            title: 'Action-Oriented',
            msg: 'Your are primarily an Action-Oriented Communicator.'
          }
    },
    { 
        buttonName: 'Love Language',
        percentCompleted: 0,
        iconResults: iconResultsllTouch,
        iconAlt: 'touch and time',
        id: 'lovelang',
        navId: 5,
        view: '/admin/mypersonalitytest/Part5',
        msgData: {
            title: 'Touch',
            msg: 'Touch is how your show or recognize love.'
          }

    },
    { 
        buttonName: 'Moods 1', // mdq
        percentCompleted: 0,
        iconResults: iconResultsMNP0,
        iconAlt: 'Icon 2',
        id: 'mdq',
        navId: 6,
        view: '/admin/mypersonalitytest/Part6',
        msgData: {
            title: 'Mood',
            msg: 'Your mood is solid and stable.'
          }
    },
    { 
        buttonName: 'Moods 2', //hcl
        percentCompleted: 0,
        iconResults: iconResultsMNP0,
        iconAlt: 'Icon 1',
        id: 'bb',
        navId: 3,
        view: '/admin/mypersonalitytest/Part3',
        msgData: {
            title: 'Mood',
            msg: 'Your mood is solid and stable.'
          }
    },
    { 
        buttonName: 'Temperament',
        percentCompleted: 0,
        iconResults: iconResultsMNP0,
        iconAlt: 'Icon 1',
        id: 'mnp',
        navId: 2,
        view: '/admin/mypersonalitytest/Part2',
        msgData: {
            title: 'Temperament',
            msg: 'Your Temperament is chill mode.'
          }
    },
    { 
        buttonName: "LET\'S UPGRADE YOU",
        percentCompleted: 0,
        iconResults: iconResultsStars,
        iconAlt: 'Icon 2',
        id:'letsUpgradeYou',
        view: '/admin/LetsUpgradeYou',
        msgData: {
            title: 'Title here',
            msg: 'Message goes here'
          }
    },
    { 
        buttonName: 'Jewelry Color',
        percentCompleted: 0,
        iconResults: webm10, 
        iconAlt: 'Icon 2',
        id: 'jewerly',
        view: '/admin/user-profile#passions',
        msgData: {
            title: 'Title here',
            msg: 'Message goes here'
          }
    },
    { 
        buttonName: 'Matches',
        percentCompleted: 0,
        iconResults: webm11, 
        iconAlt: 'Icon 2',
        id: 'matches',
        view:'/admin/YouHaveMatches',
        msgData: {
            title: 'Title here',
            msg: 'Message goes here'
          }
    }
];