 
import webm7  from '../../assets/img/icons/webm/Intraversion.webm';
import firebase from '../../config/firebase';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BackButton from '../../components/BackButton/BackButton';
import gogPersonalityLogo from '../../assets/img/brand/logo.c784cb44bc36ebf9431febfa75db79c0.svg';

import './ProfileTest.css'; 
import wakdandaCoins from '../../assets/images/gogexperience_marvel_black_panther_coin_by_dj_9340cbb7-7ae7-4b55-82e8-215e103fe26d.png';

import ButtonCircleGraph from '../ButtonCircleGraph/ButtonCircleGraph';

import {Modal, ModalBody, Form, Input, FormGroup, Label, CustomInput, Button, Container, Col, Row } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import EmbeddedYoutubeVideo from '../../components/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo';
import imgDesktopOpen from '../../assets/images/DESKTOPUX-4.png';
import imgDesktopClosed from '../../assets/images/DESKTOPUX-3.png'; 
// import imgDesktopDefault from '../../assets/images/initial-image-gradient_01_notanydesigncollective.gif'; /* woman7.png';  */
import imgDesktopDefault from '../../assets/images/orange-swirl-giphy.webp';
import ButtonCircleGraphMini from '../../components/ButtonCircleGraph/ButtonCircleGraphMini';
import handleImageChange from '../../helpers/fileUploadUtil';
import SimplestFeedback from '../../components/SimplestFeedback/SimplestFeedback';
import webm5  from '../../assets/img/icons/webm/Intraversion.webm';
import { useImage } from '../../contexts/ImageContext';
 
import BadgesSection from '../../components/BadgesSection/BadgesSection';
import diamondJewelry from '../../assets/images/transparent-heart-shaped-diamond-.png';
import moment from 'moment';
import {saveReferrerToLocalStorage} from '../../helpers/referrerStorageUtils'; 
import {checkReferrerLocalStorage } from '../../helpers/referrerStorageUtils';
import {saveBetIdToLocalStorage } from '../../helpers/referrerStorageUtils';
import {checkBetIdInLocalStorage } from '../../helpers/referrerStorageUtils';
import { updateReferredByArrayInFirestore } from '../../helpers/firebaseHelpers';
import Footer from 'components/footer/Footer';
import {defaultButtonData as buttonData} from '../../helpers/defaultButtonData';
import { usePersonalityTestResults } from '../../helpers/usePersonalityTestResults';
import { defaultButtonData } from "../../helpers/defaultButtonData";
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';

const useViewportWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
  
	useEffect(() => {
	  const handleResize = () => {
		setWidth(window.innerWidth);
	  };
  
	  window.addEventListener('resize', handleResize);
  
	  // Clean up by removing the event listener when the component unmounts
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []); // An empty dependency array means this effect will run once on mount and clean up on unmount
	return width;
};

const getBackgroundStyle = (tabIndex) => {
    switch (tabIndex) {
        case 0:
        return 'linear-gradient(135deg, #fb6d26, #ff9e64)';
        case 1:
        return 'linear-gradient(135deg, rgb(251, 109, 38), rgb(255, 158, 100)'; // 'linear-gradient(135deg, #26fb6d, #64ff9e)';
        case 2:
        return 'linear-gradient(45deg, #fe590a, rgb(251, 109, 38))';
        case 3:
        return 'linear-gradient(45deg, #F44336, rgb(251, 109, 38))';
        default:
        return 'linear-gradient(135deg, #fb6d26, #ff9e64)';
    }
};

   const timeStringToSeconds = (timeString) => { 
        const duration = moment.duration(timeString);
        return duration.asSeconds();
    }; 


    /*
    
     const [image, setNewImage] = useState('');
    const [playerAnimationState, setPlayerAnimationState] = useState(animationIdle);

    const changePlayerAnimationState = (animationType) => {
        if (animationType==='IDLE') {
            setPlayerAnimationState(animationIdle);
        } else if (animationType==='JUMP1') {
            setPlayerAnimationState(animationJump);
        }
    };
*/
 /*
tooltip: 
to upload your picture follow these steps 

1. go to  adobe express background remover. 
2. upload your picture 
3. let it remove the background. 
4. download the png file 
5. then upload it to our site. 

    */


const getLongPersonalityTestName = (personalityTestId) => {
    const dictionary = {
        big5: "Big 5 Personality Test.",
        comm: "Communication Styles Test.",
        lovelang: "Love Language Test.",
        mdq: "Mood Test 1.", 
        bb: "Mood Test 2.",// using this one 
        mnp:"Temperament Check",
        letsUpgradeYou: "Let\'s upgrade you Activities."
    };
    return dictionary[personalityTestId];
}


/* This will listen for the first click event 
    anywhere on the document and start the video accordingly.
    After the video starts playing, it removes the event listener.
  */
const ProfileTestBet = () => {

    const footerRef = useRef(null);
 
    const { uid, user } = useSelector((state) => state.authUser);

    console.log('here is user =', user);
    
    const firstCheck = user?.firstName && user?.lastName ? user?.firstName +' '+ user?.lastName : null;
    
    const secondCheck = user?.onBoardingData?.profileInformation?.firstName  &&  user?.onBoardingData?.profileInformation?.lastName ? user?.onBoardingData?.profileInformation?.firstName  +' '+  user?.onBoardingData?.profileInformation?.lastName : null;
    
    const uNameFromDB = user?.name || firstCheck || user?.onBoardingData?.profileInformation?.nickName || secondCheck || 'Shaylah Lashay';
    
    const avatarData = {
        percentCompleted: 0, // overall completion. In the previous design, it was rendered. 
        userName: uNameFromDB,
        userGender: user?.onBoardingData?.gender,
        notificationIconData: 2,
        certificatesEarned:[{id:'test1', name:'test1name'}]
    };

    const gtag = window.gtag; 
  
    const history = useHistory();
/*
    const getImgFromLocalStorage = () => {
        const str = localStorage.getItem('onboardingData'); 
        try {
            if (str) 
                return  str ? JSON.parse(str)?.profileInformation?.img : null;
        } catch (error){
            console.error(error);
            return null;
        }
    };
    const  userIMGFromLocalStorage = getImgFromLocalStorage();
    

    const [userPathFromFirebaseCloud, setUserPathFromFirebaseCloud] = useState(null);

    const getImageUrl = async (uid, fileName) => {
        if (!uid || !fileName) {
            return null;
        }
        const storageRef = firebase.storage().ref("users");
        const fileRef = storageRef.child(`${uid}/${fileName}`);
        try {
          const url = await fileRef.getDownloadURL();
          setUserPathFromFirebaseCloud(url); 
         // console.log('here is the url =', url );
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
    };
 
    useEffect(() => {
        getImageUrl(uid, uid);
    },[]);
*/

 
    /**
     * code to handle image upload 
     * I received the error message "Upload Failed: setProfileInfo is not a function"
     */
    const [image, setImage] = useState(null);
    // in the context 
    const [profileInfo, setProfileInfo2] = useState({
        img:null,        //  previewURL,
        imageUrl: null,  //  fileUrl,
        imageFileName: null, // uid
    });
    
    const setProfileInfo = (newData) => {
        setProfileInfo2((prevProfileInfo) => ({
          ...prevProfileInfo,
          ...newData
        }));
      };
     
    const [imageUrL, setImageUrl] = useState(null);

    const { imageUrlIC, setImageUrlIC, imagesIC, setImagesIC, videosIC, setVideosIC, imageDataIC, setImageDataIC } = useImage();

    const reusableHandleImageChange = handleImageChange(setImage, setImageUrl, setProfileInfo, uid);
    // Create a ref to refer to the label element.
    const refToLabel = useRef();
 

   

    const vidWidth =  useViewportWidth() * 0.70;
 

    const handleOpenPersonalityTestEvent =  useCallback((id) => {
        //console.log('Button clicked! id=', id);
        let idFound = false; // to track if the ID was found.
        buttonData.forEach(function (val, index) {
            if (val.id===id) {
                idFound = true;
                history.push(val.view);
            }
        });

        if (!idFound) {
            const errMsg = `ID of a profile button click not found: ${id}`;
            // Log the message for debugging
            console.error(errMsg);
            // Send an event to Google Analytics
            gtag('event', 'exception', {
                'description': errMsg,
                'fatal': false
            });
        }
    }, []);


    /* code below pertains to this bet page */

    /* step 1 look for betId in the querystring. 
              use the betId fetch the picture and the data for this page. 

       step 2 also check for the referrer id if present. 
    
    Note: betId is completely separate than referrer id 

    Bets  Data store in firestore is :
        betId is stored in the 
        bets/<unique bet id>/{ 
                embeddedYoutubeVideo: 'https://youtu.be/UxNuYQsZ95U',
                embeddedVideoAspectRatio: '16:9',
                influencerName: 'Snow Black',
                influencerId:'guid', 
                personalityTestId:'big5', // the specific id in use 
                
                revealDate:'October 1, 2023 6pm EST',
                betID:'',
                buttonDataMini = {}
            }

            its field will have 
                userId: '', 
                createdDate:'',
                isVisible: false,
                isReveealed: true,
            
   referredby Id is in 
   users/<userid>/{ referredBy:[] } */
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
   // const placeHolderGif = '../placeholder.gif';

    const [betIdQueryString, setbetIdQueryString] = useState('');
   // const [imgDesktopOpen, setImageDesktopOpen] = useState(placeHolderGif);
    // betProperties from comes from bets/<unique betid>/
    const [betProperties, setBetProperties]  = useState({ 
        embeddedYoutubeVideo: 'https://youtu.be/UxNuYQsZ95U',
        embeddedVideoAspectRatio: '16:9',
        influencerImg:'',
        influencerName: 'Snow Black',
        influencerId:'', 
        personalityTestName:'big5',
        personalityTestNameLong:'Big 5',
        revealDate:'October 1, 2023 6pm EST',
        betId:'' 
    });

    const getbetIdParam =  urlParams.get('bet') ? urlParams.get('bet').trim(): null;
    const savedBetId =  checkBetIdInLocalStorage() ? checkBetIdInLocalStorage().trim() : null;
    /**
     *  this hook checks querystring for bet id upon mounting and decides 
        which betid to use */
    useEffect(() => {
       
       
        console.log('const getbetIdParam=', getbetIdParam, ' savedBetId=' ,savedBetId );
        if (!getbetIdParam && savedBetId){ // no betID querystring. Use the saved one. 
            setbetIdQueryString(savedBetId);
            console.log('if (!getbetIdParam && savedBetId){'); 
        } else if (getbetIdParam && !savedBetId) { // no saved betid. Use the bet querystringid
            setbetIdQueryString(getbetIdParam);
            saveBetIdToLocalStorage(getbetIdParam);
            console.log('} else if (getbetIdParam && !savedBetId) {'); 
        } else if (getbetIdParam && savedBetId) {
            console.log('} else if (getbetIdParam && savedBetId) {'); 
            console.log('getbetIdParam= ', getbetIdParam);
            console.log('savedBetId= ', savedBetId);
            if (getbetIdParam !== savedBetId) {//query string takes precedence
                saveBetIdToLocalStorage(getbetIdParam);
                setbetIdQueryString(getbetIdParam);
                console.log('if (getbetIdParam !== savedBetId) {'); 

            } else {
                saveBetIdToLocalStorage(getbetIdParam);
                setbetIdQueryString(getbetIdParam);
            }
        } else if (!getbetIdParam && !savedBetId) {
            alert('Please Double check the querystring. It should have bet=something to let us know who you are betting on. betIdQueryString is currently='+betIdQueryString+' using a defautl betid');
            // set a default ellie the empress 
            setbetIdQueryString('0cbc1cd7-a088-4081-ac80-65cdc40c556d');
            //nina 
           // setbetIdQueryString('1e3d06ee-394c-4dbd-9013-68fe7b16acd9';
        }
        // this is where I get the data to show eg., name, picture, personality test result that we are betting on.
        
        console.log('if (betIdQueryString && uid) {', betIdQueryString, 'uid=', uid); 
   
 
    }, [getbetIdParam, savedBetId, betIdQueryString, uid]);

    useEffect(() => {
        if (betIdQueryString) {

            console.log(' betIdQueryString=', betIdQueryString);
           
            getBetIdDataFromDataStore(betIdQueryString);
        }
        if  (uid) {
            getUsersBets(betIdQueryString, uid);
        }
      }, [betIdQueryString]);
      
    

    /* this hook tracks who referred the user by comparing
    - local storage 
    - with query string
    - with database value if any.
    I will have to turn this into a reusable function
    because this is one of several entry points to the app */ 
   
    const [referredby, setReferredBy ] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const ref = urlParams.get('ref'); 
        setReferredBy(ref);
       
        let referrerData = checkReferrerLocalStorage();
    
        if (!referrerData || !Array.isArray(referrerData)) {
          referrerData = [];
        }

    // user does not have to be logged in to have 
    // the referring id saved in their local storage 
    // therefore this is not in a check for value of of user & uid 
    if (referrerData.length < 3 && !referrerData.includes(ref)) {
        referrerData.push(ref);
        saveReferrerToLocalStorage(referrerData);
    }
  
    if (user && uid && ref) {
 
        if (user && user?.referredBy && Array.isArray(user?.referredBy)) {
        const userReferredByArray = user?.referredBy;
        
        // Combine arrays and remove duplicates
        const newReferredByArray = Array.from(new Set([...userReferredByArray, ...referrerData]));
        
        // Check if update is needed
        if (newReferredByArray.length !== userReferredByArray.length) {
          // Update the referredBy array in Firebase.
          updateReferredByArrayInFirestore( uid, newReferredByArray);
          // Replace this with your actual Firebase code
          // firebase('users').doc(uid).update({referredBy: newReferredByArray});
        }
      } else if (user && uid) {
        // If user exists but has no referredBy field yet, update the database
        // Replace this with your actual Firebase code
        updateReferredByArrayInFirestore( uid, referrerData);
        // firebase('users').doc(uid).update({referredBy: referrerData});
      }
    }
  }, [location, user, uid]);

    useEffect(() => {
        // for testing 
        window.ProfileTestBetId = betIdQueryString;
        window.ProfileTestRefId = referredby;
    }, []);

    const [buttonDataMini, setButtonDataMini] = useState(
        { 
           buttonName: 'Big 5',
           percentCompleted: 0,
           iconResults: webm5,
           iconAlt: 'high for extraversion',
           id: 'big5',
           navId: 1,
           view: '/admin/mypersonalitytest/Part1'
        } 
    ); 

    const [alreadyBet, setalreadyBet]= useState(false);
   
    const [usersBets, setUserBets] = useState({
            betId:'',
            betOn:'', 
            betAmount:0,
            wonOrLost:'', 
            createdDate:'',
            revealDate:'', 
            influencerName:'',
            currentBetBalance: '10'
    });

    useEffect(()=>{
        console.log('usersBets====',usersBets);
    },[usersBets])
 
    /**
       check to see if the user betted already. 
       the database structure is  
       usersBets/<userid>/bets2/<betId>/{betId:'', betAmount:'', wonOrLost:'', createdDate:'', revealDate:'', influencerName:'', userId:'' }
       the field for the userid can be total currentBetBalance: ''
    */
    const getUsersBets = async (betId_, uid) => {
        if (!betIdQueryString || !uid) {
            // do not fetch from database if !betId || !uid
            return false;
        }
      try { 
        // usersPastBets/<uid>/bets2/<betID>
         const userDocRef = firebase.firestore().collection('usersPastBets').doc(uid);

        // Fetch user document
        const userDoc = await userDocRef.get();
        
        // This is where you get currentBetBalance field
       const currentBetBalance = userDoc.get("currentBetBalance");
        
        // Fetch specific bet
        const betDocRef = userDocRef.collection('bets2').doc(betId_);
        const betDoc = await betDocRef.get();
        
        if (betDoc.exists) {
            const betData = betDoc.data();
            setUserBets({ 
                betOn: betData.betOn ?? null,
                betId: betData.betId ?? null, 
                betAmount: betData.betAmount ?? null,  
                wonOrLost: betData.wonOrLost ?? null, 
                createdDate: betData.createdDate ?? null, 
                revealDate:  betData.revealDate ?? null, 
                influencerName: betData.influencerName ?? null,
                userId: uid,
                currentBetBalance: currentBetBalance // GET not SET use the field here
            });
            setalreadyBet(true);
        } else { 
            setalreadyBet(false);
        }
    } catch (err){ 
        console.error(`Error: ${err}`)
    }      
};
    
    
    const [amount, setAmount] = useState(0);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!user.id || !uid) {
          alert('Please Sign in to place your bet. Scroll down & login. After you sign in, you will be brought back here');
          return;
        }

        if (alreadyBet) {
            alert('You already made bet on '+ betProperties.influencerName +'\'s '+ betProperties.personalityTestNameLong + ' ' + usersBets.betOn +' for ' +  usersBets.betAmount +' wakanda coins.');
            return false;
        }
  
  console.log('usersBets= before save', usersBets);

        saveUsersBetToDataStore(usersBets);
        
        /* 
        referer id needs to be saved when they create the account 
        link to bet most come after sign in 
        bet must be saved some place and reflected.
        */ 
        console.log('in const handleSubmit with ', usersBets);
        // console.log(`Entered number: ${number}`);
        // Perform any actions, such as submitting data to your server, etc.
    };

    const [betSuccessful, setBetSuccessful] = useState(false);
    
    /**
     * expecting 
     * {
            betId:'',
            betOn:'', 
            betAmount:'',
            wonOrLost:'', 
            revealDate:'', 
            influencerName:'',
    }
     */
    const saveUsersBetToDataStore = ({betId, betOn, betAmount,revealDate, influencerName }) => {
        console.log('saveUsersBetFromDataStore =', betId, betAmount);
        firebase
            .firestore()
            .collection('usersPastBets')
            .doc(uid)
            .collection('bets2')
            .doc(betId)
            .set({
                betId: betId, 
                betOn: betOn,// eg., personality test results
                betAmount: betAmount,  
                createdDate: Date.now(),
                revealDate: revealDate, 
                influencerName: influencerName, 
                userId:uid
            }).then(rez => {
                setBetSuccessful(true);
               
               // console.log('you successfully placed your bet' );
            }).catch(err => {
                console.error(`Error: ${err}`);
            });
            /*  no longer used because currentBetBalance ends.
            will be set by trigger when the winners are announced.
            firebase.firestore().collection('usersPastBets').doc(uid).update({
                currentBetBalance: newBalance,
              }).catch(err => {
                alert(`Error: ${err}`);
            });   
            */
    };

    const testOptions = {
        big5: [
                'O - Openness',
                'C - Conscientiousness',
                'E - Extroverted',
                'A - Agreeable',
                'N - Neurotic',
                'I - Introverted',
                'M - Mixed'
            ],
        comm: [
                'Process Oriented Communicator',
                'People Oriented Communicator',
                'Action-Oriented Commuunicator',
                'Visionary/Idea Communicator'
            ],
        mdq: [
                'Moody',
                'Not Moody'
            ],
        bb: [
                'Moody',
                'Not Moody'
            ],
        loveLang: [
                'Touch/Physical Affecton',
                "Words of Affirmation",
                'Acts of Services',
                'QualityTime'
            ],
    };

    const getBetIdDataFromDataStore = (betId_) => {
        console.log('getBetIdDataFromDataStore = (betId_)=', betId_);

        firebase
            .firestore()
            .collection('bets')
            .doc(betId_)
            .get()
                .then(r => {
                    if ( r?.data()) {
                    const res = r.data();

                    const personalityTestId = res?.personalityTestName || 'big5'; 

                    setBetProperties({ 
                        embeddedYoutubeVideo: res?.embeddedYoutubeVideo || 'https://youtu.be/UxNuYQsZ95U',
                        embeddedVideoAspectRatio: res?.embeddedVideoAspectRatio || '16:9',
                        influencerImg: res?.influencerImg || '', // './woman7.png',
                        influencerName: res?.influencerName ||  'Snow Black',
                        influencerId: res?.influencerId || null,  
                        influencerReferrId: res?.influencerReferrId || null, 
                        personalityTestName: personalityTestId,
                        personalityTestNameLong: getLongPersonalityTestName(res?.personalityTestName),
                        revealDate: res?.revealDate || 'October 15, 2023 6pm EST',
                        betId: betId_
                    });
                    
                    setUserBets(prevState => ({
                        ...prevState,
                        betId: betId_,
                        revealDate: res?.revealDate,
                        influencerName: res?.influencerName
                    }))  

                     



                    const foundButtonData = defaultButtonData.find(obj => obj.id === personalityTestId ); // make sure it's betId, not betID
                    
                    if (foundButtonData) {
                        console.log('foundButtonData=', foundButtonData);
                        setButtonDataMini(foundButtonData);
                        /* expecting foundButtonData to be object with the following structure
                        { 
                            buttonName: 'Big 5',
                            percentCompleted: 0,
                            iconResults: webm5,
                            iconAlt: 'high for extraversion',
                            id: 'big5',
                            navId: 1,
                            view: '/admin/mypersonalitytest/Part1'
                        } 
                        */
                    }
                } else {
                    alert('Could not find a bet for betId = '+ betId_ +' Check query string id again');      
                }
                })
                .catch(err => {
                    console.error('Error', err);
                });
    };

    const [ posOrNeg, setPosOrNeg] = useState({  prefix: '+',  style:'green' });
        
    useEffect(() => {
    const positiveNumber = { prefix: '+', style:'green' };
    const negativeNumber = { prefix: '-', style:'darkred' };
    if (usersBets.currentBetBalance >= 0) {
        setPosOrNeg(positiveNumber);
    } else {
        setPosOrNeg(negativeNumber);
    }

    }, [usersBets.currentBetBalance])
       

    const [showMoreToBetOn, toggleShowMoreToBetOn] = useState(false);

    const handleShowMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleShowMoreToBetOn(!showMoreToBetOn);
    };
 
    const [moreBets, setMoreBets] = useState([]);
    /** 
    * Expecting getNewPeopleToBetOn to set bets/<betsId> with fields {
            embeddedVideoAspectRatio:'16:9',
            embeddedYoutubeVideo: '',
            influencerImg: '',
            wonOrLoss:'', 
            personalityTestName:'',
            revealDate:''
            isActive:1/0, 
        } 
    */  
    const getNewPeopleToBetOn = async () => {
        try { 
            const querySnapshot = await firebase.firestore().collection('bets').where('isActive','==','y').limit(20).get();
            const morebets = [];
            querySnapshot.forEach(doc => {
                morebets.push(doc.data());  
                // Assuming you want the document data to be pushed into the array
              });

              setMoreBets(morebets);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
        
    };
    
    useEffect(() => {
        getNewPeopleToBetOn();
    }, []);

// end of bet related code. 
 

    const buttonRefs = useRef([]); // Create a ref to store the button references

    const saveButtonRef = (ref, index) => { 
        buttonRefs.current[index] = ref;
       // console.log('saveButtonRef  buttonRefs', buttonRefs );
    };

   /**
     requestedInfluencers = [{ig:'', twt:''}]
   
    const [requestedInfluencers, setRequestedInfluencers] = useState([]);
    const [suggestUserIg, setSuggestUserIg] = useState('');
    const [suggestUserX, setSuggestUserX] = useState('');
    const handleSuggestInfluencer = (e) => {
        e.preventDefault();
        setRequestedInfluencers(prev => [...prev, {ig: suggestUserIg, twt: suggestUserX}]);
        saveSuggestedUsertoDataStore(suggestUserIg, suggestUserX);
    };
    const saveSuggestedUsertoDataStore = (suggestedIg, suggstedX) => {
        console.log('saveSuggestedUsertoDataStore  not yet implemented');
       // firebase.firestore.collection('suggestedUsers').doc(uid)'where','==',)
    };

     */

    return (
    <React.Fragment>
        <div className="profile2-container">
            <Link className='u-img--logo-left'  to='/admin/home'><img className='u-img--logo-left' src={gogPersonalityLogo} alt='gogPersonality.com logo' /></Link> 
            
            <BackButton/>
            <div className='new-avatar'>
               {/*   <img className='new-avatar-img-top' src={imgDesktopOpen} /> */}
               
<div className={`new-avatar-userImg-containerbg ${!(betProperties.influencerImg) ? 'is-visible' : ''}`}>
    <img src={ imgDesktopDefault } className='new-avatar-userImg'   />
</div> 
               
               
                <div className='new-avatar-userImg-container'>
                   {/*  <img src={ profileInfo?.img  || profileInfo?.imageUrl || userPathFromFirebaseCloud ||  imgDesktopDefault } */}
                      
                      <img src={betProperties.influencerImg   }
                       className='new-avatar-userImg'
                        id='u-js-userImg' />
                </div>
                <img className='new-avatar-img-bottom' src={imgDesktopClosed}  alt='background image to make the profile page look pretty. It expects your picture to be a transparent png. go to adobe express to remove your profile pictures background for the best look on this page.'  />
                <div className='new-avatar-bg-gradient'></div>
            </div>
            <div className="new-avatar-container-inner">
               
            </div>

        <div className="button-section"> 
        <div className="name-description"><p>Bet on {betProperties.influencerName}</p></div> 
            <div className='px-3 mt-2 text-white' > 
                  
                    <br/><p className='text-white mb-2'><b>{betProperties.influencerName} </b> is going to take our<br/> <b>{betProperties?.personalityTestNameLong}</b></p> 

                   { buttonDataMini && [buttonDataMini].map((button, index) => (
                    <React.Fragment key={index}>      
                        <ButtonCircleGraphMini
                        key ={index}
                            id={button.id}
                            percentCompleted= {button.percentCompleted}
                            buttonName={button.buttonName}
                            msgData={button.msgData}
                             
                            iconResults={button.iconResults}
                            iconAlt={button.iconAlt}
                            onclick={()=>handleOpenPersonalityTestEvent(button.id)}
                            ref={(ref) => saveButtonRef(ref, index)}
                            classname = {'ptest-default'}         
                        />
                        {(button.buttonName==='Jewelry Color') && (
                            <img src={diamondJewelry} className="button-section--heartJewelry rotateY360deg mx-auto d-block img-fluid" id='buttonjs--section--heartJewelry' /> 
                        )}
                    </React.Fragment>
                ))}
                    <p className='text-white'>What do you think she will score the highest in? <br/>
                    <br/>Choose one. </p>
                </div>  
     
    <Row className='mx-4 text-white'>  

        <Form onSubmit={handleSubmit}>
        <FormGroup tag="fieldset" className='text-white'>
            {testOptions[buttonDataMini.id]?.map((value, index) => (
            <FormGroup check className='text-white' key={index}>
                <Label check className='text-white'>
                <Input
                    type="radio"
                    name="item"
                    value={value}
                    checked={value === usersBets.betOn}
                    onChange= { e => {
                        setUserBets(prevState => ({
                            ...prevState,
                            betOn: e.target.value
                        }))  
                    }}
                    
              
               />
                {value}
                </Label>
            </FormGroup>
            ))}
        </FormGroup>
        <FormGroup className='text-white'>
            <Label for="numberInput" className='text-white'>
            Make a bet - from 1 to 10 Wakanda coins.<br/>
            <span className='wakandacoins-inline'><img src={wakdandaCoins} className='wakandacoins-inline--img' /> </span><br/> 
            (You can earn more by doing our personality tests too).<br/>
            You have <span style={{color: `${posOrNeg.style}`}}><b>{posOrNeg.prefix}{ usersBets.currentBetBalance }</b></span> wakanda coins.</Label>

    

            <div className='d-flex'>
            <Input
                type="number"
                name="number"
                id="numberInput"
                min="0"
                max="100"
                style={{ width: "90px" }}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                disabled={alreadyBet}
            />
            <Button type="submit" disabled={alreadyBet}>Submit</Button>
            
            </div>
            <div className={`successful-notification ${betSuccessful || alreadyBet ? 'is-visible' : ''}`}>Successfully placed your bet. <br/>Personality is everything. come back soon.</div>
        </FormGroup>
        </Form>
        
        { usersBets.wonOrLost && (
        <div className={`wonOrLost-notification ${usersBets.wonOrLost === 'W' ? 'is-won' : 'is-loss'}`}>
            {usersBets.wonOrLost === 'W' ? 'You Won This Bet' : 'You lost this bet. Watch her video to see what it was.'}
        </div> 
        )}

    </Row>
    <Row className='d-flex justify-content-center'>
        <Col xs={12}>    
            <p className='px-3 mt-4 text-white'> <b>Check out <b>{betProperties.influencerName}'s</b> video. </b> <br/>
                <b>{betProperties.influencerName}</b> will do her test LIVE on<br/>
                <b> {betProperties.revealDate}.</b> <br/>
                 
                {/* <br/>* Only the people who bet will see her results here. */}
               
            </p>
         </Col>
    </Row>
    </div>    
        <div className="mt-4 d-flex justify-content-center">
            <EmbeddedYoutubeVideo 
                path={betProperties.embeddedYoutubeVideo} 
                width = {vidWidth}
                aspectRatio={betProperties.embeddedVideoAspectRatio} />
        </div>
{/*}
        <Link onClick={handleShowMore} className='morebets__header'>Show More People to Bet on!</Link>
    {showMoreToBetOn && moreBets.length > 0 && ( 
        <React.Fragment> 
            
            <div className="morebets  row">
                { moreBets.map((value, index) => (
                    <div className='morebets__link-wrap col-6 col-md-4 col-lg-3'> 
                    <Link key={index} 
                        to={`Bet?bet=${value.betId ?? ''}`}
                        className='morebets__link'>
                        <img src={value.influencerImg} alt={`picture of ${value.influencerName}`} className='morebets__img'/>
                    </Link>
                    <p className='morebets__uname'>{value.influencerName}</p> 
                    </div> 
                ))}
            </div>
            <FriendlyErrMsgBox/> 
        </React.Fragment> 
    )}

                */}
   
        <div className='u-profile-icon--wrapper'>
            <BadgesSection user= { user } />
            <Container className='profile-bet'>
              



                <Footer ref={ footerRef } />
                <div className='profilepg profilebet'> 
 
{/*}
                <Form onSubmit={handleSuggestInfluencer}>
                    <p>Who is your favorite influencer or person that you would like to see featured here?</p>
                    <p>Enter the persons's IG or X/tTwitter handle.</p> 
                    <p>and we'll ask them and let you know when they are here.</p>
                    <Input type='text' placeholder='@igHandle' onChange={(e)=>setSuggestUserIg(e.target.value)}></Input>
                    <Input type='text' placeholder='@twitterHandle'  onChange={(e) =>setSuggestUserX(e.target.value)}></Input>
                    <Button type='submit'>SEND</Button>
                    <div>
                    <p>You Suggested </p> 
                    <ul>
                        { Array.isArray(requestedInfluencers) ? (
                            requestedInfluencers.map((val, index) => {
                               <li key={index}>
                                    <b>IG:</b> {val.ig}<br/>
                                    <b>X/Twitter:</b> {val.twt}
                                </li>
                            })
                        ) : null}
                    </ul>
                    </div>

                </Form> */}
                
                <SimplestFeedback user= { user } /> 
                </div> 
            </Container>  
        </div>
    </div>
    </React.Fragment>
    );
  };
export default ProfileTestBet;
 
/*
const UserProfileSummary = (user) => {


    return (
        <React.Fragment>
        <div className='u-tbl--wrapper'>
        <h3 className='font-weight-bold text-center text-white'>Now that you are matched up!</h3>
        <h3 className='font-weight-bold text-center text-white'>The Next Step is .... under construction. coming soon.!</h3>
            <p className='font-weight-bold text-center text-white'>Your Profile Summary</p>
                <table className='u-tbl'>
                <tbody>
                <tr><td>Your passions/Interests</td><td></td></tr>
                <tr><td>Your Gender</td><td></td></tr>
                <tr><td>Gender your are interested</td><td></td></tr>
                <tr><td>Age Range you are interested in</td><td></td></tr>
                <tr><td>Height preference</td><td></td></tr>
                <tr><td>Type you are</td><td></td></tr>
                <tr><td>Type you are interested in </td><td></td></tr>
                <tr><td>talents you would like to give to your children</td><td></td></tr>
                <tr><td>relationship goals</td><td></td></tr>
                <tr><td>match making location</td><td></td></tr>
                <tr><td>Type you are interested in </td><td></td></tr>
                <tr><td>talents you would like to give to your children</td><td></td></tr>
                
                <tr><td>dream location to live</td><td></td></tr>
                <tr><td>Nick name</td><td></td></tr>
                <tr><td>Age</td><td></td></tr>
                <tr><td>Youtube Video</td><td></td></tr> 
                </tbody>
            </table>
            </div>
        </React.Fragment>
    );
}
*/