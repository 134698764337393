import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "reactstrap";  // If you are using Row, make sure to import it
import { userFriendlyStateErrMsg } from "../../store/actions/authActions"; // Import your action
import "./FriendlyErrMsgBox.css";

const FriendlyErrMsgBox = () => {
  const dispatch = useDispatch();  // Define dispatch function
  const { userFriendlyMsg, loading, loginLoading } = useSelector(state => state.auth);

  return (
    <Row className='FriendlyErrMsgBox'>
      {userFriendlyMsg ? (
        <div>
          <p>{userFriendlyMsg}</p>
          <Button 
            type="button" 
            onClick={() => {
              // Dispatch an action to clear the userFriendlyMsg state
              dispatch(userFriendlyStateErrMsg(''));
            }}
          >
            Close
          </Button>
        </div>
      ) : null}
    </Row>
  );
};

export default FriendlyErrMsgBox;
