import { part2Questions } from "helpers/GlobalConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getQuestions } from "store/actions/QuestionsAction";
import { addSessions } from "store/actions/QuestionsAction";
import { validateLocalStorageData } from "../../helpers/validateJson";
import { updateButtonPercent } from 'store/actions/actions.js';
import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';
import VisualRewards from "../../components/VisualRewards/VisualRewards";

const PersonalityTestPart2 = ({ activeTab }) => {

  const { uid } = useSelector((state) => state.authUser);
  useEffect(() => {
    if (uid) {
      dispatch(getQuestions(uid, "part2"));
    }
  }, []);

  const { questions } = useSelector((state) => state.questions);
  const [scoreArray, setScoreArray] = useState(part2Questions);
  const [showResult, setShowResult] = useState(false);
  const [scoreAfterSummarize, setScoreAfterSummarize] = useState({
    summary: {
      mainTrait: 'NA',
      secondaryTrait: 'NA',
      mainNegativeTrait: 'NA',
      secondaryNegativeTrait: 'NA',
      percentCompleted: 0,
    }
  });
  useEffect(() => {
    if (Array.isArray(questions?.part2) && questions?.part2[0]?.finalizedValue) {
      setScoreArray(JSON.parse(JSON.stringify(questions?.part2)));
    }
  }, [questions?.part2]);

  const [triggerCalculate, setTriggerCalculate] = useState(null);
 /* let [scoreArray, setScoreArray] = useState(
    JSON.parse(localStorage.getItem("part2")) ||
      JSON.parse(
        JSON.stringify(
          part2Questions.map((part2Question, idx) => ({
            cat: part2Question.cat,
            value: "",
            finalizedValue: "",
          }))
        )
      )
  );
*/



   useEffect(() => {
    const localData = localStorage.getItem("part2") ?  JSON.parse(localStorage.getItem("part2")) : null;
    const validatedData = validateLocalStorageData(localData, part2Questions);
    setScoreArray(validatedData);
  }, []);


  useEffect(() => {
     // part 1 save it 
    localStorage.setItem("part2", JSON.stringify(scoreArray));
    // part 2 check if it is complete 
    // const newState = calculateNewStateForTest2(scoreArray, e, ques, index);
    // setScoreArray(newState);
  
    // Now you can safely call updatePercentageChange with the newState
    const pctCompleted = updatePercentageChange(scoreArray);
   
    console.log('scoreArray=', scoreArray);
    console.log('getPercentageCompleted =', pctCompleted);
   
    if (pctCompleted===100) { 
      const {obj, summaryReport} =  setUpScoreObject();
    
      setScoreAfterSummarize(summaryReport); 
      setShowResult(true);
    }
  }, [scoreArray]);

 
 

 

  const testCase = (trait) => {
    if (!["MACH", "NARC", "PSYCH"].includes(trait)) {
      throw new Error("Trait must be one of ['MACH', 'NARC', 'PSYCH']");
    }
    let temp = JSON.parse(
      JSON.stringify(
        part2Questions.map((part2Question, idx) => ({
          cat: part2Question.cat,
          value: "",
          finalizedValue: "",
        }))
      )
    );
    let idx = 0;
    for (let part2Question of part2Questions) {
      if (part2Question.cat === trait) {
        if (part2Question.isReverseCat) {
          temp[idx].value = 1;
          temp[idx].finalizedValue = 5;
        } else {
          temp[idx].value = 5;
          temp[idx].finalizedValue = 5;
        }
      } else {
        if (part2Question.isReverseCat) {
          temp[idx].value = 5;
          temp[idx].finalizedValue = 1;
        } else {
          temp[idx].value = 1;
          temp[idx].finalizedValue = 1;
        }
      }
      idx++;
    }
    setScoreArray(temp);
    console.log("response:", temp);
    setTriggerCalculate((prevState) =>
      prevState === null ? false : !prevState
    );
    return `Part 2 - Calculation Test for '${trait}'`;
  };

  const dispatch = useDispatch();
  const [MACH, setMACH] = useState(0);
  const [NARC, setNARC] = useState(0);
  const [PSYCH, setPSYCH] = useState(0);
  const [MACHAvg, setMACHAvg] = useState(0);
  const [NARCAvg, setNARCAvg] = useState(0);
  const [PSYCHAvg, setPSYCHAvg] = useState(0);
  let options = [
    "strongly disagree",
    "disagree",
    "neutral",
    "agree",
    "strongly agree",
  ];
  // https://www.psytoolkit.org/survey-library/short-dark-triad.html
  var dTriadMeansOfUser = {
    MACH: { mean: 3.1, sd: 0.76, alpha: 0.78, aboveTheNormalRange: 3.86 },
    NARC: { mean: 2.8, sd: 0.88, alpha: 0.77, aboveTheNormalRange: 3.68 },
    PSYCH: { mean: 2.4, sd: 1.0, alpha: 0.8, aboveTheNormalRange: 3.4 },
  };

  /*
  z score  for mach calculation 
  assume the user scored a mach mean of 3.5

  z-score would be calculated as   3.5 - 3.1 / 0.76 = +.52   

  Therefore the user is .52 standard devisions above the mean. 
  is that correct?
  */
  const updatePercentageChange = (newValues) => {
    let numAnswered = 0;
    const NUMBER_OF_QUESTIONS = 27;
    console.log('updatePercentageChange mnp = (newValues) with', newValues);
    for (let i = 0, ilen = newValues.length; i<ilen; i++) {
      if (newValues[i].value !=='') {
        ++numAnswered;
      }
    }
    const newPercent = Math.round((numAnswered/NUMBER_OF_QUESTIONS) * 100);
    dispatch(updateButtonPercent('mnp', newPercent));
    return newPercent;
  };

  const calculateNewStateForTest2 = (prevState, e, ques, index) => {
    let finalizedValue = Number(e.target.value);
    let value = Number(e.target.value);
    if (ques.isReverseCat) {
      finalizedValue = options.length + 1 - value;
    }
    prevState[index] = {
      ...prevState[index],
      value,
      finalizedValue,
    };
    return [...prevState];
  };
  
  const handleChange = (e, ques, index) => {
    const newState = calculateNewStateForTest2(scoreArray, e, ques, index);
    setScoreArray(newState);
  
    // Now you can safely call updatePercentageChange with the newState
    updatePercentageChange(newState);
  };
  



  const history = useHistory();
  /*
     (obj.MACHAvg - dTriadMeansOfUser.MACH.mean)/dTriadMeansOfUser.MACH.sd,
     (obj.NARCAvg - dTriadMeansOfUser.NARC.mean)/dTriadMeansOfUser.NARC.sd;  
     (obj.PSYCHAvg - dTriadMeansOfUser.PSYCH.mean)/dTriadMeansOfUser.PSYCH.sd;
     */
    
   const setUpScoreObject = () => {

    let obj = {
      MACH: 0,
      NARC: 0,
      PSYCH: 0,
      MACHAvg: 0,
      NARCAvg: 0,
      PSYCHAvg: 0
    };

    for (let score of scoreArray) {
      obj[score.cat] += score.finalizedValue;
    }
    setMACH(obj["MACH"]);
    setNARC(obj["NARC"]);
    setPSYCH(obj["PSYCH"]);
    setMACHAvg(obj["MACH"] / 9);
    setNARCAvg(obj["NARC"] / 9);
    setPSYCHAvg(obj["PSYCH"] / 9);

    obj.MACHAvg  = Math.round( obj["MACH"] / 9);
    obj.NARCAvg  = Math.round( obj["NARC"] / 9);
    obj.PSYCHAvg = Math.round( obj["PSYCH"] / 9);


    let zScores = {
      zscoreMACH : (obj.MACHAvg - dTriadMeansOfUser.MACH.mean)/dTriadMeansOfUser.MACH.sd,
      zscoreNARC : (obj.NARCAvg - dTriadMeansOfUser.NARC.mean)/dTriadMeansOfUser.NARC.sd,
      zscorePSYCH: (obj.PSYCHAvg - dTriadMeansOfUser.PSYCH.mean)/dTriadMeansOfUser.PSYCH.sd
    };

    // Convert the zScores object into an array of [key, value] pairs
    let zScoresArray = Object.entries(zScores);

    // Sort the array based on the z-score values (i.e., the second element of each pair)
    zScoresArray.sort((a, b) => b[1] - a[1]);

    // Initialize an empty array to store keys with z-scores greater than 1.5
    let aboveThreshold = [];

    // Loop through the sorted array and push keys where the value is greater than 1.5 to aboveThreshold
    for (const [key, value] of zScoresArray) {
      if (value > 1.5) {
        aboveThreshold.push(key);
      }
    }

    // The sorted zScoresArray now has the z-scores sorted in descending order
    // The aboveThreshold array contains keys with z-scores greater than 1.5

    // To find the highest z-score:
    const highestZScore = zScoresArray[0];  // This gives you the pair with the highest z-score
    const highestZScoreTrait = highestZScore[0];  // This gives you the trait with the highest z-score

    console.log(`The trait with the highest z-score is ${highestZScoreTrait} with a z-score of ${highestZScore[1]}`);
    console.log(`Traits with z-scores above 1.5 are: ${aboveThreshold.join(", ")}`);

  

    const summaryReport = {
      ...zScores,
      summary: {
        aboveThreshold: aboveThreshold.length>0 ? aboveThreshold.join(","):'clear',
        mainTrait: aboveThreshold.length>0 ? aboveThreshold.sort().join(',') : 'clear',
        secondaryTrait: '',
        mainNegativeTrait: '',
        secondaryNegativeTrait: '',
        percentCompleted: 100 // Mat
      
      }
    };
    
    return {
      obj, 
      summaryReport
    };
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   const {obj, summaryReport} =  setUpScoreObject();
    
    setScoreAfterSummarize(summaryReport);
    if (uid && obj) {
      console.log('score part2 = ', summaryReport);
      dispatch(addSessions(scoreArray, "part2", uid, summaryReport));
    } else {
      alert('you need to be logged in save personality tests. Please log in this information will be here when you get back.')
      history.push("/admin/home");
    }
  };



  useEffect(() => {
    if (triggerCalculate !== null) handleSubmit({ preventDefault() {} });
  }, [triggerCalculate]);

  useEffect(() => {
    window.TestCase = testCase;
  }, []);


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="personality-test-part">
          <Col md="12 py-4">
            <div>
              <h2 className='test-title'>M/N Traits</h2>
            </div>
            <div>
              <div>
                <Row className="px-lg-4 px-md-4 px-0">
                  <Col xs="12" md="12">
                    {part2Questions?.map((ques, index) => {
                      return (
                        <div className="mb-4" key={index}>
                          <Label>{ques.label}</Label>
                          <div className="mt-1">
                            <Input
                              type="select"
                              value={scoreArray[index]?.value}
                              className="personality-test-part__input-field"
                              onChange={(e) => handleChange(e, ques, index)}
                            >
                              <option hidden value="">
                                Rate
                              </option>
                              {options.map((option, idx) => (
                                <option value={idx + 1} key={idx}>
                                  {idx + 1}={option}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button className='personality_see_and_save_btn' type="submit">Save & See Result</Button>
        </div>
        <div className="mt-4 rounded overflow-hidden">
          <div
            className={`d-flex p-2 border text-white ${
              MACHAvg > dTriadMeansOfUser.MACH.aboveTheNormalRange
                ? "bg-danger"
                : "bg-success"
            }`}
          >
            M: {MACH}
          </div>
          <div
            className={`d-flex p-2 border text-white ${
              NARCAvg > dTriadMeansOfUser.NARC.aboveTheNormalRange
                ? "bg-danger"
                : "bg-success"
            }`}
          >
            N: {NARC}
          </div>
          <div
            className={`d-flex p-2 border text-white ${
              PSYCHAvg > dTriadMeansOfUser.PSYCH.aboveTheNormalRange
                ? "bg-danger"
                : "bg-success"
            }`}
          >
            P: {PSYCH}
          </div>
        </div>
        {scoreAfterSummarize?.summary?.secondaryTrait!==undefined && (
          <VisualRewards 
          isShowAnimations={showResult}
          part="part2"
          mainTrait={scoreAfterSummarize?.summary?.mainTrait}
          secondaryTrait={scoreAfterSummarize?.summary?.secondaryTrait}

          />
        )}
        <FriendlyErrMsgBox/>
      </Form>
    </>
  );
};

export default PersonalityTestPart2;
