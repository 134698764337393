import React, { useState } from "react";
import { Link } from 'react-router-dom'; 
import { Tooltip } from "reactstrap";
import UploadCamera from "../../assets/images/camera.svg";
import DefaultProfileImg from "../../assets/images/default-photo.png";
import InfoIcon from "../../assets/images/info-icon.svg";
import BottomDrawer from "./BottomDrawer";
import CustomToolTip from "./CustomToolTip";
import { useSelector } from "react-redux";
import firebase from '../../config/firebase';
import { useImage } from '../../contexts/ImageContext';


const FinalStep = ({
  profileInfo,
  setProfileInfo,
  completed,
  setCompleted,
  agreed,
  setAgreed,
}) => {
  
 

  const { uid } = useSelector((state) => state.authUser);

  const countries = [
    "USA",
    "UK",
    "Australia",
    "Barbados",
    "Burkina",
    "Canada",
    "Germany",
    "Jamaica",
    "Trinidad",
    "France",
    "Italy",
    "Thailand",
    "Brazil",
    "Russia",
    "India",
    "China",
    "South Africa",
    "Mali",
    "Nigeria",
    "Niger",
    "Turkey",
    "Bangladesh",
    "Pakistan",
  ];

  const [matchedCountries, setMatchedCountries] = useState([]);

  const [type, setType] = useState("text");

  const [image, setImage] = useState(null);

  // using React Context
  const { setImageUrlIC } = useImage();
//  setImageUrlIC(fileUrl); // set the image URL in the context
  
  //console.log('profileInfo.imageUrl', profileInfo.imageUrl );
  //console.log('image', image);
 // const fileName = `${Date.now()}`;

    const handleImageChange = (e) => {
      const file = e.target.files[0];

      if (file && file.type.includes("image") && file.size <= 6 * 1024 * 1024) {
        const previewUrl = URL.createObjectURL(file);
        setImage( previewUrl ); 
       
        const storageRef = firebase.storage().ref("users");
        
        const fileRef = storageRef.child(`${uid}/${uid}`);
  
        fileRef.put(file )
        .then(async () => {
          const fileUrl = await fileRef.getDownloadURL();
          setImageUrlIC(fileUrl);  // this is an alternate way to set up the profile image 
  
          setProfileInfo((prevProfileInfo) => ({
            ...prevProfileInfo,
            img: fileUrl,
            imageUrl: fileUrl,
            imageFileName: uid // Save the file name here - previously used random fileName
          }))
        })
        .catch((error) => {
          alert("Upload Failed: " + error.message);
        });

      } else {
        alert("Please select an image file less than 6 MB");
      }
    };
 
    const handleUpload = async () => {
      if (!image) {
        alert("Please select an image less than 6 MB in size \n prefereably a PNG with a transparent background.\n  People on the platform will not see this picture. Your picture is just for you and our match-making process behind the scenes.");
        return;
      }
    };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      [name]: value,
    }));
  };

  const handleCountryInputChange = (event) => {
    const value = event.target.value;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      country: value,
    }));
  
    // Filter countries that match the user input
    const matches = countries.filter((country) =>
      country.toLowerCase().includes(value.toLowerCase())
    );
  
    setMatchedCountries(matches);
  };

  const handleCountrySelect = (country) => {
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      country: country,
    }));
    setMatchedCountries([]); // Clear the suggestions
  };
  const [tooltipUploadPic, setTooltipUploadPic] = useState(false);
  const toggleUploadPic = () =>  setTooltipUploadPic(!tooltipUploadPic);
  /*
    profileInfo?.imageUrl
                  ? profileInfo?.imageUrl
                  : DefaultProfileImg
                  */
  return (
    <div className="gender">
      <div className="profile-info">
        <h5>Create your profile</h5>
        <div className="profile-img-wrapper">
          <input
            type="file"
            accept="image/*"
            id={"uploadId"}
            onChange={handleImageChange}
            hidden
          />
          <div className="profile-img">
            <img
              src={image?image:DefaultProfileImg}
            
           
              alt="img"
              className="img-fluid"
            />
            <label
              className="upload-btn"
              htmlFor={"uploadId"}
              onClick={handleUpload}
            >
              <img src={UploadCamera} alt="icon" className="img-fluid" />
            </label>
          </div>
        </div>


<Tooltip
  placement="bottom"
  isOpen={tooltipUploadPic}
  target="uploadId"
  toggle={toggleUploadPic}
  > 
<b>To upload your picture, </b>
<ol>
  <li> Go to <a href="https://www.adobe.com/express/feature/image/remove-background" className='u-ToolTip__Link' target="_blank" rel="noopener noreferrer" >Adobe Express Background Remover.</a> </li>
  <li> upload your picture </li>
  <li> let it remove the background. </li>
  <li> download the png file </li>
  <li> then upload it to our site. </li>
</ol>
</Tooltip>
        <div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Nickname"
              name="nickName"
              value={profileInfo?.nickName}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please enter your neckname" />
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              value={profileInfo?.firstName}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please enter your first name" />
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={profileInfo?.lastName}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please enter your last name" />
          </div>
          <div className="input-wrapper">
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={profileInfo?.email}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please enter your email" />
          </div>
          <div className="input-wrapper">
            <input
              type={type}
              placeholder="Choose birthdate"
              onFocus={() => setType("date")}
              onBlur={() => setType("text")}
              name="birthDate"
              value={profileInfo?.birthDate}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please select a date" />
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Select country"
              name="country"
              value={profileInfo?.country}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please enter your country name" />
          </div>
          {profileInfo?.country && (
            <>
              <ul className="suggestedCountryWrapper">
                {matchedCountries.map((country, index) => (
                  <li
                    className="suggestedCountry"
                    key={index}
                    onClick={() => handleCountrySelect(country)}
                  >
                    {country}
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Add a Youtube video of you"
              name="youtubeURL"
              value={profileInfo?.youtubeURL}
              onChange={handleInputChange}
            />
            <img src={InfoIcon} alt="icon" className="img-fluid" />
            <CustomToolTip msg="Please enter your youtubeVideo as proof identity" />
          </div>
        </div>
      </div>

      {completed && <div className="over-lay"></div>}
    </div>
  );
};

export default FinalStep;

