import { homeSliderData } from 'helpers/GlobalConstants';
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import './homeSlider.scss';

import ShareModal from 'components/shareModal/ShareModal';
const HomeSlider2 = () => {

  const dispatch = useDispatch();

  const { uid } = useSelector(state => state.authUser);

  const history = useHistory();

  const navigateToOnboarding = () => {
    // 👇️ navigate to on-boarding
    history.push("/admin/on-boarding");
  };

     const NextArrow = () => (
       <button className="next-arrow" onClick={navigateToOnboarding}>
         <svg
           width="18"
           height="16"
           viewBox="0 0 18 16"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path
             d="M11.4096 1.02289C11.1197 0.733005 10.6507 0.729931 10.357 1.01599C10.0582 1.30704 10.0547 1.78606 10.3491 2.08153L15.4992 7.25H1.13672C0.722506 7.25 0.386719 7.58579 0.386719 8C0.386719 8.41421 0.722505 8.75 1.13672 8.75H15.4992L10.3508 13.8936C10.0546 14.1896 10.0547 14.6698 10.3512 14.9656C10.647 15.2608 11.1262 15.2606 11.4217 14.965L17.6796 8.70711C18.0701 8.31658 18.0701 7.68342 17.6796 7.29289L11.4096 1.02289Z"
             fill="white"
           />
         </svg>
       </button>
     );

     const PrevArrow = ({ onClick }) => (
       <button className="prev-arrow" onClick={onClick}>
         <svg
           width="22"
           height="13"
           viewBox="0 0 22 13"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path
             fillRule="evenodd"
             clipRule="evenodd"
             d="M4.00602 6.00066L8.85399 1.71005L7.76449 0.745813L1.05664 6.68248L7.76449 12.6191L8.85399 11.6549L4.00602 7.3643L21.4058 7.3643V6.00066L4.00602 6.00066Z"
             fill="white"
             stroke="white"
             strokeWidth="0.5"
           />
         </svg>
       </button>
     );

    const settings = {
       dots: true,
       infinite: true,
       fade: true,
       speed: 500,
       autoplay: true,
       autoplaySpeed: 2000,
       slidesToShow: 1,
       slidesToScroll: 1,
       nextArrow: <NextArrow />,
       prevArrow: <PrevArrow />,
    };
  
    const settingsNew = {
      dots: true,
      fade: true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
<div className="home-slider">
	<div className="container-lg">
	  <div className="home-slider-container"> 
	    <div className="row align-items-center home-slider-item">  
	      <div className="col-lg-6 bottom-width-mobile-view col-xl-5">
	        <div className="left-side">
	          <h3 className="fs-48 fw-700">GOG Personality {/* & Matching App */}</h3>
	          <span className="desc">
	            <span className="text-white">
	              <span className="mb-1 mb-md-3 d-inline-block fw-700">
	                We are perfecting the way you find & start relationships.
	              </span>
	              <br />
	              <span className="d-inline-block mb-4">
	                <strong>We make Relationships Easier, Safer, More Reliable</strong>{" "}
	                and <strong>More Fun!</strong>
	              </span>

	              <li className="fw-700">Watch the Video</li>
	              <li className="fw-700">
	                or Click Next Button to see the Onboarding Screens{" "}
	              </li>
	              <li className="fw-700">or click Jump to Login.</li>
	            </span>
	          </span>
	        </div>
	      </div>

	      <div className="col-lg-6 intro-video col-xl-7">
	        <div className="right-side">
	          
            <iframe width="560" height="315" src="https://www.youtube.com/embed/pRyfVs43qnc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
         
         
         
          </div>
	      </div>
	    </div>
        
        <div className="d-flex align-items-center justify-content-center">
            <ul className="slick-dots">
                <li className="slick-active"><button>1</button></li>
                <li className=""><button>2</button></li>
                <li className=""><button>3</button></li>
                <li className=""><button>4</button></li>
                <li className=""><button>5</button></li>
                <li className=""><button>6</button></li>
                <li className=""><button>7</button></li>
                <li className=""><button>8</button></li>
                <li className=""><button>9</button></li>
            </ul>

        <NextArrow />
        </div>
{/* 
        {!uid && (
                  <Link to="/admin/index" className="signin-btn signin-btn-boxshadow  mt-50">
                    SIGN IN
                  </Link>
                )}
           
           <ShareModal />
        */}
	  </div>
	</div>
</div>
    );
};

export default HomeSlider2;