/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/Profile2.js";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";


import PrivacyPolicy from "components/PrivacyPolicy";
import Tos from "components/Tos";
import OnboardingSliders from "components/onboardingSliders/OnboardingSliders";
import DataDeletionPolicy from "views/DataDeletionPolicy";
import PersonalityTest from "views/PersonalityTest";
import Unlocked from "views/Unlocked/Unlocked"; // admin/Unlocked
import SeeMatch from "views/SeeMatch";
import Home from "views/home/Home";
import SignIn from "views/signIn/SignIn";

 

/*
When these components/views are imported, 
even if they are not used, their css is loaded.

So, make sure every single css is very specific to the page of interest. 
*/
import UnlockEverything from 'components/UnlockEverything/UnlockEverything';
import Partners from 'components/Partners/Partners';
import ProfileTestBet from 'components/ProfileTest/ProfileTestBet';
import ProfileTest from "components/ProfileTest/ProfileTest";
import LetsUpgradeYou from "views/LetsUpgradeYou/LetsUpgradeYou";
import HowMatchingWorks from "views/HowMatchingWorks/HowMatchingWorks";
import YouHaveMatches from "views/YouHaveMatches/YouHaveMatches";
import Admin from "views/Admin/Admin";
import CreateProfile from "views/createProfile/CreateProfile";
 import WhoIsInside from "components/WhoIsInside/WhoIsInside";
var routes = [
  { 
    path: "/home",
    name: "Home",
    icon: "ni ni-album-2",
    component: Home,
    layout: "/admin",
  },
  {
    path: "/create-profile",
     name: "Create Profile",
    //icon: "ni ni-album-2",
    component: CreateProfile,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/Admin",
    name: "Administration",
    component: Admin,
    layout: "/admin",
  },
  {
    path: "/YouHaveMatches",
    name: "YouHaveMatches",
    component: YouHaveMatches,
    layout: "/admin",
  },
  {
    path: "/original-profile",
    name: "originalprofile",
    icon: "ni ni-album-2",
    component: Profile,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/Bet",
    name: "Bet",
    icon: "ni ni-album-2",
    component: ProfileTestBet, // AvatarCircle,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/ProfileTest",
    name: "TestPage",
    icon: "ni ni-album-2",
    component: ProfileTest, // AvatarCircle,
    layout: "/admin",
    isMenu: false,
  },
  { 
      path: "/HowMatchingWorks",
      name: "How Matching Works",
      icon: "ni ni-album-2",
      component: HowMatchingWorks, 
      layout: "/admin",
      isMenu: false,
    },
   {
    path: "/LetsUpgradeYou",
    name: "LetsUpgradeYou",
    icon: "ni ni-album-2",
    component: LetsUpgradeYou, // AvatarCircle,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/signin",
    name:"signin",
    component: SignIn,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Login",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02",
    component: ProfileTest, /* original Profile, */
    layout: "/admin",
  }, 
  {
    path: "/UnlockEverything",
    name: "Unlock Everything!",
    icon: "ni ni-single-02",
    component: UnlockEverything, 
    layout: "/admin",
  },
  {
    path: "/mypersonalitytest/:id",
    name: "My Personality",
    icon: "ni ni-bullet-list-67",
    component: PersonalityTest,
    layout: "/admin",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    layout: "/admin",
  },
  {
    path: "/deletion-policy",
    name: "Data Deletion Policy",
    component: DataDeletionPolicy,
    layout: "/admin",
  },
  {
    path: "/terms-of-services",
    name: "Terms Of Services",
    component: Tos,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/On-boarding",
    name: "Onboarding",
    icon: "ni ni-album-2",
    component: OnboardingSliders,
    layout: "/admin",
  }, 
  /*
  {
    path: "/Partners",
    name: "Dev -Partners",
    icon: "ni ni-single-02",
    component: Partners, 
    layout: "/auth",
  },  // https://localhost:3000/admin/HowMatchingWorks
  {
    path: "/YouHaveMatches",
    name: "Dev - You Have Matches",
    icon: "ni ni-album-2",
    component: YouHaveMatches, 
    layout: "/admin",
   
  }, 
  { // under development return page 
    path: "/Unlocked",
    name: "Dev - Unlocked",
    icon: "ni ni-album-2",
    component: Unlocked, 
    layout: "/admin",
    isMenu: false,
  },
  { // under development returned page 
    path: "/see-match",
    name: "Dev - See Match",
    icon: "ni ni-single-02",
    component: SeeMatch,
    layout: "/admin",
  }, 
  {
    path: "/whoIsInside",
    name: "Who is Inside",
    icon: "",
    component: WhoIsInside,
    layout: "/admin",
  }*/
];
export default routes;

