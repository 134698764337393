import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg'
import Diamond from "../../assets/images/home-diamond.svg";
import ShareIcon from "../../assets/images/share-icon.svg";
import React, { useEffect, useRef, useState } from 'react';
import './home.scss'
import HomeSlider2 from 'components/homeSlider/HomeSlider2';
import Footer from 'components/footer/Footer';
import ShareModal from 'components/shareModal/ShareModal';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/actions/authActions';
import HeroSlider from 'components/heroSlider/HeroSlider';
import SimplestFeedback from 'components/SimplestFeedback/SimplestFeedback';
import {saveReferrerToLocalStorage, checkReferrerLocalStorage} from '../../helpers/referrerStorageUtils';
import firebase from '../../config/firebase';
import { updateReferredByArrayInFirestore } from '../../helpers/firebaseHelpers';

const Home = () => {

  const dispatch = useDispatch();

  const { user, uid } = useSelector(state => state.authUser);
  const location = useLocation();
  const footerRef = useRef(null);
  

  const [referredby, setReferredBy ] = useState('');

  useEffect(() => {
    // functionality #1: scroll to sign in if necessary 
    if (window.location.hash === '#signin' && footerRef.current) {
      setTimeout(() => { 
          footerRef.current.scrollIntoView({
            behavior: 'smooth',
          });
      },300);
    }

 
   /* 

     Start of algorithm to capture, compare and possibly add  referrer id.  
      Requirement: 

       referrer id can come from 3 places.
       1. querystring parameter 'ref'
       2. localStorage via checkReferrerLocalStorage();
       3. firestore database in users/<uid>/referredBy field 
      
       
      I am not storing 1 querystring paramater/referrer id.  
      I am storing an array of up to 3 

       The database takes precedence meaning if there is a new referrer id 
       the database takes precedence. 

      If the database has 2 referrer ids then I can add a 3rd to it. 

      If there is a new querystring. 
      I will add it to the array then save it in the database. 
     

    */
   
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref'); 
    setReferredBy(ref);
   
    let referrerData = checkReferrerLocalStorage();

    if (!referrerData || !Array.isArray(referrerData)) {
      referrerData = [];
    }

    // At this point, referredby = ref querystring null or string 
    // referrerData from localStorage is an array or []
    // user.referredby from database = []
    // console.log('ref=', ref);
    // console.log('referrerData =', referrerData);
    // console.log('user.referredBy =', user?.referredBy);
    // Check if it's an array and has less than 3 items
    
    // user does not have to be logged in to have 
    // the referring id saved in their local storage 
    // therefore this is not in a check for value of of user & uid 
    if (referrerData.length < 3 && !referrerData.includes(ref)) {
      referrerData.push(ref);
      saveReferrerToLocalStorage(referrerData);
    }

    if (user && uid && ref) {

      if (user && user?.referredBy && Array.isArray(user?.referredBy)) {
        const userReferredByArray = user?.referredBy;
        
        // Combine arrays and remove duplicates
        const newReferredByArray = Array.from(new Set([...userReferredByArray, ...referrerData]));
        
        // Check if update is needed
        if (newReferredByArray.length !== userReferredByArray.length) {
          // Update the referredBy array in Firebase.
          updateReferredByArrayInFirestore( uid, newReferredByArray);
          // Replace this with your actual Firebase code
          // firebase('users').doc(uid).update({referredBy: newReferredByArray});
        }
      } else if (user && uid) {
        // If user exists but has no referredBy field yet, update the database
        // Replace this with your actual Firebase code
        updateReferredByArrayInFirestore( uid, referrerData);
        // firebase('users').doc(uid).update({referredBy: referrerData});
      }
    }
  }, [location, user, uid]);

    return (
      <>
        <header className="new-header">
          <div className="container-fluid">
            <nav>
              <div>
                <Link to="/admin/home">
                  <img src={Logo} alt="logo" className="img-fluid" />
                </Link>
              </div>
              <div>
                {!uid && (
                  <Link to="/admin/home#signin" className="login-btn">
                    Sign In
                  </Link>
                )}
                {uid && (
                  <Link to="/admin/home" 
                    className="login-btn"
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    Sign Out
                  </Link>
                )}
              </div>
            </nav>
          </div>
        </header>
        <div className="hero-slider-wrapper">
          <HeroSlider />
          <div className="content-wrapper">
            <div className="hero-title">
              <div>
                <img src={Diamond} alt="img" className="img-fluid" />
              </div>
              <h2>
                GOG <span style={{ color: "#FF5603" }}>Personality</span>
                {/* <br />& Matching App */}
              </h2>
            </div>
            <div className='d-flex align-items-center share-button-wrap justify-content-center'>
              {!uid && (
                  <Link to="/admin/home#signin" className="signin-btn signin-btn-boxshadow">
                    SIGN IN
                  </Link>
                )} 
              {uid && (
                   <Link to="/admin/user-profile" className="signin-btn my-2 go-to-profile-btn signin-btn-boxshadow">
                    Go to your Profile Page
                  </Link>
                )} 
           
               {/* } <ShareModal /> */}
                <Link to="/admin/on-boarding" className="next-arrow my-0 learn-more signin-btn go-to-profile-btn signin-btn-boxshadow">
                    <span>Learn More </span> <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4096 1.02289C11.1197 0.733005 10.6507 0.729931 10.357 1.01599C10.0582 1.30704 10.0547 1.78606 10.3491 2.08153L15.4992 7.25H1.13672C0.722506 7.25 0.386719 7.58579 0.386719 8C0.386719 8.41421 0.722505 8.75 1.13672 8.75H15.4992L10.3508 13.8936C10.0546 14.1896 10.0547 14.6698 10.3512 14.9656C10.647 15.2608 11.1262 15.2606 11.4217 14.965L17.6796 8.70711C18.0701 8.31658 18.0701 7.68342 17.6796 7.29289L11.4096 1.02289Z"
                        fill="white"
                      />
                    </svg> 

                </Link>
              </div>
          </div>
          <div className="dark-overlay"></div>
        </div>
        <HomeSlider2 />
        <Footer ref={ footerRef } />
         
        <SimplestFeedback user={user} referredBy={referredby} />
      </>
    );
};

export default Home;
/*
  useEffect(() => {
    console.log('2. footerRef.current',footerRef.current);
    const handleHashChange = () => {
      if (window.location.hash === '#signin' && footerRef.current) {
        footerRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    window.addEventListener('hashchange', handleHashChange);
      // Cleanup the listener when the component unmounts
  return () => {
    window.removeEventListener('hashchange', handleHashChange);
  };
}, []);
*/