import React, { useState } from 'react';

const InterestedGender = ({interestedGender, setInterestedGender}) => {

    const handleGender = (e) => {
      setInterestedGender(e.target.value);
    };

    return (
      <div className="gender">
        <div>
          <h3>Gender you are Interested in</h3>
          <div className="input-wrapper">
            <label
              className={`gender-button ${
                interestedGender === "man" && "active"
              }`}
            >
              <input
                name="interestedGender"
                type="radio"
                value={"man"}
                checked={interestedGender === "man"}
                onChange={handleGender}
              />
              Men
              {interestedGender === "man" && (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    fill="#FF5603"
                  />
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </label>

            <label
              className={`gender-button ${
                interestedGender === "woman" && "active"
              }`}
            >
              <input
                name="interestedGender"
                type="radio"
                value={"woman"}
                checked={interestedGender === "woman"}
                onChange={handleGender}
              />
              Woman
              {interestedGender === "woman" && (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    fill="#FF5603"
                  />
                  <path
                    d="M4.1665 9.99967L8.33317 14.1663L16.6665 5.83301"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </label>
            <label
              className={`gender-button`}
            >
              <input
                type="text"
                placeholder='Type interested gender'
                value={(interestedGender === "man" || interestedGender === "woman") ? "" : interestedGender}
                onChange={handleGender}
              />
            </label>
          </div>
        </div>
      </div>
    );
};

export default InterestedGender;