import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import {Input, Button} from 'reactstrap';
import { TikTokEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';
/*
	
ADVICE ABUT COMMENTS 
Comments should explain why something is done, not what is being done, which should be obvious from the code.



This component is supposed to render 3 elements  
    1. text input for a users to type their answer. 
    2. text input for a video path which the user should enter. 
     it should be a tiktok or youtube link such as 
        
        https://www.youtube.com/shorts/T6Oq7YgO7KU

        https://youtu.be/oTDW-XU9T4w

        https://www.youtube.com/watch?v=88e4alx1_0c

        https://www.tiktok.com/@valeriaalejandravv/video/7214907750852250886

    3. embedded video - based on certificate id passed to the component 
       - a default video providing instructions 
       - a video path that the user entered and was saved to fire store database.  

	IF NO VIDEO PATH IS PRESENT, it will show the default video which provides instructions
    
    IF A VIDEO PATH IS PRESENT 
    from the user object or the user's text input , the video will be rendered.
    and the user will have the option of saving it.  
	
	 The user object will contain video paths organized by certificate Id as seen below 
		users/<userid>/userVideos = {
		certId1: {path:"https://www.tiktok.com/@valeriaalejandravv/video/7214907750852250886", answer:''},
		certId2: {path:'https://www.youtube.com/watch?v=88e4alx1_0c',  answer:''},
		certId3: {path:'https://www.tiktok.com/@almightyarcher/video/7265452929140772138',  answer:''}
	};
	
    It is located in firestore location users/<id>/userVideos  


	What did you think about my curriculum?
	Feel free to provide any feedback. 

	Regarding implementing my program in the community, I have not been able to get regular audience of young people.

 


	// these are instructional videos to show them what they should upload. 
	const youtubeAndTiktokNotworkingReliably  = {
		'cvalue'           : {path:'https://youtu.be/pRyfVs43qnc',  answer:''},
		'cBlackPride'     : {path:"https://youtube.com/shorts/2_JmxRzDcdQ?feature=share", answer:''},
		'cTop3'            : {path:'https://www.tiktok.com/@almightyarcher/video/7265452929140772138',  answer:''},
		'cCBT'                : {path:'https://www.tiktok.com/@kingJael/video/726545292772138', answer:''},
		'cReferee'          : {path:'https://youtube.com/shorts/Bf-WY0pvStA?feature=share', answer:''},
		'cHomeCooking'        : {path:'https://youtu.be/X3j-tLLB5mg',  answer:''},
		'cFoodSovereignty': {path:'https://youtu.be/NbrRuFWheGQ',  answer:''},
	};

 

	*/

import coins from '../../assets/images/gogexperience_marvel_black_panther_coin_by_dj_9340cbb7-7ae7-4b55-82e8-215e103fe26d.png';

import sprinter1 from '../../assets/vids/sprinter1.mp4';
import burpees from '../../assets/vids/burpees-blackgirl.mp4';
import cvalue           from '../../assets/vids/chapter-4-videos.mp4';
import cBlackPride      from '../../assets/vids/1--meli-1080p-latest.mp4';
import cTop3            from '../../assets/vids/brittnebabe-3_8078036001316078682_n.mp4';
import cCBT             from '../../assets/vids/49602862_1133814483435127_595458779459830982_n.mp4';
import cReferee         from '../../assets/vids/Attachment_1636936911.mp4';
import cHomeCooking     from '../../assets/vids/cooking-tik.mp4';
import cFoodSovereignty from '../../assets/vids/cook-from-garden-.mp4';
import cRelationshipTraditions from '../../assets/vids/9.mp4';

const EmbeddedUserVideo = ({certificateId, linkedUserVideo}) => { 
	
	const { user, uid } = useSelector(state => state.authUser); 

	
	const defaultVideoPaths = {
		'cPhysicallyFit' : {path:sprinter1,  answer:''},
		'cPhysicallyFit2' : {path:burpees,  answer:''},
		'cvalue'          : {path:cvalue,  answer:''},
		'cBlackPride'     : {path:cBlackPride, answer:''},
		'cTop3'           : {path:cTop3, youtubeDefault:'https://youtu.be/UN5WSVXVKgw', answer:''},
		'cCBT'            : {path:cRelationshipTraditions, answer:''},
		'cReferee'        : {path:cReferee,  answer:''},
		'cHomeCooking'    : {path:cHomeCooking,  answer:''},
		'cFoodSovereignty': {path:cFoodSovereignty,  answer:''},
	};

 
	 
	const [ defaultVideo, setDefaultVideo] = useState(defaultVideoPaths?.[certificateId]?.path) ;
	console.log('certificateId=',certificateId, 'defaultVideo', defaultVideo);
	

	const [videoProps, setVideoProps] = useState({
		isTikTokOrYoutube:null, 
		embeddedPath:null,
		tikTokUserId:null,
		frameWidth:null,
        frameHeight: null,
        tikTokVidId:null
	});



	useEffect(() => {
		// Logic to handle linkedUserVideo change
		console.log('linkedUserVideo=', linkedUserVideo);
		if (linkedUserVideo && typeof linkedUserVideo==='string' ) {
			let domain = null;

			if (/youtube\.com|youtu\.be|shorts/.test(linkedUserVideo) ) {
			
				setDefaultVideo(false);
				setVideoProps({
					isTikTokOrYoutube: 'youtube.com', 
					embeddedPath: linkedUserVideo,
					tikTokUserId:null,
					frameWidth:  null,
					frameHeight: null,
					tikTokVidId: null
				});
			} else if (/tiktok\.com/.test(linkedUserVideo)) {
			 
				setDefaultVideo(false);
				setVideoProps({
					isTikTokOrYoutube: 'tiktok.com', 
					embeddedPath: linkedUserVideo,
					tikTokUserId:null,
					frameWidth:  null,
					frameHeight: null,
					tikTokVidId: null
				});
			} else {
				setDefaultVideo(defaultVideoPaths?.[certificateId]?.path);
				setVideoProps({
					isTikTokOrYoutube: null, 
					embeddedPath: null,
					tikTokUserId:null,
					frameWidth:  null,
					frameHeight: null,
					tikTokVidId: null
				});
			}
		} else {
			if (!linkedUserVideo)  {

				if ( defaultVideoPaths?.[certificateId]?.youtubeDefault ) {
					setDefaultVideo(false);
					setVideoProps({
						isTikTokOrYoutube: 'youtube.com', 
						embeddedPath: defaultVideoPaths?.[certificateId]?.youtubeDefault,
						tikTokUserId:null,
						frameWidth:  null,
						frameHeight: null,
						tikTokVidId: null
					});		
				}
			}
		}
	  }, [linkedUserVideo]);


	const useViewportWidth = () => {
		const [width, setWidth] = useState(window.innerWidth);
	  
		useEffect(() => {
		  const handleResize = () => {
			setWidth(window.innerWidth);
		  };
		  window.addEventListener('resize', handleResize);
		  // Clean up by removing the event listener when the component unmounts
		  return () => {
			window.removeEventListener('resize', handleResize);
		  };
		}, []); // An empty dependency array means this effect will run once on mount and clean up on unmount
		return width;
	};
	
	const vidWidth = useViewportWidth() * 0.70;


  /* 
	IF THE USER has previously posted their own youtube video, 
	this will get and display their video for the certificate ID of interest. 
	 Example 

	user.userVideos = { 
		certId1: {path:''},
		certId2: {path:''},
		certId3: {path:''},
		certId4: {path:''}, 
	}
	 if the user is logged in show their video. 
	 if the user is not logged in - show a default video to let them 
	 see how they should make their video and enter their caption.  
     
	useEffect(() => {
		if (user) { // user is logged in to see the video 
			if ( user?.userVideos?.[certificateId] ) {
				const vidPath = user.userVideos[certificateId].path;
				if (vidPath) {
					createEmbeddedVideo(vidPath.path);
				}
			}
		}
	}, [user, user?.userVideos?.[certificateId] ]);

	
	const createEmbeddedVideo = (vidPath) => {
		const props = getVideoProperties(vidPath);
        let height = null;
        let aspectRatio = '';

        if (/tiktok|shorts/.test(vidPath)) {
            aspectRatio = '2:3';
        } else {
            aspectRatio = '16:9';
        }

		if (aspectRatio ==='16:9') { 
			height = Math.round(vidWidth * 9/16);
		} else if ( aspectRatio=='2:3')  {
			height = Math.round(vidWidth * 3/2);
		} else {  
			height = Math.round(vidWidth * 9/16);
		}

        // changing this property will change make it visible 
		setVideoProps({
			isTikTokOrYoutube:props.domain,  
			embeddedPath:props.embeddedPath,
			tikTokUserId:props.userId,
            tikTokVidId:props.vidId,
			frameWidth:vidWidth,
            frameHeight: height
		});
	};

	const getVideoProperties = (path) => {
		let result = {
		  domain: '',
		  vidId: '',
		  userId: '',
		  embeddedPath: '',
		};
	   
		if (/youtube\.com|youtu\.be/.test(path)) {
			result.domain = 'youtube.com';	 
            const vidIdMatch = path.match(/[?&]v=([^&]+)/);
            if (vidIdMatch) {
                result.vidId = vidIdMatch[1];
            }
            result.embeddedPath = 'https://www.youtube.com/embed/'+result.vidId;
		} 
		
		if (/tiktok\.com/.test(path)) {
            result.domain = 'tiktok.com';
            const userIdMatch = path.match(/tiktok\.com\/@([^/]+)/);
            if (userIdMatch) {
                result.userId = '@' + userIdMatch[1];
            }
            const vidIdMatch = path.match(/video\/(\d+)/);
            if (vidIdMatch) {
                result.vidId = vidIdMatch[1];
            }
            result.embeddedPath = path;
        }
		return result;
	};
	*/


	/* the code below allows the user to upload their own video path
	 and enter text to describe their video
	

    const [userAnswer, setUserAnswer] = useState('');
	
	const [userVideoPath, setUserVideoPath] = useState('');

    const saveText = (e) => {
        var txt = e.target.value; 
        e.preventDefault();
        setUserAnswer(txt);
    };

    const saveUserVideoPath = (e) => {
        var userPathInput = e.target.value;
        e.preventDefault();
		setUserVideoPath(userPathInput);
    };

	const saveVideoPathAndAnswersToFirestoreDB = async () => {
		if (!user || !uid) {
			alert('Please login. you have to be logged in to save this video');
			return false;
		}
        if ( !videoProps.embeddedPath || !userAnswer) { 
            alert('Please enter your answer  and video path  ')
            return false; 
        } 

        let userVideos = [ ...user.userVideos];
        userVideos[certificateId] = {
            path:videoProps.embeddedPath,
            answer:userAnswer
        };

		const userRef = firebase.firestore().collection('users').doc(uid);
		try {
			// Update the userVideos property without affecting other properties
			await userRef.update({
			  userVideos: userVideos
			});
		
			alert('User videos updated successfully');
			
			// Update local state or do anything else you need after a successful update
		
		} catch (error) {
			console.error(error);
			alert('An error occurred while updating the user videos');
		}
	};

	


 */
	return (
		<React.Fragment>
            {videoProps.isTikTokOrYoutube && videoProps.isTikTokOrYoutube ==='youtube.com' ? (
			 
			 <div className="mt-4 letsUpgradeYou__video-frame" style={{ display: 'flex', justifyContent: 'center' }}>
			 <YouTubeEmbed url={videoProps.embeddedPath} width={325} height={220} />
			 </div>
			 ): 
              
			 videoProps.isTikTokOrYoutube && videoProps.isTikTokOrYoutube === 'tiktok.com' ? (
				 <React.Fragment>
					 <div  className="mt-4  letsUpgradeYou__video-frame" style={{ display: 'flex', justifyContent: 'center' }}>
					 <TikTokEmbed url={videoProps.embeddedPath} width="100%" />
					 </div>
				 
				 </React.Fragment>
			 ): 

			 defaultVideo ? (
				<div className='d-flex justify-content-center mt-4  letsUpgradeYou__video-frame'>
				<video src={defaultVideo} controls loop autoPlay muted  width="100%"/> 
				</div>
			):''}

		{/*
			<div className='d-flex'>
                <Input type='text' placeholder='Which did you do?' onChange={saveText}></Input> 
            </div>
            <div className='d-flex'>
                <Input type='text' placeholder='Paste your youtube or tiktok path here eg., youtube.com/watch?v=55555' onChange={saveUserVideoPath}></Input>
                <Button type='button' className='btn-primary' onClick={saveVideoPathAndAnswersToFirestoreDB}>Save </Button>
            </div> */}
		</React.Fragment>
	);
};
export default EmbeddedUserVideo;

/*


	<blockquote className="tiktok-embed" cite={videoProps.embeddedPath} data-video-id={videoProps.tikTokVidId} 
						style={{maxWidth: videoProps.frameWidth, minWidth: 325}} >
							<section> 
								<a target="_blank" title={videoProps.tikTokUserId}
									href={`https://www.tiktok.com/${videoProps.tikTokUserId}?refer=embed`}>{videoProps.tikTokUserId}</a> 
									 
							</section> 
					</blockquote><script async src={"https://www.tiktok.com/embed.js"}></script> 


	useEffect(() => {
		let height = null;
		if (aspectRatio ==='16:9') { 
			height = width * 9/16;
		} else if ( aspectRatio=='2:3')  {
			height = Math.floor(width * 3/2);
		} else {  
			height = width * 9/16;
		}

		setYoutubeProps({
			ypath:path,
			ywidth:width,
			yheight:height
		});
	}, [path, width, aspectRatio]);


	*/