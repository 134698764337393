
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import coin1 from '../../assets/img/icons/webm//Coin Explosion 1.webm';
import coin2 from '../../assets/img/icons/webm//Coin Explosion 2.webm'; 
import coin3 from '../../assets/img/icons/webm//Coin Explosion 3.webm';
import audio1 from '../../assets/vids/magic-chime-02.mp3';
import audio2 from '../../assets/vids/magic-chime-01.mp3';
import testIconResults from '../../assets/img/icons/webm/Neurotic.webm'
import PropTypes from 'prop-types';
import './VisualRewards.css';
import { Tooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { mapTraitResults, categoryByPartMap } from '../../helpers/fetchPersonalityResults.js';
   

/*
    <VisualRewards 
    isShowAnimations={showResult}
    part="part1"
    mainTrait={scoreAfterSummarize.summary.mainTrait}/>
*/
const VisualRewards = ({isShowAnimations, part, mainTrait, secondaryTrait}) => {
    const [ animationRunning, setAnimationRunning] = useState(false);
    const vid1Ref = useRef(null);
    const vid2Ref = useRef(null);
    const vid3Ref = useRef(null);
    const audioRef1 = useRef(null);
    const audioRef2 = useRef(null);
    const iconRef = useRef(null);
    const iconRef2 = useRef(null);

    useEffect(() => {
        console.log('VisualRewards in useEffect isShowAnimations', isShowAnimations);
        setAnimationRunning(isShowAnimations);
    }, [isShowAnimations]);

    useEffect(() => {
        console.log('VisualRewards in use effect animationRunning)', animationRunning);
        if (animationRunning) {
            playVideoAndSound(animationRunning);
        }
    
        return () => {
            [iconRef, vid1Ref, vid2Ref, vid3Ref, audioRef1, audioRef2].forEach(ref => {
                if (ref.current) {
                    ref.current.pause();
                    ref.current.removeEventListener('ended', handleEnded);
                }
            });
        };
    }, [animationRunning]);
    /*
        const getPersonalityTestIcon = () => {

            return {
                iconResults: testIconResults,
                iconAlt:'icon for personality test'
            };
        };
    */
    console.log('function param mainTrait', mainTrait);
    console.log('function param secondaryTrait',secondaryTrait);
    console.log('categoryByPartMap[part]=',categoryByPartMap[part]);
    console.log('mapTraitResults', mapTraitResults);
    console.log('mapTraitResults[categoryByPartMap[part]]=',mapTraitResults[categoryByPartMap?.[part]]);
    console.log('mapTraitResults[categoryByPartMap[part]][secondaryTrait]=', mapTraitResults[categoryByPartMap?.[part]]?.[secondaryTrait]);
    // mapTraitResults[categoryByPartMap[part]][secondaryTrait].iconResults;

    const msgData = { msg: 'This icon represnts your personality test result ->'};
    
    const iconResults      = mapTraitResults[categoryByPartMap[part]]?.[mainTrait]?.iconResults;
    const secondaryResults = mapTraitResults[categoryByPartMap[part]]?.[secondaryTrait]?.iconResults;
    const iconAlt          = mapTraitResults[categoryByPartMap[part]]?.[mainTrait]?.iconAlt;
    const secondaryIconAlt = mapTraitResults[categoryByPartMap[part]]?.[secondaryTrait]?.iconAlt;
    const playWithErrorChecking = (promise) => {
        if (promise !== undefined) {
            promise.then(_ => {
              // Automatic playback started!
            })
            .catch(error => {
              console.log("Playback failed: " + error);
              // Handle the failure gracefully
            });
         }
    };

    const [isShowingText, setIsShowingText] = useState(false)
    const handleEnded = () => {
        setIsShowingText(true);
    };

    const toggleSound = Math.round(Math.random());

    const playVideoAndSound = (isPlaying) => {
        if (isPlaying) {
            [iconRef, vid1Ref, vid2Ref, vid3Ref].forEach(ref => {
                console.log('inside [vid1Ref, vid2Ref, vid3Ref].forEac', ref);
                ref.current?.pause();
                playWithErrorChecking(ref.current?.play())
            });
            
            const selectedAudioRef = toggleSound ? audioRef1 : audioRef2;
            if (selectedAudioRef.current) {
                selectedAudioRef.current.addEventListener('ended', handleEnded);  
                selectedAudioRef.current?.pause();
                playWithErrorChecking(selectedAudioRef.current?.play());
            }
        }
    };
    const { uid, user } = useSelector(state => state.authUser);

    const hasFullAccess = user?.hasFullAccess === 'n' ||  user?.hasFullAccess === 'y' ? user?.hasFullAccess : 'n';
     
    const [tooltipOpenSpeak, setTooltipOpenSpeak] = useState(false);
    const [tooltipOpenSpeak2, setTooltipOpenSpeak2] = useState(false);
    const toggleToolSpeak2 = () => setTooltipOpenSpeak2(!tooltipOpenSpeak2);
    
    const toggleToolSpeak = () => setTooltipOpenSpeak(!tooltipOpenSpeak);
  
    function renderMessage(hasFullAccess) {
        if (hasFullAccess === 'n') {
          return <p>Learn more about your personality results when you have Full Access.</p>;
        } else if (hasFullAccess === 'y') {
          return <p>More info will appear here soon.</p>;
        }
        return null;
      }

    return (
        <div className={`visualRewards my-6 ${animationRunning ? 'animationRunning':''}`}>

            <div className='d-flex justify-content-center'>

            {iconResults ? (
            <video  onClick={toggleToolSpeak}  id={`toolTipIcon-ptest`}  ref={iconRef}  autoPlay loop preload='auto' className="visual-rewards__gog-js-iconResult" src={iconResults} alt={iconAlt} ></video>
            ):null}
            
            {secondaryResults ? (
            <video  onClick={toggleToolSpeak2} id={`toolTipIcon-ptest2`} ref={iconRef2} autoPlay loop preload='auto' className="visual-rewards__gog-js-iconResult" src={secondaryResults} alt={secondaryIconAlt} ></video>
            ):null}
            
            {iconResults ? (
            <Tooltip
                placement="top"
                isOpen={tooltipOpenSpeak}
                target={`toolTipIcon-ptest`}
                toggle={toggleToolSpeak}
                ><p>{msgData?.msg}  {iconAlt}</p>
               { renderMessage(hasFullAccess) }
              
                </Tooltip> 
            ):null}


            {secondaryResults ? (
            <Tooltip
                placement="top"
                isOpen={tooltipOpenSpeak2}
                target={`toolTipIcon-ptest2`}
                toggle={toggleToolSpeak2}
                ><p>{msgData?.msg} {secondaryIconAlt}</p>
                { renderMessage(hasFullAccess) }
            </Tooltip> 
            ): null}
                
            </div>
            <p className="font-weight-bold text-white">This icon represents your main personality type for this test.</p>


            <div className='visualRewards__coins-wrap d-flex justify-content-center'>
                <video ref={vid3Ref} loop src={coin3} className='visualRewards__coins visualRewards__coin-3'></video>
                <video ref={vid1Ref} loop src={coin1} className='visualRewards__coins visualRewards__coin-1'></video>
                <video ref={vid2Ref} loop src={coin2} className='visualRewards__coins visualRewards__coin-2'></video>
            </div>
            <audio ref={audioRef1} src={audio1} ></audio>
            <audio ref={audioRef2} src={audio2} ></audio>
            <div className={`text-center visualRewards__msg ${isShowingText ? 'is-visible':''}` }>      
                You earned 3 coins for completing this personality test. Please continue.
            </div>
        </div>
    )
};
VisualRewards.propTypes = {
    isShowAnimations: PropTypes.bool.isRequired,
  };
export default VisualRewards;
