// import {saveReferrerToLocalStorage} from '../helpers/referrerStorageUtils';
// import {checkReferrerLocalStorage} from '../helpers/referrerStorageUtils';
 
import CryptoJS from "crypto-js";


export const saveReferrerToLocalStorage = (arrayReferrers) => {
  const serializedRef = CryptoJS.AES.encrypt(
    JSON.stringify(arrayReferrers),
    "my-secret-key"
  ).toString();
  localStorage.setItem("ref", serializedRef);
};

export const checkReferrerLocalStorage = () => {
  const serializedRef = localStorage.getItem("ref");
  if (serializedRef === null) return [];
  return JSON.parse(
    CryptoJS.AES.decrypt(serializedRef, "my-secret-key").toString(
      CryptoJS.enc.Utf8
    )
  );
};

// src/helpers/referrerStorageUtils.js
/**
 * saves to localStorage
 *  import {setOnboardingStatusFromLocalStorage } from '../helpers/referrerStorageUtils';
 * or getOnboardingStatusFromLocalStorage
 * @param {string} stageCompleted = "staticOb" || "dynamicOb"
 */
export const setOnboardingStatusFromLocalStorage = (stageCompleted) => {
  if ( !(stageCompleted==="staticOb" || 
      stageCompleted == "dynamicOb") ) {
      throw new Error('param stageCompleted must be either "staticOb" || "dynamicOb"');
  }
  
  const serializedRef = CryptoJS.AES.encrypt(
    JSON.stringify(stageCompleted),
    "my-secret-key"
  ).toString();
  localStorage.setItem("onboard", serializedRef);
};


export const getOnboardingStatusFromLocalStorage = () => {
  const serializedRef = localStorage.getItem("onboard");
  if (serializedRef === null) return null;
  return JSON.parse(
    CryptoJS.AES.decrypt(serializedRef, "my-secret-key").toString(
      CryptoJS.enc.Utf8
    )
  );
};

export const redirectBasedOnOnBoardingStatus = (history) => {
  
	const onBoardingStatus = getOnboardingStatusFromLocalStorage();
	if (!onBoardingStatus) {
		history.push('/admin/on-boarding');
	} else if (onBoardingStatus==="staticOb") {
		history.push('/admin/create-profile');
	} else if (onBoardingStatus==="dynamicOb") {
		history.push('/admin/user-profile');
	}
};


// saveBetIdToLocalStorage(betId)
// checkBetIdInLocalStorage();
export const saveBetIdToLocalStorage = (betId) => {
  const serializedRef = CryptoJS.AES.encrypt(
    JSON.stringify(betId),
    "my-secret-key"
  ).toString();
  localStorage.setItem("betId", serializedRef);
};

export const checkBetIdInLocalStorage = () => {
  const serializedRef = localStorage.getItem("betId");
  if (serializedRef === null) return '';
  return JSON.parse(
    CryptoJS.AES.decrypt(serializedRef, "my-secret-key").toString(
      CryptoJS.enc.Utf8
    )
  );
};
