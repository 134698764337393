// YouHaveMatches.jsx
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import BackButton from '../../components/BackButton/BackButton';
import AvatarCircle from '../../components/AvatarCircle/AvatarCircle';
import LoveLanguageModal from '../../components/Modal/LoveLanguageModal';
import '../../components/ProfileTest/ProfileTest.css';

import iconC_vision from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-eye-representing-amazin--1@2x.png";
import iconC_action from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-gold-megaphone-icon-1@2x.png";
import iconC_process from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-green-puzzle-icon-1@2x.png";
import iconC_people from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png";

import iconB5_openness from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-light-blue-d--1@2x.png";
import iconB5_agreeableness from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-green-heart-icon-1-1@2x.png";
import iconB5_mixed from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-grey-star-ic--1@2x.png";
import iconB5L_extroverted from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-orange-sun-i--1@2x.png";
import iconB5_introverted from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-purple-ancho--2@2x.png";
import iconB5_Neurotic from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-red-whirlwin--1@2x.png";
import iconB5_conscientiousness from "../../assets/img/icons/light-blue-check-mark-conscientiousness-1@2x.png";

import iconLL_acts from "../../assets/img/icons/wrench-1@2x.png";
import iconLL_gifts from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-gold-gift-box-gold-box---1@2x.png";
import iconLL_touch from "../../assets/img/icons/hand-touch-emojid-1-1@2x.png";
import iconLL_time from "../../assets/img/icons/hourglass-1@2x.png";
import iconLL_words from "../../assets/img/icons/speak-emoj-1@2x.png";

 
 
 import defaultMatch1 from "../../assets/images/junky14.jpg";
 import defaultMatch2 from "../../assets/images/junky7.jpg"; 

 
 import IconCheckbox from "../../components/IconCheckbox/IconCheckbox";
 


const YouHaveMatches = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleModalOpen = (content) => {
    setShowModal(true);
    setModalContent(content);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setModalContent('');
  };
  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = Array.from(e.target.elements)
    .filter((element) => element.type === 'checkbox')
    .map((checkbox) => ({
      id: checkbox.id,
      isChecked: checkbox.checked,
    
    }));

  console.log('Form Data:', formData);
/* 
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
    Assuming `firestore` is the Firestore instance
    try {
        const docRef = await addDoc(collection(firestore, 'Matches'), {
          uid: 'userUID', // Replace with the user's UID
          matches: formData,
        });
        console.log('Document written with ID:', docRef.id);
      } catch (error) {
        console.error('Error adding document:', error);
      }

      */

  };
  const [isBlurredLeft, setIsBlurredLeft] = useState(false);
  const [isBlurredRight, setIsBlurredRight] = useState(false);

  const handleButtonClickBlurAnimation = (btnSide) => {
    if (btnSide === "left") {
      setIsBlurredLeft(!isBlurredLeft);
    }
    if (btnSide === "right") {
      setIsBlurredRight(!isBlurredRight);
    }
  };

  const handleAnimationEnd = (btnSide) => {
    if (btnSide === 'left') {
      setIsBlurredLeft(false);
    }
    if (btnSide === 'right') {
      setIsBlurredRight(false);
    }
  };



  const avatarData = {
    percentCompleted: 40,
    userName:"Jael Lovelli",
    notificationIconData: 2
  };
  const userDataProfile = {
    userName:"Jael Lovelli",
    ageRange:""
  };
  const matchesData = {
    hasMatches:false,
    matches:[
        {id:"user1", imgSrc:'', vidSrc:'', matchedDate:0, seenDate:0, zipCode:'',matchedOn:[]},
        {id:"user2", imgSrc:'', vidSrc:'',  matchedDate:0, seenDate:0, zipCode:'',matchedOn:[]},
    ]
  };

return (
<div className="YouHaveMatches">
  <BackButton/>
    <form onSubmit={handleSubmit}>
        <div className="letsUpgradeYou-container">
            <div className="avatar-section">
                <AvatarCircle
                    pictureSource={avatarData.pictureSource}
                    percentCompleted={avatarData.percentCompleted}
                />
            </div>
            <div className="notification-bell"></div>
           
            <div> 
                {matchesData.hasMatches === true && (
                    <div>
                        <h5 className="pb-4 font-weight-bold text-center">You Have Matching Works</h5>
                        <div className="heart-button-wrap heart-button-wrap--top">
                            {matchesData.matches.map((val, index) => (
                                <IconCheckbox key={index} elementId={val.id} isSelected={val.isSelected} />
                            ))} 
                        </div>

                        <div className="d-flex justify-content-center text-align-center">
                            <div className="img-rotate-left-wrap">
                                <img  onAnimationEnd={() => handleAnimationEnd('left')} 
                                src={defaultMatch1} 
                                className={`img-rotate-left ${isBlurredLeft ? 'blur-animation' : ''}`}
                                alt="default match image1" />
                            </div>
                            <div className="img-rotate-right-wrap">
                                <img onAnimationEnd={() => handleAnimationEnd('right')} 
                                src={defaultMatch2} 
                                className={`img-rotate-right ${isBlurredRight ? 'blur-animation' : ''}`}
                                alt="default match image2" />
                            </div>
                        </div>

                    </div>
                )}
                {matchesData.hasMatches === false && (
                    <div>
                        <h5 className="pb-4 text-white font-weight-bold text-center">When Matches are Ready, <br/>they will be here.</h5>
                        
                    
                        <div className="heart-button-wrap heart-button-wrap--bottom">
                        {matchesData.matches.map((val, index) => (
                            <IconCheckbox key={index} elementId={val.id} isSelected={val.isSelected} />
                        ))} 
                        </div>
                        
                        <div className="d-flex justify-content-center text-align-center">
                            <div className="img-rotate-left-wrap">
                                <img onAnimationEnd={() => handleAnimationEnd('left')}
                                src={defaultMatch1} 
                                className={`img-rotate-left ${isBlurredLeft ? 'blur-animation' : ''}`}
                                alt="default match image1" />
                            </div>
                            <div className="img-rotate-right-wrap">
                                <img onAnimationEnd={() => handleAnimationEnd('right')}
                                src={defaultMatch2} 
                                className={`img-rotate-right ${isBlurredRight ? 'blur-animation' : ''}`}
                                alt="default match image2" />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="mt-3 ml-5 mr-5">   
                <p className="font-weight-bold">After you finish the personality test, 
                you will be matched with someone extremely compatible with you based on.  
                </p>

                <div className="m-4">
                    <ul className="list-unstyled">
                        <li>√ Your Big 5 Personality Traits</li>
                        <li>√ Your Love Languages</li>
                        <li>√ Your Communication Styles</li>
                        <li>√ Passions, Interests </li>
                        <li>√ Values & Relationship Goals </li> 
                        <li>√ and a whole lot more.</li>
                    </ul>
                </div>

                <p className="text-white">1. After you pay the subscription $8/mo, you will be able to unblur & see your match. 
               <br/>(This is a default image. You can try it to see how it works.) </p>
                <div className="d-flex mt-2 button-container justify-content-center text-align-center"> 
                    <Button type="button"  onClick={() => handleButtonClickBlurAnimation('left')}  className="m-2 btn-dark text-white">Peek At the Left Image</Button>
                    <Button type="button"  onClick={() => handleButtonClickBlurAnimation('right')} className="m-2 btn-dark text-white">Peek At the Right Image</Button>
                </div>
                <p className="text-white mt-4">
                2. Then press the heart icon, make it green 💚 to confirm that you want to be engaged,<br/>
                3. Click the Save and Meet Butten. <br/>
                4. then meet just like the TV Series "Love is Blind". 
                </p>
                
                <div className="d-flex button-container justify-content-center text-align-center"> 
                    <Button type="submit" className="mr-2 btn-dark text-white">Save & Meet</Button>
                </div>

                <div className="d-flex button-container justify-content-center text-align-center"> 
                    <Button type="submit" className="mr-2 btn-dark text-white">Pay To Get & See Matches</Button>
                    <Button type="button" className="wider-button btn-dark text-white" href="/admin/HowMatchingWorks" variant="outline-secondary">About</Button>
                </div>
            </div>
        </div>
    </form>
</div>
  );
}; 
export default YouHaveMatches;