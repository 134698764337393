/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { ImageProvider } from 'contexts/ImageContext';
/*import { createRoot } from 'react-dom'; */
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// reference is in "Image Upload Handling"
 
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

 
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import { Provider } from 'react-redux';
import store from '../src/store';


import { createRoot } from 'react-dom/client';
const container = document.getElementById('root'); // Use 'root' if that's the correct ID in your HTML file
const root = createRoot(container);
root.render(
  <ImageProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/home" />
        </Switch>
      </BrowserRouter>
    </Provider>
  </ImageProvider>
);

/*
previously - in case i need to fall back.
// reference is in "Image Upload Handling"
import React from 'react';
import ImageProvider from '../context/ImageContext';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

 
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import { Provider } from 'react-redux';
import store from '../src/store';

import ReactDOM from 'react-dom';
ReactDOM.render(
  <ImageProvider>
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/home" />
      </Switch>
    </BrowserRouter>
  </Provider>
  </ImageProvider>,
  document.getElementById("root")
);
*/ 
