/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
	Card,
	CardBody,
	Form,
	Input,
	Container,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	CardTitle,
	CardText,
	TabPane,
	Button,
	FormGroup,
	Label,
	Modal,
	ModalBody,
} from 'reactstrap';
// core components
import UserHeader from 'components/Headers/UserHeader.js';
import { useEffect, useState } from 'react'; 
import PersonalityDetail from 'components/PersonalityDetail';
import Stats from 'components/Stats';
import heightImg from '../assets/img/brand/image2.png';
import AvatarCircle from 'components/AvatarCircle/AvatarCircle';
import { useDispatch, useSelector } from 'react-redux';
import NA3 from '../assets/img/brand/NA3.png';
import NA from '../assets/img/brand/NA.png';
import { useLocation, useHistory } from "react-router-dom";

import info1 from '../assets/img/brand/info1.png';
import info2 from '../assets/img/brand/info2.png';
import info3 from '../assets/img/brand/info3.png';
import { checkFirstTimeLogin } from 'store/actions/authActions';
import { updateProfile } from 'store/actions/authActions';
import PersonalityPreferences from 'components/PersonalityPreferences';
import ButtonCircleGraph from "components/ButtonCircleGraph/ButtonCircleGraph";

const Profile = () => {
	const [activeTab, setActiveTab] = useState('1');
 
	const location = useLocation();
    if (location.search==="?tab=big5") {
		setActiveTab('1');
	} else if (location.search==="?tab=comm") {
		setActiveTab('2');
		
	} else if (location.search==="?tab=lovelang") {
		setActiveTab('3');
		
	} else if (location.search==="?tab=val") { //values and goals
		setActiveTab('4');

	} else if (location.search==="?tab=bbmnp") {
		setActiveTab('5');

	} else if (location.search==="?tab=big5") {
		setActiveTab('6');
	}
	 

    const history = useHistory();

	const { uid } = useSelector(state => state.authUser);
	const [edit, setEdit] = useState(false);
	const [profEdit, setProfEdit] = useState(false);
	const toggleProfEdit = () => {
		setProfEdit(!profEdit);
	};
	const [profileDetail, setProfileDetails] = useState({
		TypeIAm: '',
		TypeILike: '',
		RelationshipNow: '',
		RelationshipGoal: '',
		Top2FavTVMovieGenre: '',
		Top2FavYoutubeCategory: '',
		ForfunILikeTo: '',
		DreamLocation: '',
		LoveLanguage: '',
		WorkGoals: '',
		CommunicationStyle: '',
	});

	const [mySkinToneDetails, setMySkinToneDetails] = useState({});
	const [mySkinTonePreferences, setMySkinTonePreferences] = useState({});

	const [detailsAboutMe, setDetailsAboutMe] = useState({
		submissiveDominant: 50,
		meekArogant: 50,
		controlledImpulse: 50,
		stableVolatile: 50,
		financeMutualDemanding: 50,
		responsibleIrresponsible: 50,
		introvertExtrovert: 50,
		sociallyResponsibleIrresponsible: 50,
		happyAngry: 50,
		lowHighMaintenance: 50,
		thankfulNotThankful: 50,
		bullyNotBully: 50,
		needHighDramaNoDrama: 50,
		hasFriendsNoFriends: 50,
		mischievesNotMischieves: 50,
		notAttensionSeekAttensionSeek: 50,
		historyPromiscuityNoHistory: 50,
		stableUnstableOpinions: 50,
		forgivesGiveReminders: 50,
		learnsFastLearnsSlow: 50,
		flexibleThinkerRidgeThinker: 50,
		growUpNotGrowUp: 50,
		historyPromiscuityNoHistory2: 50,
		flexibleThinkerRidgeThinker2: 50,
		abusiveGrewUpNonAbusiveGrewUp: 50,
		noViolentHistoryViolentHistory: 50,
		legalIncomeProofNoProof: 50,
		noDomesticViolenceHistoryDomesticHistory: 50,
		noHateGenderHateGender: 50,
	});

	const [detailPreference, setDetailPreference] = useState({
		submissiveDominant: 50,
		meekArogant: 50,
		controlledImpulse: 50,
		stableVolatile: 50,
		financeMutualDemanding: 50,
		responsibleIrresponsible: 50,
		introvertExtrovert: 50,
		sociallyResponsibleIrresponsible: 50,
		happyAngry: 50,
		lowHighMaintenance: 50,
		thankfulNotThankful: 50,
		bullyNotBully: 50,
		needHighDramaNoDrama: 50,
		hasFriendsNoFriends: 50,
		mischievesNotMischieves: 50,
		notAttensionSeekAttensionSeek: 50,
		historyPromiscuityNoHistory: 50,
		stableUnstableOpinions: 50,
		forgivesGiveReminders: 50,

		learnsFastLearnsSlow: 50,
		flexibleThinkerRidgeThinker: 50,
		growUpNotGrowUp: 50,
		historyPromiscuityNoHistory2: 50,
		flexibleThinkerRidgeThinker2: 50,
		abusiveGrewUpNonAbusiveGrewUp: 50,
		noViolentHistoryViolentHistory: 50,
		legalIncomeProofNoProof: 50,
		noDomesticViolenceHistoryDomesticHistory: 50,
		noHateGenderHateGender: 50,
	});
	const [name, setName] = useState('');
	const [age, setAge] = useState('');
	const [country, setCountry] = useState('');
	const [city, setCity] = useState('');
	const [youtubeURL, setYoutubeURL] = useState('');
	const [youtubeURLInfo, setYoutubeURLInfo] = useState(false);
	const dispatch = useDispatch();
	const handleSubmit = e => {
		e.preventDefault();
		let basicInfo = {
			name,
			city,
			country,
			age,
			youtubeURL,
		};
		dispatch(
			updateProfile(uid, {
				basicInfo: basicInfo,
				detailsAboutMe: detailsAboutMe,
				mySkinToneDetails: mySkinToneDetails,
				detailsAboutMyPreferences: detailPreference,
				mySkinTonePreferences: mySkinTonePreferences,
				personalityDetail: profileDetail,
			})
		);
	};

	const youtubeUrlCheck = () => {
		let urlRejex =
			/^https:\/\/www\.youtube\.com\/embed\/[A-Za-z0-9_-]{11}$/;
		if (youtubeURL != '') {
			if (urlRejex.test(youtubeURL)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};
	const handleyoutubeURLInfo = () => {
		setYoutubeURLInfo(!youtubeURLInfo);
	};

	const { user } = useSelector(state => state.authUser);
	const [profilePictureURL, setProfilePictureURL] = useState('');
	 
	useEffect(() => {
		setDetailsAboutMe(user?.detailsAboutMe);
		setProfileDetails(user?.personalityDetails);
		setDetailPreference(user?.detailsAboutMyPreferences);
		setName(user?.name);
		setCountry(user?.country);
		setAge(user?.age);
		setCity(user?.city);
		setYoutubeURL(user?.youtubeURL);
		setProfilePictureURL(user?.profilePictureURL);
	 
		setMySkinToneDetails(user?.mySkinToneDetails);
		setMySkinTonePreferences(user?.mySkinTonePreferences);
	}, [user]);

    const getUserProfileImage = () => {
        let imgSrc = uid ? (profilePictureURL ? user?.profilePictureURL : NA3) : NA;
        return imgSrc;
    }
    const buttonData = [
        { 
            buttonName: 'Big 5',
            percentCompleted: 0,
            iconResults: 'icon-1',
            iconAlt: 'high for extraversion',
            page:'personalityTest',
        },
        { 
            buttonName: 'Communication Style',
            percentCompleted: 0,
            iconResults: 'icon-2',
            iconAlt: 'high for process',
            page:'personalityTest',
        },
        { 
            buttonName: 'Love Language',
            percentCompleted: 0,
            iconResults: 'icon-1',
            iconAlt: 'touch and time',
            page:'personalityTest',
        },
        { 
            buttonName: 'Values & Goals',
            percentCompleted: 0,
            iconResults: 'icon-2',
            iconAlt: 'Icon 2',
            page:'personalityTest',
        },
        { 
            buttonName: 'BBMNP',
            percentCompleted: 0,
            iconResults: 'icon-1',
            iconAlt: 'Icon 1',
            page:'personalityTest',
        },
        { 
            buttonName: "LET\'S UPGRADE YOU",
            percentCompleted: 0,
            iconResults: 'icon-2',
            iconAlt: 'Icon 2',
            page:'letsUpgradeYou',
        },
        { 
            buttonName: 'Jewelry Color',
            percentCompleted: 0,
            iconResults: 'icon-2',
            iconAlt: 'Icon 2',
            page:'jewelry',
        },
        { 
            buttonName: 'Matches',
            percentCompleted: 0,
            iconResults: 'icon-2',
            iconAlt: 'Icon 2',
            page:'matches',
        }
       
    ];
    
    const handleOpenPersonalityTestEvent = (pageName) => {
        if (pageName==='personalityTest') {
            history.push('/admin/mypersonalitytest');

        } else if (pageName==='letsUpgradeYou') {
            history.push('/admin/LetsUpgradeYou');

        } else if (pageName==='jewelry') {
            history.push('/admin/jewelry');

        } else if (pageName==='matches') {
            history.push('/admin/mypersonalitytest');    
        } else { 
            console.error('could not find path=', pageName);
        }
        console.log('pageName=',pageName);
    };


	return (
		<>
		 
			<Container className='mt--7 profile' fluid>
				<Row>
					<Col className='order-xl-2 mb-5  mb-xl-0' xl='4'>
						<Card className='card-profile shadow '>
							<Row className='justify-content-center'>
								<Col className='order-lg-2' lg='3'>
									<div className='card-profile-image '>
                                        <div className="avatar-section">
                                            <AvatarCircle
                                                pictureSource={getUserProfileImage()}
                                                percentCompleted={0}
                                            />
                                        </div>
          

									</div>
								</Col>
							</Row>

							<CardBody className=''>
								<div className='text-center'>
									<Form
										onSubmit={e => {
											handleSubmit(e);
										}}
									>
										<h3>
											{uid ? (
												<>
													{profEdit ? (
														<>
															<FormGroup>
																<Input
																	type='text'
																	placeholder='Edit your name'
																	value={name}
																	onChange={e =>
																		setName(
																			e
																				.target
																				.value
																		)
																	}
																/>
															</FormGroup>
															<FormGroup>
																<Input
																	type='text'
																	placeholder='Edit your Age'
																	value={age}
																	onChange={e =>
																		setAge(
																			e
																				.target
																				.value
																		)
																	}
																/>
															</FormGroup>

															<FormGroup>
																<div className='d-flex justify-content-end p-2 pointer'>
																	<i
																		className='fas fa-info-circle'
																		onClick={() =>
																			setYoutubeURLInfo(
																				true
																			)
																		}
																	></i>
																</div>
																<Input
																	type='text'
																	placeholder='Add your youtube Video'
																	value={
																		youtubeURL
																	}
																	invalid={youtubeUrlCheck()}
																	onChange={e =>
																		setYoutubeURL(
																			e
																				.target
																				.value
																		)
																	}
																/>
													 
															</FormGroup>
														</>
													) : (
													 
														name
													)}
													<div className='h5 font-weight-300'>
														<i className='ni location_pin mr-2' />
														{profEdit ? (
															<>
																<select
																	className='form-control mb-2'
																	value={
																		country
																	}
																	onChange={e =>
																		setCountry(
																			e
																				.target
																				.value
																		)
																	}
																>
																	<option value=''>
																		Select a
																		country
																	</option>
																	<option value='Pakistan'>
																		Pakistan
																	</option>
																	<option value='india'>
																		India
																	</option>
																</select>

																<select
																	className='form-control'
																	value={city}
																	onChange={e =>
																		setCity(
																			e
																				.target
																				.value
																		)
																	}
																>
																	<option value=''>
																		Select a
																		city
																	</option>
																	<option value='Islamabad'>
																		Islamabad
																	</option>
																	<option value='Mumbaii'>
																		Mumbai
																	</option>
																</select>
															</>
														) : (
															<>
																{city} {country}{' '}
																({age})
															</>
														)}
													</div>
													{user.youtubeURL ? (
														<iframe
															width='100%'
															height='auto'
															src={
																user?.youtubeURL
															}
															title='YouTube video player'
															frameborder='0'
															allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
															allowfullscreen
														></iframe>
													) : null}
												</>
											) : (
												<>
													<span className='font-weight-light'>
														NA
													</span>
													<div className='h5 font-weight-300'>
														<i className='ni location_pin mr-2' />
														City, Country (NA)
													</div>
												</>
											)}
										</h3>

										{uid ? (
											<>
												<div className='d-flex justify-content-between'>
													<Button
														onClick={() => {
															toggleProfEdit();
														}}
														className='my-2'
														size='sm'
													>
														{profEdit
															? 'cancel'
															: 'Edit'}
													</Button>
													{youtubeUrlCheck(
														youtubeURL
													) === false ? (
														<Button
															type='submit'
															className='my-2'
															size='sm'
															onClick={() => {
																setTimeout(
																	() => {
																		toggleProfEdit();
																	},
																	2000
																);
															}}
														>
															Save
														</Button>
													) : (
														<Button
															type='button'
															className='my-2'
															size='sm'
														>
															Save
														</Button>
													)}
												</div>
											</>
										) : (
											''
										)}
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
                    <div className="button-section">
                            <div className="u-personalitytest__button-container">
                            {buttonData.map((button, index) => (
                                <ButtonCircleGraph
								id={button.id}
                                key={index}
                                buttonName={button.buttonName}
                                percentCompleted={button.percentCompleted}
                                iconResults={button.iconResults}
                                iconAlt={button.iconAlt}
                                onclick={()=>handleOpenPersonalityTestEvent(button.page)}
                                />
                            ))}
                            </div>
                    </div>


					<Col className='profileSummary text-white order-xl-1' xl='8'>
						<Card className='bg-gogOrange1'>
							<div>
								<Nav tabs>
									<NavItem className=' cursor'>
										<NavLink
											className={`${
												activeTab == '1'
													? 'active p-2'
													: 'p-2'
											} `}
											onClick={() => setActiveTab('1')}
										>
											Personality
										</NavLink>
									</NavItem>
									<NavItem className=' cursor'>
										<NavLink
											className={`${
												activeTab == '2'
													? 'active p-2'
													: 'p-2'
											} `}
											onClick={() => setActiveTab('2')}
										>
											Details About Me
										</NavLink>
									</NavItem>
									<NavItem className=' cursor'>
										<NavLink
											className={`${
												activeTab == '3'
													? 'active p-2'
													: 'p-2'
											} `}
											onClick={() => setActiveTab('3')}
										>
											Details About: My Preferences
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={activeTab}>
									<TabPane tabId='1' className='p-1'>
										<Row className='justify-content-center mt-3 '>
									
										</Row>
										<Row className='d-flex justify-content-between'>
											{/* {personalityDetail.map((item) => { */}
											{/* return ( */}
											<>
												<Col xs='12'>
													<PersonalityDetail
														// item={item}
														edit={edit}
														setEdit={setEdit}
														profileDetail={
															profileDetail
														}
														setProfileDetails={
															setProfileDetails
														}
														name={name}
														city={city}
														country={country}
														age={age}
														youtubeURL={youtubeURL}
														detailsAboutMe={
															detailsAboutMe
														}
														mySkinToneDetails={
															mySkinToneDetails
														}
														detailPreference={
															detailPreference
														}
														mySkinTonePreferences={
															mySkinTonePreferences
														}
													/>
												</Col>
											</>
											{/* ); */}
											{/* })} */}
										</Row>
									</TabPane>
									<TabPane tabId='2'>
										{/* <Row>  <Col xs="12"> */}
										<Stats
											detailsAboutMe={detailsAboutMe}
											setDetailsAboutMe={
												setDetailsAboutMe
											}
											mySkinToneDetails={
												mySkinToneDetails
											}
											setMySkinToneDetails={
												setMySkinToneDetails
											}
										/>
										{uid ? (
											<Button
												type='submit'
												onClick={() => {
													let basicInfo = {
														name,
														city,
														country,
														age,
														youtubeURL,
													};
													dispatch(
														updateProfile(uid, {
															basicInfo:
																basicInfo,
															detailsAboutMe:
																detailsAboutMe,
															mySkinToneDetails:
																mySkinToneDetails,
															detailsAboutMyPreferences:
																detailPreference,
															mySkinTonePreferences:
																mySkinTonePreferences,
															personalityDetail:
																profileDetail,
														})
													);
												}}
											>
												Save Details About You{' '}
											</Button>
										) : (
											''
										)}
										{/* </Col> </Row> */}
									</TabPane>
									<TabPane tabId='3'>
										<Row>
											<Col xs='12'>
												<PersonalityPreferences
													detailPreference={
														detailPreference
													}
													setDetailPreference={
														setDetailPreference
													}
													mySkinTonePreferences={
														mySkinTonePreferences
													}
													setMySkinTonePreferences={
														setMySkinTonePreferences
													}
												/>
												{uid ? (
													<Button
														type='submit'
														onClick={() => {
															let basicInfo = {
																name,
																city,
																country,
																age,
																youtubeURL,
															};
															dispatch(
																updateProfile(
																	uid,
																	{
																		basicInfo:
																			basicInfo,
																		detailsAboutMe:
																			detailsAboutMe,
																		mySkinToneDetails:
																			mySkinToneDetails,
																		detailsAboutMyPreferences:
																			detailPreference,
																		mySkinTonePreferences:
																			mySkinTonePreferences,
																		personalityDetail:
																			profileDetail,
																	}
																)
															);
														}}
													>
														Save Details Preferences
													</Button>
												) : (
													''
												)}
											</Col>
										</Row>
										{/* space holder 2  */}
									</TabPane>{/* space holder 1*/}


								</TabContent>
							</div>
						</Card>
					</Col>
				</Row>
			</Container>

			<Modal
				isOpen={youtubeURLInfo}
				toggle={handleyoutubeURLInfo}
				// centered
			>
				<div className='d-flex justify-content-end pr-3 pt-3'>
					<i
						className='fa fa-times red-icon'
						onClick={handleyoutubeURLInfo}
						aria-hidden='true'
					></i>
				</div>
				<ModalBody className=' py-2 px-2 authcongrats__modalbody'>
					<p className='font-weight-bold text-center px-5 mt-5'>
						Step 1: Go to youtube and click on share button Of
						video.
					</p>
					<div className='d-flex justify-content-center'>
						<img
							src={info1}
							alt=''
							className='img-fluid'
							height={400}
							width={400}
						/>
					</div>

					<p className='font-weight-bold text-center px-5 mt-5'>
						Step 2: Click on embed option to get embed video code.
					</p>
					<div className='d-flex justify-content-center'>
						<img
							src={info2}
							alt=''
							className='img-fluid'
							height={400}
							width={400}
						/>
					</div>

					<p className='font-weight-bold text-center px-5 mt-5'>
						Step 3: Copy src code and paste it in youtubeurl field.
					</p>
					<div className='d-flex justify-content-center'>
						<img
							src={info3}
							alt=''
							className='img-fluid'
							height={400}
							width={400}
						/>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Profile;



/* 
Space holder 1 

<div className="d-flex justify-content-right align-items-center m-4">
	<Button>Save Results</Button>
	<Button>Show Results</Button>
</div>

*/
/*
Space holder 2 
 <img src={heightImg} width="100%" />
                    <Row>
                      <h3 className="m-auto">
                        Check the skin color you are Preferences for a
                        relationships/spouse
                      </h3>
                      <b className="m-auto">
                        This info will not be public it will be kept private
                      </b>
                      <Col
                        md={8}
                        className="d-flex justify-content-center m-auto"
                      >
                        {checkboxesSkin.map((checkbox, index) => (
                          <FormGroup check key={checkbox.value}>
                            <Input type="checkbox" />
                            <Label check className="mr-2 pb-1">
                              {checkbox.label}
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>


	
	*/
	/* space holder 3 
 */