/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
// import Chart from "chart.js"; not using chart due to conflicts
import google from "../assets/img/icons/common/google.png";
import twitter from "../assets/img/icons/common/twitter.png";
import phone from "../assets/img/icons/common/telephone.png";
import gmail from "../assets/img/icons/common/gmail.png";
import yahoo from "../assets/img/icons/common/yahoo.png";
import finishing from "../assets/img/icons/common/finishing.webp";

// react plugin used to create charts. not using chart due to conflicts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
/* not using charts due to conflicts
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

*/
import PersonalityTestTab from "components/PersonalityTest/PersonalityTestTab";
import PersonalityTestPart1 from "components/PersonalityTest/PersonalityTestPart1";
import PersonalityTestPart2 from "components/PersonalityTest/PersonalityTestPart2";
import PersonalityTestPart3 from "components/PersonalityTest/PersonalityTestPart3";
import PersonalityTestPart4 from "components/PersonalityTest/PersonalityTestPart4";
import PersonalityTestPart5 from "components/PersonalityTest/PersonalityTestPart5";
import PersonalityTestPart6 from "components/PersonalityTest/PersonalityTestPart6";

import {defaultButtonData} from "helpers/defaultButtonData"



import Header from "components/Headers/Header.js";
import { useHistory, useParams, withRouter } from "react-router-dom";
import FinishedModal from "components/Modal/FinishedModal";
import { useDispatch, useSelector } from "react-redux";
/*
instructions: 
1. PersonalityTests.js - change it at ^^^  
2. questionsReducers.js - change it there too 
*/
const PersonalityTest = (props) => {
  const { parts } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  console.log("activeTab", activeTab);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

 

  
  const PersonalityTestTabs = [
    { label: "Part 1", value: 1 },
    { label: "Part 2", value: 2 },
    { label: "Part 3", value: 3 },
    { label: "Part 4", value: 4 },
    { label: "Part 5", value: 5 },
    { label: "Part 6", value: 6 },
    { label: "Part 7", value: 7 },
  ];



  //   useEffect(() => {
  //     history.push("/admin/mypersonalitytest/Part1");
  //   }, [history]);

  useEffect(() => {
    if (activeTab == 1) {
      history.push("/admin/mypersonalitytest/Part1");
    } else if (activeTab == 2) {
      history.push("/admin/mypersonalitytest/Part2");
    } else if (activeTab == 3) {
      history.push("/admin/mypersonalitytest/Part3");
    } else if (activeTab == 4) {
      history.push("/admin/mypersonalitytest/Part4");
    } else if (activeTab == 5) {
      history.push("/admin/mypersonalitytest/Part5");
    } else if (activeTab == 6) {
      history.push("/admin/mypersonalitytest/Part6");
    }
  }, [activeTab, history]);

  const { id } = useParams();
  console.log("id==", id);

  useEffect(() => {
    if (id == "Part1") {
      setActiveTab(1);
    } else if (id == "Part2") {
      setActiveTab(2);
    } else if (id == "Part3") {
      setActiveTab(3);
    } else if (id == "Part4") {
      setActiveTab(4);
    } else if (id == "Part5") {
      setActiveTab(5);
    } else if (id == "Part6") {
      setActiveTab(6);
    }
  }, [history]);


  // this block of code handles the prev next buttons 
  const tabFlow = [ 
    {activeTab:1, tabName:'Big 5 Personality'},
    {activeTab:4, tabName:'Communication Style'},
    {activeTab:5, tabName:'Love Language'},
    {activeTab:6, tabName:'Moods 1'},
    {activeTab:3, tabName:'Moods 2'},
    {activeTab:2, tabName:'Temperament'},
    {activeTab:-1, tabName:'Details'},
  ];


  const findInitialIndex = (activeTab, tabFlow) => {
    for (let i = 0; i < tabFlow.length; i++) {
      if (tabFlow[i].activeTab === activeTab) {
        return i;
      }
    }
    return 0; // Default to first tab if not found
  };
  const [tabFlowCounter, setTabFlowCounter] = useState(findInitialIndex(activeTab, tabFlow));

  const handleNextPrevPersonalityTestBtns = (prevOrNext, e) => {
    e.preventDefault();
  
    let newTabFlowCounter = tabFlowCounter;
  
    if (prevOrNext === 'prev') {
      newTabFlowCounter = (tabFlowCounter - 1 >= 0) ? tabFlowCounter - 1 : 0;
    } else if (prevOrNext === 'next') {
      newTabFlowCounter = (tabFlowCounter + 1 < tabFlow.length) ? tabFlowCounter + 1 : tabFlowCounter;
    }
  
    setTabFlowCounter(newTabFlowCounter); // Update tabFlowCounter
    setActiveTab(tabFlow[newTabFlowCounter].activeTab); // Update activeTab based on new tabFlowCounter
  };




  const { uid  } = useSelector((state) => state.authUser);

  const [showImage, setShowImage] = useState(false);

  const history = useHistory();

  const buttonDataShort = defaultButtonData.slice(0,6);
  
  const handleOpenPersonalityTestEvent =  useCallback((id) => {
    //console.log('Button clicked! id=', id);
    let idFound = false; // to track if the ID was found.
    buttonDataShort.forEach(function (val, index) {
        if (val.id===id) {
            idFound = true;
            history.push(val.view);
        }
    });

    if (!idFound) {
        const errMsg = `ID of a profile button click not found: ${id}`;
        // Log the message for debugging
        console.error(errMsg);
        // Send an event to Google Analytics
        gtag('event', 'exception', {
            'description': errMsg,
            'fatal': false
        });
    }
}, []);

  return (
    <>
      <Container fluid>
        <Row className="my-0">
          <Col xl="12">
            <div>
              <div className="mb-5 p-1">
                <Col>
                  <div>
                    {" "}
                    <PersonalityTestTab
                      tabs={PersonalityTestTabs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />{" "}
                    <Row>
                      {activeTab == 1 && (
                        <Col className="px-4">
                          <PersonalityTestPart1 activeTab={activeTab} />
                        </Col>
                      )}
                      {activeTab == 2 && (
                        <Col>
                          <PersonalityTestPart2 activeTab={activeTab} />
                        </Col>
                      )}
                      {activeTab == 3 && (
                        <Col>
                          <PersonalityTestPart3 />
                        </Col>
                      )}
                      {activeTab == 4 && (
                        <Col>
                          <PersonalityTestPart4 />
                        </Col>
                      )}
                      {activeTab == 5 && (
                        <Col>
                          <PersonalityTestPart5 />
                        </Col>
                      )}
                      {activeTab == 6 && (
                        <Col>
                          <PersonalityTestPart6 />
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
                <div className="d-flex personality-test-next-prev-btn-wrap float-right m-3 p-1">
                  {activeTab !== 1 && (
                    <Button 
                      onClick={(e) => {
                        handleNextPrevPersonalityTestBtns('prev', e) ;
                        // setActiveTab(activeTab - 1);
                      }}
                    >
                      Previous: {tabFlowCounter > 0 ? tabFlow[tabFlowCounter - 1].tabName : 'N/A'}
                    </Button>
                  )}
                  {activeTab !== PersonalityTestTabs.length && (
                    <Button 
                      onClick={(e) => {
                        handleNextPrevPersonalityTestBtns('next', e) ;
                        // setActiveTab(activeTab + 1);
                      }}
                    >
                      Next: {tabFlowCounter < tabFlow.length - 1 ? tabFlow[tabFlowCounter + 1].tabName : 'N/A'}
                    </Button>
                  )}
                  {activeTab === PersonalityTestTabs.length && (
                    <>
                      {/* <Button className="bg-site-sky-blue">See Result</Button> */}
                      <Button 
                        onClick={() => {
                          toggle();
                        }}
                      >
                        Finish
                      </Button>
                    </>
                  )}
                  {/* {showImage ? <img className="centered-image"src={finishing} alt="My Image" onClick={() => setShowImage(!showImage)}/> : null } */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <FinishedModal modal={modal} toggle={toggle} img={finishing} />
    </>
  );
};

export default withRouter(PersonalityTest);

// import React, { useState } from "react";

// import { TabManager } from "./tabManager";
// import "./styles.css";

// const TABS = [
//   { label: "Tab 1", value: 1 },
//   { label: "Tab 2", value: 2 },
//   { label: "Tab 3", value: 3 }
// ];
// export default function App() {
//   const [activeTab, handleTab] = useState(1);
//   return (
//     <div className="App">
//       <TabManager tabs={TABS} activeTab={activeTab} handleTab={handleTab} />
//       <div className="tab-content">
//         {" "}
//         <div> Content of Tab {activeTab}</div>
//       </div>
//     </div>
//   );
// }
