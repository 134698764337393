import { LOGIN, 
  LOGIN_LOADER,
  USER_FRIENDLY_ERR_MSG,
   LOGOUT, 
   REGISTER_LOADER } from "../actionTypes";

const initialState = {
  loading: false,
  loginLoading: false,
  userFriendlyMsg: '',
};
// const { userFriendlyMsg, loading, loginLoading } = useSelector(state => state.auth);
export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case USER_FRIENDLY_ERR_MSG:
      return { 
        ...state,
        userFriendlyMsg: payload,

      };
    case REGISTER_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case LOGIN_LOADER:
      return {
        ...state,
        loginLoading: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
