/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import { logout } from "../../store/actions/authActions";
/*
const ForwardedNavbar = React.forwardRef((props, ref) => (
  <Navbar {...props} innerref={ref} />
));
*/
var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  // const createLinks = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.isMenu != false)
  //       if (uid && prop.name == "Login") {
  //         return;
  //       } else {
  //         return (
  //           <NavItem key={key}>
  //             <NavLink
  //               to={prop.layout + prop.path}
  //               tag={NavLinkRRD}
  //               onClick={closeCollapse}
  //               activeClassName="active"
  //             >
  //               <i className={prop.icon} />
  //               {prop.name}
  //             </NavLink>
  //           </NavItem>
  //         );
  //       }
  //   });
  // };
  const createLinks = (routes) => {
    // Split the routes into two arrays
    const bottomRoutes = routes.filter((route) =>
      ["Privacy Policy", "Terms Of Services", "Data Deletion Policy"].includes(
        route.name
      )
    );
    const topRoutes = routes.filter(
      (route) =>
        !["Create Profile",
          "signin",
          "YouHaveMatches",
          "Administration",
          "How Matching Works",
          "LetsUpgradeYou",
          "originalprofile",
          "Privacy Policy",
          "Terms Of Services",
          "Data Deletion Policy",
        ].includes(route.name)
    );

    // Render the links
    return (
      <>
        {topRoutes.map((prop, key) => {
          if (prop.isMenu != false)
            if (uid && prop.name == "Login") {
              return;
            } else {
              return (
                <NavItem key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    {prop.name}
                  </NavLink>
                </NavItem>
              );
            }
        })}
        <div
          className="bottomRoutes"
          // style={{ position: "absolute", bottom: 0 }}
        >
          {bottomRoutes.map((prop, key) => {
            if (prop.isMenu != false)
              if (uid && prop.name == "Login") {
                return;
              } else {
                return (
                  <NavItem key={key}>
                    <NavLink
                      to={prop.layout + prop.path}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      {prop.name}
                    </NavLink>
                  </NavItem>
                );
              }
          })}
        </div>
      </>
    );
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const history = useHistory();

  const sideNavMainRef = useRef();
  const location = useLocation();

  const [transparentTopBar, setTransparentTopBar] = useState(false);

  if (location.pathname.indexOf('user-profile') > -1 || 
  location.pathname.indexOf('mypersonalitytest') > -1 ||
  location.pathname.indexOf('Bet') > -1 ||
  location.pathname.indexOf('LetsUpgradeYou') > -1 ) {

    const nav =  document.getElementById('sidenav-main');
    if (nav) {
      nav.style.backgroundColor ='transparent !important';
      nav.style.zIndex = '2 !important';
      nav.style.position= 'absolute !important';
      nav.style.width= '100% !important';
    }
  
} 



  useEffect(() => {
    const clearStyle = () => {
      setTransparentTopBar('');
    };

    if (location.pathname.indexOf('user-profile') > -1 || 
      location.pathname.indexOf('mypersonalitytest') > -1 ||
      location.pathname.indexOf('Bet') > -1 ||
      location.pathname.indexOf('LetsUpgradeYou') > -1 ) {

      setTransparentTopBar('is-transparent-bar');
    } else {
      setTransparentTopBar('');
    }
    return clearStyle;
  }, [location.pathname]);
  return (
    <Navbar
      className={`navbar-vertical fixed-left navbar-light ${transparentTopBar}`}
      expand="md"
      id="sidenav-main" 
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler special-hover-opacity"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon special-hover-opacity" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="special-hover-opacity pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo?.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        {uid && (
          <Nav className="align-items-center special-hover-opacity d-md-none">
            
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="..." src={user?.profilePictureURL} />
                  </span>
                </Media>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem
                  onClick={(e) => {
                    dispatch(
                      logout(() => {
                        history.push("/admin/home");
                      })
                    );
                  }}
                >
                  <i className="ni ni-user-run" />

                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        )}
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo?.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
  logo:{
    imgSrc: "../diamond-orange-200px-2@2x.png",
    imgAlt: "The GOG Personality Logo"
  }
};


Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes?.string?.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
/*

  <UncontrolledDropdown nav>
						<DropdownToggle nav className='nav-link-icon'>
							<i className='ni ni-bell-55' />
						</DropdownToggle>
						<DropdownMenu
							aria-labelledby='navbar-default_dropdown_1'
							className='dropdown-menu-arrow'
							right
						>
							<DropdownItem>Action</DropdownItem>
							<DropdownItem>Another action</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>Something else here</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>  



                /* <DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-settings-gear-65' />
								<span>Settings</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-calendar-grid-58' />
								<span>Activity</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-support-16' />
								<span>Support</span>
							</DropdownItem> 


*/