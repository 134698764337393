import { LOGIN, LOGOUT } from "../actionTypes";

const initialState = {
  user: null,
  uid: null,
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload.user,
        uid: payload.uid,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        uid: null,
      };

    default:
      return { ...state };
  }
}
