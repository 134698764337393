import Footer from 'components/footer/Footer';
import React from 'react';
import './signIn.scss'
import { useHistory } from 'react-router-dom';

const SignIn = () => {

    const history = useHistory();

    const handleGoToHome = () => {
      history.push("/admin/home");
    };

    return (
      <div className="signin">
        <Footer />

        <button
                className="back-btn"
                onClick={handleGoToHome}
        >
          <svg
            width="22"
            height="13"
            viewBox="0 0 22 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.00602 6.00066L8.85399 1.71005L7.76449 0.745813L1.05664 6.68248L7.76449 12.6191L8.85399 11.6549L4.00602 7.3643L21.4058 7.3643V6.00066L4.00602 6.00066Z"
              fill="white"
              stroke="white"
              strokeWidth="0.5"
            />
          </svg>
        </button>
      </div>
    );
};

export default SignIn;