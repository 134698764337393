import React from 'react';
import { Modal } from 'react-bootstrap';

//import iconC_vision from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-eye-representing-amazin--1@2x.png";
import iconC_action from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-gold-megaphone-icon-1@2x.png";
//import iconC_process from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-green-puzzle-icon-1@2x.png";
import iconC_people from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-red-heart-icon-1@2x.png";

import iconB5_openness from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-light-blue-d--1@2x.png";
import iconB5_agreeableness from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-green-heart-icon-1-1@2x.png";
import iconB5_mixed from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-grey-star-ic--1@2x.png";
import iconB5L_extroverted from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-orange-sun-i--1@2x.png";
import iconB5_introverted from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-purple-ancho--2@2x.png";
import iconB5_Neurotic from "../../assets/img/icons/gogexperience-uhd-photoshoot-cinematic-transparent-red-whirlwin--1@2x.png";
import iconB5_conscientiousness from "../../assets/img/icons/light-blue-check-mark-conscientiousness-1@2x.png";

import iconLL_acts from "../../assets/img/icons/wrench-1@2x.png";
import iconLL_gifts from "../../assets/img/icons/gogexperience-uhd-cinematic-transparent-gold-gift-box-gold-box---1@2x.png";
import iconLL_touch from "../../assets/img/icons/hand-touch-emojid-1-1@2x.png";
import iconLL_time from "../../assets/img/icons/hourglass-1@2x.png";
import iconLL_words from "../../assets/img/icons/speak-emoj-1@2x.png";


//import bgB5 from "../../assets/img/icons/overlay-big5personality-1@2x.png";
//import bgLL from "../../assets/img/icons/gog-----1@2x.png";

const LoveLanguageModal = ({ showModal, handleModalClose, modalContent,animation }) => {
  let content = null;
  const hideOverlay = () => {
    handleModalClose();
  };


  const actsOfService = <div className="lovelang d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">Love Language</h6>
            <img
              className="img-fluid"
              src=  {iconLL_acts}
              alt="Acts of Service icon"
            />
          </div>
        </div>
        <h4 className="font-weight-bold">ACTS OF SERVICE!</h4>
        <p className="font-weight-bold">
        The wrench icon represents the love language of “Acts of Service”, which involves feeling loved through acts of helpfulness, support, and kindness.
        </p>
      </div>
    </div>
  </div>
</div>;

  const gifts = <div className="lovelang d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">Love Language</h6>
            <img
              className="img-fluid"
              src={iconLL_gifts}
              alt="Thoughtful Gifts icon"
            />
          </div>
        </div>
        <h4 className="font-weight-bold">THOUGHTFUL GIFTS!</h4>
        <p className="font-weight-bold">
          Gift box: representing the love language of receiving gifts, which involves feeling appreciated through
          tangible items or gestures.
        </p>
      </div>
    </div>
  </div>
</div>;

const touch = <div className="lovelang d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
         
          <h6 className="mb-4 font-weight-bold">Love Language</h6>
          <img
            className="img-fluid"
            src={iconLL_touch}
            alt="gogExperience_UHD_Cinematic_transparent_gold_gift_box_gold_box__3a46b119-3227-4ce4-a591-efaf664b31fa"
          />
        </div>
      </div>
      <h4 className="font-weight-bold">PHYSICAL TOUCH!</h4>
      <p className="font-weight-bold">
      The hand icon represents physical touch as your preferred way of expressing and understanding affection and loyalty.
      </p>
    </div>
  </div>
</div>
</div>;


const qualityTime = <div className="lovelang d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
        
          <h6 className="mb-4 font-weight-bold">Love Language</h6>
          <img
            className="img-fluid"
            src={iconLL_time}
            alt="gogExperience_UHD_Cinematic_transparent_gold_gift_box_gold_box__3a46b119-3227-4ce4-a591-efaf664b31fa"
          />
        </div>
      </div>
      <h4 className="font-weight-bold">QUALITY TIME!</h4>
      <p className="font-weight-bold">
      The Hour Glass Emoji represents the love language of quality time, which involves feeling loved through spending undivided and focused time with one another.

This is important for example, if you are extremely busy with business, medical school , being a surgeon example we are not going to set you up with someone who’s love language is quality time. This will create less friction in your relationship.
      </p>
    </div>
  </div>
</div>
</div>;

const words = <div className="lovelang d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
         
          <h6 className="mb-4 font-weight-bold">Love Language</h6>
          <img
            className="img-fluid"
            src={iconLL_words}
            alt="This is the Love Language Icon for words of affirmation. It is the speech emoji icon."
          />
        </div>
      </div>
      <h4 className="font-weight-bold">WORDS OF AFFIRMATION</h4>
      <p className="font-weight-bold">
        The “speak” emoji represents the love language of “Words of Affirmation”, which involves feeling loved through verbal praise, compliments, and affirmations
      </p>
    </div>
  </div>
</div>
</div>;

const openness =<div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5_openness}
              alt="This is our icon for openness."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored high for<br/>OPENNESS</h4>
        <p className="font-weight-bold">
        Openness to Experience: This trait refers to an individual's level of imagination, creativity, and willingness to try new things. 

People high in openness tend to be curious, adventurous, and open-minded, while those low in openness tend to be more conventional and prefer routine.
        </p>
      </div>
    </div>
  </div>
  </div>; 


  const conscientiousness = <div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5_conscientiousness}
              alt="This is our icon for conscientiousness."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored high for<br/>CONSCIENTIOUSNESS</h4>
        <p className="font-weight-bold">
        This trait refers to an individual's level of organization, responsibility, and dependability. 

People high in conscientiousness tend to be organized, reliable, and self-disciplined, while those low in conscientiousness tend to be more spontaneous and less reliable.
         </p>
      </div>
    </div>
  </div>
  </div>; 
  const extraversion = <div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5L_extroverted}
              alt="This is our icon for extraversion."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored high for<br/>EXTRAVERSION</h4>
        <p className="font-weight-bold">
        This trait refers to an individual's level of sociability, assertiveness, and outgoingness. 

People high in extraversion tend to be outgoing, talkative, and energetic, while those low in extraversion tend to be more reserved and prefer solitary activities. 
        </p>
      </div>
    </div>
  </div>
  </div>; 
  const agreeableness = <div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5_agreeableness}
              alt="This is our icon for agreeableness."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored high for<br/>AGREEABLENESS</h4>
        <p className="font-weight-bold">
        This trait refers to an individual's level of compassion, cooperativeness, and empathy. 

People high in agreeableness tend to be compassionate, cooperative, & empathetic, while those low in agreeableness tend to be more competitive and less concerned with the feelings of others.
        </p>
      </div>
    </div>
  </div>
  </div>; 
  const neuroticism = <div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5_Neurotic}
              alt="This is our icon for neuroticism."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored high for<br/>NEUROTICISM</h4>
        <p className="font-weight-bold">
        High neuroticism reflects a heightened sensitivity to emotions and a tendency to experience negative feelings more intensely than others. 

It's important to recognize that this does not mean you are overly sensitive or incapable of handling challenging situations.

Being high in neuroticism means you may be more responsive to stress and may require additional time and effort to recover from negative emotions. However, it's worth noting that there are potential positive aspects associated with high neuroticism, such as increased creativity and conscientiousness.

In relationships, individuals with high neuroticism may have a deeper understanding of intense emotions and may be able to connect on a profound level with others who share similar experiences. However, it's crucial to be aware that relationships between highly neurotic individuals can also be more prone to conflict and emotional volatility if both partners struggle to effectively manage their emotions.

Remember that neuroticism is just one aspect of your personality, and it's important to focus on developing coping mechanisms and strategies to navigate challenging situations and maintain emotional well-being.

  </p>
      </div>
    </div>
  </div>
  </div>; 
  const intraversion =<div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>
           
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5_introverted}
              alt="This is our icon for introversion."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored high for<br/>INTROVERSION</h4>
        <p className="font-weight-bold">
        This trait refers to an individual's level of preference for solitude, introspection, and lower levels of social interaction. People who score low on extraversion (high on introversion) tend to enjoy spending time alone or in small groups, find solitude energizing, and may need time alone to recharge their energy. 

They often prefer quiet environments and tend to be more reserved and reflective in their behavior. Introverts may be more focused on internal thoughts and feelings, and may have a smaller circle of close relationships. However, it's important to note that introversion is not the same as shyness or social anxiety, as introverts can still enjoy social interactions and engage with others when needed.
        </p>
      </div>
    </div>
  </div>
  </div>;  
  const mixed = <div className="big5 d-flex justify-content-center">
  <div>
    <div>
      <div className="p-3 text-center"> 
        <div className="position-relative">
          <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
            ><div>X</div>
          </a>
          <div>   
            <h6 className="mb-4 font-weight-bold">The Big 5 Personality Test</h6>
            <img
              className="img-fluid"
              src={iconB5_mixed}
              alt="This is our icon for mixed trait."
            />
          </div>
        </div>
        <h4 className="font-weight-bold">You scored evenly in everything. For all traits you are <br/> MIXED</h4>
        <p className="font-weight-bold">
        A person is classified as MIXED if they score average in all trait areas 
        </p>
      </div>
    </div>
  </div>
  </div>; 
 
const vision = <div className="communicationStyle d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
          <h6 className="mb-4 font-weight-bold">Communication Style</h6>
          <img
            className="img-fluid"
            src={iconC_action}
            alt="This is our icon for vision communicator."
          />
        </div>
      </div>
      <h4 className="font-weight-bold">You scored high for <br/>VISON/IDEA-ORIENTED <br/>communicator.</h4>
      <p className="font-weight-bold">
      The Eye Icon represents your natural tendency to communicate ideas, as creative,  visionary, and innovative.
      </p>
    </div>
  </div>
</div>
</div>; 

const people = <div className="communicationStyle d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
          <h6 className="mb-4 font-weight-bold">Communication Style</h6>
          <img
            className="img-fluid"
            src={iconC_people}
            alt="This is our icon for people oriented communicator."
          />
        </div>
      </div>
      <h4 className="font-weight-bold">You scored high for being a <br/>PEOPLE-ORIENTED <br/>communicator.</h4>
      <p className="font-weight-bold">
      Heart: representing the people communication style, which is empathetic, supportive, and relationship-focused.

You generally start a process by building consensus or seeing what others think.
      </p>
    </div>
  </div>
</div>
</div>; 

const process =<div className="communicationStyle d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
          <h6 className="mb-4 font-weight-bold">Communication Style</h6>
          <img
            className="img-fluid"
            src={iconC_action}
            alt="This is our icon for process oriented communicator."
          />
        </div>
      </div>
      <h4 className="font-weight-bold">You scored high for being a <br/>PROCESS-ORIENTED <br/>communicator.</h4>
      <p className="font-weight-bold">
      Puzzle icon: representing the process communication style, which is analytical, detail-oriented, and logical.

You generally want to start by understanding the exact steps to reach a goal.
      </p>
    </div>
  </div>
</div>
</div>;  

const action = <div className="communicationStyle d-flex justify-content-center">
<div>
  <div>
    <div className="p-3 text-center"> 
      <div className="position-relative">
        <a href="#" onClick={hideOverlay} className="position-absolute top-0 right-0 p-2"
          ><div>X</div>
        </a>
        <div>
          <h6 className="mb-4 font-weight-bold">Communication Style</h6>
          <img
            className="img-fluid"
            src={iconC_action}
            alt="This is our icon for action oriented communicator."
          />
        </div>
      </div>
      <h4 className="font-weight-bold">You scored high for being an <br/>ACTION-ORIENTED <br/>communicator.</h4>
      <p className="font-weight-bold">
      The Megaphone icon: represents the action communication style, which is direct, assertive, and expressive. 

You generally start by communicating the action that you would like to take.
      </p>
    </div>
  </div>
</div>
</div>; 



  // Conditionally set the content based on the modalContent identifier
  if (modalContent === 'gifts') {
    content = gifts;
  } else if (modalContent === 'actsOfService') {
    content = actsOfService;
  } else if (modalContent === 'touch') {
    content = touch;
  } else if (modalContent === 'qualityTime') {
    content = qualityTime;
  } else if (modalContent === 'words') {
    content = words;
  } else if (modalContent === 'openness') {
    content = openness;
  } else if (modalContent === 'conscientiousness') {
    content = conscientiousness;
  } else if (modalContent === 'extraversion') {
    content = extraversion;
  } else if (modalContent === 'agreeableness') {
    content = agreeableness;
  } else if (modalContent === 'neuroticism') {
    content = neuroticism;
  } else if (modalContent === 'intraversion') {
    content = intraversion;
  } else if (modalContent === 'mixed') {
    content = mixed;
  } else if (modalContent === 'vision') {
    content = vision;
  } else if (modalContent === 'people') {
        content = people;
  } else if (modalContent === 'process') {
        content = process;
  } else if (modalContent === 'action') {
        content = action;
  }

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
};

export default LoveLanguageModal;