import React, { useState, useEffect } from 'react';
import firebase from '../../config/firebase';
import { Container, Form, FormGroup, Row, 
  Label, Input, Button,
   Modal, ModalBody } from 'reactstrap';

import './LetsUpgradeYouModal.css';
import { useDispatch, useSelector } from "react-redux";
import EmbeddedUserVideo from '../../components/EmbeddedUserVideo/EmbeddedUserVideo';
import { saveNewCertData } from '../../store/actions/QuestionsAction';
import { getNewCertData } from '../../store/actions/QuestionsAction';


const getPDFByName = async (fileName) => {
  try {
    const storageRef = firebase.storage().ref("pdfsForLoggedInUsers");
    const fileRef = storageRef.child(`${fileName}`);
    console.log('storageRef ', storageRef);
    console.log('fileRef ', fileRef); 
    const url = await fileRef.getDownloadURL();


    console.log('url ', url);
    return url;
  } catch (error) {
    console.error("Error fetching PDF", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};


const LetsUpgradeYouModal = ({ showModal, handleModalClose, certData}) => {
  
  const [pdfPath, setPDFPath] = useState(null);
  
  // Corrected function
  const handleFetchPDFEvent = async (fileName) => {
    console.log('handleFetchPDFEvent =',fileName);
    try {
      const PDFPath = await getPDFByName(fileName);
     // setPDFPath(PDFPath);
      // Option to automatically open the PDF in a new tab
      window.open(PDFPath, '_blank');
    } catch (error) {
      // Handle error (e.g., show a notification to the user)
    }
  };

  console.log ('--certData-- ', certData);
  const [isOpen, setIsOpen] = useState(true);

  const hideOverlay = () => {
    handleModalClose();
  };

  const { uid, user } = useSelector((state) => state.authUser);

  const dispatch = useDispatch();

  const validateLink = (vidPath) => {
    let isValid = true;
    if ( /tiktok|shorts/.test(vidPath) ||
     /youtube\.com|youtu\.be/.test(vidPath) || 
     /tiktok\.com/.test(vidPath)) {
        return vidPath;
      } else {
        return null;
      }
  };

  const validateAnswers = (txt) => {
    if (txt && typeof txt ==='string' && txt.length < 300) {
      return txt;
    } else {
      return null;
    }
  };

  const [whichDidYouDo, setWhichDidYouDo] = useState('');
  const [answer, setAnswer] = useState('');
  const [linkedUserVideo, setLinkedUserVideo] = useState('');
  // hasFetchedData for this id 
   const [hasFetchedData, setHasFetchedData] = useState(false);
 

  const handleCertificateSubmission = (e) => {
    e.preventDefault();
    console.log('handleCertificateSubmission ', e);
    const whichDidYouDo = validateAnswers(e.target.elements.whichDidYouDo.value) ?? null;
    const answer = validateAnswers(e.target.elements.answer.value) ?? null;
    const linkedUserVideo = validateLink(e.target.elements.linkedUserVideo.value)?? null;
    const payload = {
      whichDidYouDo: whichDidYouDo,
      answer: answer,
      linkedUserVideo: linkedUserVideo,
      certDataId: certData.id
    };

    console.log('in  handleCertificateSubmission payload =', payload);
    saveCertDataToDB(payload);
    setHasFetchedData(false);
    return false;
  };

  const saveCertDataToDB = (payload) =>{
    if (uid) {
      dispatch(saveNewCertData(payload, uid));
    } else {
      alert('Please log in to save this');
    }
  };
  const getCertDataToDB = () =>{
  if (uid) {
      dispatch(getNewCertData(uid));
    }   else {
      alert('Please log in to save this');
    }
  };

  const { letsUpgradeYouActivities } = useSelector( (state) => state.questions);
 
  const pdfPaths = {
		'cPhysicallyFit' :  {path:null,  answer:''},
		'cPhysicallyFit2' : {path:"relationship-chapters-pages-484-832-book-june-10-2021-after-image-corrections-gog-book.pdf"},
		'cvalue'          : {path:"JUSTSECTION4.1chapter4--relationship-attitudes-GUARDIANS-OF-GREATNESS-VOLUME1.pdf"},
		'cBlackPride'     : {path: "chapter-2-sample-pages-12-to-121.pdf", answer:''},
		'cTop3'           : {path:"chapter8-relationship-skills-GUARDIANS-OF-GREATNESS-VOLUME1.pdf"},
		'cCBT'            : {path:"chapter11-communities-back-to-greatness-GUARDIANS-OF-GREATNESS-VOLUME1.pdf"},
		'cReferee'        : {path:"chapter8-relationship-skills-GUARDIANS-OF-GREATNESS-VOLUME1.pdf",  answer:''},
		'cHomeCooking'    : {path:null},
		'cFoodSovereignty': {path:null},
	};

  
  useEffect(()=>{
    console.log('letsUpgradeYouActivities=',letsUpgradeYouActivities);

    console.log('letsUpgradeYouActivities[certData.id] ', letsUpgradeYouActivities[certData.id]);
    console.log('hasFetchedData ', hasFetchedData);
    if (!hasFetchedData) {
     // if (!letsUpgradeYouActivities[certData.id]) {
        console.log('Fetching data from the database');
        getCertDataToDB();
        setHasFetchedData(true);
     // }
     
    } else {
      const w = letsUpgradeYouActivities?.[certData.id]?.data.payload.whichDidYouDo ?? "";
      const a = letsUpgradeYouActivities?.[certData.id]?.data.payload.answer ?? "";
      const l = letsUpgradeYouActivities?.[certData.id]?.data.payload.linkedUserVideo ?? "";
      console.log(' w=', w, ' a=',a, ' l=', l);
      setWhichDidYouDo(w);
      setAnswer(a);
      setLinkedUserVideo(l);
     
    }

    if (pdfPaths[certData.id] && pdfPaths[certData.id].path) {
      setPDFPath(pdfPaths[certData.id].path);
    } else {
      setPDFPath(null); // Reset the path if not found
    }
    
   
    // i plan to use this to hold existing data 
  },[letsUpgradeYouActivities, certData.id]);
  
  useEffect(()=>{
   console.log('in whichDidYouDo,answer,linkedUserVideo', whichDidYouDo,answer,linkedUserVideo);  
  }, [whichDidYouDo,answer,linkedUserVideo ] );

  
  const toggleModal = () => {
    setIsOpen(!isOpen);
    handleModalClose();
  }; 

  const CertContent1 = ({certData}) => (
  <> 
    {/* <div className="slide-up-modal-divider"></div> */} 
    <button 
      className="btn btn--close-modal position-absolute top-0 right-0 p-2"
      onClick={() => hideOverlay()}>
         <svg enableBackground="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24"><g><path d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16.9,15.5l-1.4,1.4L12,13.4l-3.5,3.5   l-1.4-1.4l3.5-3.5L7.1,8.5l1.4-1.4l3.5,3.5l3.5-3.5l1.4,1.4L13.4,12L16.9,15.5z"/></g></svg>
     </button>
    <div className="big5 d-flex justify-content-center">
      <div className="p-2"> 


       { (certData.img1 && !certData.img2)?
       <> 
          <Row className='my-3 d-flex justify-content-center'>
            <div className="p-0 col-md-4">
                <img
                  className="img-fluid"
                  src={certData.img1}
                  alt="Picture of our certificate."
                />
            </div>   
            <div className="col-md-8 pt-4"> 
                <h5 className='text-align-left pl-2 my-1'><b>1. Do The Challenge</b></h5> 
                <h5 className='text-align-left pl-2 my-1'><b>2. Link Your Video Proof</b> </h5> 
                <h5 className='text-align-left pl-2 my-1'><b>3. Earn the certificate</b></h5>              
            </div> 
          </Row>
           <h5 className='text-center mb-4'><b>Category: Physical Fitness</b></h5> 
           </>
       :
       <> 
        <div>
          <div className="mt-0">
            <div className='d-flex justify-content-center'>  
                <div className="p-0">
                  <img
                    className="img-fluid"
                    src={certData.img1}
                    alt="Picture of a certificate"
                  />
                </div>
                <div className="p-0"><br/>
                  <img
                    className="img-fluid"
                    src={certData.img2}
                    alt="Picture of our book."
                  />
                </div>
              </div>
          </div>
          <div className='my-4 d-flex justify-content-center'>
            <div> 
                <h5 className='text-center my-1'><b>Do The Challenge</b></h5> 
                <h5 className='text-center my-1'><b>Link Your Video Proof</b> </h5> 
                <h5 className='text-center my-1'><b>Earn the certificate</b></h5> 
                <h5 className='text-center my-1'><b>Category: {certData.title}</b></h5> 
            </div> 
          </div>
      </div>
      </>
        }
        <div className="position-relative">
      
          <div className="ml-3  mr-3 mb-3">
            {/* <h6 className="font-weight-bold mt-3  mb-3">{certData.title}</h6> */}
              <h6 dangerouslySetInnerHTML={{ __html: certData.instructions}} />  
              <Container>
                <Form id="CertContent1" className="my-3" onSubmit={handleCertificateSubmission}>
      
                  {pdfPath && (
                    <Button type='button' className='btn-primary' 
                      onClick={() => handleFetchPDFEvent(pdfPath)}>
                      
                      Open The PDF
                    </Button>
                  )}

                  <FormGroup>
                    <Label className='sr-only' for="whichDidYouDo">Which one did you do? </Label>
                    <Input
                        type="text"
                        id="whichDidYouDo"
                        defaultValue={whichDidYouDo}
                        placeholder="Put Your Answer Here..."
                        required 
                      />
                  </FormGroup>
                  <FormGroup>
                    <Label className='sr-only' for="answer">What was it like?</Label>
                    <Input
                        type="text"
                        id="answer"
                        defaultValue={answer}
                        placeholder="Put Your Answer Here..."
                        required
                      />
                  </FormGroup>
                  <FormGroup>
                    <Label className='sr-only' for="linkedUserVideo">Past your video in this box</Label>
                    <Input
                      type="textarea"
                      id="linkedUserVideo"
                      defaultValue={linkedUserVideo}
                      rows="2"
                      placeholder="Paste your youtube/tiktok video link here. "
                      required
                      
                    />
                  </FormGroup>
                  <Button type="submit" size='lg' className="btn-primary variant-border-radius">Save</Button>
                 
                </Form>
              </Container>
            </div>
        </div>
      </div>
    </div>
  </>
  );

  return (
    <Modal isOpen={showModal} toggle={toggleModal} className={`letsUpgradeYou-Modal overlay-slide-up ${showModal ? 'show' : ''}`} >
      <ModalBody>
       <EmbeddedUserVideo 
        certificateId={certData.id}
        linkedUserVideo={linkedUserVideo} />
        <CertContent1 { ...certData }  />

  
      </ModalBody>
    </Modal>
  );
};
export default LetsUpgradeYouModal;

  /*

import cert1a from "../../assets/img/icons/seal-for-both-leaders-certificate-test@2x.png";
import cert1b from "../../assets/img/icons/chapter-relationship-agreement-1@2x.png";

import cert2a from "../../assets/img/icons/seal-for-both-men-and-womens-certificate-test@2x.png";
import cert2b from "../../assets/img/icons/chap-strip-ames-in-chapter-2.png";

import cert3a from "../../assets/img/icons/seal-for-mens-certificate-test@2x.png";
import cert3b from "../../assets/img/icons/blackmen-section-2@2x.png";

import cert4a from "../../assets/img/icons/seal-for-womens-certificate-test@2x.png";
import cert4b from "../../assets/img/icons/chapter-relationship-skills-1@2x.png";

import cert5a from "../../assets/img/icons/seal-for-both-leaders-certificate-test@2x.png";
import cert5b from "../../assets/img/icons/chapteregypt2-2-1@2x.png";

import cert6a from "../../assets/img/icons/certified-seal-referee@2x.png";
import cert6b from "../../assets/img/icons/chcalculate1-1@2x.png";

import cert7a from "../../assets/img/icons/greenhouse-seal-1-1@2x.png";
import cert7b from "../../assets/img/icons/greenhouse-seal-1@2x.png";

import cert8a from "../../assets/img/icons/certified-seal-referee--oblood@2x.png";
import cert8b from "../../assets/img/icons/seal-fishes--@2x.png";
*/
  /* let content;

  if (certificateId === 'Certificate-Value') {
      content = <CertContent1 />;
  } else if (certificateId ===  'Certificate-Pride') {
      content = <div>Content for Presentation 2</div>;
  } else if (certificateId ===  'Certificate-Top-3') {
      content = <div>Content for Presentation 3</div>;
  } else if (certificateId ===  'Certificate-CBT') {
      content = <div>Content for Presentation 4</div>;
  } else if (certificateId ===  'Certificate-Referee') {
      content = <div>Content for Presentation 5</div>;
  } else if (certificateId === 'Certificate-Home Cooking') {
      content = <div>Content for Presentation 6</div>;
  } else if (certificateId === 'Certificate-Protectors') {
      content = <div>Content for Presentation 7</div>;
  } else if (certificateId ===  'Certificate-FoodSovereignty') {
      content = <div>Content for Presentation 8</div>;
  } else {
      content = null;//  <div>Default Content could not find certificateId </div>;
  }*/
  