const initialState = {
	stat: [],
	loading: false,
};

export default function statsReducer(state = initialState, { type, payload }) {
	switch (type) {
		case 'GET_STATS_BY_ID':
			return {
				...state,
				stat: payload,
			};
		default:
			return {
				...state,
			};
	}
}
