import CustomSelect from "components/customSelect/CustomSelect";
import { useState } from "react";

const DreamPlace = ({ dreamLocation, setDreamLocation }) => {

  console.log(dreamLocation);
  
  const [customDreamLocation, setCustomDreamLocation] = useState("");

  const options = [
    { value: "No Preference at All", label: "No Preference at All" },
    { value: "North America/USA", label: "North America/USA" },
    { value: "Canada", label: "Canada" },
  ];


  const handleChange = ({ option, checked }) => {
      
      if (checked) {
        setDreamLocation((prevDreamLocation) => [...prevDreamLocation, option]);
      } else {
        setDreamLocation((prevDreamLocation) =>
          prevDreamLocation.filter((loaction) => loaction !== option)
        );
      }
  };
  
   const handleAdd = (event) => {
     setCustomDreamLocation(event.target.value);

      if (customDreamLocation.trim() !== "") {
        setDreamLocation((prevSelectedOptions) => [
          ...prevSelectedOptions,
          customDreamLocation,
        ]);
      }
   };

  return (
    <div className="gender">
      <h3 style={{ marginBottom: "0px" }}>
        Where is your Dream Place to Live?
      </h3>

      <div className="dream-place">
        <p>
          Sometimes people marry & are shocked find out that their partner
          REALLY WANTS to live in another country in Jamaica, Brazil, Hawaii -
          and their partner actually leaves.
        </p>
        <p>
          We avoid that problem by matching you on dream locations to live. If
          you REALLY WANT to live somewhere - because it pays the best or
          because you always wanted to live there - select the place(s) below.
        </p>

        <CustomSelect
          options={options}
          multiSelectList={dreamLocation}
          handleChange={handleChange}
          defaultLabel="Select Your Dream Location"
        />

        <div className="other-type-wrapper">
          <input
            className="other-type"
            type="text"
            placeholder="If other, Enter the location here."
            value={customDreamLocation}
            onChange={(event) => setCustomDreamLocation(event.target.value)}
          />
          {customDreamLocation !== "" && (
            <button type="button" onClick={handleAdd}>
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DreamPlace;
