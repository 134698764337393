import {
	Card,
	CardBody,
	Form,
	Input,
	Container,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	CardTitle,
	CardText,
	TabPane,
	Button,
	FormGroup,
	Label,
	Modal,
	ModalBody,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from 'store/actions/authActions';


import PersonalityPreferences from '../../components/PersonalityPreferences';
import PersonalityDetail from '../../components/PersonalityDetail';
import Stats from '../../components/Stats';

/* 
    <PersonalitySummary 
        uid: {uid},
        user:{user}
    />


Documentation about the functionality called. 
    Details about me 
    Details about my preferences 

These 2 views are a list of sliders. 
The user is allowed to edit them at any time. 
The list of slider items are currently in 

    helpers/statesProfile.js 

    it is imported into the following pages. 

     
    PersonalityDetails.js 
    PersonalityPreferences.js 
   
    Stats.js 
    authActions.js 



        helpers/statesProfile.js  provides several exports 

        stats 


        stats has arrays like 
        	{
                left: 'Wants a poly family',
                right: 'Want a mono family',
                value: 80,
                key: 'polyVsMono',
            },
            {
                left: 'Wants Church As a Family Tradition',
                right: 'Does NOT Want Church as a Family Tradition',
                value: 80,
                key: 'churchTradition',
            },



    import { personalityDetail } from 'helpers/statesProfile';



*/
const PersonalitySummary = ({uid, user, onTabChange}) => {
  //  const [uid, user] = useSelector((state) => state.authUser);
/*
    if (!uid || !user) {
        // Retrieve uid and user from the state if not passed as props
        const authUser = useSelector((state) => state.authUser);
        uid = authUser.uid;
        user = authUser.user;
      }
     */
    const [activeTab, setActiveTab] = useState('2');
	const [edit, setEdit] = useState(false);
	const [profEdit, setProfEdit] = useState(false);


	const [aboutMyPreferenceText, setAboutMyPreferenceText] = useState('');
	const [aboutMeText, setAboutMeText] = useState('');

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber); // update the local state
        onTabChange(Number(tabNumber)); // notify the parent component
    };

	const toggleProfEdit = () => {
		setProfEdit(!profEdit);
	};
	const [profileDetail, setProfileDetails] = useState({
        firstName:'',
        lastName:'',
        nickName:'',
        youtubeURL:'',
        tikTokURL:'',
		TypeIAm: '',
		TypeILike: '',
		RelationshipNow: '',
		RelationshipGoal: '',
		Top2FavTVMovieGenre: '',
		Top2FavYoutubeCategory: '',
		ForfunILikeTo: '',
		DreamLocation: '',
		LoveLanguage: '',
		WorkGoals: '',
		CommunicationStyle: '',
	});

	const [mySkinToneDetails, setMySkinToneDetails] = useState({});
	const [mySkinTonePreferences, setMySkinTonePreferences] = useState({});

	const [detailsAboutMe, setDetailsAboutMe] = useState({
        polyVsMono: 50,
        churchTradition: 50,
        SXWorkForALiving: 50,
        addict: 50,
        addictedToPrn: 50,
		submissiveDominant: 50,
		meekArogant: 50,
		controlledImpulse: 50,
		stableVolatile: 50,
		financeMutualDemanding: 50,
		responsibleIrresponsible: 50,
		introvertExtrovert: 50,
		sociallyResponsibleIrresponsible: 50,
		happyAngry: 50,
		lowHighMaintenance: 50,
		thankfulNotThankful: 50,
		bullyNotBully: 50,
		needHighDramaNoDrama: 50,
		hasFriendsNoFriends: 50,
		mischievesNotMischieves: 50,
		notAttensionSeekAttensionSeek: 50,
		historyPromiscuityNoHistory: 50,
		stableUnstableOpinions: 50,
		forgivesGiveReminders: 50,
		learnsFastLearnsSlow: 50,
		flexibleThinkerRidgeThinker: 50,
		growUpNotGrowUp: 50,
		historyPromiscuityNoHistory2: 50,
		flexibleThinkerRidgeThinker2: 50,
		abusiveGrewUpNonAbusiveGrewUp: 50,
		noViolentHistoryViolentHistory: 50,
		legalIncomeProofNoProof: 50,
		noDomesticViolenceHistoryDomesticHistory: 50,
		noHateGenderHateGender: 50,
	});

	const [detailPreference, setDetailPreference] = useState({
        polyVsMono: 50,
        churchTradition: 50,
        SXWorkForALiving: 50,
        addict: 50,
        addictedToPrn: 50,
		submissiveDominant: 50,
		meekArogant: 50,
		controlledImpulse: 50,
		stableVolatile: 50,
		financeMutualDemanding: 50,
		responsibleIrresponsible: 50,
		introvertExtrovert: 50,
		sociallyResponsibleIrresponsible: 50,
		happyAngry: 50,
		lowHighMaintenance: 50,
		thankfulNotThankful: 50,
		bullyNotBully: 50,
		needHighDramaNoDrama: 50,
		hasFriendsNoFriends: 50,
		mischievesNotMischieves: 50,
		notAttensionSeekAttensionSeek: 50,
		historyPromiscuityNoHistory: 50,
		stableUnstableOpinions: 50,
		forgivesGiveReminders: 50,

		learnsFastLearnsSlow: 50,
		flexibleThinkerRidgeThinker: 50,
		growUpNotGrowUp: 50,
		historyPromiscuityNoHistory2: 50,
		flexibleThinkerRidgeThinker2: 50,
		abusiveGrewUpNonAbusiveGrewUp: 50,
		noViolentHistoryViolentHistory: 50,
		legalIncomeProofNoProof: 50,
		noDomesticViolenceHistoryDomesticHistory: 50,
		noHateGenderHateGender: 50,
	});
	const [name, setName] = useState('');
	const [age, setAge] = useState('');
	const [country, setCountry] = useState('');
	const [city, setCity] = useState('');
	const [youtubeURL, setYoutubeURL] = useState('');
	const [youtubeURLInfo, setYoutubeURLInfo] = useState(false);
	const dispatch = useDispatch();


	const handleSubmit = e => {
		e.preventDefault();
		let basicInfo = {
            name: profileDetail?.firstName +' '+ profileDetail?.lastName,
            firstName: profileDetail?.firstName,
            lastName: profileDetail?.lastName,
            nickName: profileDetail?.nickName,
            youtubeURL: profileDetail?.youtubeURL,
            tikTokURL: profileDetail?.tikTokURL,
			city,
			country,
			age,
		};
		dispatch(
			updateProfile(uid, {
				basicInfo: basicInfo,
				detailsAboutMe: detailsAboutMe,
				mySkinToneDetails: mySkinToneDetails,
				detailsAboutMyPreferences: detailPreference,
				mySkinTonePreferences: mySkinTonePreferences,
				personalityDetail: profileDetail,
			})
		);
	};

	const youtubeUrlCheck = () => {
		let urlRejex =
			/^https:\/\/www\.youtube\.com\/embed\/[A-Za-z0-9_-]{11}$/;
		if (youtubeURL != '') {
			if (urlRejex.test(youtubeURL)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};
	const handleyoutubeURLInfo = () => {
		setYoutubeURLInfo(!youtubeURLInfo);
	};

	console.log('PersonalitySummary user = ', user);
	const [profilePictureURL, setProfilePictureURL] = useState('');
	 
	useEffect(() => {
        console.log('inside use effect with user = ', user);
		setDetailsAboutMe(user?.detailsAboutMe);
		setProfileDetails(user?.personalityDetails);
		setDetailPreference(user?.detailsAboutMyPreferences);
		setName(user?.name);
		setCountry(user?.country);
		setAge(user?.age);
		setCity(user?.city);
		setYoutubeURL(user?.youtubeURL);
		setProfilePictureURL(user?.profilePictureURL);
		 
		setMySkinToneDetails(user?.mySkinToneDetails);
		setMySkinTonePreferences(user?.mySkinTonePreferences);
        setAboutMeText(user?.aboutMeText || '');
        setAboutMyPreferenceText(user?.aboutMyPreferenceText || '');

	}, [user]);
    
    const handleChangeMeAboutMeText= (e) => {
        const value = e.target.value || '';
        if (value.length <= 300) { // Check for the 300 character limit
            setAboutMeText(value);
        }
    };
    const handleChangeAboutMyPrefText = (e) => {
        const value = e.target.value || '';
        if (value.length <= 300) { // Check for the 300 character limit
            setAboutMyPreferenceText(value);
        }
    };
    const getUserProfileImage = () => {
        let imgSrc = uid ? (profilePictureURL ? user?.profilePictureURL : NA3) : NA;
        return imgSrc;
    }

    return (
    <Container className='mt--7 p-0 profile' fluid>
        <Row>
            <Col className='profileSummary text-white order-xl-1' xl='8'>
                <Card className='bg-transparent'>
                    <div>
                        <Nav tabs>
                            <NavItem className=' cursor'>
                                <NavLink
                                    className={`${
                                        activeTab == '1'
                                            ? 'active p-2'
                                            : 'p-2'
                                    } `}
                                    onClick={() => handleTabChange('1')}
                                >
                                    Personality
                                </NavLink>
                            </NavItem>
                            <NavItem className=' cursor'>
                                <NavLink
                                    className={`${
                                        activeTab == '2'
                                            ? 'active p-2'
                                            : 'p-2'
                                    } `}
                                    onClick={() => handleTabChange('2')}
                                >
                                    About You
                                </NavLink>
                            </NavItem>
                            <NavItem className=' cursor'>
                                <NavLink
                                    className={`${
                                        activeTab == '3'
                                            ? 'active p-2'
                                            : 'p-2'
                                    } `}
                                    onClick={() => handleTabChange('3')}
                                >
                                    About Your Preferences
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='1' className='p-1'>
                                <Row className='justify-content-center unique-row'>
                            
                                </Row>
                                <Row className='d-flex justify-content-between'>
                                    {/* {personalityDetail.map((item) => { */}
                                    {/* return ( */}
                                    <>
                                        <Col xs='12'>
                                            <PersonalityDetail
                                                // item={item}
                                                edit={edit}
                                                setEdit={setEdit}
                                                profileDetail={
                                                    profileDetail
                                                }
                                                setProfileDetails={
                                                    setProfileDetails
                                                }
                                                name={name}
                                                city={city}
                                                country={country}
                                                age={age}
                                                youtubeURL={youtubeURL}
                                                detailsAboutMe={
                                                    detailsAboutMe
                                                }
                                                mySkinToneDetails={
                                                    mySkinToneDetails
                                                }
                                                detailPreference={
                                                    detailPreference
                                                }
                                                mySkinTonePreferences={
                                                    mySkinTonePreferences
                                                }
                                            />
                                        </Col>
                                    </>
                                    {/* ); */}
                                    {/* })} */}
                                </Row>
                            </TabPane>
                            <TabPane tabId='2'>
                                {/* <Row>  <Col xs="12"> */}
                                <Stats
                                    detailsAboutMe={detailsAboutMe}
                                    setDetailsAboutMe={
                                        setDetailsAboutMe
                                    }
                                    mySkinToneDetails={
                                        mySkinToneDetails
                                    }
                                    setMySkinToneDetails={
                                        setMySkinToneDetails
                                    }
                                />


                            <FormGroup>
                                <Label for="aboutMeText">About Me Text</Label>
                                <Input 
                                    type="textarea" 
                                    name="aboutMeText" 
                                    placeholder="Type some text about YOUR preferences."
                                    id="aboutMeText" 
                                    value={aboutMeText} 
                                    onChange={handleChangeMeAboutMeText} 
                                    maxLength="300" // Optionally, enforce the limit on the HTML level
                                />
                                <small>{aboutMeText.length}/300</small> {/* Display character count */}
                                </FormGroup>
                                {uid ? (
                                    <div className=' d-flex justify-content-center'>
                                    <Button className='btn slider-btn-save'
                                        type='submit'
                                        onClick={() => {
                                            let basicInfo = {
                                                name: profileDetail?.firstName +' '+ profileDetail?.lastName,
                                                firstName: profileDetail?.firstName,
                                                lastName: profileDetail?.lastName,
                                                nickName: profileDetail?.nickName,
                                                youtubeURL: profileDetail?.youtubeURL,
                                                tikTokURL: profileDetail?.tikTokURL,
                                                city,
                                                country,
                                                age,
                                              
                                            };
                                            dispatch(
                                                updateProfile(uid, {
                                                    basicInfo:
                                                        basicInfo,
                                                    detailsAboutMe:
                                                        detailsAboutMe,
                                                    mySkinToneDetails:
                                                        mySkinToneDetails,
                                                    detailsAboutMyPreferences:
                                                        detailPreference,
                                                    mySkinTonePreferences:
                                                        mySkinTonePreferences,
                                                    personalityDetail:
                                                        profileDetail,
                                                        aboutMeText:
                                                        aboutMeText,
                                                })
                                            );
                                        }}
                                    >
                                        Save Details About YOU
                                    </Button>
                                    <p>Then click the tab that says "Details About your Preferences"</p>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {/* </Col> </Row> */}
                            </TabPane>
                            <TabPane tabId='3'>
                                <Row>
                                    <Col xs='12'>
                                        <PersonalityPreferences
                                            detailPreference={
                                                detailPreference
                                            }
                                            setDetailPreference={
                                                setDetailPreference
                                            }
                                            mySkinTonePreferences={
                                                mySkinTonePreferences
                                            }
                                            setMySkinTonePreferences={
                                                setMySkinTonePreferences
                                            }
                                        />
                                      <FormGroup>
                                        <Label for="aboutMyPreferenceText">About My Preference Text</Label>
                                        <Input 
                                            type="textarea" 
                                            name="aboutMyPreferenceText" 
                                            id="aboutMyPreferenceText" 
                                            placeholder="Type some text about your signifiant others Preferences."
                                            value={aboutMyPreferenceText} 
                                            onChange={handleChangeAboutMyPrefText} 
                                            maxLength="300" // Optionally, enforce the limit on the HTML level
                                        />
                                        <small>{aboutMyPreferenceText.length}/300</small> {/* Display character count */}
                                        </FormGroup>

                                        {uid ? (
                                            <div className=' d-flex justify-content-center'>
                                            <Button  className='btn slider-btn-save'
                                                type='submit'
                                                onClick={() => {
                                                    let basicInfo = {
                                                        name: profileDetail?.firstName +' '+ profileDetail?.lastName,
                                                        firstName: profileDetail?.firstName,
                                                        lastName: profileDetail?.lastName,
                                                        nickName: profileDetail?.nickName,
                                                        youtubeURL: profileDetail?.youtubeURL,
                                                        tikTokURL: profileDetail?.tikTokURL,
                                                        city,
                                                        country,
                                                        age,
                                                        
                                                    };
                                                    dispatch(
                                                        updateProfile(
                                                            uid,
                                                            {
                                                                basicInfo:
                                                                    basicInfo,
                                                                detailsAboutMe:
                                                                    detailsAboutMe,
                                                                mySkinToneDetails:
                                                                    mySkinToneDetails,
                                                                detailsAboutMyPreferences:
                                                                    detailPreference,
                                                                mySkinTonePreferences:
                                                                    mySkinTonePreferences,
                                                                personalityDetail:
                                                                    profileDetail,
                                                                aboutMyPreferenceText:
                                                                    aboutMyPreferenceText,
                                                            }
                                                        )
                                                    );
                                                }}
                                            >
                                                Save Details About Your Preferences
                                            </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </Col>
                                </Row>
                                {/* space holder 2  */}
                            </TabPane>{/* space holder 1*/}


                        </TabContent>
                    <div className='gog-lower-tabs'>
                        <Nav tabs>
                            <NavItem className=' cursor'>
                                <NavLink
                                    className={`${
                                        activeTab == '1'
                                            ? 'active p-2'
                                            : 'p-2'
                                    } `}
                                    onClick={() => handleTabChange('1')}
                                >
                                    Personality
                                </NavLink>
                            </NavItem>
                            <NavItem className=' cursor'>
                                <NavLink
                                    className={`${
                                        activeTab == '2'
                                            ? 'active p-2'
                                            : 'p-2'
                                    } `}
                                    onClick={() => handleTabChange('2')}
                                >
                                    About You
                                </NavLink>
                            </NavItem>
                            <NavItem className=' cursor'>
                                <NavLink
                                    className={`${
                                        activeTab == '3'
                                            ? 'active p-2'
                                            : 'p-2'
                                    } `}
                                    onClick={() => handleTabChange('3')}
                                >
                                    About Your Preferences
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>



                    </div>
                </Card>
            </Col>
        </Row>
    </Container>
    );
};

export default PersonalitySummary;