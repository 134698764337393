/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react';
import {
	useLocation,
	Route,
	Switch,
	Redirect,
	useHistory,
} from 'react-router-dom';
// reactstrap components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import logo from '../assets/img/brand/diamond-orange-200px-2@2x.png';
import routes from 'routes.js';
import { useDispatch, useSelector } from 'react-redux';
import { getCompleteResult } from 'store/actions/QuestionsAction';
import { reRegisterSnapShot } from 'store/actions/authActions';
// marked for deletion import { getStats } from 'store/actions/StatsAction';


/*

 fix up the presentation of the default user when user is not logged in 
1. matching 
2. webhook for paypal 
3. invitation to influencers 
4. making a massive request ot influencer after 3 i working 
5. real time presentation of referre id 
 with the real time database 
6. show the other person to make b3ets on 


*/

const Admin = props => {
	const mainContent = React.useRef(null);
	const location = useLocation();
	const { uid } = useSelector(state => state.authUser);
	const dispatch = useDispatch();
	const history = useHistory();

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);
/* 
	
thse are very expensive queries an definitely should not be here. 
useEffect(() => {
		if (uid !== null) dispatch(getCompleteResult(uid));
	}, [uid]);

	useEffect(() => {
		if (uid) {
			dispatch(reRegisterSnapShot(uid, history, () => {}));
		}
	}, []);

	*/

	
/* 
	marked for deletion 
useEffect(() => {
		if (uid) {
			dispatch(getStats(uid));
		}
	}, []);
*/
	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	const getBrandText = path => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return 'Brand2';
	};

	const hideSidebar_Navbar =
    location.pathname === "/admin/on-boarding" ||
    location.pathname === "/admin/home" ||
	location.pathname === "/admin/signin" || 
    location.pathname === "/admin/create-profile";
	
	//console.log('logo =', logo );

	let cssStyle = '';
	if (/mypersonalitytest\/Part1/.test(location.pathname) || 
		/mypersonalitytest\/Part5/.test(location.pathname) || 
	    /mypersonalitytest\/Part2/.test(location.pathname) ) {
		cssStyle = ' style-js-BlackBackground u-js-centered'; // center 

	} else if (/mypersonalitytest\/Part3/.test(location.pathname) || 
		/mypersonalitytest\/Part4/.test(location.pathname) || 
		/mypersonalitytest\/Part6/.test(location.pathname) ) {
		cssStyle = ' style-js-BlackBackground'; // center 
	}
	const pageStyleClassName = `main-content ${cssStyle}`;
	return (
    <>
      {!hideSidebar_Navbar && (
        <Sidebar
          {...props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
			imgSrc: logo, // "../../assets/img/brand/diamond-orange-200px-2@2x.png",
			imgAlt: "The GOG Personality Logo is a diamond with a couple encased. It represents unbreakable strength forged from adversity."
            
          }}
        />
      )}
      <div className={pageStyleClassName} ref={mainContent}>
        {!hideSidebar_Navbar && (
          <AdminNavbar
            {...props}
            routes={routes}
            brandText={''} /* getBrandText(props.location.pathname) */
          />
        )}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
      </div>
    </>
  );
};

export default Admin;
