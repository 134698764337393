import firebase from "../config/firebase"; // replace with your actual import

const handleImageChange = (setImage, setImageUrl, setProfileInfo, uid) => async (e) => {
    console.log('inside handleImageChange filuploadUtil');


  const file = e.target.files[0];
  if (file && file.type.includes("image") && file.size <= 6 * 1024 * 1024) {
    const previewUrl = URL.createObjectURL(file);
    setImage(previewUrl);

    const storageRef = firebase.storage().ref("users");
    const fileRef = storageRef.child(`${uid}/${uid}`);

    try {
      await fileRef.put(file);
      const fileUrl = await fileRef.getDownloadURL();
      setImageUrl(fileUrl); // Update the Context

      // Update the parent component
      setProfileInfo({
        img: fileUrl,
        imageUrl: fileUrl,
        imageFileName: uid
      });
    } catch (error) {
      alert("Upload Failed: " + error.message);
    }
  } else {
    alert("Please select an image file less than 6 MB");
  }
};
export default handleImageChange;
