import firebase from '../../config/firebase';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import '../../components/ProfileTest/ProfileTest.css'; 
import AvatarCircle from '../../components/AvatarCircle/AvatarCircle';
import ButtonCircleGraph from '../../components/ButtonCircleGraph/ButtonCircleGraph';
import BackButton from '../../components/BackButton/BackButton';

import certChapt4Pages from "../../assets/img/icons/chcalculate1-1@2x.png";
import certChapt4Seal from "../../assets/img/icons/seal-for-both-leaders-certificate-test@2x.png";

import certChapt2Pages  from "../../assets/img/icons/chapteregypt2-2-1@2x.png";
import certChapt2Seal from "../../assets/img/icons/seal-for-both-men-and-womens-certificate-test@2x.png";

import certChap8Pages from "../../assets/img/icons/chapter-relationship-skills-1@2x.png";
import certChap8Seal from "../../assets/img/icons/seal-for-womens-certificate-test@2x.png";

import chapterRefereeSeal from "../../assets/img/icons/certified-seal-referee@2x.png";
import chapterRefereePages from "../../assets/img/icons/blackmen-section-1@2x.png";

import chapterCBTSeal from "../../assets/img/icons/certified-seal-referee@2x.png";
import chapterCBTPages from "../../assets/img/icons/chapter4-section-4-cbt.png";

import certifSprinter1 from "../../assets/img/icons/certificte-runner-seal-1.png";
import certifSprinter2 from "../../assets/img/icons/sprinter-beautiul-tled.png";


import cert1a from "../../assets/img/icons/seal-for-both-leaders-certificate-test@2x.png";
import cert1b from "../../assets/img/icons/chapter-relationship-agreement-1@2x.png";
import gogPersonalityLogo from '../../assets/img/brand/logo.c784cb44bc36ebf9431febfa75db79c0.svg';
import ReactModal from 'react-modal';
import imgDesktopOpen from '../../assets/images/DESKTOPUX-4.png';
import imgDesktopClosed from '../../assets/images/DESKTOPUX-3.png'; 
import imgDesktopDefault from '../../assets/images/woman7.png'; 
import { getNewCertData } from '../../store/actions/QuestionsAction';

import { Button, Col, Row } from 'reactstrap';
import BadgesSection from '../../components/BadgesSection/BadgesSection';
import LetsUpgradeYouModal from './LetsUpgradeYouModal';
import './LetsUpgradeYouModal.css';
const LetsUpgradeYou = () => {
    
  const { uid, user } = useSelector((state) => state.authUser);
   
const dispatch = useDispatch();
  const avatarData = {
        percentCompleted: 40,
        userName:"Jael Lovelli",
        notificationIconData: 2
    };

    const userDataProfile = {
        userName:"Jael Lovelli",
        ageRange:""
    };
  
    const { letsUpgradeYouActivities } = useSelector( (state) => state.questions);
  
    useEffect(()=>{
      console.log('preparing to place letsUpgradeYouActivities', letsUpgradeYouActivities);
   
      if (!letsUpgradeYouActivities?.cPhysicallyFit?.data) {
        console.log('getCertDataToDB()=');
        if (uid) {
          dispatch(getNewCertData(uid));
        } 
      }
    },[]);

    const isChallengeComplete = (certId) => {
      const activity = letsUpgradeYouActivities?.[certId]?.data;
      return activity?.payload.whichDidYouDo && activity?.payload.answer && activity?.payload.linkedUserVideo ? 100 : 0;
    };
 
    let buttonData = [
      { 
        id:'cPhysicallyFit',
          buttonName: 'Certificate - Physically Fit 1',
          percentCompleted: 0,
          iconResults: 'icon-2',
          iconAlt: 'Icon 2',
          cssid: 'Physically-Fit1',
          certData:{
            id:'cPhysicallyFit',
            img1: certifSprinter2,
            img2: null, 
            title:'Physically Fit 1',
            instructions:'<b>Being Physically Fit is attractive.<br/>\
            Step 1: Choose 1 or all.</b><br/>\
            <ul class="mt-2 pl-4">\
            <li>100 meter dash less than 30 seconds.</li>\
            <li>200 meter dash less than 60 seconds.</li>\
            <li>400 meter dash less than 3 minutes</li>\
            <li>Pull ups 8</li>\
            </ul>\
            <b>Step 2: Answer the question. What was it like? </b><br/>\
            <b>Step 3: Paste your youtube or tiktok path showing that you achieved it!</b><br/>'
             
          }
      },
      { 
        id:'cPhysicallyFit2',
          buttonName: 'Certificate - Physically Fit 2',
          percentCompleted: 0,
          iconResults: 'icon-2',
          iconAlt: 'Icon 2',
          cssid: 'cPhysicallyFit2',
          certData:{
            id:'cPhysicallyFit2',
            img1: certifSprinter2,
            img2: null, 
            title:'Physically Fit 2',
            instructions:'<b>Being Physically Fit is attractive.<br/>\
            Step 1: Choose 1 or all.</b><br/>\
            <ul class="mt-2 pl-4">\
            <li>20 or more burpees in 2 minutes. 2 sets </li>\
            <li>30 or more burpees in 2 minutes. 2 sets </li>\
            </ul>\
            <b>Step 2: Answer the question. What was it like? </b><br/>\
            <b>Step 3: Paste your youtube or tiktok path showing that you achieved it!</b>'
             
          }
      },
      { 
          id:'cvalue',
          buttonName: 'Certificate - Value',
          percentCompleted: 0,
          iconResults: 'icon-1',
          iconAlt: 'high for extraversion',
          cssid: 'Certificate-Value',
          certData:{
            id:'cvalue',
            img1: certChapt4Seal,
            img2: certChapt4Pages,
            title:'Calculate the REAL VALUE of your Sig/O',
            instructions:'<b>Step 1: Do the exercise in Chapter 4 section 1</b>.<br/>\
            It will dramatically upgrade the way you value your partner & vice versa<br/> \
            Do this exercise as a group or on your own. \
            <b>Step 2: When you are finished, type in the total amount that you calculated as the real value of a decent partner at home.</b><br/>\
            <b>Step 3: Paste your youtube or tiktok path showing your thoughts about that exercise.</b>.\
            <br/><a href="#">Here is the link the sample of the book </a>'
            
          }
      },
      { 
        id:'cBlackPride',
          buttonName: 'Certificate - Black Pride',
          percentCompleted:0,
          iconResults: 'icon-2',
          iconAlt: 'high for process',
          cssid: 'Certificate-Pride',
          certData:{
            id:'cBlackPride',
            img1:certChapt2Seal,
            img2:certChapt2Pages,
            title:'Black Pride Upgrades',
            instructions:'<b>Step 1: Check out Chapter 2: Greatness is in your DNA.</b><br/>\
            <b>Step 2: Answer this question: Did it upgrade your confidence in Black people? In what way?</b><br/>\
            <b>Step 3: Post a youtube or tiktok link to you saying this.</b><br/>\
            <br/><a href="#"  target="_new">Here is the link the sample of the book </a>'
          }
      },
      { 
        id:'cTop3',
          buttonName: 'Certificate - Top 3',
          percentCompleted:0,
          iconResults: 'icon-1',
          iconAlt: 'touch and time',
          cssid: 'Certificate-Top-3',
          certData:{
            id:'cTop3',
            img1: certChap8Seal,
            img2: certChap8Pages,
            title:'Chapter 8 - The Most Important Relationships skills. ',
            instructions: 'You can READ it at <a href="https://ideas.ted.com/the-3-core-skills-that-every-person-needs-for-healthy-romantic-relationships" target="_blank" rel="noopener noreferrer">this TED Talk Link</a> <br/>\
            You can WATCH it on TED TALKS talks at <a href="https://www.youtube.com/watch?v=gh5VhaicC6g" target="_blank" rel="noopener noreferrer">this TED TALK YouTube link</a> <br/>\
            Or you can read about it in the Guardians of Greatness book chapter 10 section 1 <br/>\
            Then compare it to Chapter 8 section 1. Summarize the most important relationship skills. you can just put up a video explaining it.<br/>\
            <br/><a href="#"  target="_new">Here is the link the sample of the book </a>'

          }
      },
      { 
        id:'cCBT', // unique id 
          buttonName: 'Certificate - New Relationship Traditions',
          percentCompleted: 0,
          iconResults: 'icon-2',
          iconAlt: 'Icon 2',
          cssid: 'Certificate-CBT',
          certData:{
            id:'cCBT',
            img1:chapterCBTSeal,
            img2:chapterCBTPages,  
            title:'New Relationship Traditions',
            instructions:'Chapter 9 and 10 present some amazing new relationship traditions.<br/>\
            <b>Step 1: Check out the traditions</b> <br/>\
            <b>Step 2: List at least 3 common problems that these new traditions will solve. </b> <br/>\
            <b>Step 3: Say it on youtube or tiktok and  past the link below</b> <br/>\
            <br/><a href="#"  target="_new">Here is the link the sample of the book </a>'
          }
      },
      { 
        id:'cReferee',
          buttonName: 'Certificate - Referee',
          percentCompleted: 0,
          iconResults: 'icon-1',
          iconAlt: 'Icon 1',
          cssid: 'Certificate-Referee',
          certData:{
            id:'cReferee',
            img1:chapterRefereeSeal,
            img2:chapterRefereePages,
            title:'The Relationship Referee ',
            instructions:'Chapter 10 presents The Power of Groups in relationships & families.\
             WE have a great way of giving you agreat new extended family. The relationship referee traditions teaches you how toget along and help each other.<br/>\
             <b>Step 1: Check out the traditions</b> <br/>\
             <b>Step 2: Type 3 common relationship problems that this will solve. </b><br/>\
             <b>Step 3: Share what you think about it on youtube and tiktok and paste the link below</b> <br/>\
             <br/><a href="#" target="_new">Here is the link the sample of the book </a>'
          }
      },
      {
        id:'cHomeCooking',
          buttonName: 'Certificate - Home Cooking',
          percentCompleted: 0,
          iconResults: 'icon-2',
          iconAlt: 'Icon 2',
          cssid: 'Certificate-HomeCooking',
          certData:{
            id:'cHomeCooking',
            img1:cert1a,
            img2:cert1b,
            title:'Cooking For the Family',
            instructions:'<b>Step 1: Make food for a family of 4.</b><br/>\
            <b>Step 2: Type the name of the foods you made.</b>\
            <b>Step 3: Paste the link to your youtube or tiktok video doing it. </b>'
          }
      }, 
      {
        id:'cFoodSovereignty',
          buttonName: 'Certificate - Food Sovereignty',
          percentCompleted: 0,
          iconResults: 'icon-2',
          iconAlt: 'Icon 2',
          cssid: 'Certificate-FoodSovereignty',
          certData:{
            id:'cFoodSovereignty',
            img1:cert1a,
            img2:cert1b,
            title:'Cooking What You Grow',
            instructions:'<b>Step 1: Make food for a family of 4 that you grew or catched.</b><br/>\
            <b>Step 2: Type the name of the foods you made.</b>\
            <b>Step 3: Paste the link to your youtube or tiktok video doing it. </b>'  
          }
      }
    ];

 

    useEffect(() => {
      // Create a new updated array based on the original buttonData
      const updatedButtonData = buttonData.map((button) => {
        return {
          ...button,
          percentCompleted: isChallengeComplete(button.id)
        };
      });
    
      // Update the buttonData with the new array
      buttonData = updatedButtonData;
    }, [letsUpgradeYouActivities]);
 



    const [showModal, setShowModal] = useState(false);

    const [certData, setCertData] = useState(null);

    const handleModalOpen = (cssid) => {
      
      const o =  buttonData.find(item => item.cssid===cssid);
      console.log('o=',o);
      setCertData(o);
      setShowModal(true);
    };
  
    const handleModalClose = () => {
      setShowModal(false);
       
    };

    const buttonRefs = useRef([]); // Create a ref to store the button references

    const saveButtonRef = (ref, index) => { 
        buttonRefs.current[index] = ref;
       // console.log('saveButtonRef  buttonRefs', buttonRefs );
    };
    
    const [userPathFromFirebaseCloud, setUserPathFromFirebaseCloud] = useState(null);
    
    const getImageUrl = async (uid, fileName) => {
        if (!uid || !fileName) {
            return null;
        }
        const storageRef = firebase.storage().ref("users");
        const fileRef = storageRef.child(`${uid}/${fileName}`);
        try {
          const url = await fileRef.getDownloadURL();
          setUserPathFromFirebaseCloud(url); 
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
    };

    useEffect(() => {
        getImageUrl(uid, uid);
    },[]);
    ReactModal.setAppElement('#root');

    console.log('buttonData=', buttonData, buttonData[0].certData.img1);

    const getBackgroundStyle = (tabIndex) => {
      switch (tabIndex) {
          case 0:
          return 'linear-gradient(135deg, #fb6d26, #ff9e64)';
          case 1:
          return 'linear-gradient(135deg, rgb(251, 109, 38), rgb(255, 158, 100)'; // 'linear-gradient(135deg, #26fb6d, #64ff9e)';
          case 2:
          return 'linear-gradient(135deg, rgb(251 109 38), rgb(100, 100, 255)';
          case 3:
          return 'linear-gradient(45deg, darkgreen, green)';
          default:
          return 'linear-gradient(135deg, #fb6d26, #ff9e64)';
      }
  };

  const [infoModal, setInfoModal] = useState(false);
  
  const [activeQuestion, setQuestion] = useState(0);

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  return (
    <React.Fragment>
      <Link className='u-img--logo-left'  to='/admin/user-profile'>
       <img className='u-img--logo-left' 
            src={gogPersonalityLogo} alt='gogPersonality.com logo' />
      </Link>
      <div className="letsUpgradeYou-container">
       
       
        <BackButton/>
       
        <BadgesSection user={ user } />

        <div className='new-avatar'>
          <img className='new-avatar-img-top' src={imgDesktopOpen} />
          <div className='new-avatar-userImg-container'>
              <img src={  userPathFromFirebaseCloud ||  imgDesktopDefault }
                  className='new-avatar-userImg'
                  id='u-js-userImg' />
          </div>
          <img className='new-avatar-img-bottom' src={imgDesktopClosed}  alt='This is a background image to make the profile page look pretty. It expects your profile picture to have a transparent background in png format. To remove your profile pictures background you can go to  adobe express to remove your profile picture for free.  Then upload it to this site as your profile picture'  />
          <div className='new-avatar-bg-gradient'></div>
        </div>

        <div className="notification-bell"></div>
       
        <div className='new-avatar-container-inner'>
          <div className="name-description">
            <p>Let's Upgrade You!</p>
          </div>
          
          <div className="button-section">
            <div className="u-personalitytest__button-container">
              {buttonData.map((button, index) => (
                  <ButtonCircleGraph
                  key={index}
                  id={button.id}
                  buttonName={button.buttonName}
                  percentCompleted={button.percentCompleted}
                  msgData={button.msgData}
                  iconResults={button.iconResults}
                  iconAlt={button.iconAlt}
                  ref={(ref) => saveButtonRef(ref, index)}
                  onclick = { () => handleModalOpen(button.cssid) }       
                  />
              ))}
            </div>
          </div>
        </div>
        {showModal && certData && (
          <LetsUpgradeYouModal
            showModal={showModal}
            handleModalClose={handleModalClose}
            certData={certData}>
        </LetsUpgradeYouModal>
       )};

    </div>
    </React.Fragment>
)};
export default LetsUpgradeYou;

/*



        <ReactModal
          isOpen={infoModal}
          onRequestClose={toggleInfoModal} 
          style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowX:"scroll",
                zIndex:100000,
                backgroundColor: 'rgba(0, 5,  5, 0.75)'
              },
              content: {
                  position: 'absolute',
                  top: '40px',
                  left: '60px',
                  right: '60px',
                  maxWidth: '560px',
                  margin: '0 auto',
                  width: 'auto',
                  bottom: 'initial',
                  border: 'none', 
                  paddingTop: '68px',
                  background: getBackgroundStyle(activeQuestion),
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '0',
                  outline: 'none',
                  padding: '20px'
              }
          }}> 
        <p className="white-text"> We have great activities make you a great relationship partner.
        Do some of those activities and earn a certificate and be 
        recognize for the skills you learned.  Click these buttons for info.
        </p>
      </ReactModal>

      */