import { personalityDetail } from 'helpers/statesProfile';
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Input } from 'reactstrap';
import { updateProfile } from 'store/actions/authActions';


const PersonalityDetail = ({
	edit,
	setEdit,
	profileDetail,
	setProfileDetails,
	detailsAboutMe,
	mySkinToneDetails,
	detailPreference,
	mySkinTonePreferences,
	name,
   /* firstName,
    lastName,
	nickName, */
	city,
	country,
	age,
	youtubeURL,
}) => {

	const PersonalityDetails = () => {
		return Object.assign(
			{},
			...personalityDetail.map(x => ({ [x.key]: x.value }))
		);
	};

	const personalityDetailLabel = value => {
		let obj = personalityDetail.find(ele => ele.key === value);
		if (obj) {
			return obj?.label;
		}
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setProfileDetails(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const { uid, user } = useSelector(state => state.authUser);

	const dispatch = useDispatch();

	const getDataObject = user => {
		if (user == null) {
			return PersonalityDetails();
		} else {
			return profileDetail;
		}
	};
	const getOrderlyProfileDetails = (dataObject, personalityDetail) => {
		return personalityDetail.map(detail => ({
		  label: detail.label,
		  value: dataObject[detail.key] || '',
		  key: detail.key,
		}));
	};

	const dataObject = getDataObject(user);

	const orderedProfileDetails = getOrderlyProfileDetails(dataObject, personalityDetail);
/*
console.log('dataObject=',dataObject);
console.log('orderedProfileDetails = ',orderedProfileDetails );
console.log('profileDetail=',profileDetail);
console.log('orderedProfileDetails = ',orderedProfileDetails ); 

console.log('PersonalityDetails() =',PersonalityDetails());
*/
	return (
		<React.Fragment>

			<table className="profile_summary_table">
				<tbody>
					{orderedProfileDetails &&
						orderedProfileDetails.map(detail => {
							return (
								<tr key={detail.key} className='profile__detail'>
									<td className='profile__detail--question py-2 '>
										{detail.label}
									</td>
									<td className='profile__detail--answer py-2'>
										{edit ? (
											<FormGroup className='mb-0'>
												<Input
													type='text'
													name={detail.key}
													value={detail.value  || ''}
													onChange={handleChange}
												/>
											</FormGroup>
										) : (
											<span>
												{detail.value}
											</span>
										)}
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<div>

				{uid ? (
					<>
						<Button
							className='ml-4 my-2'
							onClick={() => {
								setEdit(!edit);
							}}
						>
							Edit
						</Button>
						<Button
							className='ml-4 my-2'
							onClick={() => {
								let basicInfo = {
									name: profileDetail?.firstName +' '+ profileDetail?.lastName,
									firstName: profileDetail?.firstName,
									lastName: profileDetail?.lastName,
									nickName: profileDetail?.nickName,
									youtubeURL: profileDetail?.youtubeURL,
									tikTokURL: profileDetail?.tikTokURL,
									city,
									country,
									age,


								};
								dispatch(
									updateProfile(uid, {
										personalityDetail: profileDetail,
										basicInfo: basicInfo,
										detailsAboutMe: detailsAboutMe,
										mySkinToneDetails: mySkinToneDetails,
										detailsAboutMyPreferences:
											detailPreference,
										mySkinTonePreferences:
											mySkinTonePreferences,
									}),
									setEdit(false)
								);
							}}
						>
							Save 
						</Button>
						<Button onClick={() => setEdit(false)}>Cancel</Button>
					</>
				) : (
					''
				)}
			</div>
	</React.Fragment>
	);
};
export default PersonalityDetail;



	/* 
	The purpose of getOrderlyProfileDetails function
	is keep the presentation in the same order as the 
	array found in helpers/stateProfile.
	
	Previously, the order is saved as an object 
	in the database instead of an array. 
	So the order would be lost through many manipulations 
	and the pattern of adding and editing items into an array  
	
	Having a different presentation everytime the user looks at the view is confusing.
	 This function fixes that. and puts the view in the same order as the array in helper/stateProfile.js  
	 

	In this case personalityDetail from helper/stateProfile.js  is an array of objects . 


	rgb(251, 109, 38)
	export const personalityDetail = [
		{
			label: 'Type I Am:',
			key: 'TypeIAm',
			value: '',
		},
		{
			label: 'Type I like:',
			key: 'TypeILike',
			value: '',
		},
		...
	];
dataObject = { 
	TypeIAm: "corn ball", 
	TypeILike: "corn ball",
	workGoals: "ngo"
	CommunicationStyle: "action", 
	DreamLocation:  "bali",
	ForfunILikeTo: "piano",
	LoveLanguage: "touch",
	RelationshipGoal: "family",
	RelationshipNow:  "complex",
	Top2FavTVMovieGenre: "sci fi movie",
	Top2FavYoutubeCategory: "",

};


		the function takes the user object which is dataObject  and the array personalityDetail
		it goes thorugh 
	*/
	/*


 user?.firstName && user?.lastName 
user?.onBoardingData?.profileInformation?.firstName  &&  user?.onBoardingData?.profileInformation?.lastName ? user?.onBoardingData?.profileInformation?.firstName  +' '+  user?.onBoardingData?.profileInformation?.lastName 
user?.name
.profileInformation?.nickName 

therefore the query to update the name will be 
firebase.firestore.collection('users').update({
    firstName: ,
    lastName: ,
    name: 
    onBoardingData: {
        profileInformation : {
            firstName:
            lastName :
            nickName : 
        }
    }


}, {merge:true})


	console.log('personalityDetail= = = ', Array.isArray(personalityDetail) );
	console.log('profileDetail= = = ', Array.isArray(profileDetail ) );
 
	console.log('PersonalityDetail props \n',
	'\n edit=', edit,
	'\n setEdit=', setEdit,
	'\n profileDetail=', profileDetail,
	'\n setProfileDetails=', setProfileDetails,
	'\n detailsAboutMe=', detailsAboutMe,
	'\n mySkinToneDetails=', mySkinToneDetails,
	'\n detailPreference=', detailPreference,
	'\n mySkinTonePreferences=', mySkinTonePreferences,
	'\n name=', name,
	'\n city=', city,
	'\n country=', country,
	'\n age=', age,
	'\n youtubeURL=', youtubeURL );
	*/