import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody, 
    Container,
    Row,
    Col,
  } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import firebase from '../../config/firebase';
import { WatchDirectoryFlags } from 'typescript';
import zipcodes from 'zipcodes';

/*
Functionality under development. 
1. a list of buttons/links are presented as seen in the body. 

2. onClick event will pass the user id to the handler.

3. the handler will then search the firestore database by user id and return that user's object  which is a properties from firestore. 

4. when the data returns, it will pass the data to the call back. 

5. then the callBack will analyze it and do another search on the database. 

Question Is my strategy reasonable? 
I know that I did not set up the callback correctly.  Please provide guidance on the best strategy adn the best way to set up the callback.
here's my code so far. 

*/


/* 
Todo: 
1. hooks for paypal 
2. test paypal  with the card 
3. referrer id - create a formal test script 
4. ambassador  build out that page some more and shorten it 
5. probably upgrade canva.




app todos

1. the manual part 
2. the matching part 

Chat gpt said I need to do it as a cloud functon 
As seen from the conversation 
September 17 "Firebase Cloud Functions Setup"

Terminology
1. Cloud Functions: The serverless framework provided by Firebase to execute your Node.js code.
2. HTTP Triggers: Functions that are triggered via HTTP requests.
3. Event Triggers: Functions that run in response to specific events such as database changes or new user sign-ups.
4. Callable Functions: Functions that can be directly invoked from your client-side app but run on the server-side.




*/
const Admin = () => {

    // You can use useState to handle the form inputs or other state-related logic
    const [continent, setContinent] = useState('');

    const [state, setState] = useState('');

    const [city, setCity] = useState('');
     
    const { uid, user } = useSelector((state) => state.authUser);

    console.log('user', user);
    /*
       getUsersByLocation  will find matches for the logged in user. 
        This is the entry point when we start by searching by location 
    */
    const getUsersByLocation = async (continent, state, city, { onSuccess = () => { }, onError = () => { } }) => {
      
        try {
            let query = firebase.firestore().collection("users");

            if (city) {
                query = query.where(new firebase.firestore.FieldPath('onBoardingData', 'city'), '==', city);
            }

            if (continent) {
                query = query.where(new firebase.firestore.FieldPath('onBoardingData', 'continent'), '==', continent);
            }

            const res = await query.get()
            .then(async (res) => {
                console.log(res.size, "size");
                if (res.size > 0) {
                
                    let usersToCheck = res.docs; 

                    // Example: You can now loop through the users and check for location matches
                    usersToCheck.forEach(user => {
                        const onBoardingDataA = user?.data()?.onBoardingData;//logged in usr
                        const onBoardingDataB = userToMatch?.onBoardingData;

                        if (onBoardingDataA && onBoardingDataB) {
                            const zipA = onBoardingDataA?.zipCode;
                            const zipB = onBoardingDataB?.zipCode;
                            const radiusA = onBoardingDataA?.radius[1];
                            const radiusB = onBoardingDataB?.radius[1];
                            if (locationMatch(zipA, zipB, radiusA, radiusB)) {
                                console.log("Match found:", user);
                            } else {
                                console.warn("Missing onBoardingData for one or both users:", user, userToMatch);
                            }
                        }
                    });
                    onSuccess(usersToCheck);
                }
            });
        } catch (error) {
            console.error("An error occurred:", error);
            onError(error); // You can use this to pass the error to a parent component or log it somewhere else
        } finally {
            // Code that you want to run regardless of success or failure can go here
            console.log('finaly place holder');
        }
    };
           

// Function to calculate compatibility between two users
function calculateCompatibility(userA, userB) {
    // Check location
    if (!locationMatch(userA, userB)) return null;
  
    // Check basic criteria
    if (!basicCriteriaMatch(userA, userB)) return null;
  
    // Calculate personality and preferences score
    const personalityScore = calculatePersonalityScore(userA, userB);
    const preferencesScore = calculatePreferencesScore(userA, userB);
  
    // Calculate overall score
    const totalWeight = 100; // Sum of all individual weights
    const overallScore = (personalityScore + preferencesScore) / totalWeight;
  
    return {
      compatibility: overallScore,
      details: { personalityScore, preferencesScore }
    };
  }
  
  // Function to check location match
  const locationMatch = (zipA, zipB, radiusA, radiusB) => {
    // Ensure valid zip codes
    if (!zipcodes.lookup(zipA) || !zipcodes.lookup(zipB)) {
      return false;
    }

    // Calculate distance between zip codes
    const distance = zipcodes.distance(zipA, zipB);

    // Check if distance is within specified radii
    return distance <= radiusA && distance <= radiusB;
  };
  
    // Function to find common interests
    const findCommonInterests = (interestsA, interestsB) => {
        const commonInterests = interestsA.filter(interest => interestsB.includes(interest));
        return commonInterests;
    };

  // Function to check basic criteria match
  function basicCriteriaMatch(userA, userB) {
    // Implement logic for matching basic criteria like age, gender, dream locaton to live 
    // Return true if match, false otherwise
    const commonInterests = findCommonInterests(userA.interest, userB.interest);
    if ( userA.gender === userB.interestedGender && 
        userA.interestedGender === userB.gender && 
        userB.onBoardingData?.ageRange[0] <= userA?.age && userA?.age >= userB?.onBoardingData.ageRange[1] && 
        userA.onBoardingData?.ageRange[0] <= userB?.age && userB?.age >= userA?.onBoardingData.ageRange[1] &&
        userA.onBoardingData?.dreamLocation === userB.onBoardingData?.dreamLocation && 
        commonInterests.length > 0 && 
        userB.onBoardingData?.relationshipGoal === userA.onBoardingData?.relationshipGoal) { 
 
        return true; 
      }
      return false; 
  }
  
  // Function to calculate personality score
  /* 
    the personality score is saved in 

    sessions/<random-unique-sessionid>/userid 
  */
  function calculatePersonalityScore(userA, userB) {
    // Implement logic to compare personality tests
    // Return weighted score



  }
  
  // Function to calculate preferences score
  function calculatePreferencesScore(userA, userB) {
    // Implement logic to compare preferences
    // Return weighted score
  }
    const [clientUserObj, setClientUserObj] = useState(null); 
   
    const getUserDetailsByUID = async (clientUID, onSuccess = () => {} ) => {
        try {
            await firebase
            .firestore()
            .collection('users')
            .doc(clientUID)
            .onSnapshot(async query => {
                if (query.data()) {
                    const userData = {
                        uid: query.id,
                        user: {
                            id: query.id,
                            ...query.data(),
                        },
                    };
                    onSuccess(userData.uid, userData.user); 
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

    const handleMatchUser = (clientId) => {
        console.log('const handleMatchUser = (clientId) =', clientId); 
        if (clientId) {
            getUserDetailsByUID(clientId, ( uid, user ) => {
                // i think this is the callback 
                setClientUserObj(user);

                console.log('in the callback with getUserDetailsByUID clientId=', clientId); 
                console.log('in the callback with getUserDetailsByUID user =', user); 
                //now do a new search for users who match the criteria.  
                findMatches(user);

            });
        }
    };

    const findMatches = (user) => {
        // do the search in firestore .
        // return recommendations for where to recruit 
    };

    const actions = [
        {
            title: 'Admin Page Login History',
            items: [ 
                { txt:'Last Login:', result:''}, 
                { txt:'Last Login Location:', result:''}, 
                { txt:'Last Login Username:', result:''}, 
            ]
        },
        {
            title: 'User Summary',
            msg:'(show last 4 weeks link to more)', 
            items: [ 
                { txt:'Total # of Users:', result:''}, 
                { txt:'New Sign Ups per Week:', result:''},  
            ]
        },
        {
            title: 'Heart Jewelry for User',
            msg:'link to the users referenced below.',
            items: [ 
                { txt:'# of Users who started the tests, but did not complete it', result:''}, 
                { txt:'# of Users who completed test - and earned the heart color:', result:''} 
            ]
        },
        { 
            title: 'Matching Events',
            items: [ 
                { txt:'# Matched Users', result:''}, 
                { txt:'# unmatched users:', result:''}, 
                { txt:'Upcoming events:', result:''}, 
            ]
        },
        {  /* 
                http(s)://data.searchbug.com/api/search.aspx?
            CO_CODE=<AccountID>&PASS=<Password>&TYPE=<TYPE>&FNAME=<FNAME>&MNAME=<MNA
            ME>&LNAME=<LNAME>&CITY=<CITY>&STATE=<STATE>&DOB=<DOB>&FORMAT=<Format> <br/>
            Reference documentation: searchbug pdf</p>
            */
            title: 'Criminal Background Check Report',
            items: [ 
                { txt:'Total # of background checks', result:''}, 
                { txt:'# positive checks:', result:''},
                { txt: '# recommended for therapy or values remediation.', results:''},
                { txt:'# Resubmit Background Check:', result:''},
                { txt:'# indicating they will start remediation', result:''}
            ]
        },
        { 
            title: 'Matching Algortithm',
            items: [ 
                { txt:'Total # of background checks', result:''}, 
                { txt:'# positive checks:', result:''},
                { txt: '# recommended for therapy or values remediation.', results:''},
                { txt:'# Resubmit Background Check:', result:''},
                { txt:'# indicating they will start remediation', result:''}
            ] 
        }
    ];


    // look at SimplestFeedback in Profile.jsx, profiletestbet.jsx 
    // and home.js for the feedback side 
    const [adminMsgs, setAdminMsgs] = useState([]);
/*
    useEffect(() => {
        const db = firebase.firestore();
        const feedbackRef = db.collection("feedbackMsgs");
    
        const unsubscribe = feedbackRef.onSnapshot(snapshot => {
            snapshot.docChanges().forEach(async change => {
                if (change.type === "added") {
                    const userDoc = change.doc;
                    const msgsRef = userDoc.ref.collection('msgs');
                    const msgsSnapshot = await msgsRef.get();
                    msgsSnapshot.forEach(msgDoc => {
                        const newMsg = {
                            id: msgDoc.id,
                            ...msgDoc.data()
                        };
                        setAdminMsgs(prevMsgs => [...prevMsgs, newMsg]);
                    });
                }
            });
        });
        return () => unsubscribe(); // Clean up listener on component unmount
    }, [user]); // Assuming 'user' determines if someone is an administrator
*/





    return (
        <Container>
            <Row><h1>GOGPersonality Admin Page</h1></Row>
            <Row>
                {actions.map((action, index) => (
                    <Col xs={12} sm={6} md={5} lg={4} key={index}>
                        <Card className="mb-4">
                            <CardHeader>{action.title}</CardHeader>
                            <CardBody>
                            <ul>
                            { action.items.map((item, k) => ( 
                                <li key={k}><p>{item.txt} {item.result}</p></li> 
                            ))}
                            </ul>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row>
                <div> 
                    <h3>Purpose: This search form will find matches for you - the person who is logged in. </h3>
                    <p>This algorithm might run on the  user facing side.</p>
                    <div>
                        <label>Continent:</label>
                        <input type="text" value={continent} onChange={e => setContinent(e.target.value)} />
                        <label>State:</label>
                        <input type="text" value={state} onChange={e => setState(e.target.value)} />
                        <label>City:</label>
                        <input type="text" value={city} onChange={e => setCity(e.target.value)} />
                        <button onClick={() => getUsersByLocation(continent, state, city, { onSuccess: (users) => console.log(users) })}>Search</button>
                    </div>
                </div>
            </Row>
            <Row>
                <h3>Purpose: This search form will find matches for - the list of people below.</h3>
                  <p>This algorithm will only be seen on the admin side.</p>
                    
                <p>Below is a stubbed-in list of unmatched users. </p> 
                <p> Clickign the button will find default matches for these users </p>
            </Row>
            <Row>
                <Button type="button" onClick = { () => handleMatchUser('A5GEs3wcs5cdRJp6lCU2A93mC953')}>User1X</Button>
                <Button type="button" onClick = { () => handleMatchUser('BL733veRDSfmgkOz5SQJjkuTnTl1')}>User2X</Button>
                <Button type="button" onClick = { () => handleMatchUser('DVnzZS7pWuS9O3sNQFl3JbT8Q2L2')}>User3X</Button>
                <Button type="button" onClick = { () => handleMatchUser('FS15UJ2qs7W5SRYnQOIsjJ65LOW2')}>User4X</Button>
                <Button type="button" onClick = { () => handleMatchUser('GNn8tBC9ugRnUIxuqxLbIpIk4wC2')}>User5X</Button>
                <Button type="button" onClick = { () => handleMatchUser('SKJ5nLmHrucyXROHy72jOAVVLuq1')}>User6X</Button>
                <Button type="button" onClick = { () => handleMatchUser('Tm5MjqSFcsT99Hibc4KYsKfXpQg2')}>User7X</Button>
                <Button type="button" onClick = { () => handleMatchUser('bVH7zuaHJxPzVRdDol0cAeLPHJx1')}>User8X</Button>
                <Button type="button" onClick = { () => handleMatchUser('bjQRkFNtyGMKjSsAU68xFsQ2BCB3')}>User9X</Button>
                <Button type="button" onClick = { () => handleMatchUser('d25IuFTAOJRS6wo7HjoDcSDTj6M2')}>User10X</Button>
                <Button type="button" onClick = { () => handleMatchUser('fNnYDVTQe5OJHSxp383mpF8hsGv2')}>User11X</Button>
                <Button type="button" onClick = { () => handleMatchUser('j1xeCMOEwSM5mNEHN2HCrYoeuB63')}>User12X</Button>
                <Button type="button" onClick = { () => handleMatchUser('tz47OiXKSscKkMGAoFCKPiHi7lx1')}>User13X</Button>
                <Button type="button" onClick = { () => handleMatchUser('uzc3V6J4QBQmKaYFVu3aIn9F4aH3')}>User14X</Button>
                <Button type="button" onClick = { () => handleMatchUser('wCgninvreQb6iDhSOmhUrHZluVf2')}>User15X</Button>
                <Button type="button" onClick = { () => handleMatchUser('wrNnZFP2kIfvczcLOBzCV8necGS2')}>User16X</Button>
            </Row>
        </Container>
    );
};
export default Admin;
 /* 
   
 
 Sessions.where('userId='' ).where('active'==true)

 results/part1/score

 
 
 
 
 const addSessions = async (payload, part, uid, scoreObj) => (dispatch) => {
      firebase
        .firestore()
        .collection("sessions")
        .where("userId", "==", uid)
        .where("active", "==", true)
        .get()
        .then(async (res) => {
          console.log(res.size, "size");
          if (res.size > 0) {
            
            let sessionId = res.docs[0].id;

            console.log(sessionId, "sessionid");
         
            1-443-473-9108 
faceswap plugin  

realstate  
            let newData = await firebase
              .firestore()
              .collection("stats")
              .doc(sessionId)
              .get();
            if (!newData.exists) {
              firebase.firestore().collection("stats").doc(sessionId).set({
                mdq: [],
              });
            }

          
            firebase
              .firestore()
              .collection("sessions")
              .doc(sessionId)
              .collection("results")
              .doc(part)
              .set({
                payload,
                score: scoreObj || "",
              })
              .then(() => {
                alert(`${part} Answer Submitted Successfully`);
              });
          }  
        });
    };
  

        // match criteria 
            
                profilePictureURL:  
                role: 'customer',
                age: '',
                name: 
                city: '',
                country: '',
                youtubeURL: '',

                detailsAboutMe: {},
                detailsAboutMyPreferences: {},

                personalityDetails:  {},
              
                mySkinToneDetails:{} 
                mySkinTonePreferences:  {}
                onBoardingData: {
                    ageRange: [19, 35],
                    dreamLocation:'',
                    gender: 
                    interestedGender: 'woman',
                    interests: [], 
                    likedType: [],
                    profileInformation: {},
                    youtubeVideo: ''
                    racist: [],
                    relationshipGoal:'', 
                    personalityDetails: {}
                }
                
 
      
        clientUserObj is expecting 

         user = {
            firstName: first,
            lastName: rest.join(''),
            email: data.user.email,
            createdAt: firebase.firestore.Timestamp.now(),
            userName: data.additionalUserInfo.username,
            phone: data.user.phoneNumber,
            platform: 'twitter',
            profilePictureURL: data.user.photoURL,
            role: 'customer',
            age: '',
            name: data.user.displayName,
            city: '',
            country: '',
            youtubeURL: '',
            detailsAboutMe: finalStats(),
            detailsAboutMyPreferences: finalStats(),
            personalityDetails: PersonalityDetails(),
            mySkinToneDetails: mySkinToneDetails,
            mySkinTonePreferences:  mySkinTonePreferences,
        }

        gcloud firestore export gs://gogpersonality.appspot.com/users/j1xeCMOEwSM5mNEHN2HCrYoeuB63 --project=gogpersonality

    */
 
   
  /*

      Select Algorithm Thypes <Br/> 
      <select>
        <option>big 5 + comm + love language + values + interests</option>
        <option>love language + values + interests + big 5 + comm  </option>
        <option>values + interests + big 5 + commlove language +  </option>
      </select>
      
           <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
          <i class="material-icons material-icons--frm-chk">check_circle</i>
            <input class="mdl-textfield__input" type="text" id="edit-profile--phone">
            <label class="mdl-textfield__label" for="edit-profile--phone">Phone for Text Messages</label>
          </div>

<button type='button' class='mdl-js-button mdl-button mdl-button--raised'>Run matching algortithm for a specific user. </button>
    

    <button type='button' class='mdl-js-button mdl-button mdl-button--raised'>Run matching algorithm for unmatched users </button>


    </div>
</div><!-- closs smmary wrap -->

<p><b>Pseudo logic for the algorithm.</b>

<br/><br/> 
STEP #!: Gather  the User's Categories for <br/>

big 5 + comm + love language + values + interests + BBMNP <br/> 


If BBMNP -- tell them to get some counseling first to double check then report back.<br/> <Br/>


<b>BIG 5</b><br/>
big 5 will be either (OCEAN acronym)<br/> 
high openness <Br/>
high conscientiousness <br/> 
high extraversion <br> 
high aggreableness <br/> 
high neuroticism  <br> 
low extraversion <br> 

<b>COMMunication style </b><br/>

etc <Br/> 
<b> location and radius </b> 

Then search for other individuals with the same things in that order <br/> 
big 5 + comm + love language + values + interests 


</p>

 
</div><!-- admin section -->

<!-- 

put pod cast 1 minute clip on tiktok and reels 
-->

<div>

<h3>Matching Algorithm Reminders </h3>


<h4>Big 5 + Communication Styles</h4>
<table class='u-admin__table'>
<tr><td>Big 5 + Communication Styles</td><td>Name </td><td>Color of the heart</td></tr>
<tr><td>High Openness + High Action Communication Style:           </td><td>The Explorer      </td><td>Orange Heart <img src='app-imgs/publicImgs/bf-united-imgs/animationElements/jewelry-item-torquoise.png' class='u-img'/></td>    </tr>
<tr><td>High Openness + High Process Communication Style:          </td><td>The Strategist    </td><td>Blue Star <img src='app-imgs/publicImgs/bf-united-imgs/animationElements/jewelry-item-purple.png' class='u-img'/></td>       </tr>
<tr><td>High Openness + High People Communication Style:           </td><td>The Empath        </td><td>Green Diamond <img src='app-imgs/publicImgs/bf-united-imgs/animationElements/jewelry-item-yellow.png' class='u-img'/></td>   </tr>
<tr><td>High Openness + High Ideas Communication Style:            </td><td>The Innovator     </td><td>Purple Sun <img src='app-imgs/publicImgs/bf-united-imgs/animationElements/jewelry-item-green.png' class='u-img'/></td>      </tr>
<tr><td>High Conscientiousness + High Action Communication Style:  </td><td>The Implementer   </td><td>Teal Pentagon <img src='app-imgs/publicImgs/bf-united-imgs/animationElements/jewelry-item-red.png' class='u-img'/></td>   </tr>
<tr><td>High Conscientiousness + High Process Communication Style: </td><td>The Planner       </td><td>Gray Hexagon</td>    </tr>
<tr><td>High Conscientiousness + High People Communication Style:  </td><td>The Director      </td><td>Gold Circle</td>     </tr>
<tr><td>High Conscientiousness + High Ideas Communication Style:   </td><td>The Perfectionist </td><td>Black Diamond</td>   </tr>
<tr><td>High Extraversion + High Action Communication Style:       </td><td>The Energizer     </td><td>Red Square</td>      </tr>
<tr><td>High Extraversion + High Process Communication Style:      </td><td>The Networker     </td><td>Pink Triangle</td>   </tr>
<tr><td>High Extraversion + High People Communication Style:       </td><td>The Charmer       </td><td>Silver Cloud</td>    </tr>
<tr><td>High Extraversion + High Ideas Communication Style:        </td><td>The Visionary     </td><td>Turquoise Spiral</td></tr>
<tr><td>High Agreeableness + High Action Communication Style:      </td><td>The Helper        </td><td>Green Heart</td>     </tr>
<tr><td>High Agreeableness + High Process Communication Style:     </td><td>The Mediator      </td><td>Gold Cross</td>      </tr>
<tr><td>High Agreeableness + High People Communication Style:      </td><td>The Collaborator  </td><td>Blue Circle</td>     </tr>
<tr><td>High Agreeableness + High Ideas Communication Style:       </td><td>The Diplomat      </td><td>Gray Cross</td>      </tr>
<tr><td>High Neuroticism + High Action Communication Style:        </td><td>The Survivor      </td><td>Teal Diamond</td>    </tr>
<tr><td>High Neuroticism + High Process Communication Style:       </td><td>The Analytical Thinker</td><td>Black Square</td></tr>
<tr><td>High Neuroticism + High People Communication Style:        </td><td>The Caretaker    </td><td>Green Star</td>       </tr>
<tr><td>High Neuroticism + High Ideas Communication Style:         </td><td>The Dreamer      </td><td>Purple Cloud</td>     </tr>
<tr><td>Low Trait Score + Low Action Communication Style:          </td><td>The Laid-back    </td><td>Gray Circle</td>      </tr>
<tr><td>Low Trait Score + Low Process Communication Style:         </td><td>The Simple       </td><td>Orange Square</td>    </tr>
<tr><td>Low Trait Score + Low People Communication Style:          </td><td>The Introverted  </td><td>Blue Heart </td>      </tr>
<tr><td>Low Trait Score + Low Ideas Communication Style:           </td><td>The Practical    </td><td>Teal Hexagon</td>     </tr>
<tr><td>Low Extraversion + High Action Communication Style:        </td><td>The Doer         </td><td>Red Triangle</td>     </tr>
<tr><td>Low Extraversion + High Process Communication Style:       </td><td>The Methodical   </td><td>Gray Star</td>        </tr>
<tr><td>Low Extraversion + High People Communication Style:        </td><td>The Listener     </td><td>Green Circle</td>     </tr>
<tr><td>Low Extraversion + High Ideas Communication Style:         </td><td>The Contemplator </td><td>Purple Pentagon</td>  </tr>
</table> 









<h4>Big 5 Personality</h4>
<p>put the user in the group based on the score - or high standard deviations </p>
<table  class='u-admin__table'>
  <tr><td>Category        </td><td> Match Criteria</td></tr>
  <tr><td>Action          </td><td> Scores 17-20 for Action</td></tr>
  <tr><td>Process         </td><td>Scores 17-20 for Process</td></tr>
  <tr><td>People-Oriented </td> <td>Scores 17-20 for People</td></tr>
  <tr><td>Idea-Oriented   </td><td>Scores 17-20 for Ideas</td></tr>
  <tr><td>Moderate        </td><td>Scores less than 17 in any category</td></tr>
</table>




<h4>Communication Styles</h4>
<p>put the user in the group based on the score </p>
<table  class='u-admin__table'>
<tr><td>Category       <td></td>Match Criteria</td></tr>
<tr><td>Action         <td></td>Scores 17-20 for Action</td></tr>
<tr><td>Process        <td></td>Scores 17-20 for Process</td></tr>
<tr><td>People-Oriented<td></td> Scores 17-20 for People</td></tr>
<tr><td>Idea-Oriented  <td></td>Scores 17-20 for Ideas</td></tr>
<tr><td>Moderate       </td><td>Scores less than 17 in any category</td></tr>
</table>

<p>Communication Style Notes:<br/>
For individuals who score extremely high in one communication style, they will be matched with someone who also scores extremely high in that same category. For individuals who score moderately in two or more categories, they will be matched with others who also score moderately regardless of their specific score distribution. For individuals who score less than 17 in any category, they will be placed in the moderate category and matched accordingly.</p>





<h4>Love Language</h4>
<table  class='u-admin__table'>
<tr><td> Love Language Match </td><td> Criteria</td></tr>
<tr><td> Words of Affirmation </td><td>  Scores 8-10 in Words of Affirmation</td></tr>
<tr><td> Acts of Service </td><td> Scores 8-10 in Acts of Service</td></tr>
<tr><td> Receiving Gifts</td><td>  Scores 8-10 in Receiving Gifts</td></tr>
<tr><td> Quality Time </td><td>  Scores 8-10 in Quality Time</td></tr>
<tr><td> Physical Touch  </td><td> Scores 8-10 in Physical Touch</td></tr>
<tr><td> Moderate  Scores </td><td> less than 8 in any category</td></tr>
</table>
<p> 
     <b>  Notes About Love Language Matching</b>  <br/>

For individuals who score highly in one love language category, they will be matched with someone who also scores highly in that same category. For individuals who score moderately in two or more categories, they will be matched with others who also score moderately regardless of their specific score distribution. For individuals who score less than 8 in any category, they will be placed in the moderate category and matched accordingly.
</p>



<h4>Shared Interests & Passions</h4>
<table  class='u-admin__table'>
<tr><td> first match on category  like outdoor, sports, visual arts </td><td> then match on specific interest  </td></tr>
</table>


<h4>Family Values and Family Goals.</h4>
<p>The test has 20 questions. Each has up to 5 point. 

The question are categorized as <br/>
<b> Traditional </b> <br/>
<b> Poly</b> <br/> 
<b> hooks up and kink</b> <br/> 
 
<b> Problematic</b>  <br/> 
- will divorce<br/>
- values a man or woman based on money <br/> 
- etc  </p>

<table  class='u-admin__table'>
  <tr><td> Traditional - Best Values </td><td> Criteria</td></tr>
  <tr><td> Poly - Best values </td><td>  Scores 8-10 in Words of Affirmation</td></tr>
  <tr><td> Self-centered value  </td><td>  Scores 8-10 in Words of Affirmation</td></tr>
</table>


 */