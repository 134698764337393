import React from 'react';

const CustomToolTip = ({msg}) => {
    return (
        <div className='toolTip'>
            <p>{msg}</p>
        </div>
    );
};

export default CustomToolTip;