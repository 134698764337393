import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { clearSession } from 'store/actions/QuestionsAction';

const FinishedModal = props => {
	let dispatch = useDispatch();
	return (
		<>
			<Modal
				isOpen={props.modal}
				toggle={props.toggle}
				centered={true}
				className='px-0'
				onClosed={() => {
					dispatch(clearSession());
					localStorage.removeItem('part1');
					localStorage.removeItem('part2');
					localStorage.removeItem('part3');
					localStorage.removeItem('part4');
					localStorage.removeItem('part5');
					localStorage.removeItem('part6');
				}}
			>
				<div className='d-flex justify-content-end pr-3 pt-3'>
					<i
						className='fa fa-times red-icon'
						onClick={props.toggle}
						aria-hidden='true'
					></i>
				</div>
				<ModalBody className=' py-md-5 py-3 px-2 authcongrats__modalbody'>
					<div className='d-flex flex-column justify-content-center align-items-center'>
						<img
							src={props.img}
							alt=''
							className='img-fluid'
							height={400}
							width={400}
						/>
						<p className='font-weight-bold text-center px-5 mt-5'>
							Congratulation you have completed all parts for
							personality test
						</p>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default FinishedModal;
