import React from "react";

const DeletionPolicyContent = () => {
  return (
    <>
      <h1>Data Deletion Policy</h1>
      <p>
        A fundamental principle of data privacy and security is that information
        that is not necessary for the business should not be kept. This
        principle is known as data minimization, and it is meant to protect
        against unnecessary and disproportionate harm in the event of a security
        breach. The most common method used to minimize data is to enact and
        enforce data retention and data deletion policies across an
        organization.
      </p>
      <h3>When data should be deleted</h3>
      <p>
        Data should be deleted when it is no longer needed for authorized
        purposes. The period of time that information remains necessary for
        authorized purposes, however, is not standardized across organizations,
        industries, or operations. Determining the appropriate time period
        requires an underlying knowledge of the data a company has, how that
        data is classified (for example, if it includes personal information),
        how that data is used in the business, and any laws applicable to its
        retention. The most common means of determining this time period is
        through the process of developing and documenting data retention
        policies and schedules.
      </p>
      <p>
        A data retention policy is a corporate policy that goes beyond statutory
        legal requirements, and directs operations about which information the
        company should retain, delete, or retain for a period and then delete.
        For data that is permitted under policy to be retained for a given
        period of time and then must be deleted, the retention period is
        generally documented in a data retention schedule. Both the policy and
        the schedule should reflect the types of data the company has, the laws
        applicable to its retention, and the risk position of the company.
      </p>
      <h3>How data can be deleted</h3>
      <p>
        There are a variety of methods for deleting data. These methods vary in
        effectiveness, from simply pressing the Delete button on a personal
        computer to manual destruction of the media on which the data is stored.
        The best method of data deletion can be determined based on the type and
        nature of the data and the risk associated with its exposure
      </p>

      <h3>How GOGPersonality handles data deletion</h3>
      <p>
        In the settings menu of the GOGPersonality app, click "deactivate
        account". Your account will be hidden for 30 days.
      </p>
      <p>
        If you want to reactivate your account log in before the 30 days are up.
        After 30 days your data will be deleted.
      </p>
    </>
  );
};

export default DeletionPolicyContent;
