import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from "react";
import { Input, Label } from "reactstrap";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const AgeRange = ({ageRange, setAgeRange, usersAge, setUsersAge}) => {

  const handleRangeChange = (values) => {
    setAgeRange(values);
  };
  const handleMyAge = (age) => {
    setUsersAge(age);
  };
 

  const customTipProps = {
    visible: true, // Set the tooltip to always be visible.
    placement: "bottom", // Change the tooltip's placement (top, bottom, left, right).
    overlayStyle: {
      fontSize: 9,
      backgroundColor: "transparent",
      color: "white",
    }, // Custom styles for the tooltip.
  };

  return (
    <div className="gender">
      <h3 style={{marginBottom:"100px"}}>Age Range that you are Interested in</h3>
      <div className="range-wrapper">
      <Range
        min={17}
        max={100}
        defaultValue={[25, 55]}
        tipFormatter={(value) => `${value} years old`}
        onChange={handleRangeChange}
        tipProps={customTipProps}
      />
      </div>
      <div className='d-flex justify-content-center align-items-end'>
        <Label for='usersAge' className='createProfile__users-age-label flex-grow-0 text-white font-weight-bold'>Your Age: </Label>
        <Input id='usersAge'
          name="usersAge"
          required 
          min="16"
          max="150"
          className="flex-grow-0"
          type="number"
          value={usersAge}
          onChange={(e) => {
            if (parseInt(e.target.value) <= 150) {
              handleMyAge(e.target.value);
            }
          }}
          style={{width:'100px',
          backgroundColor: '#ff5603',
          color: 'white',
          fontWeight: 'bold',
          border: 'none',
          marginLeft: '4px'}}
        
        ></Input>
      </div>
    </div>
  );
};

export default AgeRange;
