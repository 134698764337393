/**
 * firebase authentication methods are 
 * Email/Password 
 * Phone 
 * Google 
 * Facebook
 * Twitter 
 * 
 * today's task is referrer id 
 * and paypal 
 */
import {
	LOGIN,
	LOGIN_LOADER,
	LOGOUT,
	REGISTER_LOADER,
	USER_FRIENDLY_ERR_MSG,
	SIGNUP,
} from '../actionTypes';
import { useHistory } from 'react-router-dom';
import firebase from '../../config/firebase';
import CryptoJS from 'crypto-js';
import { checkReferrerLocalStorage } from '../../helpers/referrerStorageUtils.js';
import { getOnboardingStatusFromLocalStorage } from '../../helpers/referrerStorageUtils';
import { redirectBasedOnOnBoardingStatus } from '../../helpers/referrerStorageUtils';




/*
<FriendlyErrMsgBox/>import FriendlyErrMsgBox from '../../components/FriendlyErrMsgBox/FriendlyErrMsgBox';
*/

import {
	stats,
	personalityDetail,
	mySkinToneDetails,
	mySkinTonePreferences,
} from '../../helpers/statesProfile';

const finalStats = () => {
	return Object.assign({}, ...stats.map(x => ({ [x.key]: x.value })));
};

const PersonalityDetails = () => {
	return Object.assign(
		{},
		...personalityDetail.map(x => ({ [x.key]: x.value }))
	);
};
export const userFriendlyStateErrMsg = val => async dispatch => {
	dispatch({
		type: USER_FRIENDLY_ERR_MSG,
		payload: val,
	});
};
export const signupLoader = val => async dispatch => {
	dispatch({
		type: REGISTER_LOADER,
		payload: val,
	});
};
export const LogInLoader = val => async dispatch => {
	dispatch({
		type: LOGIN_LOADER,
		payload: val,
	});
};

let unsubscribe;

export const EmailCheck =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		await firebase
			.auth()
			.fetchSignInMethodsForEmail(payload.email)
			.then(methods => {
				if (methods.length === 0) {
					onSuccess('notExist');
				} else {
					onSuccess('Exist');
				}
			})
			.catch(error => {
				console.log(error);
			});
	};
export const signUpWithEmail_Password =
	(payload, password, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(signupLoader(true));
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.createUserWithEmailAndPassword(payload.email, password)
			.then(data => {
				firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.set({
						...payload,
						// email: data.user.email,
						createdAt: firebase.firestore.Timestamp.now(),
						referredBy: checkReferrerLocalStorage(),
						age: '',
						name: payload?.userName,
						city: '',
						country: '',
						youtubeURL: '',
						profilePictureURL: '',
						detailsAboutMe: finalStats(),
						detailsAboutMyPreferences: finalStats(),
						personalityDetails: PersonalityDetails(),
						mySkinToneDetails: mySkinToneDetails,
						mySkinTonePreferences: mySkinTonePreferences,
					})
					.then(res => {
						dispatch(signupLoader(false));
						alert('sign up successfully');
						onSuccess('pass');
					})
					.catch(err => {
						dispatch(signupLoader(false));
						alert(err, 'danger');
					});
			})
			.catch(err => {
				dispatch(signupLoader(false));
				alert(err.message);
			});
	};
export const signInWithEmail_Password =
	(payload, password, history, onSuccess) => async dispatch => {
		try {
			dispatch(LogInLoader(true));
			await firebase
				.auth()
				.signInWithEmailAndPassword(payload.email, password)
				.then(data => {
					if (data && data.user && data.user.uid) {
						dispatch(reRegisterSnapShot(data.user.uid, history, onSuccess));
					} else {
						console.error('.signInWithEmailAndPassword returned with if (data && data.user && data.user.uid) evaluating to false');
					}
				})
				.catch(err => {
					dispatch(LogInLoader(false));
					console.log(err);
				});
		} catch (error) {
			dispatch(LogInLoader(false));
			alert(error.message);
		}
	};


export const AuthWithPhone =
	(data, onSuccess = () => {}) => async dispatch => {
		try {
			unsubscribe = await firebase
				.firestore()
				.collection('users')
				.doc(data.user.uid)
				.onSnapshot(async query => {
					const userData = query.data();

					if (userData) {

						dispatch(LogInLoader(true));
						dispatch({
							type: LOGIN,
							payload: {
								uid: data.user.uid,
								user: { 
									id: data.user.uid,
									...userData
								},
							},
						});
						dispatch(LogInLoader(false));
						onSuccess(); // redirection code

					} else {
						const newUser = {
							createdAt: firebase.firestore.Timestamp.now(),
							referredBy: checkReferrerLocalStorage(),
							age: '',
							name: '',
							nickName:'',
							firstName:'',
							lastName:'',
							city: '',
							country: '',
							youtubeURL: '',
							tikTokURL:'',
							profilePictureURL: '',
							detailsAboutMe: finalStats(),
							detailsAboutMyPreferences: finalStats(),
							personalityDetails: PersonalityDetails(),
							mySkinToneDetails: mySkinToneDetails,
							mySkinTonePreferences: mySkinTonePreferences,
						};
						firebase
							.firestore()
							.collection('users')
							.doc(data.user.uid)
							.set(newUser)
							.then(async ()=> {
								dispatch(signupLoader(true));
								dispatch({
									type: LOGIN,
									payload: {
										uid: data.user.uid,
										user: { 
											id: data.user.uid,
											...newUser 
										},
									},
								});
								dispatch(signupLoader(false));
								onSuccess(); // redirection code 
							})
							.catch(err => {
								console.error(err, 'danger');
								dispatch(userFriendlyStateErrMsg('Problem logging i by phone. Please try again or try another method.'));
							});
					}
					
				});
		} catch (error) {
			console.log(error);
			dispatch(userFriendlyStateErrMsg('Problem logging i by phone. Please try again or try another method.'));				
		}
	};

export const AuthWithTwitter =
	(onSuccess = () => {}) =>
	async dispatch => {
		const provider = new firebase.auth.TwitterAuthProvider();

		await firebase
			.auth()
			.signInWithPopup(provider)
			.then(async data => {

				const twitterAccessToken = data.credential.accessToken;
    			const twitterSecret = data.credential.secret;

				const [first, ...rest] = data.user.displayName.split(' ');
				unsubscribe = firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.onSnapshot(async query => {
						if (query.data()) {
							
							console.log('inside onSnap query query.data() = true ',query.data());
							const userData = {
								uid: data.user.uid,
								user: {
								  id: data.user.uid,
								  ...query.data(),
								},
							};
							dispatch({
								type: LOGIN,
								payload: userData,
							});
							onSuccess(userData.uid, userData.user);
							// if (query.data().isDeleted) {
							// 	alert(
							// 		'Your account data have been deleted from database. But the good news is, your account is still alive. Please contact your admin to create a new slot for you to store data then you will be able to login.'
							// 	);
							// } else {
						} else {

							//console.log('inside onSnap query selse query.data() = falsey ',query );

							firebase
								.firestore()
								.collection('users')
								.doc(data.user.uid)
								.set({
									firstName: first,
									lastName: rest.join(''),
									referredBy: checkReferrerLocalStorage(),
									email: data.user.email,
									nickName: '',
									createdAt:
										firebase.firestore.Timestamp.now(),
									userName: data.additionalUserInfo.username,
									phone: data.user.phoneNumber,
									platform: 'twitter',
									profilePictureURL: data.user.photoURL,
									role: 'customer',
									age: '',
									name: data.user.displayName,
									city: '',
									country: '',
									youtubeURL: '',
									tikTokURL: '',
									detailsAboutMe: finalStats(),
									detailsAboutMyPreferences: finalStats(),
									personalityDetails: PersonalityDetails(),
									mySkinToneDetails: mySkinToneDetails,
									mySkinTonePreferences:
										mySkinTonePreferences,
								})
								.then(res => {
									// firebase.firestore().collection("users").doc(data.user.uid);
									// onSuccess("pass");
									console.log('1. inside AuthWithTwitter THEN with data = ', data);
								})
								.catch(err => {
									alert(err, 'danger');
									console.log('r', err);
								});
						}
					});
			})
			.catch(err => {
				alert(err.message);
			});
	};
/* 
most recently commented out facebook code.

export const AuthWithFacebook =
	(onSuccess = () => {}) =>
	async dispatch => {
		const provider = new firebase.auth.FacebookAuthProvider();
		
		try {
			const data = await firebase.auth().signInWithPopup(provider);

			const userDocRef = firebase.firestore().collection('users').doc(data.user.uid);

		    const [first, ...rest] = data.user.displayName.split(' ');
			unsubscribe =userDocRef.onSnapshot(async (query) => {
				if (query.exists) {
				  const userData = {
					uid: data.user.uid,
					user: {
					  id: data.user.uid,
					  ...query.data(),
					},
				  };
				  
				    dispatch(LogInLoader(true));
					dispatch({ type: 'LOGIN', payload: userData });
					onSuccess(userData.uid, userData.user);
					dispatch(LogInLoader(false));
				} else {
					dispatch(signupLoader(true));
					const newUser = {
						firstName: first ?? '',
						lastName: rest?.join('') ?? '',
						referredBy: checkReferrerLocalStorage(),
						email: data?.user?.email ?? '',
						createdAt:
							firebase.firestore.Timestamp.now(),
						userName: data?.additionalUserInfo?.username ?? 'default username',
						phone: data?.user?.phoneNumber ?? '',
						nickName: '',
						platform: 'facebook',
						profilePictureURL: data?.user?.photoURL ?? '',
						role: 'customer',
						age: '',
						name: data?.user?.displayName ?? 'default',
						city: '',
						country: '',
						youtubeURL: '',
						tikTokURL: '',
						detailsAboutMe: finalStats(),
						detailsAboutMyPreferences: finalStats(),
						personalityDetails: PersonalityDetails(),
						mySkinToneDetails: mySkinToneDetails,
						mySkinTonePreferences:
							mySkinTonePreferences,
					};
					await userDocRef.set(newUser);
        
					const userData = {
					  uid: data.user.uid,
					  user: {
						id: data.user.uid,
						...newUser,
					  },
					};
					dispatch(signupLoader(true));
					dispatch({ type: 'LOGIN', payload: userData });
					onSuccess(userData.uid, userData.user);
					dispatch(signupLoader(false));
		 
				}
			})
			} catch (err) {
				console.error('an error occured during fb authentication', err);
				alert(err, 'danger');
			}
	};
*/

export const logout = (onSuccess = () => {}) =>
	async dispatch => {

		if (unsubscribe) {
			unsubscribe();
		}
		firebase
			.auth()
			.signOut()
			.then(data => {
				localStorage.clear();
				onSuccess();
				dispatch({
					type: LOGOUT,
					uid: '',
				});
			})
			.catch(error => {
				dispatch({
					type: 'LOGOUT_FAIL',
					uid: '',
					error: error,
				});
			});
	};



/*
export const AuthWithYahoo =
	(history, onSuccess = () => {}) =>
	async dispatch => {
		var provider = new firebase.auth.OAuthProvider('yahoo.com');

		firebase
			.auth()
			.signInWithPopup(provider)
			.then(result => {
				onSuccess();
			})
			.catch(error => {
				console.log(error);
			});
	};
*/
	/* previous before the big change 


	export const AuthWithFacebook =
	(onSuccess = () => {}) =>
	async dispatch => {
		const provider = new firebase.auth.FacebookAuthProvider();
		await firebase
			.auth()
			.signInWithPopup(provider)
			.then(data => {
				const [first, ...rest] = data.user.displayName.split(' ');

				firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.onSnapshot(async query => {
						if (query.data()) {
							const userData = {
								uid: data.user.uid,
								user: {
									id: data.user.uid,
									...query.data(),
								},
							};
							dispatch(LogInLoader(true));
							dispatch({
								type: LOGIN,
								payload: userData,
							});
							dispatch(LogInLoader(false));
							onSuccess(userData.uid, userData.user);
						} else {
							dispatch(signupLoader(true));
							await firebase
								.firestore()
								.collection('users')
								.doc(data.user.uid)
								.set({
									firstName: first ?? '',
									lastName: rest?.join('') ?? '',
									referredBy: checkReferrerLocalStorage(),
									email: data?.user?.email ?? '',
									createdAt:
										firebase.firestore.Timestamp.now(),
									userName: data?.additionalUserInfo?.username ?? 'default username',
									phone: data?.user?.phoneNumber ?? '',
									platform: 'facebook',
									profilePictureURL: data?.user?.photoURL ?? '',
									role: 'customer',
									age: '',
									name: data?.user?.displayName ?? 'default',
									city: '',
									country: '',
									youtubeURL: '',
									detailsAboutMe: finalStats(),
									detailsAboutMyPreferences: finalStats(),
									personalityDetails: PersonalityDetails(),
									mySkinToneDetails: mySkinToneDetails,
									mySkinTonePreferences:
										mySkinTonePreferences,
								})
								.then(async res => {
									dispatch(signupLoader(true));

									await firebase
										.firestore()
										.collection('users')
										.doc(data.user.uid)
										.then(data => { 

											const userData = {
												uid: data.user.uid,
												user: {
												id: data.user.uid,
												...query.data(),
												},
											};
											dispatch({
												type: LOGIN,
												payload: userData,
											});


											dispatch(signupLoader(false));
											onSuccess(data.uid, data.user);
										})
								})
								.catch(err => {
									dispatch(signupLoader(false));
									alert(err, 'danger');
								});
						}
					});
			})
			.catch(err => {
				dispatch(signupLoader(false));
				alert(err, 'danger');
			});
	};



	*/
/*
export const AuthWithFacebook =
	(onSuccess = () => {}) =>
	async dispatch => {
		const provider = new firebase.auth.FacebookAuthProvider();
		await firebase
			.auth()
			.signInWithPopup(provider)
			.then(data => {
				var credential = data.credential;
				var accessToken = credential.accessToken;

				const [first, ...rest] = data.user.displayName.split(' ');
				onSuccess();
				firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.onSnapshot(async query => {
						if (query.data()) {
							// console.log('login');
							// if (query.data().isDeleted) {
							// 	alert(
							// 		'Your account data have been deleted from database. But the good news is, your account is still alive. Please contact your admin to create a new slot for you to store data then you will be able to login.'
							// 	);
							// } else {
							// alert('Successfully logged in');
							dispatch(LogInLoader(true));
							dispatch({
								type: LOGIN,
								payload: {
									uid: data.user.uid,
									user: {
										id: data.user.uid,
										...query.data(),
									},
								},
							});
							dispatch(LogInLoader(false));
							// }
						} else {
							dispatch(signupLoader(true));
							await firebase
								.firestore()
								.collection('users')
								.doc(data.user.uid)
								.set({
									firstName: first,
									lastName: rest.join(''),
									email: data.user.email,
									createdAt:
										firebase.firestore.Timestamp.now(),
									dob: '',
									gender: '',
									phone: data.user.phoneNumber,
									platform: 'facebook',
									profilePictureURL: data.user.photoURL,
									role: 'customer',
									age: '',
									name: data.user.displayName,
									city: '',
									country: '',
									youtubeURL: '',
									detailsAboutMe: finalStats(),
									detailsAboutMyPreferences: finalStats(),
									personalityDetails: PersonalityDetails(),
									mySkinToneDetails: mySkinToneDetails,
									mySkinTonePreferences:
										mySkinTonePreferences,
								})
								.then(async res => {
									await firebase
										.firestore()
										.collection('users')
										.doc(data.user.uid);

									dispatch(signupLoader(false));
									// onSuccess("pass");
								})
								.catch(err => {
									dispatch(signupLoader(false));
									alert(err, 'danger');
								});
						}
					});
			})
			.catch(err => {
				dispatch(signupLoader(false));
				alert(err, 'danger');
			});
	};
*/

export const AuthWithGoogle =
	(onSuccess = () => {}) =>
	async dispatch => {
		const provider = new firebase.auth.GoogleAuthProvider();
		await firebase
			.auth()
			.signInWithPopup(provider)
			.then(async data => {
				const [first, ...rest] = data.user.displayName.split(' ');
				onSuccess();
				unsubscribe = firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.onSnapshot(async query => {
						if (query.data()) {
							const userData = {
								uid: data.user.uid,
								user: {
								  id: data.user.uid,
								  ...query.data(),
								},
							};
							dispatch({
								type: LOGIN,
								payload: userData,
							});
							onSuccess(userData.uid, userData.user);
						} else {
							firebase
								.firestore()
								.collection('users')
								.doc(data.user.uid)
								.set({
									firstName: first,
									lastName: rest.join(''),
									nickName: '',
									email: data.user.email,
									referredBy: checkReferrerLocalStorage(),
									createdAt:
										firebase.firestore.Timestamp.now(),
									profilePictureURL: data.user.photoURL,
									type: 'google',
									age: '',
									name: '',
									city: '',
									country: '',
									youtubeURL: '',
									tikTokURL: '',
									detailsAboutMe: finalStats(),
									detailsAboutMyPreferences: finalStats(),
									personalityDetails: PersonalityDetails(),
									mySkinToneDetails: mySkinToneDetails,
									mySkinTonePreferences:
										mySkinTonePreferences,
								})
								.then(res => {
									firebase
										.firestore()
										.collection('users')
										.doc(data.user.uid);

										const userData = {
											uid: data.user.uid,
											user: {
											  id: data.user.uid,
											  ...query.data(),
											},
										};
										dispatch({
											type: LOGIN,
											payload: userData,
										});

								})
								.catch(err => {
									alert(err, 'danger');
								});
						}
					});
			})
			.catch(err => {
				alert(err, 'danger');
			});
	};
// src/store/actions/authActions.js
export const updateProfile = (uid, otherPayload) => async dispatch => {
	const {
		detailsAboutMe,
		personalityDetail,
		detailsAboutMyPreferences,
		basicInfo,
		mySkinToneDetails,
		mySkinTonePreferences,
	} = otherPayload;

	const userRef = firebase.firestore().collection('users').doc(uid);
	try {
		await userRef
			.update({
				name: basicInfo?.name,
				firstName: basicInfo?.firstName,
				lastName: basicInfo?.lastName,
				nickName: basicInfo?.nickName,
				age: basicInfo?.age,
				referredBy: checkReferrerLocalStorage(),
				city: basicInfo?.city,
				youtubeURL: basicInfo?.youtubeURL,
				tikTokURL: basicInfo?.tikTokURL,
				country: basicInfo?.country,
				mySkinToneDetails: mySkinToneDetails,
				mySkinTonePreferences: mySkinTonePreferences,
				detailsAboutMyPreferences: detailsAboutMyPreferences,
				personalityDetails: personalityDetail,
				detailsAboutMe: detailsAboutMe,
			})
			.then(() => {
				alert('Profile Updated Successfully');
			});

		const checkObj = localStorage.getItem('auth');
		const authUser = JSON.parse(
			CryptoJS.AES.decrypt(checkObj, 'my-secret-key').toString(
				CryptoJS.enc.Utf8
			)
		);
		let newObj = {
			...authUser.user,
			name: basicInfo.name,
			firstName: basicInfo?.firstName,
			lastName: basicInfo?.lastName,
			nickName: basicInfo?.nickName,
			age: basicInfo?.age,
			city: basicInfo?.city,
			referredBy: checkReferrerLocalStorage(),
			youtubeURL: basicInfo?.youtubeURL,
			tikTokURL: basicInfo?.tikTokURL,
			country: basicInfo?.country,
			detailsAboutMe: detailsAboutMe,
			personalityDetails: personalityDetail,
			detailsAboutMyPreferences: detailsAboutMyPreferences,
			mySkinTonePreferences: mySkinTonePreferences,
			mySkinToneDetails: mySkinToneDetails,
		};

		let finalObj = { ...authUser, user: newObj };

		const finalData = CryptoJS.AES.encrypt(
			JSON.stringify(finalObj),
			'my-secret-key'
		).toString();
		localStorage.setItem('auth', finalData);

		// alert("updated");
	} catch (error) {
		console.error(error);
		alert('An error occurred while updating the profile');
	}
};

export const reRegisterSnapShot =
	(id, history, onSuccess) => async dispatch => {
		unsubscribe = firebase
			.firestore()
			.collection('users')
			.doc(id)
			.onSnapshot(query => {
				if (query && query.data()) {
					onSuccess();
					// history.push('/admin/user-profile');
					dispatch(LogInLoader(false));
					dispatch({
						type: LOGIN,
						payload: {
							uid: id,
							user: {
								id: id,
								...query.data(),
							},
						},
					});
				} else {
					console.error('unexpected condition .onSnapshot(query is falsey');
				}
			});
	};
