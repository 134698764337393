const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const v = urlParams.get('v');
localStorage.setItem('queryParam', v);

let MobileSliderImg1, MobileSliderImg2, MobileSliderImg3, MobileSliderImg4, MobileSliderImg5, MobileSliderImg6,  MobileSliderImg7, MobileSliderBg;

switch (v) {
  case '1': // hindi couple
    MobileSliderImg1 = await import("../assets/images/hindi-couple1.png").then(module => module.default);
    MobileSliderImg2 = await import("../assets/images/hindi-couple2.png").then(module => module.default);
    MobileSliderImg3 = await import("../assets/images/hindi-couple3.png").then(module => module.default);
    MobileSliderImg4 = await import("../assets/images/hindi-couple4.png").then(module => module.default);
    MobileSliderImg5 = await import("../assets/images/hindi-couple5.png").then(module => module.default);
    MobileSliderImg6 = await import("../assets/images/hindi-couple6.png").then(module => module.default);
    MobileSliderBg   = await import("../assets/images/hindi-couple7.png").then(module => module.default);
    MobileSliderImg7 = await import("../assets/images/hindi-couple8.png").then(module => module.default);
    break;

  case '2': // pakistani couple
    MobileSliderImg1 = await import("../assets/images/pakistani-couple1.png").then(module => module.default);
    MobileSliderImg2 = await import("../assets/images/pakistani-couple2.png").then(module => module.default);
    MobileSliderImg3 = await import("../assets/images/pakistani-couple3.png").then(module => module.default);
    MobileSliderImg4 = await import("../assets/images/pakistani-couple4.png").then(module => module.default);
    MobileSliderImg5 = await import("../assets/images/pakistani-couple5.png").then(module => module.default);
    MobileSliderImg6 = await import("../assets/images/pakistani-couple6.png").then(module => module.default);
    MobileSliderBg   = await import("../assets/images/pakistani-couple7.png").then(module => module.default);
    MobileSliderImg7 = await import("../assets/images/pakistani-couple8.png").then(module => module.default);
    break;

  case '3': // diverse couple
    MobileSliderImg1 = await import("../assets/images/diverse-couple1.png").then(module => module.default);
    MobileSliderImg2 = await import("../assets/images/diverse-couple2.png").then(module => module.default);
    MobileSliderImg3 = await import("../assets/images/diverse-couple3.png").then(module => module.default);
    MobileSliderImg4 = await import("../assets/images/diverse-couple4.png").then(module => module.default);
    MobileSliderImg5 = await import("../assets/images/diverse-couple5.png").then(module => module.default);
    MobileSliderImg6 = await import("../assets/images/diverse-couple6.png").then(module => module.default);
    MobileSliderBg   = await import("../assets/images/diverse-couple7.png").then(module => module.default);
    MobileSliderImg7 = await import("../assets/images/diverse-couple8.png").then(module => module.default);
    break;

  case '4': // blk
    MobileSliderImg1 = await import("../assets/images/onboarding1.png").then(module => module.default);
    MobileSliderImg2 = await import("../assets/images/onboarding2.png").then(module => module.default);
    MobileSliderImg3 = await import("../assets/images/onboarding3.png").then(module => module.default);
    MobileSliderImg4 = await import("../assets/images/onboarding4.png").then(module => module.default);
    MobileSliderImg5 = await import("../assets/images/onboarding5.png").then(module => module.default);
    MobileSliderImg6 = await import("../assets/images/onboarding6.png").then(module => module.default);
    MobileSliderBg   = await import("../assets/images/onboarding7.png").then(module => module.default);
    MobileSliderImg7 = await import("../assets/images/onboarding8.png").then(module => module.default);
    break;

  default: // default images
    MobileSliderImg1 = await import("../assets/images/onboarding1.png").then(module => module.default);
    MobileSliderImg2 = await import("../assets/images/onboarding2.png").then(module => module.default);
    MobileSliderImg3 = await import("../assets/images/onboarding3.png").then(module => module.default);
    MobileSliderImg4 = await import("../assets/images/onboarding4.png").then(module => module.default);
    MobileSliderImg5 = await import("../assets/images/onboarding5.png").then(module => module.default);
    MobileSliderImg6 = await import("../assets/images/onboarding6.png").then(module => module.default);
    MobileSliderBg   = await import("../assets/images/onboarding7.png").then(module => module.default);
    MobileSliderImg7 = await import("../assets/images/onboarding8.png").then(module => module.default);
    break;
}

export {
  MobileSliderImg1,
  MobileSliderImg2,
  MobileSliderImg3,
  MobileSliderImg4,
  MobileSliderImg5,
  MobileSliderImg6,
  MobileSliderImg7,
  MobileSliderBg
};
