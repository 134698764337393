import gmail from "../../assets/img/icons/common/gmail.png";
import google from "../../assets/img/icons/common/google.png";
import phone from "../../assets/img/icons/common/telephone.png";
import twitter from "../../assets/img/icons/common/twitter.png";

// react plugin used to create charts

// reactstrap components
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; 
import {
    CardBody,
    Col,
    Row
} from "reactstrap";

import SignInEmailModal from "components/Modal/SignInEmailModal";
import SignInPhoneModal from "components/Modal/SignInPhoneModal";
import { useDispatch, useSelector } from "react-redux";
import {  AuthWithGoogle, AuthWithTwitter } from "store/actions/authActions";
//  most recently commented out facebook code.
// import facebook from "../../assets/img/icons/facebook-icon.png";
// import {  AuthWithFacebook } from "store/actions/authActions";


const LoginPanel = (props) => {
    const { userFriendlyMsg, loading, loginLoading } = useSelector(state => state.auth);
    const { uid, user } = useSelector((state) => state.authUser);
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);

    const toggle = () => {
      setModal(!modal);
    };
    const phoneToggle = () => {
      setPhoneModal(!phoneModal);
    };

    const [phoneDiv, setPhoneDiv] = useState(false);

    const phoneDivToggle = () => {
      setPhoneDiv(!phoneDiv);
    };

    const dispatch = useDispatch();

    // useEffect(() => {
    //   setTimeout(() => {
    //     if (uid) {
    //       history.push("/admin/user-profile");
    //     }
    //   }, 500);
    // }, [uid]);

    return (
      <div className="container mt-50">
          <Row className="justify-content-center py-2">
            <Col
              md="7"
              className="cursor-pointer login__buttonShadow bg-site-white py-3 px-3"
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={google}
                    alt=""
                    className="img-fluid login__img mr-3 cursor-pointer"
                  />
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        AuthWithGoogle((uid, user) => {
                          
                           if (uid && user?.onBoardingData) {
                             history.push("/admin/user-profile");
                           } else if (uid && !user?.onBoardingData) {
                             history.push("/admin/create-profile");
                           } 
                        })
                      );
                    }}
                  >
                    Sign in with Google
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center py-2 ">
            <Col
              md="7"
              className="cursor-pointer login__buttonShadow bg-site-twitter py-3 px-3 text-white "
            >
              <div
                className="d-flex align-items-center "
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    AuthWithTwitter((uid, user) => {
                     //  console.log('uid and user =', uid, user);
                      if (uid && user) {
                        if (user?.onBoardingData) {
                          history.push("/admin/user-profile");
                        } else if (uid && !user?.onBoardingData) {
                          history.push("/admin/create-profile");
                        }
                      }
                    })
                  );
                }}
              >
                <div>
                  <img
                    src={twitter}
                    alt=""
                    className="img-fluid login__img mr-3"
                  />
                  <span>Sign in with Twitter</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center py-2 ">
            <Col
              md="7"
              className="cursor-pointer login__buttonShadow bg-site-red py-3 px-3 text-white"
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  toggle();
                  // dispatch(
                  //   AuthWithTwitter(() => {
                  //     history.push("/admin/user-profile");
                  //   })
                  // );
                }}
              >
                <div>
                  <img
                    src={gmail}
                    alt=""
                    className="img-fluid login__img mr-3"
                  />
                  <span>Sign in with Email</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center py-2">
            <Col
              md="7"
              className="cursor-pointer login__buttonShadow bg-site-green py-3 px-3 text-white"
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  phoneDivToggle();
                }}
              >
                <div>
                  <img
                    src={phone}
                    alt="smartphone icon"
                    className="img-fluid login__img mr-3"
                  />
                  <span>Sign in with Phone</span>
                </div>
              </div>
            </Col>
          </Row>
          {/*  
          most recently commented out facebook code.

          <Row className="justify-content-center py-2">
            <Col
              md="7"
              className="cursor-pointer login__buttonShadow bg-site-primary py-3 px-3 text-white"
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    AuthWithFacebook((uid, user) => {
                       if (uid && user?.onBoardingData) {
                         history.push("/admin/user-profile");
                       } else if (uid && !user?.onBoardingData) {
                         history.push("/admin/create-profile");
                       } 
                      
                    })
                  );
                }}
              >
                <div>
                  <img
                    src={facebook}
                    alt=""
                    className="img-fluid login__img mr-3"
                  />
                  <span>Sign in with Facebook</span>
                </div>
              </div>
            </Col>
          </Row> */} 
        

        <SignInEmailModal
          modal={modal}
          toggle={toggle}
          btntext="Save"
          title="Sign in With Email"
        />
        {phoneDiv && <SignInPhoneModal />}
      </div>
    );
}
export default LoginPanel;