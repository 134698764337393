import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DeletionPolicyContent from "./DeletionPolicyContent";
import Header from "./Headers/Header";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

const PrivacyPolicy = () => {
  // const [activeIndex, setActiveIndex] = useState({
  //   index: 0,
  //   data: "Data Deletion Policy",
  // });
  // const [menuData, setMenuData] = useState("");

  // const handleClick = (index, item) => {
  //   setActiveIndex({
  //     index: index,
  //     data: item,
  //   });
  // };
  // const menu = [
  //   {
  //     menu: "Data Deletion Policy",
  //   },
  //   {
  //     menu: "Privacy Policy",
  //   },
  // ];
  return (
    <>
      <Header />
      <Container className="mt--7 privacy-policy" fluid>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between">
                    <div className="d-flex ">
                      <h3 className="page-heading">Privacy Policy</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row className="">
                  {/* <Col xl={3} className="privacy-policy__sidebar pt-5">
                    <ul>
                      {menu.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className={
                              activeIndex.index === index ? "active" : ""
                            }
                            onClick={() => handleClick(index, item.menu)}
                          >
                            {item.menu}
                          </li>
                        );
                      })}
                    </ul>
                  </Col> */}
                  <Col className="privacy-policy__right">
                    <PrivacyPolicyContent />
                  </Col>
                </Row>
                <hr />
                <p>
                  Generated using{" "}
                  <Link>TermsFeed Privacy Policy Generator</Link>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
